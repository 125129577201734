import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { MDBDataTable } from "mdbreact";
import { rdvsByUserMailUrl } from "../../Url";

import "@fortawesome/fontawesome-free/css/all.min.css";
import "mdbreact/dist/css/mdb.css";
import '../../style/sesame/index.css';
import '../../style/sesame/listAndTable.css';
import '../../style/sesame/sesame.css';

const DatatableDemandes = () => {
  const navigate = useNavigate();
  let storedToken = window.localStorage.getItem("token");
  const location = useLocation();
  console.log("location", location.pathname)
  const [load, setLoad]= useState(true); //Set for loading state while axios is retrieving data from display
  const handleDetailClick = (email) => {
    navigate(`/dashboard/details-demande/${email}`);
  };

  const [usersGlobal, setUsersGlobal] = useState([]); //Global retrieve of all procedures of users
  const [users, setUsers] = useState([]); //This is the main User we gonna use for toggling if pathname is historique-demandes or not

  const user = JSON.parse(window?.localStorage.getItem("userData") ?? "{}");

  useEffect(() => {

    if (!user?.id) {
      navigate('/login')
    }
  }, [])

  useEffect(() => {
    console.log("email", user.email)
    axios
      .post(`${rdvsByUserMailUrl}/${500}`, { email: user?.email }, {
        headers: {
          Authorization: `Bearer ${storedToken}`
        }
      })
      .then((response) => {
        console.log("axios post for rdv usere mail", response.data.data);
        setUsersGlobal(response.data.data)
        setLoad(false)
      })
      .catch((err) => {
        console.log(err);
        setLoad(false)
      });
  }, [])


  //Remove rdv who already pays
  useEffect(() => {
    if (location.pathname === "/dashboard/list-des-nouvelles-demandes") {
      setUsers(usersGlobal.filter(
        element => element.pay_status != "1" && element.rdv_status == null
      ))

      console.log("pathname list", users)
    }

    if (location.pathname === "/dashboard/historique-demandes") {
      setUsers(usersGlobal.filter(
        element => element.rdv_status != null 
      ))

      console.log("pathname historique", users)
    }

    if (location.pathname === "/dashboard/demandes-info-attente") {
      setUsers(usersGlobal.filter(
        element => element.rdv_status == "PENDING"
      ))

      console.log("pathname en attente", users)
    }

    if (location.pathname === "/dashboard/demandes-refus") {
      setUsers(usersGlobal.filter(
        element => element.rdv_status == "REFUSED"
      ))

      console.log("pathname REFUSED", users)
    }

    if (location.pathname === "/dashboard/demandes-valides") {
      setUsers(usersGlobal.filter(
        element => element.rdv_status == "VALIDATED" && element.pay_status != "1"
      ))

      console.log("pathname demande validés", users)
    }

    if (location.pathname === "/dashboard/demandes-final") {
      setUsers(usersGlobal.filter(
        element => element.rdv_status == "VALIDATED" && element.pay_status == "1"
      ))

      console.log("pathname demande finalisés", users)
    }
  }, [usersGlobal, location.pathname])




  const datas = {
    columns: [
      {
        label: "Nom",
        field: "firstname",
        sort: "asc",
        width: 150,
      },
      {
        label: "Prenom",
        field: "lastname",
        sort: "asc",
        width: 270,
      },
      {
        label: "Téléphone",
        field: "phone",
        sort: "asc",
        width: 270,
      },
      {
        label: "Email",
        field: "email",
        sort: "asc",
        width: 200,
      },
      {
        label: "ID de la demande",
        field: "numero",
        sort: "asc",
        width: 200,
      },
      {
        label: "Type",
        field: "type",
        sort: "asc",
        width: 150,
      },
      {
        label: "Actions",
        field: "actions",
        width: 100,
      },
    ],
    rows: [
      /*{
        firstname: "Consulat",
        lastname: "Admin",
        phone: "+2250555475465",
        email: "admin-fr@gmail.com",
        numero: "6534984337",
        id: "01",
        type: "Information"
      },
      {
        firstname: "Consulat",
        lastname: "Root",
        phone: "+2250555475465",
        email: "root-fr@gmail.com",
        numero: "6534984337",
        id: "02",
        type: "Formation"
      }*/
    ],
  };

  const [data, setData] = useState(datas);

  useEffect(() => {
    if (users?.length > 0) {

      const newData = [];
      for (const user of users) {
        newData.push({
          firstname: user.firstname,
          lastname: user.lastname,
          phone: user.phone,
          email: user.email,
          numero: user.request_invoice,
          id: user.id,
          type: user.type
        })
      }
      console.log(data)
      setData({ columns: data.columns, rows: newData })
    }
  }, [users])

  const rows = data.rows.map((rowData, index) => {
    return {
      ...rowData,
      actions: (
        <button
          className="btn-details px-3 py-1 shadow d-flex justify-content-center"
          onClick={() => handleDetailClick(rowData.numero)}
          disabled={rowData.numero ? false : true}
        >
          Detail
        </button>
      ),
      id: index,
    };
  });

  return (
    <>
      {users.length > 0 && 
      (<>
        <div className="scroll-x">
          <MDBDataTable striped bordered small data={{ ...data, rows }} />
        </div>
      </>)}


      {users.length < 1 && 
      <div>
        {load === true ? "Chargement..." : "Aucune procédure enregistrée par ici."}
      </div>}
    </>
  );
};

export default DatatableDemandes;