import React, { useEffect, useState } from 'react';
import VerificationPaymentDeclaration from '../Declaration/VerificationPaymentDeclaration';
import axios from "axios";
import { completeDeclaration, rdvByIdUlr, registerUrl, updatePayStatus, updateRdv } from "../Url";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";
import { TextField } from "@mui/material";
import { Alert } from "react-bootstrap";
import ChoicePayment from '../Declaration/ChoicePayment';
import InfoPayment from '../Declaration/InfoPayment';
import { checkDeclarationById } from '../Url';
import Spinner from "../gifs/Spinner.gif"
import ImgSuccess from "../imgs/checked.png"

const PaymentDeclarationPage = () => {
  const location = useLocation();

  const { request_receipt } = useParams();

  const [showOrangeMoney, setShowOrangeMoney] = useState(false)
  const [otp, setOtp] = useState("")
  const [alert, setAlert] = useState("none")
  const [alertWave, setAlertWave] = useState("none")
  const [errorOfAxios, setErrorOfAxios] = useState("")
  const [timeoutDiscount, setTimeoutDiscount] = useState(false)

  const [loadingTransactions, setLoadingTransactions] = useState(false) //Toggle display of loader
  const [showAlertDanger, setShowAlertDanger] = useState(false) //Show Modal for inscription Validé
  const [showAlertSuccess, setShowAlertSuccess] = useState(false) //Show Modal for Inscription Réfusée
  const [showAlertSuccessWave, setShowAlertSuccessWave] = useState(false) //Show Modal for Wave

  const [userInformations, setUserInformations] = useState("");

  const [stepper, setStepper] = useState(1);

  const [choicePayment, setChoicePayment] = useState({});
  const [verificationPaymentDeclaration, setVerificationPaymentDeclaration] = useState({}); //Paiement

  const [loading, setLoading] = useState(false);
  const user = JSON.parse(window?.localStorage.getItem("userData") ?? "{}");
  const navigate = useNavigate();

  const [choicePaymentValid, setChoicePaymentValid] = useState(false)
  const [verificationPaymentDeclarationValid, setVerificationPaymentDeclarationValidValid] = useState(false)

  const [submit, setSubmit] = useState(false);

  //Check on DB if user already pays so we will block his path to pay again
  useEffect(() => {
    const checkDeclarationByIdUlr = async () => {
      await axios.get(`${checkDeclarationById}/${request_receipt}`)
        .then(response => {
          console.log('response',response)
          if (response.data.status_payment === 'PAID' && response.data.declaration_status !== 'NEW') {
            navigate("/errors");
          }
        })
        .catch(err => {console.log('error axios getRdvByIdUlr', err); navigate('/errors')})
    }

    checkDeclarationByIdUlr();
  }, [request_receipt])


  const handleCloseModalOrangeMoney = () => {
    setShowOrangeMoney(false);
  }

  const handleShowModalOrangeMoney = () => {
    setShowOrangeMoney(true);
  }

  const handleCloseModalForLoadingTransactions = () => {
    setLoadingTransactions(false);
  }

  const handleCloseModalForAlertDanger = () => {
    setShowAlertDanger(false);
    setSubmit(false)
  }

  const handleCloseModalForAlertSuccess = () => {
    setShowAlertSuccess(false);
  }

  const handleCloseModalForAlertSuccessWave = () => {
    setShowAlertSuccessWave(false);
  }

  const handleNext = () => {
    if (stepper >= 1 && stepper < 3) {
      setStepper(stepper + 1);
    }
  };
  const handlePrevious = () => {
    if (stepper >= 2 && stepper <= 3) {
      setStepper(stepper - 1);
    }
  };

  useEffect(() => {
    if (user && user?.id) {
      window.localStorage.clear();
      //navigate("/");
    }
  }, []);

  function choicePaymentData(data) {
    setChoicePayment(data);
    setChoicePaymentValid(data.isValid)
    // console.log(stepper, step1Valid,
    //   step2Valid, step3Valid, step4Valid,
    //   choicePaymentValid, submit)
  }

  function VerificationPaymentDeclarationData(data) {
    setVerificationPaymentDeclaration(data);
    verificationPaymentDeclarationValid(data.isValid)

  }


  const onChangeTextField = (event) => {
    console.log("otp", otp)
    setOtp(event.target.value)
    setChoicePayment({ ...choicePayment, otp: otp })
  }

  useEffect(() => {
    setChoicePayment({ ...choicePayment, otp: otp })
  }, [otp])

  useEffect(() => {
    setAlertWave(alertWave)
  }, [alertWave])

  //Check if the user can proceed to payment
  useEffect(() => {
    const getUserInfo = async () => {
      await axios.get(`${checkDeclarationById}/${request_receipt}`)
        .then(response => { setUserInformations(response.data); console.log('response axios userInfo', response) })
        .catch(err => console.log('error axios userInfo', err))
    }

    getUserInfo()
  }, [request_receipt])

  // useEffect(() => {
  //   const verifyPayStatus = async () =>
  // })


  function handleSubmitForMobileMoney() {
    setLoadingTransactions(true)
    setSubmit(true)

    if (showOrangeMoney) {
      handleCloseModalOrangeMoney() //Close Modal if canal is Orange Money
    }

    let data = {
      ...userInformations,
      ...choicePayment,
      declaration_status: "COMPLETED",
      status_payment: "PAID",
    };
    console.log("data MobileMoney", data)

    axios.all([
      axios.post(`${process.env.REACT_APP_API_PAYMENT}`, {
        "beneficiaire": data.phonePayment,
        "beneficiaireOmnivox": null,
        "idClientCarte": null,
        "numeroCarte": null,
        "montant": data.amount_payment,
        "devise": "XOF",
        "canal": data.gateway_payment,
        "type": "Retrait",
        "otp": data.otp,
        "description": "Paiement demarche Etat Civil",
        "submit": null
      }, {
        headers: {
          'Authorization': `Bearer ${process.env.REACT_APP_WALLETID}`
        }
      }).then((response) => {
        setLoadingTransactions(false);
        window.localStorage.setItem("recu", JSON.stringify(response.data));
        setAlert("sucess"); setShowAlertSuccess(true); setTimeout(() => { window.location.reload() }, 7000)


        //Lauch update automatically if payment is ok
        axios.put(`${completeDeclaration}/${request_receipt}`, data)
          .then((response) => { console.log(response); console.log("update is OK"); })
          .catch((error) => { console.log(error) })
      })
        .catch((error) => { setLoadingTransactions(false); console.log(error); setAlert("danger"); setSubmit(false); setShowAlertDanger(true); setErrorOfAxios(error.response.data) }),
    ])
  }

  function handleSubmitForWave() {
    setLoadingTransactions(true)
    setSubmit(true)
    console.log("choicePayment", choicePayment)

    let data = {
      ...userInformations,
      ...choicePayment,
      declaration_status: "COMPLETED",
      status_payment: "PAID",
    };

    console.log("data Wave", data)

    axios.all([
      axios.post(`${process.env.REACT_APP_API_PAYMENT}`, {
        "beneficiaire": data.phonePayment,
        "beneficiaireOmnivox": null,
        "idClientCarte": null,
        "numeroCarte": null,
        "montant": data.amount_payment,
        "devise": "XOF",
        "canal": "Wave",
        "type": "Retrait",
        "otp": "",
        "description": "Paiment Demarche Etat Civil",
        "submit": null
      }, {
        headers: {
          'Authorization': `Bearer ${process.env.REACT_APP_WALLETID}`,
          'Content-Type': 'application/json',
          'Accept': '*/*'
        }
      }).then((response) => {
        setAlert("pending");
        console.log("response Wave", response.data);
        setAlertWave(response.data.paymentUrl);
        window.localStorage.setItem("recu", JSON.stringify(response.data));


        //Lauch update automatically if payment is ok
        axios.put(`${completeDeclaration}/${request_receipt}`, data)
          .then((response) => { console.log(response); console.log("update is OK"); })
          .catch((error) => { console.log(error) })

      })
        .catch((error) => { setLoadingTransactions(false); console.log("paiment isnt OK"); setAlert("danger"); setShowAlertDanger(true); setSubmit(false); console.log(error); }),
    ])
  }

  function handleSubmitForEspece() {
    setLoadingTransactions(true)
    setSubmit(true)

    let data = {
      ...userInformations,
      ...choicePayment,
      declaration_status: "COMPLETED",
      status_payment: "PAID",
    };

    console.log("data Espece", data)

    axios.put(`${completeDeclaration}/${request_receipt}`, data)
      .then((response) => {
        console.log(response);
        console.log("update is OK");
        setLoadingTransactions(false);
        setAlert("sucess"); setShowAlertSuccess(true);; setTimeout(() => { window.location.reload() }, 7000)
      })

      .catch((error) => {
        console.log(error);
        setAlert("danger"); setSubmit(false);
        setShowAlertDanger(true); setErrorOfAxios(error.response.data)
      })
  }
  return (
    <div>
      {/* Loading for Payment and Register */}
      {
        loadingTransactions ? (
          <Modal show={loadingTransactions} onHide={handleCloseModalForLoadingTransactions} backdrop="static" keyboard={false} size="lg"
            centered>
            <Modal.Header className="">
              <Modal.Title className="">
                <div className="d-flex align-items-center text-center notification-containgImg-text">
                  Veuillez patienter le temps qu'on actionne la procédure de paiement. Cela peut prendre 1 - 2 minutes. (Voir plus si vous ne detenez pas de connexion stable).
                </div>
                <div className="notification-containgImg-spinner text-center">
                  <img src={Spinner} className="notification-containImg-spinner-img" />
                </div>
              </Modal.Title>
            </Modal.Header>
          </Modal>
        ) : ""
      }


      {/* Alert For Mobile Money */}
      {
        alert === "sucess" ? (
          <Modal show={showAlertSuccess} onHide={handleCloseModalForAlertSuccess} backdrop="static" keyboard={false} size="lg"
            centered>
            <Modal.Header className="bg-success-subtle border-bottom border-success">
              <Modal.Title className="w-100">
                <div className="d-flex w-100 justify-content-center text-success">
                  Paiement validé
                </div>
              </Modal.Title>
            </Modal.Header>

            <Modal.Body className="bg-success-subtle">
              <div className="d-flex w-100 justify-content-center">
                Suivez vos mails ou vos SMS pour plus d'informations
              </div>
              <div className="d-flex w-100 justify-content-center">
                Redirection vers la page dans 7 secondes
              </div>
              <div className="notification-containgImg-spinner text-center">
                <img src={ImgSuccess} className="notification-containImg-spinner-img" />
              </div>
            </Modal.Body>
          </Modal>
        )

          :

          alert === "danger" ?
            (
              <Modal show={showAlertDanger} onHide={handleCloseModalForAlertDanger} backdrop="static" keyboard={false} size="lg"
                centered>
                <Modal.Header className="bg-danger-subtle text-danger border-bottom border-danger" closeButton>
                  <Modal.Title className="w-100">
                    <div className="d-flex w-100 justify-content-center notification-containgImg-text">
                      Paiement réfusé
                    </div>
                  </Modal.Title>
                </Modal.Header>

                <Modal.Body className="bg-danger-subtle">
                  <p>Il se peut que :</p>
                  <div className="notification-containgTextError">
                    <ul>
                      <li>---Les champs pour la procédure soient mal renseignés. Vérifiez et réessayez</li>
                      <li>---Votre connexion WiFi ou donnée mobile ait un problème. Vérifiez votre connexion et réessayez</li>
                      <li>---Le serveur du RDV-ÉtatCivil soit saturé. Patientez quelques minutes et réessayez</li>
                    </ul>
                  </div>
                </Modal.Body>

              </Modal>
            )

            :

            ""
      }

      {/* Alert For Wave */}
      {
        alert === "pending" ? (
          <Modal show={showAlertSuccessWave} onHide={handleCloseModalForAlertSuccessWave} backdrop="static" keyboard={false} size="lg"
            centered>
            <Modal.Header className="bg-info-subtle border-bottom border-info">
              <Modal.Title className="w-100">
                <div className="d-flex w-100 justify-content-center text-info">
                  Paiement presque validé avec Wave Money
                </div>
              </Modal.Title>
            </Modal.Header>

            <Modal.Body className="bg-info-subtle">
              <div className="d-flex w-100 justify-content-center">
                Cliquez sur ce lien pour finaliser votre paiement Wave :
              </div>

              <div className="d-flex w-100 justify-content-center">
                <button role="button"
                  className="link-opacity-100-hover btn btn-info"
                  onClick={() => {
                    window.open(alertWave, "_blank"); setTimeoutDiscount(true);
                    setTimeout(() => { navigate('/') }, 60000)
                  }}
                  target="_blank">
                  {alertWave}
                </button>
              </div>

              {
                timeoutDiscount && (
                  <>
                    <div className="d-flex w-100 justify-content-center">
                      redirection vers la page d'accueil dans X secondes
                    </div>

                    <div className="notification-containgImg-spinner text-center">
                      <img src={ImgSuccess} className="notification-containImg-spinner-img" />
                    </div>
                  </>
                )
              }
            </Modal.Body>

          </Modal>
        )

          :

          ""
      }
      <div className="my-5 stepperContainer d-flex justify-content-center align-items-center">
        {/* Modal for OTP if choicePaymrnt.canal === Orange Money */}
        {
          choicePayment.gateway_payment === "Orange Money"
            ?
            <Modal show={showOrangeMoney} onHide={handleCloseModalOrangeMoney}>
              <Modal.Header closeButton>
                <Modal.Title>Vu que vous avez sélectionner Orange Money, entrez sur votre téléphone #144*82#, suivez la procédure. Vous recevrez par SMS, un code important à 4 chiffres que vous entrerez dans le champ ci-dessous</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <TextField className="w-100" value={otp} onChange={onChangeTextField} label="Entrez le code OTP que vous avez reçu par SMS" variant="outlined" />
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseModalOrangeMoney}>
                  Fermer
                </Button>
                <Button variant="primary" onClick={handleSubmitForMobileMoney}>
                  Valider la transaction
                </Button>
              </Modal.Footer>
            </Modal>

            :

            ""

        }

        <div className="my-2 col-10">
          <div className="steps d-flex gap-3">

            {/* Step 1 */}
            {
              <div
                className={`${stepper == 1 ? "activeStep shadow" : ""
                  } px-sm-4 py-sm-2 step d-flex justify-content-center align-items-center text-center rounded-4 `}
              >
                <span className="step-number">1</span>
                <span className="step-title">Choix pour paiement</span>
              </div>
            }


            {
              <div
                className={`${stepper == 2 ? "activeStep shadow" : ""
                  } px-sm-4 py-sm-2 step d-flex justify-content-center align-items-center text-center rounded-4 `}
              >
                <span className="step-number">2</span>
                <span className="step-title">Informations importantes</span>
              </div>
            }

            {
              <div
                className={`${stepper == 3 ? "activeStep shadow" : ""
                  } px-sm-4 py-sm-2 step d-flex justify-content-center align-items-center text-center rounded-4 `}
              >
                <span className="step-number">3</span>
                <span className="step-title">Vérification & Paiement</span>
              </div>
            }

          </div>

          <div className="bg-white step-box text-start rounded p-4 mt-3">
            {loading && (
              <div className="text-center rendu mt-3">
                Votre demande a été envoyée avec succès. Veuillez vérifier votre boite mail pour plus de détails!
              </div>
            )}
            {!loading && (
              <>
                <div className="rendu mt-3">
                  {stepper == 1 && (
                    <ChoicePayment
                      choicePayment={choicePayment}
                      choicePaymentData={choicePaymentData}
                    />
                  )}
                  {
                    stepper == 2 && (
                      <InfoPayment
                        userInformations={userInformations}
                      />
                    )
                  }
                  {stepper == 3 && (
                    <VerificationPaymentDeclaration
                      verificationPaymentDeclaration={{ ...userInformations, ...choicePayment }}
                    />
                  )}
                </div>

                <div className="my-3 block-btn">
                  {
                    stepper == 1 && choicePaymentValid ? (
                      <div className="text-end align-bottom">
                        <button className="btn-step-next rounded-5" onClick={handleNext}>
                          Suivant
                        </button>
                      </div>
                    ) : stepper > 1 && stepper <= 3 ? (
                      <div className="d-flex justify-content-between">
                        <button className="btn-step-back rounded-5" onClick={handlePrevious}>
                          Retour
                        </button>

                        {stepper === 1 && choicePaymentValid && !submit && (
                          <button className="btn-step-next rounded-5" onClick={handleNext}>
                            Suivant
                          </button>
                        )}

                        {stepper === 2 && (
                          <button className="btn-step-next rounded-5" onClick={handleNext}>
                            Suivant
                          </button>
                        )}


                        {stepper === 3 && choicePaymentValid && !submit &&
                          choicePayment.gateway_payment === "Orange Money" && choicePayment.gateway_payment !== "Wave" &&
                          (
                            <button className="btn-step-next rounded-5" onClick={handleShowModalOrangeMoney}>
                              Valider
                            </button>
                          )}

                        {stepper === 3 && choicePaymentValid && !submit && choicePayment.mode_payment != "Espece" &&
                          choicePayment.gateway_payment !== "Orange Money" && choicePayment.gateway_payment !== "Wave" &&
                          (
                            <button className="btn-step-next rounded-5" onClick={handleSubmitForMobileMoney}>
                              Valider
                            </button>
                          )}

                        {stepper === 3 && choicePaymentValid && !submit && choicePayment.mode_payment != "Espece" &&
                          choicePayment.gateway_payment !== "Orange Money" && choicePayment.gateway_payment === "Wave" &&
                          (
                            <button className="btn-step-next rounded-5" onClick={handleSubmitForWave}>
                              Valider
                            </button>
                          )}

                        {stepper === 3 && choicePaymentValid && !submit && choicePayment.mode_payment == "Espece" &&
                          (
                            <button className="btn-step-next rounded-5" onClick={handleSubmitForEspece}>
                              Payer en espèces maintenant
                            </button>
                          )}
                      </div>
                    ) : (
                      <div className="d-flex justify-content-between">

                      </div>
                    )}
                </div>
              </>
            )}

          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentDeclarationPage;