import React, { useState, useEffect } from 'react';
import { BsFillQuestionCircleFill, } from "react-icons/bs";
import { FileUploader } from "react-drag-drop-files";
import { uploadUrl } from "../Url";
import axios from "axios";
import Spinner from "../gifs/Spinner.gif"

const DocumentDeclaration = ({ documentDeclaration, documentDeclarationData, actualStep }) => {
  const [piece, setPiece] = useState("");
  const [all, setAll] = useState([]);
  const [pieceSrc, setPieceSrc] = useState("");
  const fileTypes = ["JPG", "JPEG", "PNG", "PDF"];
  const [loadingUpload, setLoadingUpload] = useState(false); //toggle loading on upload event

  const [formDocumentDeclaration, setFormDocumentDeclaration] = useState({
    documents: [],
    isValid: false,
    ...documentDeclaration,
  });

  useEffect(() => {
    setAll([...formDocumentDeclaration.documents]);
  }, [actualStep])

  useEffect(() => {
    if (all.length > 0) {
      console.log("all length superior",all)
      setFormDocumentDeclaration({ ...formDocumentDeclaration, isValid: true });
      documentDeclarationData({...formDocumentDeclaration, isValid: true});
    } else {
      console.log("all length inferior",all)
      setFormDocumentDeclaration({ ...formDocumentDeclaration, isValid: false });
      documentDeclarationData({...formDocumentDeclaration, isValid: false});
    }
  }, [all]);

  async function handleFileSelect(file, type) {
    const name = file?.name;
    const types = file?.type;
    const size = file?.size;

    const formData = new FormData();
    formData.append("file", file);
    console.log(formData);

    axios
      .post(`${uploadUrl}/upload`, formData)
      .then(async (response) => {
        const doc_uri = response.data.replace("uploads/", "");
        setAll([...all, { name, size, type: types, url: doc_uri }]);
        setLoadingUpload(false)
        setFormDocumentDeclaration({
          ...formDocumentDeclaration,
          documents: [...all, { name, size, type: types, url: doc_uri }],
        });
        if (type === "piece") {
          setPieceSrc(doc_uri);
        }
      })
      .catch((error) => {
        console.log(error.message);
      });
  }

  const handleChangeFile = (file, type) => {
    console.log(file);
    setLoadingUpload(true)

    const reader = new FileReader();
    const files = file; // we use here an librarie wich take directly the target of file selected

    if (files && files?.name?.length) {
      reader.onload = async () => { };

      handleFileSelect(files, type);
      reader.readAsDataURL(files);

      if (reader.result !== null) {
        if (type === "piece") {
          setPiece(reader.result);
        }
      }
    }
  };

  return (
    <>
      <div>
        <div className="d-flex justify-content-center align-items-center py-5 p-md-4 gap-md-5 flex-wrap">
          <div className="col-12 col-md-5">
            <form className="col-12">
              <div className="">
                <p className="fs-small py-0 my-0">
                  * Veuillez joindre les documents demandés
                </p>
              </div>
              <div className="">
                <FileUploader
                  handleChange={handleChangeFile}
                  name="file"
                  types={fileTypes}
                  hoverTitle="Veuillez sélectionner un fichier"
                  classes="zone-drop col-1"
                />
              </div>

              <p className="text-danger fs-small">
                * Veuillez télécharger les documents ci-dessous :<br />
                - Certificat de naissance enfant <br />
                - Pièce d'identité Recto et Verso de la mère<br />
                - Pièce d'identité Recto et Verso du père<br />
                - Formulaire de déclaration signé par le declarant (père ou mère)<br />
              </p>

              <div className="pt-4 py-6">
                {formDocumentDeclaration &&
                  formDocumentDeclaration.documents &&
                  formDocumentDeclaration.documents.length > 0 && <hr />}

                {loadingUpload && (
                  <div className="d-flex justify-content-center mt-2">
                    <div className="d-flex justify-content-center align-items-center p-2 rounded border">
                      <div className="notification-containgImg-spinner">
                        <img src={Spinner} />
                      </div>
                      <div className="text-custom-green">Veuillez patienter pour le téléchargement de votre fichier.</div>
                    </div>
                  </div>
                )}

                {formDocumentDeclaration &&
                  formDocumentDeclaration.documents &&
                  formDocumentDeclaration.documents.length > 0 &&
                  formDocumentDeclaration.documents.map((val, key) => (
                    <div key={key}>
                      <li
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        {val.type == "application/pdf" ? (
                          <span>
                            <i
                              className="fa fa-file-pdf-o"
                              style={{ fontSize: "25px", color: "blue" }}
                            ></i>
                          </span>
                        ) : (
                          <span>
                            <i
                              className="fa fa-file-image-o"
                              style={{ fontSize: "25px", color: "blue" }}
                            ></i>
                          </span>
                        )}

                        <span>{val.name}</span>
                        <span>{val.size}Ko</span>
                      </li>
                      <hr />
                    </div>
                  ))}
              </div>
            </form>
          </div>
          <div className="col-12 col-md-6 border py-2">

            <div className="px-3">

              <BsFillQuestionCircleFill className="text-danger" />{" "}
              <span>Pièces requises pour une déclaration de naissance</span>
              <p className="text-success fst-italic">
                Délai moyen ( ...)
              </p>
              {/* <p className='text-danger'>
                Conditions à remplir pour faire une déclaration de naissance :
              </p>
              <ul className=" list-typeActe ">
                <li>...</li>
                <li>...</li>

              </ul> */}
              <p className='text-danger'>
                Documents à fournir :
              </p>
              <span className='text-danger'>NB : <span className='text-dark'>La déclaration peut être faite avec les pièces d'un seule parent si l'autre n'est pas pas en capacité de fournir les pièces ou si il est décédé</span></span>
              <div>
                <ul className=" list-typeActe ">
                  <li>
                    Le certificat de naissance
                  </li>
                  <li>
                    La copie de la CNI recto et verso du père
                  </li>
                  <li>
                    La copie de la CNI recto et verso de la mère
                  </li>
                  <li>
                    ,Ou de tout autre document justifiant leur identité.
                  </li>
                </ul>
              </div>
            </div>

          </div>
        </div>
      </div>


    </>
  );
};

export default DocumentDeclaration;