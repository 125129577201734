import { Button } from "react-bootstrap";
import { useContext, useEffect } from "react";
import { userInfoToPrintContext } from "../../context/UserDetailToPrint";
import axios from "axios"
import { useNavigate, useParams } from "react-router-dom";
import { checkDeclarationById } from "../../Url";
import generatePDF, { Resolution, Margin } from 'react-to-pdf';
import RealReceiptDeclaration from "./RealReceipt/RealReceiptDeclaration";


const RecuDeclaration = () => {
    const { request_receipt } = useParams();
    const { userInfoToPrint, setUserInfoToPrint } = useContext(userInfoToPrintContext);
    const navigate = useNavigate()

    useEffect(() => {
        const handleGetReceipt = async () => {
            await axios.get(`${checkDeclarationById}/${request_receipt}`)
                .then(response => {
                    setUserInfoToPrint({
                        receipt: response.data.request_receipt,
                        firstname: response.data.firstname,
                        lastname: response.data.lastname,
                        father_firstname: response.data.father_firstname,
                        father_lastname: response.data.father_lastname,
                        mother_firstname: response.data.mother_firstname,
                        mother_lastname: response.data.mother_lastname,
                        father_email: response.data.father_email,
                        mother_email: response.data.mother_email,
                        father_nationality: response.data.father_nationality,
                        mother_nationality: response.data.mother_nationality,
                        father_profession: response.data.father_profession,
                        mother_profession: response.data.mother_profession,
                        birth_date: response.data.birth_date,
                        birth_hour: response.data.birth_hour,
                        birth_place: response.data.birth_place,
                        birth_territory: response.data.birth_territory,
                        country: response.data.country,
                        declaration_status: response.data.declaration_status,
                        status_payment: response.data.status_payment,
                        amount_payment: response.data.amount_payment,
                        mode_payment: response.data.mode_payment,
                        gateway_payment: response.data.gateway_payment,
                        phonePayment: response.data.phonePayment,
                        pay_date: response.data.updated_at,
                        integration_date: response.data.created_at,
                        commune: response.data.commune,
                        type_commune_or_sousPrefecture: response.data.type_commune_or_sousPrefecture
                    })
                })
                .catch(error => console.log(error))

        }

        handleGetReceipt()
    }, [request_receipt])

    const Print = () => {
        //console.log('print');  
        let printContents = document.getElementById('printablediv').innerHTML;
        let originalContents = document.body.innerHTML;
        document.body.innerHTML = printContents;
        window.print();
        document.body.innerHTML = originalContents;
    }

    const options = {
        filename: `recu_declaration_${userInfoToPrint?.receipt}_${userInfoToPrint?.firstname}_${userInfoToPrint?.lastname}.pdf`,
        method: "save",
        // default is Resolution.MEDIUM = 3, which should be enough, higher values
        // increases the image quality but also the size of the PDF, so be careful
        // using values higher than 10 when having multiple pages generated, it
        // might cause the page to crash or hang.
        resolution: Resolution.EXTREME,
        page: {
            // margin is in MM, default is Margin.NONE = 0
            margin: Margin.SMALL,
            // default is 'A4'
            format: "letter",
            // default is 'portrait'
            orientation: "landscape"
        },
        canvas: {
            // default is 'image/jpeg' for better size performance
            mimeType: "image/jpeg",
            qualityRatio: 1
        },
        // Customize any value passed to the jsPDF instance and html2canvas
        // function. You probably will not need this and things can break,
        // so use with caution.
        overrides: {
            // see https://artskydj.github.io/jsPDF/docs/jsPDF.html for more options
            pdf: {
                compress: true
            },
            // see https://html2canvas.hertzen.com/configuration for more options
            canvas: {
                useCORS: true
            }
        }
    };

    const getTargetElement = () => document.getElementById("pdfSaveInFile");

    const downloadPdf = () => generatePDF(getTargetElement, options);

    return (
        <div className="bg-receipt-blank">
            <div className="position-sticky top-0 d-flex justify-content-center">
                <Button variant="secondary" onClick={() => navigate(`/dashboard/details-declaration/${userInfoToPrint?.receipt}`)}>Retour</Button>
                <Button variant="primary" onClick={Print}>Cliquez pour imprimer</Button>
                <Button variant="danger" onClick={downloadPdf}>Cliquez pour télécharger en format PDF</Button>
            </div>

             <div id="pdfSaveInFile">
                 <RealReceiptDeclaration userInfoToPrint={userInfoToPrint} />
             </div>
         </div>
    )
}

export default RecuDeclaration