import QRCode from "react-qr-code"

const RealReceiptDeclaration = ({ userInfoToPrint }) => {
    const pay_date = userInfoToPrint?.pay_date.split('').slice(0, 10).join('')
    const pay_hour = userInfoToPrint?.pay_date.split('').slice(11, 16).join('')

    return (
        <div className="bg-receipt-content shadow mx-3" id='printablediv'>
            <header className="text-center py-2 text-secondary">--- Reçu généré par RDV - EtatCivil ---</header>

            <div className="mt-1 p-3 border-top border-bottom info-receipt container">
                <div className="d-flex justify-content-between align-items-center">
                    <div>
                        <span className="fw-bold">Reçu n° {userInfoToPrint?.receipt} DECLARATION</span>
                    </div>

                    <div className="recu-containDivImg">
                        <div className="recu-containDivImg-containImg">
                            <img src={"/images/Armoirie.png"} className="" />
                        </div>
                    </div>
                </div>
            </div>

            <div className="info-small-toPayment mt-4 d-flex justify-content-between container">
                <div>
                    <h4 className="my-2"><u>Informations sur bénéficiaire de la déclaration</u></h4>
                    <div>
                        <span className="fw-bold">Nom de l'enfant</span> : {userInfoToPrint?.firstname}
                    </div>
                    <div>
                        <span className="fw-bold">Prénom de l'enfant</span> : {userInfoToPrint?.lastname}
                    </div>
                </div>

                <div>
                    <h4 className="my-2"><u>Informations sur le reçu</u></h4>
                    <div>
                        <span className="fw-bold">Numéro de declaration</span> : {userInfoToPrint?.receipt}
                    </div>

                    <div>
                        {userInfoToPrint?.pay_date !== userInfoToPrint?.integration_date && <span><span className="fw-bold">Date de paiement</span> : {pay_date}</span>}
                        {userInfoToPrint?.pay_date === userInfoToPrint?.integration_date && <span><span className="fw-bold">Date de paiement</span> : Inconnu</span>}
                    </div>
                    <div>
                        {userInfoToPrint?.pay_date !== userInfoToPrint?.integration_date && <span><span className="fw-bold">Heure de paiement</span> : {pay_hour}</span>}
                        {userInfoToPrint?.pay_date === userInfoToPrint?.integration_date && <span><span className="fw-bold">Heure de paiement</span> : Inconnu</span>}
                    </div>
                    <div className="mt-1 d-flex justify-content-start align-items-center">
                        <span className="fw-bold">Statut de declaration</span> :
                        {userInfoToPrint?.declaration_status === "NEW" && userInfoToPrint?.status_payment === "NOT PAID" &&
                            <div className="bg-warning text-dark p-2 mx-2 recu-notification">Pas encore payé</div>}

                        {userInfoToPrint?.declaration_status === "COMPLETED" && userInfoToPrint?.status_payment === "PAID" &&
                            <div className="bg-success p-2 mx-2 recu-notification">Succès</div>}
                    </div>
                </div>
            </div>

            <div className="info-big-toPayment mt-5">

                {userInfoToPrint?.declaration_status === "COMPLETED" && userInfoToPrint?.status_payment === "PAID" && (
                    <>
                        <h2 className="p-3 container">DETAIL SUR LE PAIEMENT</h2>
                        <section class="intro pb-5">
                            <div class="h-100">
                                <div class="mask d-flex align-items-center h-100">
                                    <div class="w-100">
                                        <div class="row justify-content-start">
                                            <div class="col-12">
                                                <div class="table-responsive bg-white">
                                                    <table class="table mb-0">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">Type</th>
                                                                <th scope="col">Telephone Paiement</th>
                                                                <th scope="col">Canal</th>
                                                                <th scope="col">Montant</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <th scope="row">DECLARATION</th>
                                                                <td>{userInfoToPrint?.phonePayment}</td>
                                                                <td>{userInfoToPrint?.gateway_payment}</td>
                                                                <td>{userInfoToPrint?.amount_paymennt} FCFA</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                </>)}

                {userInfoToPrint?.declaration_status === "NEW" && userInfoToPrint?.status_payment === "NOT PAID" && (<h2 className="p-3 container text-center">VEUILLEZ PROCÉDER AU PAIEMENT AVEC LE LIEN ENVOYÉ PAR MAIL OU SMS.</h2>)}
            </div>

            <div className="d-flex justify-content-between container">
                <div>
                    <div>
                        <h4><u>Informations sur le père</u></h4>
                        <div>
                            <span className="fw-bold">Nom du père :</span> : {userInfoToPrint?.father_firstname}
                        </div>
                        <div>
                            <span className="fw-bold">Prénom du père</span> : {userInfoToPrint?.father_lastname}
                        </div>
                        <div>
                            <span className="fw-bold">Numéro du père</span> : {userInfoToPrint?.father_phone}
                        </div>
                        <div>
                            {userInfoToPrint?.father_email && <><span className="fw-bold">Mail du père</span> : {userInfoToPrint?.father_email}</>}
                        </div>
                    </div>

                    <div className="mt-2">
                        <h4><u>Informations sur la mère</u></h4>
                        <div>
                            <span className="fw-bold">Nom de la mère</span> : {userInfoToPrint?.mother_firstname}
                        </div>
                        <div>
                            <span className="fw-bold">Prénom de la mère</span> : {userInfoToPrint?.mother_lastname}
                        </div>
                        <div>
                            <span className="fw-bold">Numéro de la mère</span> : {userInfoToPrint?.mother_phone}
                        </div>
                        <div>
                            {userInfoToPrint?.father_email && <><span className="fw-bold">Mail de la mère</span> : {userInfoToPrint?.mother_email}</>}
                        </div>
                    </div>
                </div>

                <div>
                    <h4><u>En charge</u></h4>

                    <div><span className="fw-bold">Type de localité</span> : {userInfoToPrint?.type_commune_or_sousPrefecture}</div>
                    <div><span className="fw-bold">Nom de la {userInfoToPrint?.type_commune_or_sousPrefecture}</span> : {userInfoToPrint?.commune}</div>
                </div>
            </div>

            <div className="container info-addiontional-toPayment d-flex justify-content-start align-items-center mt-5">
                <div className="receipt-containDivCodeQR">
                    <div className="receipt-containDivCodeQR-containCodeQR px-2">
                        <QRCode size={256} viewBox={`0 0 256 256`} style={{ height: "100%", width: "100%" }} value={`https://www.rdv-etatcivil.cloud/receipt_declaration/${userInfoToPrint?.receipt}`} />
                    </div>
                </div>

                <div>
                    Ce code QR sera utile pour des vérifications rapides de la {userInfoToPrint?.type_commune_or_sousPrefecture}.
                </div>
            </div>

            <div className="text-center py-4">
                <h4>NOUS VOUS REMERCIONS !</h4>
                <h5 className="disabledMarginBottom">Guichet du RDV - ÉtatCivil</h5>
            </div>

            <footer className="footer-receipt p-3 d-flex justify-content-center">
                <div class="container text-center">
                    <small>Copyright &copy; RDV - EtatCivil.cloud</small>
                </div>
            </footer>
        </div>
    )
}

export default RealReceiptDeclaration;