import React, { useEffect } from "react";
import Navbar from "./Components/header/Navbar";
import HomePage from "./Pages/HomePage";
import { Route, Routes, useLocation } from "react-router-dom";
import FooterInfo from "./Components/Footer_Info";
import LoginPage from "./Pages/LoginPage";
import DetailMenu from "./Pages/DetailMenu";
import InfoFlash from "./Components/header/InfoFlash";
import NavbarAlternative from "./Components/header/NavbarAlternative";
import RdvPage from "./Pages/RdvPage";
import Sidebar from "./Components/Navigation/Sidebar";
import Datatable from "./Components/Table/DataTableHistory";
import DetailPage from "./Pages/DetailPage";
import Header from "./Components/Navigation/Header";
import EnvoiDocumentPage from "./Pages/EnvoiDocumentPage";
import { useNavigate } from "react-router-dom";
import ProfilePage from "./Pages/ProfilePage";
import Step44 from "./Components/stepper/Step44";
import AvisPage from "./Pages/AvisPage";
import DetailsActualite from "./Components/Actualites/DetailsActualite";
import SideNav from "./dashboard/SideNav";
import PaymentPage from "./Pages/PaymentPage";
import "./dashboard/dashboard.css"
import './style/home.css'
import FormDeclaration from "./Declaration/FormDeclaration";

import DeclarationPage from "./Pages/DeclarationPage";
import DocumentDeclaration from "./Declaration/DocumentDeclaration";
import VerificationDeclaration from "./Declaration/VerificationDeclaration";
import VerificationPaymentDeclaration from "./Declaration/VerificationPaymentDeclaration";
import PaymentDeclarationPage from "./Pages/PaymentDeclarationPage";
import ErrorPaymentPage from "./Pages/ErrorPaymentPage";
import RecuDemande  from "./Components/Pdf/RecuDemande";
import RecuDeclaration from "./Components/Pdf/RecuDeclaration";

const App = () => {
  const params = useLocation();
  const user = window.localStorage.getItem("userData");
  console.log(user);
  const navigate = useNavigate();

  useEffect(() => {
    if (!user && params.pathname.includes("dashboard")) {
      navigate("/login");
    }
  }, []);

  return (
    <>
      {(params.pathname == "/login" ||
        params.pathname == "/rdv" || params.pathname.includes("/login") ) && (
          <>
            <NavbarAlternative />
            <Routes>
              <Route path="/login" element={<LoginPage />} />
              <Route path="/login/:tokenParams" element={<LoginPage />} />
              <Route path="/rdv" element={<RdvPage />} />
            </Routes>
            <FooterInfo />
          </>
        )}

      {params.pathname.includes("/rdv_checkout") && (
        <>
          <NavbarAlternative />
          <Routes>
            <Route path="/rdv_checkout/:requestInvoice" element={<PaymentPage />} />
          </Routes>
          <FooterInfo />
        </>
      )}

      {params.pathname.includes("completer-documents") && (
        <>
          <NavbarAlternative />
          <Routes>
            <Route
              path="/completer-documents/:request_invoice"
              element={<Step44 />}
            />
            <Route path="*" element={<HomePage />} />
          </Routes>
        </>
      )}

      {params.pathname.includes("dashboard") && (
        <SideNav />
      )}

      {(params.pathname == "/" ||
        params.pathname == "/avis" || params.pathname.includes("/details/")) && (
          <>
            <InfoFlash />
            <Navbar />
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/details/:lien" element={<DetailMenu />} />
              <Route path="/details/:lien/:id" element={<DetailsActualite />} />
              <Route path="/avis" element={<AvisPage />} />
            </Routes>
            <FooterInfo />
          </>
        )}

      {/* <Routes>
          <Route path="*" element={<HomePage />} />
      </Routes> */}

      {(params.pathname === "/declaration" || params.pathname.includes("/declaration")) && (
        <>
          <NavbarAlternative />
          <Routes>
            <Route path="/declaration" element={<DeclarationPage />} />
            <Route path="/declaration_checkout/:request_receipt" element={<PaymentDeclarationPage />} />
          </Routes>
          <FooterInfo />
        </>
      )}

      {(params.pathname === "/errors") && (
        <>
          <NavbarAlternative />
          <Routes>
            <Route path="/errors" element={<ErrorPaymentPage />} />
          </Routes>
          <FooterInfo />
        </>
      )}
      <Routes >
        <Route path="/receipt_demande/:request_invoice" element={<RecuDemande />} />
        <Route path="/receipt_declaration/:request_receipt" element={<RecuDeclaration />} />
      </Routes>
    </>
  );
};

export default App;