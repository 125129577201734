import React from 'react';
import { Navigate, useNavigate } from "react-router-dom";

const MenuCertificatResidence = () => {
  const navigate = useNavigate()
  return (
    <div className="">
      <h2 className="text-center pt-5">
        PIÈCES À FOURNIR POUR L'OBTENTION DU CERTIFICAT DE RESIDENCE
      </h2>
      <div className="d-flex align-items-center justify-content-center text-center mb-5">
        <div className="col-8 fw-lighter text-secondary">
          N.B : le reçu de paiement pour une demande de visa au niveau du
          Consulat Général de Côte d'Ivoire à Paris n'est pas un document de
          voyage. Après l'obtention du reçu de paiement, le requérant est
          obligé de se faire enrôler au Consulat Général de Côte d'Ivoire à
          Paris pour obtenir son visa avant de prendre son vol à destination
          de la Côte d'Ivoire.
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-center">
        <div className="col-10 border rounded p-4 mb-3">
          <div className="fw-lighter mb-3">
            <h3 className="text-center">Procédure</h3>

            <div className="process">
              <div>
                <p className="process-title fw-bold">Documents a fournir</p>

              </div>
              <div>
                <p className="process-title fw-bold">
                  1- Titre d'identité
                </p>

                <ul className="list-typeActe">
                  <li className="">
                    Un passeport
                  </li>
                  <li>
                    Ou une carte nationale d'identité
                  </li>
                  <li>
                    Ou une attestation d'identité
                  </li>
                  <li>
                    Ou une carte de résident
                  </li>
                  <li>
                    Ou tout autre document tenant lieu de titre d'identité
                  </li>
                </ul>
              </div>
              <div>
                <p className="fw-bold">2- Preuve de residence</p>
                <ul className="list-typeActe">
                  <li className="">
                    Une Facture CIE / SODECI

                  </li>
                  <li>
                    Ou un contrat de bail

                  </li>
                  <li>
                    Ou une attestation d'hébergement et une pièce d'identité de la personne qui héberge

                  </li>
                  <li>
                    Ou une réservation d'hôte

                  </li>
                  <li>
                    Ou autre (tout autre document justifiant d'une résidence en Côte d'Ivoire)                 
                  </li>
                </ul>
              </div>
              <div>
                <p className="process-title fw-bold">Retrait du certificat de residence</p>

              </div>
              <div>
                <p className="fw-bold">1- Prise de rendez vous</p>
                <p>
                  Vous pouvez prendre un rendez-vous en ligne sur{" "}
                  <a
                    onClick={() => navigate("/rdv")}
                    // href="https://www.rdv-consulat.ci"
                    className="text-decoration-none text-black fw-bolder"
                  >
                    https://www.rdv-etat-civil.cloud
                  </a>{" "} <br />
                  Ou vous rendre à Abidjan - Plateau (Agence Principale, face à la Cathédrale St Paul) <br />
                  Ou à Abidjan - Cocody (Agence Prestige, II Plateaux Vallons) <br />
                  Avec les documents susmentionné
                </p>
               
                
              </div>
              <div>
                <p className="fw-bold">2- Rétrait du certificat de résidence</p>
                <ul className="list-typeActe">
                  <li>
                    Le dossier de visa est traité dans un délai de quarante-huit (48)
                    heures après le dépot de dossier.
                  </li>
                  <li>
                    Le requérant se présente personnellement dans un des lieux où il aura eu à présenter ses différents documents muni de son reçu de payement pour le
                    retrait de son certificat de résidence.
                  </li>
                </ul>
              </div>
              <div>
                <p>
                  <span className="fw-bolder">HORAIRES :</span>
                  9h30- 12h30 Dépôt de dossier / 14h30 - 16h Retrait de dossier
                  (prendre rdv pour le retrait sur{" "}
                  <a
                    onClick={() => navigate("/rdv")}
                    // href="www.rdv-consulat.ci"
                    className="text-decoration-none text-black fw-bolder"
                  >
                    www.rdv-etat-civil.ci
                  </a>{" "}
                  )
                </p>
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MenuCertificatResidence;
