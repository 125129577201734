import React from "react";

function Form() {
  return (
    <div className="container">
      <div className="bg-light">
        <div className="row pt-3 d-flex justify-content-center align-items-center">
          <div className="col-lg-6 col-md-8 col-sm-10 row">
            <div className="text-center mb-4">
              <h3 className="fs-3">
                ECRIRE AU CONSULAT GENERAL DE COTE D'IVOIRE A PARIS
              </h3>
              <p className="">
                Avez-vous besoin d’aide ou d’informations dans le cadre de vos
                démarches consulaires ? <br />
                Ecrivez directement au Consulat Général de la Côte d’Ivoire à
                Paris
              </p>
            </div>
            <div
              className="text-light p-3"
              style={{ backgroundColor: "#384742" }}
            >
              <form
                id="email-form"
                name="email-form"
                method="get"
                className="needs-validation"
                noValidate
              >
                <div className="mb-3">
                  <label htmlFor="name" className="form-label fs-4 p-3">
                    Votre Nom Complet
                  </label>
                  <input
                    type="text"
                    className="form-control border-bottom"
                    style={{ backgroundColor: "transparent", border: "none" }}
                    id="name"
                    name="name"
                    required
                  />
                  <div className="invalid-feedback">
                    Please enter your name.
                  </div>
                </div>
                <div className="mb-3">
                  <label htmlFor="email" className="form-label fs-4 p-3">
                    Email*
                  </label>
                  <input
                    type="email"
                    className="form-control border-bottom"
                    style={{ backgroundColor: "transparent", border: "none" }}
                    id="email"
                    name="email"
                    required
                  />
                  <div className="invalid-feedback">
                    Please enter a valid email.
                  </div>
                </div>
                <div className="mb-3">
                  <label htmlFor="phone" className="form-label fs-4 p-3">
                    Téléphone
                  </label>
                  <input
                    type="tel"
                    className="form-control border-bottom"
                    style={{ backgroundColor: "transparent", border: "none" }}
                    id="phone"
                    name="phone"
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="message" className="form-label fs-4 p-3">
                    Votre message
                  </label>
                  <textarea
                    className="form-control border-bottom"
                    style={{ backgroundColor: "transparent", border: "none" }}
                    id="message"
                    name="message"
                    rows="3"
                    required
                  ></textarea>
                  <div className="invalid-feedback">
                    Please enter a message.
                  </div>
                </div>
                <button
                  type="submit"
                  className="p-3 mt-3"
                  style={{ backgroundColor: "#ffc663", border: "none" }}
                >
                  Transmettre
                </button>
              </form>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-8 row ">
            <div className="col-12 d-flex justify-content-center align-items-center flex-column m-sm-5">
              <h3>ADRESSE</h3>
              <p>19 rue de la Pépinière, 75008 Paris</p>
              <h3>EMAIL</h3>
              <p>support@rdv-consulat.ci</p>
              <h3>WHATSAPP</h3>
              <p>+33 7 51 38 46 06</p>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
}

export default Form;
