import Step11 from "./Step11";
import React, { useState, useEffect } from "react";
import { FileUploader } from "react-drag-drop-files";
import { uploadUrl } from "../../Url";
import axios from "axios";
import Spinner from "../../gifs/Spinner.gif"

const Step4 = ({ step4Fom, stepActualInformations, step4Data, type }) => {
    const [piece, setPiece] = useState("");
    const [all, setAll] = useState([]);
    const [pieceSrc, setPieceSrc] = useState("");
    const fileTypes = ["JPG", "JPEG", "PNG", "PDF"];
    const [loadingUpload, setLoadingUpload] = useState(false); //toggle loading on upload event

    const [formStep4, setFormStep4] = useState({
        documents: [],
        isValid: false,
        ...step4Fom,
    });

    useEffect(() => {
        console.log("piece Step4", piece)
    }, [piece]);

    useEffect(() => {
        if (all.length > 0) {
            console.log("all length superior",all)
            setFormStep4({ ...formStep4, isValid: true });
            step4Data({...formStep4, isValid: true});
            
        } else {
            console.log("all length inferior",all)
            setFormStep4({ ...formStep4, isValid: false });
            step4Data({...formStep4, isValid: false});
        }
    }, [all]);

    // async function handleDeleteFile(file, path){
    //     const name = file?.name;
    //     const types = file?.type;
    //     const size = file?.size;

    //     axios.delete(`${uploadUrl}/deleteUpload`, )
    // }

    async function handleFileSelect(file, type) {
        const name = file?.name;
        const types = file?.type;
        const size = file?.size;

        const formData = new FormData();
        formData.append("file", file);
        console.log("formData append",formData);
        const dataSent = {...formData, ...stepActualInformations}

        axios
            .post(`${uploadUrl}/upload`, formData, {
                headers : {
                    'Access-Control-Allow-Origin' : '*',
                }
            })
            .then(async (response) => {
                console.log("response from server",response);
                const doc_uri = response.data;
                setAll([...all, { name, size, type: types, url: doc_uri }]);
                setLoadingUpload(false)
                setFormStep4({
                    ...formStep4,
                    documents: [...all, { name, size, type: types, url: doc_uri }],
                });
                if (type === "piece") {
                    setPieceSrc(doc_uri);
                }
                console.log("its good")
            })
            .catch((error) => {
                console.log(error.message);
            });
    }

    const handleChangeFile = (file, type) => {
        console.log('file step4',file);
        setLoadingUpload(true)

        const reader = new FileReader();
        const files = file; // we use here an library which take directly the target of file selected

        if (files && files?.name?.length) {
            reader.onload = async () => { };

            handleFileSelect(files, type);
            reader.readAsDataURL(files);

            if (reader.result !== null) {
                if (type === "piece") {
                    setPiece(reader.result);
                }
            }
        }
    };


    return (
        <div>
            <div className="text-center text-danger">
                Veuillez porter une attention particulière aux indications ci-dessous.
            </div>

            <div className="d-flex justify-content-center align-items-center py-5 p-md-4 gap-md-5 flex-wrap">
                <div className="col-12 col-md-5">
                    <form className="col-12">
                        <div className="">
                            <p className="fs-small py-0 my-0">
                                * Veuillez joindre le(s) document(s) demandés(s) en cliquant sur la box ci-dessous
                            </p>
                            {stepActualInformations.who_request !== "moiMeme" &&
                                (<p className="fs-small"> <br />
                                    * Vu que vous n'êtes pas celui qui bénéficiera directement des services de RDV-EtatCivil, veuillez lier votre pièce d'identité <br />
                                    Appelez le fichier PieceIdentite_"NOM"_"PRENOM".pdf <br />
                                    Exemple : PieceIdentite_KOUADIO_Ulbert.pdf (KOUADIO est son nom. Ulbert est son prénom)
                                </p>)}
                        </div>
                        <div className="">
                            <FileUploader
                                handleChange={handleChangeFile}
                                name="file"
                                types={fileTypes}
                                hoverTitle="Veuillez sélectionner un fichier"
                                classes="zone-drop col-1"
                                onDrop={(dragging) => console.log("dragging", dragging)}
                            />
                        </div>

                        <p className="text-danger fs-small mt-4">
                            * Votre demande peut être réfusée par les agents de l'État Civil si elle ne respecte pas les conditions requises. Alors lisez bien et suivez la procédure indiquée.
                        </p>

                        <div className="pt-4 py-6">
                            {formStep4 &&
                                formStep4.documents &&
                                formStep4.documents.length > 0 && <hr />}


                            {loadingUpload && (
                                <div className="d-flex justify-content-center mt-2">
                                    <div className="d-flex justify-content-center align-items-center p-2 rounded border">
                                        <div className="notification-containgImg-spinner">
                                            <img src={Spinner} />
                                        </div>
                                        <div className="text-custom-green">Veuillez patienter pour le téléchargement de votre fichier.</div>
                                    </div>
                                </div>
                            )}


                            {formStep4 &&
                                formStep4.documents &&
                                formStep4.documents.length > 0 &&
                                formStep4.documents.map((val, key) => (
                                    <div key={key}>
                                        <li
                                            style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                            }}
                                        >
                                            {val.type == "application/pdf" ? (
                                                <span>
                                                    <i
                                                        className="fa fa-file-pdf-o"
                                                        style={{ fontSize: "25px", color: "blue" }}
                                                    ></i>
                                                </span>
                                            ) : (
                                                <span>
                                                    <i
                                                        className="fa fa-file-image-o"
                                                        style={{ fontSize: "25px", color: "blue" }}
                                                    ></i>
                                                </span>
                                            )}

                                            <span>{val.name}</span>
                                            <span>{val.size}Ko</span>
                                            {/* <span><button className="btn btn-danger fs-smaller">Supprimer</button></span> */}
                                        </li>
                                        <hr />
                                    </div>
                                ))}
                        </div>
                    </form>
                </div>
                <Step11 type={type} />
                
            </div>
        </div>
    )
}

export default Step4;