const InfoPayment = ({userInformations}) => {
    return(
        <div>
            <h3 className="text-center text-danger">Veuillez lire cette notice</h3>
            <div style={{marginLeft : 50}}>
                Vous payerez un total de <span className="text-danger">2000 FCFA</span> qui s'explique par :
                <ul style={{listStyleType : "circle", marginTop : 30}}>
                    <li>Frais de services de la plateforme : <span className="text-danger">1000 FCFA</span></li>
                    <li>Transport de l'agent de déclaration : <span className="text-danger">500 FCFA</span></li>
                    <li>Frais de timbre : <span className="text-danger" >500 FCFA</span></li>
                    <hr  />
                    <li>TOTAL : <span className="text-danger">2000 FCFA</span></li>
                </ul>
            </div>
        </div>
    )
}

export default InfoPayment