import React from "react";
import { useNavigate, Link } from "react-router-dom";
import './NavBar.css'
// import ImgBrand from "../../../public/images/rci.svg";
import InfoFlash from "./InfoFlash";
import { LinksTo } from "./LinksTo/LinksTo";

const Navbar = () => {
  const navigate = useNavigate();
  return (
    <div className="fixed-top">
      <InfoFlash />

      <nav class="navbar navbar-expand-lg">
        <div class="container fw-bold">
          <div className="col-10 col-lg-4 col-md-8 col-sm-10 d-flex gap-2 align-items-center" href="/">
            <div className="w-25">
              <img src="/images/rci.svg" className="w-100" alt="rci" />
              <h6 className="text-center ">Côte d'Ivoire</h6>
            </div>
            <Link class="w-75 navbar-brand text-wrap app-title" to="/">
              RDV - ÉtatCivil.Cloud
            </Link>
          </div>

          <button
            class="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>

          {/*Liens importants du header par ici */}
          <LinksTo />

        </div>
      </nav>
    </div>
  );
};

export default Navbar;
