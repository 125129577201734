import React from "react";
import { Navigate, useNavigate } from "react-router-dom";

const MenuCertificatNational = () => {
  const navigate = useNavigate()
  return (
    <div className="">
      <h2 className="text-center pt-5">
        PIÈCES À FOURNIR POUR LA DEMANDE D'UN CERTIFICAT DE NATIONNALITE IVOIRIENNE
      </h2>
      <div className="d-flex align-items-center justify-content-center text-center mb-5">
        <div className="col-8 fw-lighter text-secondary">
          Acte permettant de certifier qu'un individu est ivoirien
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-center">
        <div className="col-10 border rounded p-4 mb-3">
          <div className="fw-lighter mb-3">
            <h3 className="text-center">Procédure</h3>

            <div className="process">
              <div>
                <p className="process-title fw-bold">DOCUMENTS A FOURNIR</p>
                <p>
                  Une requête aux fins d'établissement d'un certificat de nationalité
                  <br />
                  Les pièces justificatives de la qualité d'Ivoirien
                  <br />
                  <ul className="list-typeActe">
                    <li>
                      Le lien de filiation avec son auteur (père ou mère) par la production d'un extrait d'acte de naissance ou du livret de famille
                    </li>
                    <li>
                      La nationalité ivoirienne de cet auteur par la production d'un certificat de nationalité ivoirienne ou d'une carte nationalité d'identité ou par témoignage
                    </li>
                  </ul>
                  <span>
                    Dans la pratique, il n'est exigé que l'original de l'extrait d'acte de naissance du demandeur et la photocopie de la carte nationale d'identité de l'un des parents, quel que soit la date d'établissement et la validité.
                  </span>
                </p>
              </div>
              <div>
                <p className="process-title fw-bold">
                  * Ivoirien par effet extensif de l'acquisition de la nationalité du père et/ou de la mère (Acquisition de plein droit)
                </p>

                <ul className="list-typeActe">
                  <li className="">
                    un extrait d'acte de naissance de l'intéressé pour vérification de la minorité à la date d'acquisition de la nationalité du père et/ou de la mère, et l'effectivité du lien de filiation avec l'ascendant qui a acquis la nationalité ivoirienne)
                  </li>
                  <li>
                    une copie du document acquisitif de la nationalité du père et/ou de la mère (Décret de naturalisation, certificat d'acquisition de la nationalité ivoirienne par déclaration, attestation d'acquisition par mariage délivré par la DACP)
                  </li>
                </ul>
              </div>
              <div>
                <p className="process-title fw-bold">
                  * Ivoirien par naissance sur le sol ivoirien de parents inconnus (Acquisition de plein droit)
                </p>
                <span className="fw-bold">
                  en application de la circulaire n° 007/MJDH/CAB du 04 octobre 2019 relative à la délivrance de certificats de nationalité aux enfants trouvés en côte d'Ivoire de parents inconnus
                </span>
                <ul className="list-typeActe">
                  <li className="">
                    un extrait d'acte de naissance sans mentions des père et mère
                  </li>
                  <li>
                    copie de l'ordonnance du juge des tutelles reconnaissant la qualité d'enfant trouvé
                  </li>

                </ul>
              </div>
              <div>
                <p className="process-title fw-bold">
                  * Ivoirien par adoption (Acquisition de plein droit)
                </p>
                <ul className="list-typeActe">
                  <li className="">
                    Un original de l'extrait d'acte de naissance du demandeur
                  </li>
                  <li>
                    Une expédition du jugement de légitimation adoptive
                  </li>
                  <li>
                    un certificat de nationalité ivoirienne ou carte nationale d'identité d'un des époux adoptifs ou témoignage
                  </li>
                </ul>

              </div>
              <div>
                <p className="process-title fw-bold">
                  * Ivoirien par mariage (Acquisition de plein droit, avant 2004 et depuis 2013)  par la femme étrangère épousant un Ivoirien
                </p>

                <ul className="list-typeActe">
                  <li className="">
                    une preuve du mariage (extrait d'acte de mariage ou livret de famille)
                  </li>
                  <li>
                    une preuve que le mari est Ivoirien (certificat de nationalité ivoirienne ou carte nationale d'identité certifié conforme à l'original)
                  </li>
                  <li>
                    une attestation du Ministre de la Justice constatant qu'elle n'a pas souscrit une déclaration en vue de décliner la nationalité ivoirienne (Attestation de non déclination)
                  </li>
                  <li>
                    une attestation du ministre de la Justice constatant qu'il n' y a pas de décret d'opposition du Gouvernement et/ ou décret de déchéance de la nationalité du mari s'il a acquis la nationalité ivoirienne par naturalisation
                  </li>
                  <li>
                    une attestation des services du ministère de l'Intérieur constatant qu'il n' y a pas eu d'arrêté d'expulsion du conjoint étranger.
                  </li>
                </ul>
              </div>
              <div>
                <p className="process-title fw-bold">
                  * Pour la femme étrangère
                </p>
                <span>
                  Mêmes documents que dans les cas précédents.
                  <br />
                  L'option pour l'acquisition de la nationalité ivoirienne lors de la célébration du mariage, doit être mentionnée sur l'extrait ou la copie intégrale de l'acte de mariage.
                  <br />
                  Ou encore
                </span><br />
                <span className="fw-bold">
                  * Pour la femme étrangère ou l'homme étranger qui épouse un conjoint ivoirien
                </span>
                <ul className="list-typeActe">
                  <li className="">
                    une preuve du mariage (extrait d'acte de mariage ou livret de famille)
                  </li>
                  <li>
                    une preuve que le mari est Ivoirien (certificat de nationalité ivoirienne ou carte nationale d'identité certifié conforme à l'original)
                  </li>
                  <li>
                    une attestation du Ministre de la Justice constatant qu'elle n'a pas souscrit une déclaration en vue de décliner la nationalité ivoirienne (Attestation de non déclination)
                  </li>
                  <li>
                    une attestation du Ministre de la Justice constatant qu'elle n'a pas souscrit une déclaration en vue de décliner la nationalité ivoirienne (Attestation de non déclination)
                  </li>
                  <li>
                    une attestation des services du ministère de l'Intérieur constatant qu'il n' y a pas eu d'arrêté d'expulsion du conjoint étranger.
                  </li>
                </ul>
              </div>
              <div>
                <p className="process-title fw-bold">
                  * Pour l'ivoirien par déclaration
                </p>
                <span className="fw-bold">
                  en application des articles 17 à 23 du Code qui ont été abrogés par la loi 72-852 du 21 Décembre 1972 qui a supprimé la déclaration comme mode d'acquisition de la nationalité.
                </span><br />
                <span>
                  un extrait d'acte de naissance de l'intéressé
                </span>
                <ul className="list-typeActe">
                  <li>
                    un extrait d'acte de naissance de l'intéressé
                  </li>
                  <li className="">
                    une attestation délivrée par le Ministre de la Justice et constatant que la déclaration a été souscrite et enregistrée au niveau de la direction compétente de son ministère
                  </li>
                </ul>
                <span className="fw-bold">
                en application de la Loi n° 2013-653 du 13 septembre 2013 portant dispositions particulières en matière d'acquisition de la nationalité par déclaration (janvier 2014 à janvier 2016)
                </span>
                <ul className="list-typeActe">
                  <li>
                    un extrait d'acte de naissance de l'intéressé
                  </li>
                  <li className="">
                  une copie certifiée conforme à l'original du certificat d'acquisition de la nationalité ivoirienne délivré par le Ministre de la Justice et constatant que la déclaration a été enregistrée conformément aux dispositions légales en vigueur.
                  </li>
                </ul>
              </div>
              <div>
                <p className="process-title fw-bold">
                  * Pour l'ivoirien par naturalisation (Acquisition par décision de l'autorité publique)
                </p>

                <ul className="list-typeActe">
                  <li className="">
                  Extrait d'acte de naissance de l'intéressé
                  </li>
                  <li>
                  soit une ampliation du décret de naturalisation, soit un exemplaire du Journal officiel ayant publié le décret, soit une attestation délivrée par le Ministre de la Justice et constatant l'existence du décret
                  </li>
                 
                </ul>
              </div>
              <div>
                <p className="process-title fw-bold">
                  * Pour l'ivoirien par la réintégration (Acquisition par décision de l'autorité publique)
                </p>

                <ul className="list-typeActe">
                  <li className="">
                  Documents à produire : Idem que pour la naturalisation
                  </li>
                </ul>
              </div>
              <div>
              <p className="process-title fw-bold">Retrait du certificat de nationalité</p>
              </div>
              <div>
                <p className="fw-bold">1- Prise de Rendez-vous </p>
                <p>
                  Vous pouvez prendre un rendez-vous en ligne sur{" "}
                  <a
                    onClick={() => navigate("/rdv")}
                    // href="https://www.rdv-consulat.ci"
                    className="text-decoration-none text-black fw-bolder"
                  >
                    https://www.rdv-Etat-civil.cloud
                  </a>{" "} <br />
                  Ou vous rendre au service des casiers judiciaires et certificats de nationalité de
                  votre commune avec les documents vous concernants susmentionné
                </p>
              </div>
              
              <div>
                <p className="fw-bold">2- Retrait du certificat de nationnalité</p>
                <ul className="list-typeActe">
                  <li>
                    Le dossier de visa est traité dans un délai de vingt-quatre (24)
                    heures après l'enrôlement.
                  </li>
                  <li>
                    Le requérant se présente personnellement au au service des casiers judiciaires et certificats de nationalité de
                    votre commune muni de son reçu de payement pour le
                    retrait de son certificat de nationnalité.
                  </li>
                </ul>
              </div>
              <div>
                <p>
                  <span className="fw-bolder">HORAIRES :</span>
                  9h30- 12h30 Dépôt de dossier / 14h30 - 16h Retrait de dossier
                  (prendre rdv pour le retrait sur{" "}
                  <a
                    onClick={() => navigate("/")}
                    // href="www.rdv-consulat.ci"
                    className="text-decoration-none text-black fw-bolder"
                  >
                    www.rdv-consulat.ci
                  </a>{" "}
                  )
                </p>
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MenuCertificatNational;
