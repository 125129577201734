
//const BASE_URL = "http://localhost:8000";
// const loginUrl = BASE_URL+"/api/login";
// const registerUrl = BASE_URL+"/api/register"
// const logoutUrl = BASE_URL+"/api/logout"
// const usersUrl = BASE_URL+"/api/user-by-state/all/5000"
// const mailUrl = BASE_URL+"/api/mail";
// const userByMailUrl = BASE_URL+"/api/check-user-by-email";
// const rdvsByUserMailUrl = BASE_URL+"/api/check-rdvs-by-users-email";
// const staticUrl = BASE_URL+"/public";
// const uploadUrl = BASE_URL+"/api";
// const updateUserUrl = BASE_URL+"/api/update-user-info"
// const myProfile = BASE_URL+"/api/my-profile";
// const rdvByIdUlr = BASE_URL+"/api/check-rdv-by-id";
// const updateDemandeDocumentUrl = BASE_URL+"/api/update-rdv";
// const frontUrl = "http://localhost:3000";

// const BASE_URL = "http://localhost:8000";
const BASE_URL = "https://backend-rdv-etatcivil-2f4df8ab39c1.herokuapp.com";
// const BASE_URL = "https://consultat.fintechgodwin.com";
const loginUrl = BASE_URL+"/api/login";
const registerUrl = BASE_URL+"/api/register"
const logoutUrl = BASE_URL+"/api/logout"
const usersUrl = BASE_URL+"/api/user-by-state/all/5000"
const mailUrl = BASE_URL+"/api/mail";

const userByMailUrl = BASE_URL+"/api/check-user-by-email";
const rdvsByUserMailUrl = BASE_URL+"/api/check-rdvs-by-users-email";
const checkDeclarationById = BASE_URL+"/api/check-declaration-by-id";
const checkDeclarationByAll = BASE_URL+"/api/checkAllDeclarations";
const staticUrl = BASE_URL+"/uploads"; //Old /public
const checkAgents = BASE_URL+"/api/checkAgentsByCommune";
const checkAgentByMatricule = BASE_URL+"/api/checkAgentByMatricule";

const uploadUrl = BASE_URL+"/api";
const updateUserUrl = BASE_URL+"/api/update-user-info"
const myProfile = BASE_URL+"/api/my-profile";
const rdvByIdUlr = BASE_URL+"/api/check-rdv-by-id";
const updateDemandeDocumentUrl = BASE_URL+"/api/update-rdv";
const registerAdmin = BASE_URL+"/api/register-admin";
const registerRoot = BASE_URL+"/api/register-root";
const registerAgent= BASE_URL+"/api/registerAgent";
const registerDeclaration = BASE_URL+"/api/register-declaration";
const frontUrl = "https://www.rdv-etatcivil.cloud";
const updateRdv = BASE_URL+"/api/update-rdv";
const updatePayStatus = BASE_URL+"/api/updatePayStatus";
const completeDeclaration = BASE_URL+"/api/completeDeclaration";
const ToastTokenLoginUrl = BASE_URL+"/api/auth/toToastOnRedirectLogin";

const APP_NAME = "Rdv - EtatCivil";
const WethearLink="https://home.openweathermap.org/";
const WethearApiKey="7c3a8f82facace79c6b42137e49f4d7e"

//ssh -p 65002 u257389445@191.101.79.5
export {frontUrl, APP_NAME, loginUrl, registerUrl, logoutUrl,myProfile, usersUrl, 
    mailUrl, userByMailUrl, staticUrl, updateUserUrl, uploadUrl, 
    rdvsByUserMailUrl, rdvByIdUlr, updateDemandeDocumentUrl, updateRdv, updatePayStatus, registerAdmin, registerDeclaration, checkDeclarationById, completeDeclaration, checkDeclarationByAll,
    ToastTokenLoginUrl, registerRoot, registerAgent, checkAgents, checkAgentByMatricule}
