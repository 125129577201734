import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { MDBDataTable } from "mdbreact";
import { checkDeclarationByAll } from "../../Url";

import "@fortawesome/fontawesome-free/css/all.min.css";
import "mdbreact/dist/css/mdb.css";
import '../../style/sesame/index.css';
import '../../style/sesame/listAndTable.css';
import '../../style/sesame/sesame.css';

const DatatableDeclarations = () => {
    const navigate = useNavigate();
    let storedToken = window.localStorage.getItem("token");
    const location = useLocation();
    console.log("location", location.pathname)
    const [load, setLoad] = useState(true); //Set for loading state while axios is retrieving data from display
    const handleDetailClick = (email) => {
        navigate(`/dashboard/details-declaration/${email}`);
    };

    const [declarationsGlobal, setDeclarationsGlobal] = useState([]); //Global retrieve of all procedures of users demandes
    const [users, setUsers] = useState([]); //This is the main User we gonna use for toggling if pathname is historique-demandes or not

    const user = JSON.parse(window?.localStorage.getItem("userData") ?? "{}");

    useEffect(() => {

        if (!user?.id) {
            navigate('/login')
        }
    }, [])

    useEffect(() => {
        const getDeclarations = () => {
            axios
                .post(`${checkDeclarationByAll}/${500}`, { commune: user?.commune }, {
                    headers: {
                        Authorization: `Bearer ${storedToken}`
                    }
                })
                .then((response) => {
                    console.log("axios post for get declarations", response.data.data);
                    setDeclarationsGlobal(response.data.data)
                    setLoad(false)
                })
                .catch((err) => {
                    console.log(err);
                    setLoad(false)
                });
        }

        getDeclarations()
    }, [])

    //Remove declarations who already pays
    useEffect(() => {
        if (location.pathname === "/dashboard/list-des-nouvelles-declarations") {
            setUsers(declarationsGlobal.filter(
                element => element.status_payment != "PAID" && element.declaration_status == "NEW"
            ))

            console.log("pathname declarations", users)
        }

        if (location.pathname === "/dashboard/declarations-finals") {
            setUsers(declarationsGlobal.filter(
                element => element.status_payment == "PAID" && element.declaration_status != "NEW"
            ))

            console.log("pathname declarations finals", users)
        }
    }, [declarationsGlobal, location.pathname])

    const datas = {
        columns: [
            {
                label: "ID de la declaration",
                field: "numero",
                sort: "asc",
                width: 200,
            },
            {
                label: "Nom",
                field: "firstname",
                sort: "asc",
                width: 150,
            },
            {
                label: "Prenom",
                field: "lastname",
                sort: "asc",
                width: 270,
            },
            {
                label: "Nom - Père",
                field: "father_firstname",
                sort: "asc",
                width: 200,
            },
            {
                label: "Prenom - Père",
                field: "father_lastname",
                sort: "asc",
                width: 200,
            },
            {
                label: "Nom - Mère",
                field: "mother_firstname",
                sort: "asc",
                width: 200,
            },
            {
                label: "Prenom - Mère",
                field: "mother_lastname",
                sort: "asc",
                width: 200,
            },
            {
              label: "Actions",
              field: "actions",
              width: 100,
            },
        ],
        rows: [
            /*{
              firstname: "Consulat",
              lastname: "Admin",
              phone: "+2250555475465",
              email: "admin-fr@gmail.com",
              numero: "6534984337",
              id: "01",
              type: "Information"
            },
            {
              firstname: "Consulat",
              lastname: "Root",
              phone: "+2250555475465",
              email: "root-fr@gmail.com",
              numero: "6534984337",
              id: "02",
              type: "Formation"
            }*/
        ],
    }

    const [data, setData] = useState(datas);

    useEffect(() => {
        if (users?.length > 0) {

            const newData = [];
            for (const user of users) {
                newData.push({
                    numero: user.request_receipt,
                    firstname: user.firstname,
                    lastname: user.lastname,
                    father_firstname: user.father_firstname,
                    father_lastname: user.father_lastname,
                    mother_firstname: user.mother_firstname,
                    mother_lastname: user.mother_lastname
                })
            }
            console.log("data as", data)
            setData({ columns: data.columns, rows: newData })
        }
    }, [users])

    const rows = data.rows.map((rowData, index) => {
        return {
            ...rowData,
            actions: (
                <button
                    className="btn-details px-3 py-1 shadow d-flex justify-content-center"
                    onClick={() => handleDetailClick(rowData.numero)}
                    disabled={rowData.numero ? false : true}
                >
                    Detail
                </button>
            ),
            id: index,
        };
    });

    return (
        <>
            {users.length > 0 &&
                (<>
                    <div className="scroll-x">
                        <MDBDataTable striped bordered small data={{ ...data, rows }} />
                    </div>
                </>)}


            {users.length < 1 &&
                <div>
                    {load === true ? "Chargement..." : "Aucune procédure enregistrée par ici."}
                </div>}
        </>
    );
};

export default DatatableDeclarations;
