import React from 'react';
import { useNavigate } from "react-router-dom";
const MenuConstruction = () => {
  const navigate = useNavigate();
  return (
    <div>
      <div className="">
        <h2 className="text-center pt-5">
          CETTE PAGE EST ACTUELLEMENT EN COURS DE CONSTRUCTION, NOUS VOUS INFORMERONS TRÈS PROCHAINEMENT LORS DE LA MISE EN SERVICE DE CELLE-CI
        </h2>
      </div>
      <div className="d-flex align-items-center justify-content-center text-center mb-5">
        <div className="col-8 fw-lighter text-secondary">
          NOUS VOUS INVITONS A VOUS REDIRIGEZ VERS LA PAGE D'ACCUEILLE
          <button className='btn' onClick={() => {
            navigate("/");
          }}>
            Accueille
          </button>
        </div>
      </div>
    </div>
  );
};

export default MenuConstruction;