import React from 'react';

const VerificationPaymentDeclaration = ({verificationPaymentDeclaration}) => {
  console.log("verificationPaymentDeclaration", verificationPaymentDeclaration)
  return (
    <div>
      <div className="">
        <h1 className="text-center">Vérifier les informations entrées</h1>

        <div>
          <form>
            <div className="d-flex col-12 col-md-12 justify-content-center align-items-center px-3">
              <div className="bande col"></div>
              <div className="col bande-title text-center pb-2 fs-3">
                Detail pour le paiement
              </div>
              <div className="bande col"></div>
            </div>
            <div class="row">
              <div class="col">
                <label for="formGroupExampleInput">Mode de paiement</label>
                <input type="text" disabled class="form-control" placeholder="" value={verificationPaymentDeclaration.mode_payment} />
              </div>
              {
                (verificationPaymentDeclaration.mode_payment === "Mobile Money" || verificationPaymentDeclaration.mode_payment === "VisaMasterCard") && (
                  <div class="col">
                    <label for="formGroupExampleInput">Canal de paiement</label>
                    <input type="text" disabled class="form-control" placeholder="Last name" value={verificationPaymentDeclaration.gateway_payment} />
                  </div>
                )
              }
            </div>

            {
              (verificationPaymentDeclaration.mode_payment === "Mobile Money") && (
                <div class="row">
                  <div class="col-12">
                    <label for="formGroupExampleInput">Numéro de paiement</label>
                    <input type="text" disabled class="form-control" placeholder="" value={verificationPaymentDeclaration.phonePayment} />
                  </div>
                </div>
              )
            }

            <div class="row">
              <div class="col-12">
                <label for="formGroupExampleInput">Numéro de reçu de declaration</label>
                <input type="text" disabled class="form-control" placeholder="" value={verificationPaymentDeclaration.request_receipt} />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default VerificationPaymentDeclaration;