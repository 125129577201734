import React from 'react';
import Button from 'react-bootstrap/Button';
import { useNavigate } from 'react-router-dom';

const ErrorPaymentPage = () => {
  const navigate = useNavigate();
  return (
    <div style={{ height: "67vh", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <div>
        <h1 class="text-center text-danger">Non autorisé</h1>
        <p class="text-center">Vous n'êtes apparemment pas autorisé à vous rendre sur la page que vous avez demandé</p>
        <p class="text-center">--Certainement, <span className='text-danger fs-bold'>un problème de connexion</span></p>
        <p class="text-center">--Ou soit, <span className='text-danger fs-bold'>l'utilisateur indexé a déjà opéré la demarche de l'opération</span></p>

        <div style={{ width: "200px", margin: "0 auto" }}>
          <img className='' style={{ width: "200px" }} src="/images/erreur.png" alt="" />
        </div>

        <div style={{display: 'flex', justifyContent: 'center'}}>
          <Button variant="danger" onClick={() => navigate('/')}>Cliquer pour revenir sur la page d'accueil</Button>
        </div>

      </div>
    </div>
  );
};

export default ErrorPaymentPage;