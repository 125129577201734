import React, { useState, useEffect } from 'react';
import {
  BsFillQuestionCircleFill,
} from "react-icons/bs";

const Step11 = ({ type, declaration }) => {
  const [formStep1, setFormStep1] = useState({ type: '' })

  useEffect(() => {
    setFormStep1({ type: type })
  }, [type]);

  return (
    <>
      <div className="col-12 col-md-6 border py-2">
        {formStep1.type == "DEMANDE CARTE CONSULAIRE - Majeurs résidents" ?
          (
            <>
              <div className="px-3">
                <BsFillQuestionCircleFill className="text-danger" />{" "}
                <span>
                  Pièces requises CARTE CONSULAIRE - Majeurs résidents
                </span>
                <p className="text-success fst-italic">
                  Délai moyen ( 1-2 semaines)
                </p>
              </div>
              <div>
                <ul className=" list-typeActe ">
                  <li>
                    Carte nationale d’identité ou attestation d’identité
                    délivrée par l’ONECI (Office National de l’Etat Civil et de
                    l’Identification) ou passeport en cours de validité ou
                    certificat de nationalité authentifié par le Ministère de la
                    Justice (moins de 2 ans);
                  </li>
                  <li>
                    Copie intégrale de l’acte de naissance ou copie du jugement
                    supplétif (moins de 1 an);
                  </li>
                  <li>
                    Copie intégrale de l’acte de mariage ou livret de famille
                    (pour les couples mariés et leur famille) ;{" "}
                  </li>
                  <li>
                    Titre de séjour en France en cours de validité ou la preuve
                    des démarches administratives à la Préfecture en vue de
                    l’obtention d’un titre;
                  </li>
                  <li>
                    Justificatif de domicile (EDF ou France Télécom ou Quittance
                    loyer) ;
                  </li>
                  <li>
                    Attestation d'hébergement légalisée (pour les personnes
                    habitant chez un tiers) + photocopie de la pièce d'identité
                    de l'hebergeant(e);{" "}
                  </li>
                  <li>Une (01) photo d’identité; ;</li>
                  <li>Certificat de célibat des deux conjoints;</li>
                  <li>
                    La fiche d'identification dûment renseignée{" "}
                    <a
                      href="https://france.diplomatie.gouv.ci/source/Fiche%20d%20immatriculation%20Consulat%20P
                  aris.pdf"
                      target='_blank'
                      className="text-decoration-none text-black fw-bolder"
                    >
                      france.diplomatie.gouv.ci
                    </a>
                    ;
                  </li>
                  <li>
                    Reçu de paiement des droits d'utilisation de la plateforme :
                    <span className="text-danger fw-bolder">1000 FCFA</span> (à
                    payer en ligne sur la plateforme:{" "}
                    <a
                      href="https://rdv-etatcivil.cloud"
                      target='_blank'
                      className="text-decoration-none text-black fw-bolder"
                    >
                      rdv-etatcivil.cloud
                    </a>
                    .)
                  </li>
                </ul>
                <p className="text-danger px-3">NOTA BENE :</p>
                <ul className="notaBene">
                  <li className="text-danger">
                    {" "}
                    En cas de perte de la carte consulaire :
                    <ul className=" list-typeActe  text-black px-2">
                      <li>
                        Déclaration de perte (main courante au commissariat du
                        lieu de résidence ou une déclaration sur l’honneur
                        délivrée par le Consulat Général)
                      </li>
                      <li>
                        Justificatif de domicile (EDF ou France Télécom ou
                        Quittance loyer) ;
                      </li>
                      <li>Une pièce d’identité</li>
                      <li>
                        Reçu de paiement des droits d'utilisation de la plateforme :{" "}
                        <span className="text-danger fw-bolder">1000 FCFA</span>{" "}
                        (à payer en ligne sur la plateforme:{" "}

                        .)
                      </li>
                    </ul>
                  </li>
                  <li className="text-danger">
                    {" "}
                    En cas de renouvellement de la carte consulaire :
                    <ul className=" list-typeActe  text-black px-2">
                      <li>
                        Renseigner le formulaire de demande d’actes consulaires
                        (à télécharger en ligne) ;
                      </li>
                      <li>Joindre la carte expirée au Consulat Général ;</li>
                      <li>
                        Joindre un justificatif de domicile (EDF ou France
                        Télécom ou Quittance loyer) ;
                      </li>
                      <li>
                        Reçu de paiement des droits d'utilisation de la plateforme :{" "}
                        <span className="text-danger fw-bolder">1000 FCFA</span>{" "}
                        (à payer en ligne sur la plateforme:{" "}

                        .)
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </>
          ) :
          formStep1.type == "DEMANDE CARTE CONSULAIRE - Mineurs" ?
            (
              <>
                <div className="px-3">
                  <BsFillQuestionCircleFill className="text-danger" />{" "}
                  <span>
                    Pièces requises CARTE CONSULAIRE - Mineurs
                  </span>
                  <p className="text-success fst-italic">
                    Délai moyen ( 1- 2 semaines)
                  </p>
                </div>
                <div>
                  <ul className=" list-typeActe ">
                    <li>
                      Copie intégrale de l’acte de naissance ou l’extrait d’acte de naissance ou copie du jugement supplétif (moins de 1 an);
                    </li>
                    <li>
                      Justificatif de domicile (Facture EDF ou France Télécom ou Quittance de loyer);
                    </li>
                    <li>
                      Autorisation parentale + photocopie de la pièce d’identité du père ou de la mère;{" "}
                    </li>
                    <li>
                      Une (01) photo d’identité ;
                    </li>
                    <li>
                      Reçu de paiement des droits d'utilisation de la plateforme :
                      <span className="text-danger fw-bolder">1000 FCFA</span> (à
                      payer en ligne sur la plateforme:{" "}

                      .)
                    </li>
                  </ul>
                  <p className="text-danger px-3">NOTA BENE :</p>
                  <ul className="notaBene">
                    <li className="text-danger">
                      {" "}
                      En cas de perte de la carte consulaire :
                      <ul className=" list-typeActe  text-black px-2">
                        <li>
                          Déclaration de perte (main courante au commissariat du
                          lieu de résidence ou une déclaration sur l’honneur
                          délivrée par le Consulat Général)
                        </li>
                        <li>
                          Justificatif de domicile (EDF ou France Télécom ou
                          Quittance loyer) ;
                        </li>
                        <li>Une pièce d’identité</li>
                        <li>
                          Reçu de paiement des droits d'utilisation de la plateforme :{" "}
                          <span className="text-danger fw-bolder">1000 FCFA</span>{" "}
                          (à payer en ligne sur la plateforme:{" "}


                          .)
                        </li>
                      </ul>
                    </li>
                    <li className="text-danger">
                      {" "}
                      En cas de renouvellement de la carte consulaire :
                      <ul className=" list-typeActe  text-black px-2">
                        <li>
                          Renseigner le formulaire de demande d’actes consulaires
                          (à télécharger en ligne) ;
                        </li>
                        <li>Joindre la carte expirée au Consulat Général ;</li>
                        <li>
                          Joindre un justificatif de domicile (EDF ou France
                          Télécom ou Quittance loyer) ;
                        </li>
                        <li>
                          Une enveloppe timbrée précisant l‘adresse exacte du demandeur.
                        </li>
                        <li>
                          Reçu de paiement des droits d'utilisation de la plateforme :{" "}
                          <span className="text-danger fw-bolder">1000 FCFA</span>{" "}
                          (à payer en ligne sur la plateforme:{" "}


                          .)
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </>
            ) :
            formStep1.type == "DEMANDE CARTE CONSULAIRE - Mineurs accompagnés par une association" ?
              (
                <>
                  <div className="px-3">
                    <BsFillQuestionCircleFill className="text-danger" />{" "}
                    <span>
                      Pièces requises CARTE CONSULAIRE - Mineurs accompagnés par une association
                    </span>
                    <p className="text-success fst-italic">
                      Délai moyen ( 1- 2 semaines)
                    </p>
                  </div>
                  <div>
                    <ul className=" list-typeActe ">
                      <li>
                        Copie intégrale de l 'acte de naissance ou de l’extrait d’acte de naissance ou copie du jugement supplétif (moins d ’un an) ;
                      </li>
                      <li>
                        Certificat de nationalité de moins de 2 ans, authentifié par le Ministère de la Justice ou passeport en cours de validité ;
                      </li>
                      <li>
                        Certificat de scolarité ;{" "}
                      </li>
                      <li>
                        Décision du Juge ;
                      </li>
                      <li>
                        Prise en charge de l’association ;
                      </li>
                      <li>
                        Autorisation parentale délivrée par le père ou la mère ou le tuteur légal;{" "}
                      </li>
                      <li>Une (01) photo d’identité;</li>
                      <li>Photocopie des pièces d’identité du père ou de la mère (Carte nationale d’identité ou certificat de nationalité) ;</li>
                      <li>
                        Reçu de paiement des droits d'utilisation de la plateforme :
                        <span className="text-danger fw-bolder">1000 FCFA</span> (à
                        payer en ligne sur la plateforme:{" "}

                        .)
                      </li>
                    </ul>
                    <p className="text-danger px-3">NOTA BENE :</p>
                    <ul className="notaBene">
                      <li className="text-danger">
                        {" "}
                        En cas de perte de la carte consulaire :
                        <ul className=" list-typeActe  text-black px-2">
                          <li>
                            Déclaration de perte (main courante au commissariat du
                            lieu de résidence ou une déclaration sur l’honneur
                            délivrée par le Consulat Général)
                          </li>
                          <li>
                            Justificatif de domicile (EDF ou France Télécom ou
                            Quittance loyer) ;
                          </li>
                          <li>Une pièce d’identité</li>
                          <li>
                            Reçu de paiement des droits d'utilisation de la plateforme :{" "}
                            <span className="text-danger fw-bolder">1000 FCFA</span>{" "}
                            (à payer en ligne sur la plateforme:{" "}


                            .)
                          </li>
                        </ul>
                      </li>
                      <li className="text-danger">
                        {" "}
                        En cas de renouvellement de la carte consulaire :
                        <ul className=" list-typeActe  text-black px-2">
                          <li>
                            Renseigner le formulaire de demande d’actes consulaires
                            (à télécharger en ligne) ;
                          </li>
                          <li>Joindre la carte expirée au Consulat Général ;</li>
                          <li>
                            Joindre un justificatif de domicile (EDF ou France
                            Télécom ou Quittance loyer) ;
                          </li>
                          <li>
                            Reçu de paiement des droits d'utilisation de la plateforme :{" "}
                            <span className="text-danger fw-bolder">1000 FCFA</span>{" "}
                            (à payer en ligne sur la plateforme:{" "}

                            .)
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </>
              ) :
              formStep1.type == "DEMANDE DE PASSEPORT" ? (
                <>
                  <div className="px-3">
                    <BsFillQuestionCircleFill className="text-danger" />{" "}
                    <span>
                      Pièces requises DEMANDE DE PASSEPORT
                    </span>
                    {/* <p className="text-success fst-italic">
                  Délai moyen ( 1- 2 semaines)
                </p> */}
                  </div>
                  <div>

                    <ul className=" list-typeActe ">
                      <h5 className="mt-3">
                        CAS POUR TOUS LES DEMANDEURS
                      </h5>
                      <li>
                        Une photocopie de la carte nationale d'identité (format CB ou CNI verte années 90);
                      </li>
                      <li>
                        Timbre de paiement : CB, Mandat, Virement: www.snedai.com;
                      </li>
                      <li>
                        Formulaire de demande désactivé rempli + 1 photo ;{" "}
                      </li>
                    </ul>

                    <p className="text-danger px-3">NOTA BENE :</p>
                    <ul className="notaBene">
                      <li className="text-danger">
                        {" "}
                        Pour plus de détails sur la liste complète des pièces à fournir :
                        <ul className=" list-typeActe  text-black px-2">
                          <li>
                            Veuillez consulter l'onglet passeport de la plateforme
                          </li>
                        </ul>
                      </li>
                    </ul>


                  </div>
                </>
              ) :
                formStep1.type == "RETRAIT DE PASSEPORT / VISAS" ? (
                  <>
                    <div className="px-3">
                      <BsFillQuestionCircleFill className="text-danger" />{" "}
                      <span>
                        Pièces requises RETRAIT DE PASSEPORT / VISAS
                      </span>
                    </div>
                    <div>
                      <ul className=" list-typeActe ">
                        <li>
                          Le numero du passeport ou du visa
                        </li>
                      </ul>
                    </div>
                  </>
                )
                  : formStep1.type == "CARTE CONSULAIRE - Majeurs résidents" ? (
                    <>
                      <div className="px-3">
                        <BsFillQuestionCircleFill className="text-danger" />{" "}
                        <span>
                          Pièces requises CARTE CONSULAIRE - Majeurs résidents
                        </span>
                        <p className="text-success fst-italic">
                          Délai moyen ( 1- 2 semaines)
                        </p>
                      </div>
                      <div>
                        <ul className=" list-typeActe ">
                          <li>
                            Carte nationale d’identité ou attestation d’identité
                            délivrée par l’ONECI (Office National de l’Etat Civil et de
                            l’Identification) ou passeport en cours de validité ou
                            certificat de nationalité authentifié par le Ministère de la
                            Justice (moins de 2 ans);
                          </li>
                          <li>
                            Copie intégrale de l’acte de naissance ou copie du jugement
                            supplétif (moins de 1 an);
                          </li>
                          <li>
                            Copie intégrale de l’acte de mariage ou livret de famille
                            (pour les couples mariés et leur famille) ;{" "}
                          </li>
                          <li>
                            Titre de séjour en France en cours de validité ou la preuve
                            des démarches administratives à la Préfecture en vue de
                            l’obtention d’un titre;
                          </li>
                          <li>
                            Justificatif de domicile (Facture CIE, SODECI , ...) ;
                          </li>
                          <li>
                            Attestation d'hébergement légalisée (pour les personnes
                            habitant chez un tiers) + photocopie de la pièce d'identité
                            de l'hebergeant(e);{" "}
                          </li>
                          <li>Une (01) photo d’identité; ;</li>
                          <li>Certificat de célibat des deux conjoints;</li>
                          <li>
                            La fiche d'identification dûment renseignée{" "}

                          </li>
                          <li>
                            Reçu de paiement des droits d'utilisation de la plateforme :
                            <span className="text-danger fw-bolder">1000 FCFA</span> (à
                            payer en ligne sur la plateforme:{" "}

                            .)
                          </li>
                        </ul>
                        <p className="text-danger px-3">NOTA BENE :</p>
                        <ul className="notaBene">
                          <li className="text-danger">
                            {" "}
                            En cas de perte de la carte consulaire :
                            <ul className=" list-typeActe  text-black px-2">
                              <li>
                                Déclaration de perte (main courante au commissariat du
                                lieu de résidence ou une déclaration sur l’honneur
                                délivrée par le Consulat Général)
                              </li>
                              <li>
                                Justificatif de domicile (EDF ou France Télécom ou
                                Quittance loyer) ;
                              </li>
                              <li>Une pièce d’identité</li>
                              <li>
                                Reçu de paiement des droits d'utilisation de la plateforme :{" "}
                                <span className="text-danger fw-bolder">1000 FCFA</span>{" "}
                                (à payer en ligne sur la plateforme:{" "}

                                .)
                              </li>
                            </ul>
                          </li>
                          <li className="text-danger">
                            {" "}
                            En cas de renouvellement de la carte consulaire :
                            <ul className=" list-typeActe  text-black px-2">
                              <li>
                                Renseigner le formulaire de demande d’actes consulaires
                                (à télécharger en ligne) ;
                              </li>
                              <li>Joindre la carte expirée au Consulat Général ;</li>
                              <li>
                                Joindre un justificatif de domicile (EDF ou France
                                Télécom ou Quittance loyer) ;
                              </li>
                              <li>
                                Reçu de paiement des droits d'utilisation de la plateforme :{" "}
                                <span className="text-danger fw-bolder">1000 FCFA</span>{" "}
                                (à payer en ligne sur la plateforme:{" "}

                                )
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </>
                  ) : formStep1.type == "CARTE CONSULAIRE - Mineurs" ? (
                    <>
                      <div className="px-3">
                        <BsFillQuestionCircleFill className="text-danger" />{" "}
                        <span>Pièces requises {formStep1.type}</span>
                        <p className="text-success fst-italic">
                          Délai moyen ( 1- 2 semaines)
                        </p>
                      </div>
                      <div>
                        <ul className=" list-typeActe ">
                          <li>
                            Copie intégrale de l’acte de naissance ou copie du jugement
                            supplétif (moins de 1 an);
                          </li>

                          <li>
                            Justificatif de domicile (Facture CIE, SODECI , ...) ;
                          </li>
                          <li>
                            Autorisation parentale + photocopie de la pièce d’identité
                            du père ou de la mère ;
                          </li>

                          <li>Une (01) photo d’identité; ;</li>
                          <li>
                            La fiche d'identification dûment renseignée{" "}

                            ;
                          </li>
                          <li>
                            Reçu de paiement des droits d'utilisation de la plateforme :{" "}
                            <span className="text-danger fw-bolder">1000 FCFA</span> (à
                            payer en ligne sur la plateforme:{" "}

                            .)
                          </li>
                        </ul>
                        <p className="text-danger px-3">NOTA BENE :</p>
                        <ul className="notaBene">
                          <li className="text-danger">
                            {" "}
                            En cas de perte de la carte consulaire :
                            <ul className=" list-typeActe  text-black px-2">
                              <li>
                                Déclaration de perte (main courante au commissariat du
                                lieu de résidence ou une déclaration sur l’honneur
                                délivrée par le Consulat Général)
                              </li>
                              <li>
                                Justificatif de domicile (EDF ou France Télécom ou
                                Quittance loyer) ;
                              </li>
                              <li>Une pièce d’identité</li>
                              <li>
                                Reçu de paiement des droits d'utilisation de la plateforme :{" "}
                                <span className="text-danger fw-bolder">1000 FCFA</span>{" "}
                                (à payer en ligne sur la plateforme:{" "}


                                .)
                              </li>
                            </ul>
                          </li>
                          <li className="text-danger">
                            {" "}
                            En cas de renouvellement de la carte consulaire :
                            <ul className=" list-typeActe  text-black px-2">
                              <li>
                                Renseigner le formulaire de demande d’actes consulaires
                                (à télécharger en ligne) ;
                              </li>
                              <li>Joindre la carte expirée au Consulat Général ;</li>
                              <li>
                                Joindre un justificatif de domicile (EDF ou France
                                Télécom ou Quittance loyer) ;
                              </li>
                              <li>
                                Reçu de paiement des droits d'utilisation de la plateforme :{" "}
                                <span className="text-danger fw-bolder">1000 FCFA</span>{" "}
                                (à payer en ligne sur la plateforme:{" "}

                                .)
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </>
                  ) : formStep1.type ==
                    "CARTE CONSULAIRE - Mineurs accompagnés par une association" ? (
                    <>
                      <div className="px-3">
                        <BsFillQuestionCircleFill className="text-danger" />{" "}
                        <span>Pièces requises {formStep1.type}</span>
                        <p className="text-success fst-italic">
                          Délai moyen ( 1- 2 semaines)
                        </p>
                      </div>
                      <div>
                        <ul className=" list-typeActe ">
                          <li>
                            Copie intégrale de l’acte de naissance ou copie du jugement
                            supplétif (moins de 1 an);
                          </li>

                          <li>
                            Justificatif de domicile (Facture CIE, SODECI , ...) ;
                          </li>
                          <li>
                            Autorisation parentale + photocopie de la pièce d’identité
                            du père ou de la mère ;
                          </li>

                          <li>Une (01) photo d’identité; ;</li>
                          <li>
                            La fiche d'identification dûment renseignée{" "}

                            ;
                          </li>
                          <li>
                            Reçu de paiement des droits d'utilisation de la plateforme :{" "}
                            <span className="text-danger fw-bolder">1000 FCFA</span> (à
                            payer en ligne sur la plateforme:{" "}

                            .)
                          </li>
                        </ul>
                        <p className="text-danger px-3">NOTA BENE :</p>
                        <ul className="notaBene">
                          <li className="text-danger">
                            {" "}
                            En cas de perte de la carte consulaire :
                            <ul className=" list-typeActe  text-black px-2">
                              <li>
                                Déclaration de perte (main courante au commissariat du
                                lieu de résidence ou une déclaration sur l’honneur
                                délivrée par le Consulat Général)
                              </li>
                              <li>
                                Justificatif de domicile (EDF ou France Télécom ou
                                Quittance loyer) ;
                              </li>
                              <li>Une pièce d’identité</li>
                              <li>
                                Reçu de paiement des droits d'utilisation de la plateforme :{" "}
                                <span className="text-danger fw-bolder">1000 FCFA</span>{" "}
                                (à payer en ligne sur la plateforme:{" "}

                                .)
                              </li>
                            </ul>
                          </li>
                          <li className="text-danger">
                            {" "}
                            En cas de renouvellement de la carte consulaire :
                            <ul className=" list-typeActe  text-black px-2">
                              <li>
                                Renseigner le formulaire de demande d’actes consulaires
                                (à télécharger en ligne) ;
                              </li>
                              <li>Joindre la carte expirée au Consulat Général ;</li>
                              <li>
                                Joindre un justificatif de domicile (EDF ou France
                                Télécom ou Quittance loyer) ;
                              </li>
                              <li>
                                Reçu de paiement des droits d'utilisation de la plateforme :{" "}
                                <span className="text-danger fw-bolder">1000 FCFA</span>{" "}
                                (à payer en ligne sur la plateforme:{" "}

                                .)
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </>
                  ) : formStep1.type ==
                    "COPIE D'ACTE DE NAISSANCE" ? (
                    <>
                      <div className="px-3">
                        <BsFillQuestionCircleFill className="text-danger" />{" "}
                        <span>Pièces requises {formStep1.type}</span>
                        <p className="text-success fst-italic">
                          Délai moyen ( 01 jour)
                        </p>
                      </div>
                      <div>
                        <ul className=" list-typeActe fs-6">
                          <li>
                            Le numéro de l'acte
                          </li>
                          <li>
                            La photocopie de l'acte
                          </li>

                          <li>Le nom du propriétaire de l'acte;</li>
                          <li>
                            Les nom et prénom des parents
                          </li>

                          <li>
                            Coût de la copie
                            <span className="text-danger fw-bold"> 500 FCFA</span> (à
                            payer en ligne sur la plateforme: Rdv-EtatCivil.cloud.)
                          </li>
                        </ul>
                      </div>
                    </>
                  ) : formStep1.type ==
                    "COPIE D'ACTE DE MARIAGE" ? (
                    <>
                      <div className="px-3">
                        <BsFillQuestionCircleFill className="text-danger" />{" "}
                        <span>Pièces requises {formStep1.type}</span>
                        <p className="text-success fst-italic">
                          Délai moyen ( 01 jour)
                        </p>
                      </div>
                      <div>
                        <ul className=" list-typeActe ">

                          <li>
                            Copie intégrale de l’acte de mariage de moins de 3 mois
                            (original);
                          </li>

                          <li>
                            Copies des pièces d ’identité des deux conjoints. L’un au
                            moins des conjoints doit être de nationalité ivoirienne et
                            présenter soit une copie de la carte nationale d’identité ou
                            une attestation d’identité délivrée par l 'ONECI ou
                            passeport en cours de validité ou certificat de nationalité
                            de moins de 2 ans, authentifié par le Ministère de la
                            Justice ;
                          </li>

                          <li>
                            Coût de la copie :
                            <span className="text-danger fw-bold">500 FCFA</span> (à
                            payer en ligne sur la plateforme: Rdv-EtatCivil.cloud


                            .)
                          </li>
                        </ul>
                      </div>
                    </>
                  ) : formStep1.type ==
                    "COPIE D'ACTE DE DECES" ? (
                    <>
                      <div className="px-3">
                        <BsFillQuestionCircleFill className="text-danger" />{" "}
                        <span>Pièces requises {formStep1.type}</span>
                        <p className="text-success fst-italic">
                          Délai moyen ( 01 jour)
                        </p>
                      </div>
                      <div>
                        <ul className=" list-typeActe ">
                          <li>
                            Le numéro de l'acte
                          </li>
                          <li>
                            La photocopie de l'acte
                          </li>
                          <li>Le nom du propriétaire de l'acte;</li>
                          <li>
                            Les nom et prénom des parents
                          </li>

                          <li>
                            Coût de la copie
                            <span className="text-danger fw-bold">500 FCFA</span> (à
                            payer en ligne sur la plateforme: Rdv-EtatCivil.cloud

                            .)
                          </li>
                        </ul>
                      </div>
                    </>
                  )
                    : formStep1.type ==
                      "JUGEMENT SUPPLETIF" ? (
                      <>
                        <div className="px-3">
                          <BsFillQuestionCircleFill className="text-danger" />{" "}
                          <span>Pièces requises {formStep1.type}</span>
                          <p className="text-success fst-italic">
                            Délai moyen ( ...)
                          </p>
                          <p className="">
                            Passé le délai légal de <span className="text-danger">3 mois</span>  requis pour la déclaration de naissance, le requérant doit se rendre à la <span className="text-danger">Mairie pour l’établissement d’un certificat de non déclaration de naissance, afin de poursuivre la procédure du jugement supplétif au Tribunal de Première Instance.</span>
                          </p>
                        </div>
                        <div>
                          <ul className=" list-typeActe ">
                            <li>
                              Certificat médical de naissance de l’enfant, ou un certificat d’âge physiologique délivré par un médecin
                            </li>
                            <li>
                              La copie de la CNI des père et mère, ou tout autre document justifiant l’identité
                            </li>

                          </ul>
                        </div>
                      </>
                    )
                      : formStep1.type ==
                        "LEGITIMATION A MA PROGENITURE" ? (
                        <>
                          <div className="px-3">
                            <BsFillQuestionCircleFill className="text-danger" />{" "}
                            <span>Pièces requises {formStep1.type}</span>
                            <p className="text-success fst-italic">
                              Délai moyen ( ...)
                            </p>
                            <p className="">
                              La légitimation concerne les enfants nés du couple, avant le mariage.                        </p>
                          </div>
                          <div>
                            <ul className=" list-typeActe ">
                              <li>
                                Certificat ou extrait de mariage du couple,                          </li>
                              <li>
                                Copie de l’extrait de naissance des enfants nés du couple avant le mariage,                          </li>

                            </ul>
                          </div>
                        </>
                      )
                        : formStep1.type == "DEMANDE DE RECONNAISSANCE D'ENFANT" ? (
                          <>
                            <div className="px-3">
                              <BsFillQuestionCircleFill className="text-danger" />{" "}
                              <span>Pièces requises {formStep1.type}</span>
                              <p className="text-success fst-italic">
                                Délai moyen ( ...)
                              </p>
                              <p className='text-danger'>
                                Conditions à remplir pour la reconnaissance d’un enfant :
                              </p>
                              <ul className=" list-typeActe ">
                                <li>La naissance de l’enfant qui n’a pas été reconnu par le père devra avoir été préalablement déclarée à la Mairie du lieu de naissance,</li>
                                <li>Le père qui veut reconnaitre l’enfant se rend à ladite Mairie avec la mère, muni de :</li>

                              </ul>
                              <p className='text-danger'>
                                Pièces à fournir :
                              </p>
                              <div>
                                <ul className=" list-typeActe ">

                                  <li>
                                    L’extrait de naissance de l’enfant à reconnaitre
                                  </li>

                                  <li>
                                    La copie de la CNI du père et de la mère, ou de tout autre document justifiant leur identité.
                                  </li>

                                </ul>
                              </div>
                            </div>

                          </>
                        ) : formStep1.type ==
                          "DEMANDE DE CELEBRATION DE MARIAGE" ? (
                          <>
                            <div className="px-3">
                              <BsFillQuestionCircleFill className="text-danger" />{" "}
                              <span>Pièces requises {formStep1.type}</span>
                            </div>
                            <div>
                              <ul className=" list-typeActe ">
                                <li>
                                  Une demande adressée au guichet d'etat civil concerné;
                                </li>
                                <li>
                                  Copie de la carte consulaire du conjoint
                                  ;
                                </li>

                                <li>
                                  {" "}
                                  Les pièces d'identité des conjoints (Carte nationale
                                  d’identité ou attestation d’identité délivrée par l 'ONECI
                                  ou passeport en cours de validité);
                                </li>
                                <li>
                                  Justificatif de domicile (Facture CIE, SODECI , ...) ;
                                </li>
                                <li>
                                  Attestation d’hébergement légalisée (pour les personnes
                                  habitant chez un tiers) + photocopie de la pièce d’identité
                                  de l’hébergeant (e) ;
                                </li>
                                <li>
                                  {" "}
                                  Les actes de naissance des deux conjoints de moins de trois
                                  (3) mois;
                                </li>
                                <li> Certificat de célibat pour les deux conjoints;</li>
                                <li>
                                  Certificat de coutume pour le conjoint de nationalité
                                  étrangère, délivré par le Consulat de son pays;
                                </li>
                                <li>
                                  Pièces d’identité et contacts des témoins ainsi que leurs
                                  adresses respectives ;
                                </li>

                                <li>
                                  Reçu de paiement des droits d'utilisation de la plateforme :{" "}
                                  <span className="text-danger fw-bold">20000 FCFA</span>
                                  {

                                  }
                                </li>
                              </ul>
                            </div>
                          </>
                        ) : formStep1.type == "DOSSIER EN VUE DE MARIAGE" ? (
                          <>
                            <div className="px-3">
                              <BsFillQuestionCircleFill className="text-danger" />{" "}
                              <span>Pièces requises {formStep1.type}</span>
                              <p className="text-success fst-italic">
                                Délai moyen ( 02 semaines)
                              </p>
                            </div>
                            <div>
                              {/* <ul className="notaBene">
                          <li> CERTIFICAT DE CAPACITE A MARIAGE (CCM)</li>
                          <li> CERTIFICAT DE COUTUME</li>
                          <li> CERTIFICAT DE CELIBAT</li>
                        </ul> */}
                              <ul className=" list-typeActe ">
                                <li>
                                  Extrait d'acte de naissance des futurs époux datant de moins de 3 mois ;

                                </li>

                                <li>Les pièces d'identité des futurs époux ; </li>
                                <li>
                                  les certificat de résidence des futurs époux ;
                                </li>
                                <li>
                                  2 Photos d'identité des futurs époux ;
                                </li>
                                <li>
                                  Une attestation de capacité matrimoniale pour le conjoint étranger
                                  ;
                                </li>
                                <li>
                                  Pieces d'identité des témoins
                                  ;
                                </li>
                                <li>
                                  Coût : Jeudi :<span className="text-danger fw-bold">41.000 FCFA</span>  Autres jours :<span className="text-danger fw-bold">51.000 FCFA</span>  (à payer en ligne sur la plateforme:

                                  .)
                                </li>


                              </ul>
                            </div>
                          </>
                        ) : formStep1.type == "CERTIFICAT DE CELIBAT" ? (
                          <>
                            <div className="px-3">
                              <BsFillQuestionCircleFill className="text-danger" />{" "}
                              <span>Pièces requises {formStep1.type}</span>
                              <p className="text-success fst-italic">
                                Délai moyen ( 02 semaines)
                              </p>
                            </div>
                            <div>
                              <ul className=" list-typeActe ">
                                <li>Carte consulaire ;</li>
                                <li>
                                  Carte nationale d’identité ou attestation d’identité
                                  délivrée par l’ONECI ou passeport en cours de validité ;
                                </li>

                                <li>
                                  Copie originale de l’extrait d’acte de naissance comportant
                                  toutes les mentions marginales, authentifié par la DGAT (au
                                  Ministère de l’Intérieur et de la Sécurité à Abidjan);
                                </li>
                                <li>
                                  Titre de séjour en cours de validité ou la preuve des
                                  démarches administratives à la Préfecture en vue de
                                  l’obtention d’un titre ;
                                </li>
                                <li>
                                  Justificatif de domicile (Facture CIE, SODECI , ...) ;
                                </li>
                                <li>
                                  Attestation d’hébergement légalisée (pour les personnes
                                  habitant chez un tiers) + photocopie de la pièce d’identité
                                  de l’hébergeant (e);
                                </li>

                                <li>
                                  Reçu de paiement des droits d'utilisation de la plateforme :{" "}
                                  <span className="text-danger fw-bold">6000 FCFA</span>
                                  (à payer en ligne sur la plateforme:

                                  .)
                                </li>
                              </ul>
                            </div>
                          </>
                        ) : formStep1.type ==
                          "AUTRES TYPES DE CERTIFICATS DE COUTUME - Pour les demandeurs ivoiriens résidents" ? (
                          <>
                            <div className="px-3">
                              <BsFillQuestionCircleFill className="text-danger" />{" "}
                              <span>Pièces requises {formStep1.type}</span>
                              <p className="text-success fst-italic">
                                Délai moyen ( 02 semaines)
                              </p>
                            </div>
                            <div>
                              <ul className=" list-typeActe ">
                                <li>Carte consulaire ;</li>
                                <li>
                                  Carte nationale d’identité ou attestation d’identité
                                  délivrée par l’ONECI ou passeport en cours de validité ;
                                </li>

                                <li>
                                  Copies des documents auxquels l 'intéressé(e) se réfère dans
                                  sa demande ;
                                </li>
                                <li>
                                  Titre de séjour en cours de validité ou la preuve des
                                  démarches administratives à la Préfecture en vue de
                                  l’obtention d’un titre ;
                                </li>
                                <li>
                                  Justificatif de domicile (Facture CIE, SODECI , ...) ;
                                </li>
                                <li>
                                  Attestation d’hébergement légalisée (pour les personnes
                                  habitant chez un tiers) + photocopie de la pièce d’identité
                                  de l’hébergeant (e);
                                </li>

                                <li>
                                  Reçu de paiement des droits d'utilisation de la plateforme :{" "}
                                  <span className="text-danger fw-bold">5000 FCFA</span>
                                  (à payer en ligne sur la plateforme:

                                  ).
                                </li>
                              </ul>
                            </div>
                          </>
                        ) : formStep1.type ==
                          "AUTRES TYPES DE CERTIFICATS DE COUTUME - Pour les demandeurs non ivoiriens" ? (
                          <>
                            <div className="px-3">
                              <BsFillQuestionCircleFill className="text-danger" />{" "}
                              <span>Pièces requises {formStep1.type}</span>
                              <p className="text-success fst-italic">
                                Délai moyen ( 02 semaines)
                              </p>
                            </div>
                            <div>
                              <ul className=" list-typeActe ">
                                <li>
                                  {" "}
                                  Formulaire de demande d’actes consulaires à renseigner (à
                                  télécharger en ligne) ;
                                </li>
                                <li> Pièce d'identité du demandeur ;</li>

                                <li>
                                  Copies des documents auxquels l 'intéressé(e) se réfère dans
                                  sa demande ;
                                </li>
                                <li>
                                  Justificatif de domicile (Facture CIE, SODECI , ...) ;
                                </li>
                                <li>
                                  Attestation d’hébergement légalisée (pour les personnes
                                  habitant chez un tiers) + photocopie de la pièce d’identité
                                  de l’hébergeant (e);
                                </li>

                                <li>
                                  Reçu de paiement des droits d'utilisation de la plateforme :{" "}
                                  <span className="text-danger fw-bold">5000 FCFA</span>
                                  (à payer en ligne sur la plateforme:

                                  ).
                                </li>
                              </ul>
                            </div>
                          </>
                        ) : formStep1.type == "LAISSEZ-PASSER - Pour les majeurs" ? (
                          <>
                            <div className="px-3">
                              <BsFillQuestionCircleFill className="text-danger" />{" "}
                              <span>Pièces requises {formStep1.type}</span>
                              <p className="text-success fst-italic">
                                Délai moyen ( 01 semaine)
                              </p>
                            </div>
                            <div>
                              <ul className=" list-typeActe ">
                                <li>
                                  {" "}
                                  Carte nationale d’identité ou attestation d 'identité
                                  délivrée par l’ONECI ou certificat de nationalité
                                  authentifié par le Ministère de la Justice (moins de 2 ans)
                                  ou copie du passeport perdu ;
                                </li>
                                <li>
                                  {" "}
                                  Déclaration de perte (Main courante de la Police ou
                                  déclaration sur l’honneur au Consulat Général) ;
                                </li>

                                <li>
                                  Justificatif de la fonction de l’intéressé (Facultatif) ;
                                </li>
                                <li>
                                  {" "}
                                  Attestation de réservation dans une compagnie aérienne ;
                                </li>
                                <li> Deux photos d’identité de même tirage ;</li>
                                <li> Justificatif de domicile ;</li>
                                <li>
                                  Attestation d’hébergement légalisée (pour les personnes
                                  habitant chez un tiers) + photocopie de la pièce d’identité
                                  de l’hébergeant (e);
                                </li>

                                <li>
                                  Reçu de paiement des droits d'utilisation de la plateforme :{" "}
                                  <span className="text-danger fw-bold">10000 FCFA</span>
                                  (à payer en ligne sur la plateforme:


                                  ).
                                </li>
                              </ul>
                            </div>
                          </>
                        ) : formStep1.type == "LAISSEZ-PASSER - Pour les mineurs" ? (
                          <>
                            <div className="px-3">
                              <BsFillQuestionCircleFill className="text-danger" />{" "}
                              <span>Pièces requises {formStep1.type}</span>
                              <p className="text-success fst-italic">
                                Délai moyen ( 01 semaine)
                              </p>
                            </div>
                            <div>
                              <ul className=" list-typeActe ">
                                <li>
                                  Copie intégrale de l 'acte de naissance délivrée par les
                                  autorités ivoiriennes ;
                                </li>
                                <li>
                                  Copie de la pièce d’identité ivoirienne de l'un des parents;
                                </li>

                                <li>
                                  {" "}
                                  Attestation de réservation dans une compagnie aérienne ;
                                </li>
                                <li> Deux photos d’identité de même tirage ;</li>
                                <li> Copie de la carte circulaire ;</li>
                                <li>
                                  {" "}
                                  Autorisation parentale légalisée du père ou de la mère ou
                                  des deux parents ou encore du représentant légal si ces
                                  personnes n 'accompagnent pas l’enfant + Copie de la pièce
                                  d’identité du parent donnant l’autorisation ;
                                </li>

                                <li>
                                  Reçu de paiement des droits d'utilisation de la plateforme :{" "}
                                  <span className="text-danger fw-bold">10000 FCFA</span>
                                  (à payer en ligne sur la plateforme:


                                  ).
                                </li>
                              </ul>
                            </div>
                          </>
                        ) : formStep1.type == "AUTRES ATTESTATIONS CONSULAIRES" ? (
                          <>
                            <div className="px-3">
                              <BsFillQuestionCircleFill className="text-danger" />{" "}
                              <span>Pièces requises {formStep1.type}</span>
                              <p className="text-success fst-italic">
                                Délai moyen ( 03 jours)
                              </p>
                            </div>
                            <div>
                              <ul className=" list-typeActe ">
                                <li>Carte consulaire ;</li>
                                <li>
                                  {" "}
                                  Copie de la pièce d’identité du demandeur (Carte nationale
                                  d’identité ou attestation d 'identité délivrée par l 'ONECI
                                  ou passeport en cours de validité ou certificat de
                                  nationalité authentifié par le Ministère de la Justice ;
                                </li>

                                <li>
                                  {" "}
                                  Titre de séjour en cours de validité ou la preuve des
                                  démarches administratives à la Préfecture en vue de
                                  l’obtention d’un titre ;
                                </li>
                                <li>
                                  {" "}
                                  Justificatif de domicile (Facture CIE, SODECI , ...) ;
                                </li>
                                <li>
                                  {" "}
                                  Attestation d’hébergement légalisée (pour les personnes
                                  habitant chez un tiers) + photocopie de la pièce d’identité
                                  de l’hébergeant (e);
                                </li>

                                <li>
                                  Reçu de paiement des droits d'utilisation de la plateforme :{" "}
                                  <span className="text-danger fw-bold">5000 FCFA</span>
                                  (à payer en ligne sur la plateforme:


                                  ).
                                </li>
                              </ul>
                              <p className="px-3">
                                <span className="fw-bolder text-danger">NB: </span> En raison
                                de la variété des attestations, le Consulat Général, après
                                examen de la demande, peut requérir un complément de pièces du
                                dossier.
                              </p>
                            </div>
                          </>
                        ) : formStep1.type == "CERTIFICAT DE VIE - Pour les ivoiriens" ? (
                          <>
                            <div className="px-3">
                              <BsFillQuestionCircleFill className="text-danger" />{" "}
                              <span>Pièces requises {formStep1.type}</span>
                              <p className="text-success fst-italic">
                                Délai moyen ( 03 jours)
                              </p>
                            </div>
                            <div>
                              <ul className=" list-typeActe ">
                                <li>
                                  {" "}
                                  Formulaire de demande d’actes consulaires à renseigner (à
                                  télécharger en ligne) ;
                                </li>
                                <li>Carte consulaire ;</li>
                                <li>Présence Physique de l 'intéressé(e) ;</li>
                                <li>
                                  {" "}
                                  Copie de la pièce d’identité du demandeur (Carte nationale
                                  d’identité ou attestation d 'identité délivrée par l 'ONECI
                                  ou passeport en cours de validité ) ;
                                </li>

                                <li>
                                  {" "}
                                  Titre de séjour en cours de validité ou la preuve des
                                  démarches administratives à la Préfecture en vue de
                                  l’obtention d’un titre ;
                                </li>
                                <li>
                                  {" "}
                                  Justificatif de domicile (Facture CIE, SODECI , ...) ;
                                </li>
                                <li>
                                  {" "}
                                  Attestation d’hébergement légalisée (pour les personnes
                                  habitant chez un tiers) + photocopie de la pièce d’identité
                                  de l’hébergeant (e);
                                </li>

                                <li>
                                  Reçu de paiement des droits d'utilisation de la plateforme :{" "}
                                  <span className="text-danger fw-bold">5000 FCFA</span>
                                  (à payer en ligne sur la plateforme:


                                  ).
                                </li>
                              </ul>
                              <p className="px-3">
                                <span className="fw-bolder text-danger">NB: </span> Au cas où
                                l’intéressé ne peut se présenter au Consulat :
                              </p>
                              <ul className=" list-typeActe ">
                                <li>
                                  {" "}
                                  Solliciter le certificat de vie auprès de l 'administration
                                  française de son lieu de résidence ;
                                </li>
                                <li>
                                  {" "}
                                  Faire légaliser ledit certificat de vie au Ministère
                                  français des Affaires Etrangères puis le soumettre au
                                  Consulat Général de Côte d’Ivoire pour traitement de la
                                  demande;
                                </li>
                              </ul>
                            </div>
                          </>
                        ) : formStep1.type ==
                          "CERTIFICAT DE VIE - Pour les non ivoiriens" ? (
                          <>
                            <div className="px-3">
                              <BsFillQuestionCircleFill className="text-danger" />{" "}
                              <span>Pièces requises {formStep1.type}</span>
                              <p className="text-success fst-italic">
                                Délai moyen ( 03 jours)
                              </p>
                            </div>
                            <div>
                              <ul className=" list-typeActe ">
                                <li>
                                  {" "}
                                  Faire légaliser le certificat de vie (délivré par la Mairie)
                                  au Ministère français des Affaires Etrangères puis le
                                  soumettre au Consulat Général de Côte d’Ivoire pour
                                  traitement ;
                                </li>
                                <li>
                                  Formulaire de demande d’actes consulaires à renseigner (à
                                  télécharger en ligne) ;
                                </li>

                                <li>
                                  {" "}
                                  Justificatif de domicile (Facture CIE, SODECI , ...) ;
                                </li>
                                <li>
                                  {" "}
                                  Attestation d’hébergement légalisée (pour les personnes
                                  habitant chez un tiers) + photocopie de la pièce d’identité
                                  de l’hébergeant (e);
                                </li>

                                <li>
                                  Reçu de paiement des droits d'utilisation de la plateforme :{" "}
                                  <span className="text-danger fw-bold">5000 FCFA</span>
                                  (à payer en ligne sur la plateforme:


                                  ).
                                </li>
                              </ul>
                            </div>
                          </>
                        ) : formStep1.type == "ATTESTATION CONSULAIRE D'IDENTITE" ? (
                          <>
                            <div className="px-3">
                              <BsFillQuestionCircleFill className="text-danger" />{" "}
                              <span>Pièces requises {formStep1.type}</span>
                              <p className="text-success fst-italic">
                                Délai moyen ( 03 jours)
                              </p>
                            </div>
                            <div>
                              <ul className=" list-typeActe ">
                                <li>
                                  {" "}
                                  Formulaire de demande d’actes consulaires à renseigner (à
                                  télécharger en ligne) ;
                                </li>
                                <li>Carte consulaire ;</li>
                                <li> Demande formulée par l’intéressé (e);</li>
                                <li>
                                  {" "}
                                  Copie intégrale de l 'acte de naissance de moins de 3 mois
                                  (original);
                                </li>
                                <li>
                                  {" "}
                                  Carte nationale d’identité ou attestation d’identité
                                  délivrée par l’ONECI ou photocopie du passeport expiré ou
                                  certificat de nationalité authentifié par le Ministère de la
                                  Justice ;
                                </li>

                                <li>
                                  {" "}
                                  Justificatif de domicile (Facture CIE, SODECI , ...) ;
                                </li>
                                <li>
                                  {" "}
                                  Pièce d’identité des parents si le demandeur ne peut
                                  présenter sa carte nationale d’identité, son attestation
                                  d’identité ou la photocopie de son passeport.
                                </li>
                                <li>
                                  Titre de séjour en cours de validité ou la preuve des
                                  démarches administratives à la Préfecture en vue de
                                  l’obtention d’un titre ;
                                </li>
                                <li>
                                  {" "}
                                  Attestation d’hébergement légalisée (pour les personnes
                                  habitant chez un tiers) + photocopie de la pièce d’identité
                                  de l’hébergeant (e);
                                </li>

                                <li>
                                  Reçu de paiement des droits d'utilisation de la plateforme :{" "}
                                  <span className="text-danger fw-bold">5000 FCFA</span>
                                  (à payer en ligne sur la plateforme:


                                  ).
                                </li>
                              </ul>
                              <p className="px-3">
                                <span className="fw-bolder text-danger">NB:</span> Cette
                                attestation n’est pas celle délivrée par l’ONECI ; c’est un
                                acte consulaire qui permet de « confirmer » l'identité de l
                                'usager de nationalité ivoirienne, sur la base des documents
                                de l’ONECI produit par le demandeur.
                              </p>
                            </div>
                          </>
                        ) : formStep1.type == "AUTORISATION DE TRANSFERT DE CORPS" ? (
                          <>
                            <div className="px-3">
                              <BsFillQuestionCircleFill className="text-danger" />{" "}
                              <span>Pièces requises {formStep1.type}</span>
                              <p className="text-success fst-italic">
                                Délai moyen ( 01 jour)
                              </p>
                            </div>
                            <div>
                              <ul className=" list-typeActe ">
                                <li>
                                  {" "}
                                  Copie intégrale de l’acte de décès du défunt (à faire
                                  transcrire dans les registres d’état civil) ;
                                </li>
                                <li> Certificat médical de décès ;</li>
                                <li> Certificat médical de non contagion;</li>
                                <li> Certificat de non épidémie ;</li>
                                <li> Certificat de soins conservations ;</li>
                                <li> Procès-verbal de mise en bière ;</li>
                                <li>
                                  {" "}
                                  Autorisation de transport de corps après mise en bière ;
                                </li>
                                <li> Autorisation de fermeture du cercueil ;</li>
                                <li> Laissez-passer mortuaire ;</li>
                                <li>
                                  {" "}
                                  Justificatif d 'identité du défunt (Carte Nationale
                                  d’Identité, ou attestation d 'identité délivrée par l 'ONECI
                                  ou passeport en cours de validité) ;
                                </li>

                                <li>
                                  Reçu de paiement des droits d'utilisation de la plateforme :{" "}
                                  <span className="text-danger fw-bold">5000 FCFA</span>
                                  (à payer en ligne sur la plateforme:


                                  ).
                                </li>
                              </ul>
                              {/* <p className="px-3">
                  <span className="fw-bolder text-danger">NB:</span> Contacter
                  les pompes funèbres pour les démarches et le dépôt du dossier
                  au Consulat Général.
                </p> */}
                            </div>
                          </>
                        ) : formStep1.type == "PROCURATION" ? (
                          <>
                            <div className="px-3">
                              <BsFillQuestionCircleFill className="text-danger" />{" "}
                              <span>Pièces requises {formStep1.type}</span>
                              <p className="text-success fst-italic">
                                Délai moyen ( 03 jours)
                              </p>
                            </div>
                            <div>
                              <ul className=" list-typeActe ">
                                <li> Lettre de procuration/de constitution ;</li>
                                <li> Carte consulaire ;</li>
                                <li>
                                  {" "}
                                  Carte nationale d’identité ou attestation d’identité
                                  délivrée par l’ONECI ou passeport en cours de validité ou
                                  certificat de nationalité authentifié par le Ministère de la
                                  Justice (moins de 2 ans) du mandant ;
                                </li>
                                <li>
                                  {" "}
                                  Copie de la pièce d’identité du demandeur (Carte nationale
                                  d’identité ou attestation d 'identité délivrée par l 'ONECI
                                  ou passeport en cours de validité ou certificat de
                                  nationalité authentifié par le Ministère de la Justice
                                  (moins de 2 ans) ;
                                </li>
                                <li>
                                  {" "}
                                  Titre de séjour en cours de validité ou la preuve des
                                  démarches administratives à la Préfecture en vue de
                                  l’obtention d’un titre ;
                                </li>
                                <li>
                                  {" "}
                                  Justificatif de domicile (Facture CIE, SODECI , ...) ;
                                </li>
                                <li>
                                  {" "}
                                  Attestation d’hébergement légalisée (pour les personnes
                                  habitant chez un tiers) + photocopie de la pièce d’identité
                                  de l’hébergeant (e);
                                </li>
                                <li> Copie de la pièce d’identité du mandataire ;</li>
                                <li> Laissez-passer mortuaire ;</li>

                                {/* <li>
                    Reçu de paiement des droits d'utilisation de la plateforme :{" "}
                    <span className="text-danger fw-bold">5000 FCFA</span>
                    (à payer en ligne sur la plateforme:
                    <
          tex
                    ).
                  </li> */}
                                <ul className="liste-imbriquee">
                                  <li>
                                    <span className="fw-bolder text-danger">1500 FCFA</span>{" "}
                                    pour les ressortissants Ivoiriens
                                  </li>
                                  <li>
                                    {" "}
                                    <span className="fw-bolder text-danger">
                                      3000 FCFA
                                    </span>{" "}
                                    pour les Non-Ivoiriens
                                  </li>
                                </ul>
                              </ul>
                            </div>
                          </>
                        ) : formStep1.type ==
                          "LEGALISATION DES ACTES/ LEGALISATION DE SIGNATURE - Acte à légaliser" ? (
                          <>
                            <div className="px-3">
                              <BsFillQuestionCircleFill className="text-danger" />{" "}
                              <span>Pièces requises {formStep1.type}</span>
                              <p className="text-success fst-italic">
                                Délai moyen ( 01 jour)
                              </p>
                            </div>
                            <div>
                              <ul className=" list-typeActe ">
                                <li> Carte consulaire ;</li>
                                <li>
                                  {" "}
                                  Carte nationale d 'identité ou attestation d 'identité
                                  délivrée par l 'ONECI ou passeport en cours de validité ou
                                  certificat de nationalité authentifié par le Ministère de la
                                  Justice ;
                                </li>
                                <li>
                                  {" "}
                                  Titre de séjour en cours de validité ou la preuve des
                                  démarches administratives à la Préfecture en vue de
                                  l’obtention d’un titre ;
                                </li>
                                <li>
                                  Justificatif de domicile (Facture CIE, SODECI , ...) ;
                                </li>
                                <li>
                                  {" "}
                                  Attestation d’hébergement légalisée (pour les personnes
                                  habitant chez un tiers) + photocopie de la pièce d’identité
                                  de l’hébergeant (e);
                                </li>

                                <li>
                                  Reçu de paiement des droits d'utilisation de la plateforme :{" "}
                                  <span className="text-danger fw-bold">2000 FCFA</span>
                                  (à payer en ligne sur la plateforme:


                                  ).
                                </li>
                              </ul>
                              {/* <p className="px-3">
                  <span className="fw-bolder text-danger">NB:</span> Contacter
                  les pompes funèbres pour les démarches et le dépôt du dossier
                  au Consulat Général.
                </p> */}
                            </div>
                          </>
                        ) : formStep1.type ==
                          "LEGALISATION DES ACTES/ LEGALISATION DE SIGNATURE - Acte sous-seing privé" ? (
                          <>
                            <div className="px-3">
                              <BsFillQuestionCircleFill className="text-danger" />{" "}
                              <span>Pièces requises {formStep1.type}</span>
                              <p className="text-success fst-italic">
                                Délai moyen ( 01 jour)
                              </p>
                            </div>
                            <div>
                              <ul className=" list-typeActe ">
                                <li> Carte consulaire ;</li>
                                <li>
                                  {" "}
                                  Carte nationale d 'identité ou attestation d 'identité
                                  délivrée par l 'ONECI ou passeport en cours de validité ou
                                  certificat de nationalité authentifié par le Ministère de la
                                  Justice ;
                                </li>
                                <li>
                                  {" "}
                                  Titre de séjour en cours de validité ou la preuve des
                                  démarches administratives à la Préfecture en vue de
                                  l’obtention d’un titre ;
                                </li>
                                <li>
                                  Justificatif de domicile (Facture CIE, SODECI , ...) ;
                                </li>
                                <li>
                                  {" "}
                                  Attestation d’hébergement légalisée (pour les personnes
                                  habitant chez un tiers) + photocopie de la pièce d’identité
                                  de l’hébergeant (e);
                                </li>

                                <li>
                                  Reçu de paiement des droits d'utilisation de la plateforme :{" "}
                                  <span className="text-danger fw-bold">1500 FCFA</span>
                                  (à payer en ligne sur la plateforme:


                                  ).
                                </li>
                              </ul>
                              {/* <p className="px-3">
                  <span className="fw-bolder text-danger">NB:</span> Contacter
                  les pompes funèbres pour les démarches et le dépôt du dossier
                  au Consulat Général.
                </p> */}
                            </div>
                          </>
                        ) : formStep1.type ==
                          "LEGALISATION DES ACTES/ LEGALISATION DE SIGNATURE - Diplômes - Autres documents (Contrats - conventions…)" ? (
                          <>
                            <div className="px-3">
                              <BsFillQuestionCircleFill className="text-danger" />{" "}
                              <span>Pièces requises {formStep1.type}</span>
                              <p className="text-success fst-italic">
                                Délai moyen ( 01 jour)
                              </p>
                            </div>
                            <div>
                              <ul className=" list-typeActe ">
                                <li> Carte consulaire ;</li>
                                <li>
                                  {" "}
                                  Carte nationale d 'identité ou attestation d 'identité
                                  délivrée par l 'ONECI ou passeport en cours de validité ou
                                  certificat de nationalité authentifié par le Ministère de la
                                  Justice ;
                                </li>
                                <li>
                                  {" "}
                                  Titre de séjour en cours de validité ou la preuve des
                                  démarches administratives à la Préfecture en vue de
                                  l’obtention d’un titre ;
                                </li>
                                <li>
                                  Justificatif de domicile (Facture CIE, SODECI , ...) ;
                                </li>
                                <li>
                                  {" "}
                                  Attestation d’hébergement légalisée (pour les personnes
                                  habitant chez un tiers) + photocopie de la pièce d’identité
                                  de l’hébergeant (e);
                                </li>

                                <li>
                                  Reçu de paiement des droits d'utilisation de la plateforme :{" "}
                                  <span className="text-danger fw-bold">5000 FCFA</span>
                                  (à payer en ligne sur la plateforme:


                                  ).
                                </li>
                              </ul>
                              {/* <p className="px-3">
                  <span className="fw-bolder text-danger">NB:</span> Contacter
                  les pompes funèbres pour les démarches et le dépôt du dossier
                  au Consulat Général.
                </p> */}
                            </div>
                          </>
                        ) : formStep1.type == "DECLARATION DE PERTE" ? (
                          <>
                            <div className="px-3">
                              <BsFillQuestionCircleFill className="text-danger" />{" "}
                              <span>Pièces requises {formStep1.type}</span>
                              <p className="text-success fst-italic">
                                Délai moyen ( 01 jour)
                              </p>
                            </div>
                            <div>
                              <span className="px-3">(Sur l’honneur)</span>
                              <ul className=" list-typeActe ">
                                <li>
                                  {" "}
                                  Carte nationale d’identité ou attestation d’identité
                                  délivrée par l 'ONECI ou passeport en cours de validité ou
                                  (acte de naissance + certificat de nationalité authentifié
                                  par le Ministère de la Justice (moins de 2 ans)) ;
                                </li>
                                <li>
                                  {" "}
                                  Titre de séjour en cours de validité ou la preuve des
                                  démarches administratives à la Préfecture en vue de
                                  l’obtention d’un titre ;
                                </li>
                                <li>
                                  Justificatif de domicile (Facture CIE, SODECI , ...) ;
                                </li>
                                <li>
                                  {" "}
                                  Attestation d’hébergement légalisée (pour les personnes
                                  habitant chez un tiers) + photocopie de la pièce d’identité
                                  de l’hébergeant (e);
                                </li>
                                <li>
                                  Reçu de paiement des droits d'utilisation de la plateforme :{" "}
                                  <span className="text-danger fw-bold">10000 FCFA</span>
                                  (à payer en ligne sur la plateforme:


                                  ).
                                </li>
                              </ul>
                              {/* <p className="px-3">
                  <span className="fw-bolder text-danger">NB:</span> Contacter
                  les pompes funèbres pour les démarches et le dépôt du dossier
                  au Consulat Général.
                </p> */}
                            </div>
                          </>
                        ) : formStep1.type == "AUTHENTIFICATION DE PERMIS" ? (
                          <>
                            <div className="px-3">
                              <BsFillQuestionCircleFill className="text-danger" />{" "}
                              <span>Pièces requises {formStep1.type}</span>
                              <p className="text-success fst-italic">
                                Délai moyen ( 02 semaines)
                              </p>
                            </div>
                            <div>
                              <ul className=" list-typeActe ">
                                <li>Carte consulaire ;</li>
                                <li>Copie du permis de conduire nouvelle formule ;</li>
                                <li>
                                  Carte nationale d’identité ou attestation d’identité
                                  délivrée par l’ONECI ou certificat de nationalité (moins de
                                  2 ans) authentifié par le Ministère de la Justice ou
                                  passeport en cours de validité;
                                </li>
                                <li>
                                  {" "}
                                  Titre de séjour en cours de validité ou la preuve des
                                  démarches administratives à la Préfecture en vue de
                                  l’obtention d’un titre ;
                                </li>
                                <li>
                                  Justificatif de domicile (Facture CIE, SODECI , ...) ;
                                </li>
                                <li>
                                  {" "}
                                  Attestation d’hébergement légalisée (pour les personnes
                                  habitant chez un tiers) + photocopie de la pièce d’identité
                                  de l’hébergeant (e);
                                </li>
                                <li>
                                  Reçu de paiement des droits d'utilisation de la plateforme :{" "}
                                  <span className="text-danger fw-bold">2000 FCFA</span>
                                  (à payer en ligne sur la plateforme:


                                  ).
                                </li>
                              </ul>
                              {/* <p className="px-3">
                  <span className="fw-bolder text-danger">NB:</span> Contacter
                  les pompes funèbres pour les démarches et le dépôt du dossier
                  au Consulat Général.
                </p> */}
                            </div>
                          </>
                        ) : formStep1.type == "AUTORISATION PARENTALE" ? (
                          <>
                            <div className="px-3">
                              <BsFillQuestionCircleFill className="text-danger" />{" "}
                              <span>Pièces requises {formStep1.type}</span>
                              {/* <p className="text-success fst-italic">
                  Délai moyen ( 02 semaines)
                </p> */}
                            </div>
                            <div>
                              <ul className=" list-typeActe ">
                                <li>Carte consulaire ;</li>
                                <li>
                                  {" "}
                                  Carte nationale d’identité ou attestation d’identité
                                  délivrée par l 'ONECI ou passeport en cours de validité ou
                                  (acte de naissance + certificat de nationalité du parent,
                                  authentifié par le Ministère de la Justice (moins de 2 ans)
                                  ;)
                                </li>
                                <li>
                                  Justificatif de domicile (Facture CIE, SODECI , ...) ;
                                </li>
                                <li>
                                  {" "}
                                  Formulaire de demande d’actes consulaires à renseigner (à
                                  télécharger en ligne) ;
                                </li>
                                <li> Copie intégrale de l’acte de naissance de l’enfant;</li>
                                <li>
                                  Reçu de paiement des droits d'utilisation de la plateforme :{" "}
                                  <span className="text-danger fw-bold"> 1500 FCFA</span>
                                  (à payer en ligne sur la plateforme:


                                  ).
                                </li>
                              </ul>
                              {/* <p className="px-3">
                  <span className="fw-bolder text-danger">NB:</span> Contacter
                  les pompes funèbres pour les démarches et le dépôt du dossier
                  au Consulat Général.
                </p> */}
                            </div>
                          </>
                        ) : formStep1.type == "AVIS DE NON OPPOSITION (PUBLICATION)" ? (
                          <>
                            <div className="px-3">
                              <BsFillQuestionCircleFill className="text-danger" />{" "}
                              <span>Pièces requises {formStep1.type}</span>
                              <p className="pt-3">
                                <span className="text-uppercase">à</span> la demande des
                                administrations françaises (mairies).
                              </p>
                              <p className="text-danger">Droits d'utilisation de la plateforme : Sans</p>
                            </div>
                          </>
                        ) : formStep1.type ==
                          "DEMANDE DE CONFIRMATION DE SIGNATURE PAR LES BANQUES OU AUTRES INSTITUTIONS" ? (
                          <>
                            <div className="px-3">
                              <BsFillQuestionCircleFill className="text-danger" />{" "}
                              <span>Pièces requises {formStep1.type}</span>
                              <p className="text-success fst-italic">
                                Délai moyen ( 01 jour)
                              </p>
                              <ul className=" list-typeActe ">
                                <li>Lettre de demande de confirmation de la Banque ;</li>
                                <li> Copie du document légalisé par le Consulat Général ;</li>
                              </ul>
                              <p className="text-danger">Droits d'utilisation de la plateforme : Sans</p>
                            </div>
                          </>
                        ) : formStep1.type ==
                          "DOCUMENTS « À CERTIFIER CONFORME » À L'ORIGINAL" ? (
                          <>
                            <div className="px-3">
                              <BsFillQuestionCircleFill className="text-danger" />{" "}
                              <span>Pièces requises {formStep1.type}</span>
                              <p className="text-success fst-italic">
                                Délai moyen ( 01 jour)
                              </p>
                              <ul className=" list-typeActe ">
                                <li> Carte consulaire ;</li>
                                <li>
                                  {" "}
                                  Documents produits par le Consulat Général ou par
                                  l’Ambassade à certifier conforme à l’original ;
                                </li>
                                <li>
                                  {" "}
                                  Ou photocopie en couleur du document (recto verso)
                                  accompagné de l’original ;
                                </li>
                              </ul>
                              <p className="text-danger">Droits d'utilisation de la plateforme : Sans</p>
                            </div>
                          </>
                        ) : formStep1.type == "AVIS DE MENTION" ? (
                          <>
                            <div className="px-3">
                              <BsFillQuestionCircleFill className="text-danger" />{" "}
                              <span>Pièces requises {formStep1.type}</span>
                              <p className="text-danger pt-3">
                                Droits d'utilisation de la plateforme : Sans
                              </p>
                            </div>
                          </>
                        ) : (
                          ""
                        )}
        <div className="px-3">
          <span >
            Les frais de l'acte ne constituent pas le montant finale à payer , ajouter à ceux-ci les frais de service de RDV-ETATCIVIL qui s'élève à <span className="text-danger">350 FCFA</span>  et/ou des frais de livraison selon la tarification que vous choisirez
          </span>
        </div>

      </div>


    </>
  );
}

export default Step11;
