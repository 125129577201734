import React from "react";
import { useNavigate } from "react-router-dom";
import { ENUM_LINKS } from "../../constants/enum_links";

function Footer() {
  const navigate = useNavigate()
  return (
    <div class="footer-main m-md-0 p-md-5 p-5">
      <div class="row text-center">
        <div class="col-md-12 col-sm-12 col-lg-4  pt-5">
          <img src="/images/rci.svg" alt="" srcset="" style={{ width: 145 }} />
          <h3 className="text-center">Côte d'Ivoire</h3>
        </div>
        <div class="col-md-6 col-sm-6 col-lg-4 pt-5">
          <h3>Liens rapides</h3>
          {/* <hr style={{width:'17%'}}/> */}
          <p onClick={() => {navigate("/"); window.scrollTo(0,0)}} style={{ cursor: 'pointer' }}>Accueil</p>
          <p onClick={() => {navigate("/details/actes-civil"); window.scrollTo(0,0)}} style={{ cursor: 'pointer' }}>Actes d'etats d'etats civil</p>

          {
            ENUM_LINKS.map((link) => {
              return (
                <p onClick={() => {navigate(link.href); window.scrollTo(0,0)}} style={{ cursor: 'pointer' }}>
                  {link.name}
                </p>
              );
            })
          }
        </div>
        <div class="col-md-6 col-sm-6 col-lg-4 pt-5">
          <h3>Vos données</h3>
          {/* <hr style={{width:'17%'}}/> */}
          <p>Politique de confidentialité</p>
          <p>Mentions légales</p>
        </div>
      </div>
    </div>
  );
}

export default Footer;
