import React, { useState, useEffect } from 'react';
import FormDeclaration from '../Declaration/FormDeclaration';
import DocumentDeclaration from '../Declaration/DocumentDeclaration';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { Alert, Modal } from "react-bootstrap";
import Spinner from '../gifs/Spinner.gif';
import ImgSuccess from '../imgs/checked.png';
import { registerAdmin, registerUrl, registerDeclaration } from "../Url";
import VerificationDeclaration from '../Declaration/VerificationDeclaration';
import ChoicePayment from '../Declaration/ChoicePayment';

const DeclarationPage = () => {
  const [alert, setAlert] = useState("none")
  const [loadingTransactions, setLoadingTransactions] = useState(false) //Toggle display of loader
  const [showAlertDanger, setShowAlertDanger] = useState(false) //Show Modal for inscription Validé
  const [showAlertSuccess, setShowAlertSuccess] = useState(false) //Show Modal for Inscription Réfusée

  const [stepper, setStepper] = useState(1);
  const [documentDeclaration, setDocumentDeclaration] = useState({});
  const [fomDeclaration, setFomDeclaration] = useState({});
  const [fomVerification, setFomVerificationDeclaration] = useState({});
  const [choicePayment, setChoicePayment] = useState({});


  const user = JSON.parse(window?.localStorage.getItem("userData") ?? "{}");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [formDeclarationValid, setFormDeclarationValid] = useState(false)
  const [documentDeclarationValid, setDocumentDeclarationValid] = useState(false)
  const [verificationDeclarationValid, setVerificationDeclarationValid] = useState(false)
  const [choicePaymentValid, setChoicePaymentValid] = useState(false);

  const [submit, setSubmit] = useState(false);

  const handleCloseModalForLoadingTransactions = () => {
    setLoadingTransactions(false);
  }

  const handleCloseModalForAlertDanger = () => {
    setShowAlertDanger(false);
    setSubmit(false)
  }

  const handleCloseModalForAlertSuccess = () => {
    setShowAlertSuccess(false);
  }

  const handleNext = () => {
    if (stepper >= 1 && stepper < 3) {
      setStepper(stepper + 1);
    }
  };
  const handlePrevious = () => {
    if (stepper >= 2 && stepper <= 3) {
      setStepper(stepper - 1);
    }
  };

  function formDeclarationData(data) {
    setFomDeclaration(data);
    setFormDeclarationValid(data.isValid)
  }

  function documentDeclarationData(data) {
    setDocumentDeclaration(data);
    setDocumentDeclarationValid(data.isValid)
  }

  function verificationDeclarationData(data) {
    setFomVerificationDeclaration(data);
    setVerificationDeclarationValid(data.isValid)
  }

  function choicePaymentData(data) {
    setChoicePayment(data);
    setChoicePaymentValid(data.isValid)
    console.log("choicePaymentData in DeclarationPage", data)
  }


  const handleSubmit = () => {
    setLoadingTransactions(true)
    let data = {
      ...fomDeclaration,
      country: "Cote d'Ivoire",
      ...documentDeclaration,
      request_receipt: "", //After
      status_payment: "NOT PAID", //will be "PAID" after payment
      declaration_status: "NEW", //will be "COMPLETED" after payment
    };

    data['declarations'] = {
      ...fomDeclaration,
      country: "Cote d'Ivoire",
      status_payment: "NOT PAID", //will be "PAID" after payment
      declaration_status: "NEW", //will be "COMPLETED" after payment
      ...documentDeclaration,
      request_receipt: "", //After
    }

    console.log("data declaration", data)

    axios.all([
      // axios.post(registerAdmin).then(console.log("Adminn is OK")),
      axios.post(registerDeclaration, data)
        .then((response) => { console.log("response axios", response); console.log("register is OK"); setLoadingTransactions(false); setAlert("sucess"); setShowAlertSuccess(true); setTimeout(() => { window.location.reload() }, 5000) })

        .catch((error) => { console.log(error); setLoadingTransactions(false); setAlert("danger");  setShowAlertDanger(true) })
    ])

  }

  return (
    <div className='justify-content-center align-items-center d-flex px-5'>
      <div className="bg-white text-start rounded  mt-3 my-2 col-12 ">
        <div>
          {/* Loading for Payment and Register */}
          {
            loadingTransactions ? (
              <Modal show={loadingTransactions} onHide={handleCloseModalForLoadingTransactions} backdrop="static" keyboard={false} size="lg"
                centered>
                <Modal.Header className="">
                  <Modal.Title className="">
                    <div className="d-flex align-items-center text-center notification-containgImg-text">
                      Veuillez patienter le temps qu'on actionne la procédure d'enregistrement. Cela peut prendre 1 - 2 minutes.
                    </div>
                    <div className="notification-containgImg-spinner text-center">
                      <img src={Spinner} className="notification-containImg-spinner-img" />
                    </div>
                  </Modal.Title>
                </Modal.Header>
              </Modal>
            ) : ""
          }


          {/* Alert For Inscription */}
          {
            alert === "sucess" ? (
              <Modal show={showAlertSuccess} onHide={handleCloseModalForAlertSuccess} backdrop="static" keyboard={false} size="lg"
                centered>
                <Modal.Header className="bg-success-subtle border-bottom border-success">
                  <Modal.Title className="w-100">
                    <div className="d-flex w-100 justify-content-center text-success">
                      Inscription validé
                    </div>
                  </Modal.Title>
                </Modal.Header>

                <Modal.Body className="bg-success-subtle">
                  <div className="d-flex w-100 justify-content-center">
                    Suivez votre mail ou vos SMS
                  </div>
                  <div className="d-flex w-100 justify-content-center">
                    Rechargement de la page 4 secondes
                  </div>
                  <div className="notification-containgImg-spinner text-center">
                    <img src={ImgSuccess} className="notification-containImg-spinner-img" />
                  </div>
                </Modal.Body>

              </Modal>
            )

              :

              alert === "danger" ?

                (
                  <Modal show={showAlertDanger} onHide={handleCloseModalForAlertDanger} backdrop="static" keyboard={false} size="lg"
                    centered>
                    <Modal.Header className="bg-danger-subtle text-danger border-bottom border-danger" closeButton>
                      <Modal.Title className="w-100">
                        <div className="d-flex w-100 justify-content-center notification-containgImg-text">
                          Procédure de déclaration réfusée
                        </div>
                      </Modal.Title>
                    </Modal.Header>

                    <Modal.Body className="bg-danger-subtle">
                      <p>Il se peut que :</p>
                      <div className="notification-containgImg-spinner">
                        <ul>
                        <li>---Les champs pour la procédure soient mal renseignés. Vérifiez et réessayez</li>
                          <li>---Votre connexion WiFi ou donnée mobile ait un problème. Vérifiez votre connexion et réessayez</li>
                          <li>---Le serveur du RDV-ÉtatCivil soit saturé. Patientez quelques minutes et réessayez</li>
                        </ul>
                      </div>
                    </Modal.Body>

                  </Modal>
                )

                :

                ""
          }
          <div className="my-5 stepperContainer d-flex justify-content-center align-items-center">

            <div className="my-2 col-12">
              <div className="steps d-flex gap-3 justify-content-center">

                {/* Step 1 */}
                <div
                  className={`${stepper == 1 ? "activeStep shadow" : ""
                    } px-sm-4 py-sm-2 step d-flex justify-content-center align-items-center text-center rounded-4 `}
                >
                  <span className="step-number">1</span>
                  <span className="step-title">Informations personnelles</span>
                </div>

                {/* Step 2 */}
                <div
                  className={`${stepper == 2 ? "activeStep shadow" : ""
                    } px-sm-4 py-sm-2 step d-flex justify-content-center align-items-center text-center rounded-4`}
                >
                  <span className="step-number">2</span>
                  <span className="step-title">Documents réquis</span>
                </div>

                {/* Step 3 */}
                <div
                  className={`${stepper == 3 ? "activeStep shadow" : ""
                    } px-sm-4 py-sm-2 step d-flex justify-content-center align-items-center text-center rounded-4 `}
                >
                  <span className="step-number">3</span>
                  <span className="step-title">Vérification</span>
                </div>

              </div>
              <div className="bg-white shadow step-box text-start rounded p-4 mt-3">
                {loading && (
                  <div className="text-center rendu mt-3">
                    Votre demande a été envoyée avec succès. Veuillez vérifier votre boite mail pour plus de détails!
                  </div>
                )}
                {!loading && (
                  <>
                    <div className="rendu mt-3">
                      {stepper == 1 && (
                        <FormDeclaration
                          fomDeclaration={fomDeclaration}
                          formDeclarationData={formDeclarationData}
                        />
                      )}
                      {stepper == 2 && (
                        <DocumentDeclaration
                          documentDeclaration={documentDeclaration}
                          documentDeclarationData={documentDeclarationData}
                          actualStep={stepper}
                        />
                      )}
                      {/* {stepper == 3 && (
                        <ChoicePayment
                          choicePayment={choicePayment}
                          choicePaymentData={choicePaymentData} />
                      )} */}

                      {stepper == 3 && (
                        <VerificationDeclaration
                          declarationSummarize={{ ...fomDeclaration, ...documentDeclaration }}
                          verificationDeclarationData={verificationDeclarationData}
                        />
                      )}



                    </div>

                    <div className="my-3 block-btn">
                      {stepper == 1 && formDeclarationValid ? (
                        <div className="text-end align-bottom">
                          <button className="btn-step-next rounded-5" onClick={handleNext}>
                            Suivant
                          </button>
                        </div>
                      ) : stepper > 1 && stepper <= 4 ? (
                        <div className="d-flex justify-content-between">
                          {/* <button className="btn-step-back" onClick={handlePrevious}>
                      Retour
                    </button> */}
                          {stepper > 1 && (
                            <button className="btn-step-back rounded-5" onClick={handlePrevious}>
                              Retour
                            </button>
                          )}
                          {stepper === 2 && documentDeclarationValid && (
                            <button className="btn-step-next rounded-5" onClick={handleNext}>
                              Suivant
                            </button>
                          )}
                          {/* {stepper === 3 && choicePaymentValid && (
                            <button className="btn-step-next rounded-5" onClick={handleNext}>
                              Suivant
                            </button>
                          )} */}
                          {stepper === 3 && (
                            <button className="btn-step-next rounded-5" onClick={handleSubmit}>
                              Valider
                            </button>
                          )}

                          {stepper === 3 && submit && (
                            <button className="btn-step-next-disabled rounded-5" disabled>
                              Patientez...
                            </button>
                          )}

                        </div>
                      ) : (
                        <div className="d-flex justify-content-between">
                          {formDeclarationValid &&
                            documentDeclarationValid && submit && (
                              <button className="btn-step-next rounded-5" disabled>
                                Patientez...
                              </button>
                            )}
                          {stepper === 3 && formDeclarationValid &&
                            documentDeclarationValid && !submit && (
                              <button className="btn-step-next rounded-5" onClick={handleSubmit}>
                                Valider
                              </button>
                            )}
                        </div>
                      )}
                    </div>
                  </>
                )}

              </div>
            </div>
          </div>
        </div>


      </div>
    </div>
  );
};

export default DeclarationPage;