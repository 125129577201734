import React, { useState, useEffect } from 'react';
import Step1 from "../Components/stepper/Step1";
import Step2 from "../Components/stepper/Step2";
import Step3 from "../Components/stepper/Step3";
import Step4 from "../Components/stepper/Step4";
import Step5 from "../Components/stepper/Step5";
import Step6 from "../Components/stepper/Step6";
import Step7 from "../Components/stepper/Step7";
import axios from "axios";
import { rdvByIdUlr, registerUrl, updatePayStatus, updateRdv } from "../Url";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";
import { TextField } from "@mui/material";
import { Alert } from "react-bootstrap";
import Spinner from '../gifs/Spinner.gif'
import ImgSuccess from "../imgs/checked.png"

const PaymentPage = () => {

  const location = useLocation();

  const { requestInvoice } = useParams();

  const [showOrangeMoney, setShowOrangeMoney] = useState(false)
  const [otp, setOtp] = useState("")
  const [alert, setAlert] = useState("none")
  const [alertWave, setAlertWave] = useState("none")
  const [errorOfAxios, setErrorOfAxios] = useState("")
  const [timeoutDiscount, setTimeoutDiscount] = useState(false)

  const [loadingTransactions, setLoadingTransactions] = useState(false) //Toggle display of loader
  const [showAlertDanger, setShowAlertDanger] = useState(false) //Show Modal for inscription Validé
  const [showAlertSuccess, setShowAlertSuccess] = useState(false) //Show Modal for Inscription Réfusée
  const [showAlertSuccessWave, setShowAlertSuccessWave] = useState(false) //Show Modal for Wave

  const [userInformations, setUserInformations] = useState("");

  const [stepper, setStepper] = useState(5);
  const [step1Fom, setStep1Fom] = useState({});
  const [step2Fom, setStep2Fom] = useState({});
  const [step3Fom, setStep3Fom] = useState({});
  const [step4Fom, setStep4Fom] = useState({});
  const [step33Fom, setStep33Fom] = useState({});
  const [step5Fom, setStep5Fom] = useState({});
  const [step6Fom, setStep6Fom] = useState({}); //Paiement
  const [step7Fom, setStep7Fom] = useState({});

  const [loading, setLoading] = useState(false);
  const user = JSON.parse(window?.localStorage.getItem("userData") ?? "{}");
  const navigate = useNavigate();
  const [step1Valid, setStep1Valid] = useState(false)
  const [step2Valid, setStep2Valid] = useState(false)
  const [step3Valid, setStep3Valid] = useState(false)
  const [step4Valid, setStep4Valid] = useState(false)
  const [step33Valid, setStep33Valid] = useState(false)
  const [step5Valid, setStep5Valid] = useState(false)
  const [step6Valid, setStep6Valid] = useState(false)
  const [step7Valid, setStep7Valid] = useState(false)

  const [submit, setSubmit] = useState(false);

  //Check on DB if user already pays so we will block his path to pay again
  useEffect(() => {
    const getUserInfo = async () => {
      await axios.get(`${rdvByIdUlr}/${requestInvoice}`)
        .then(response => { 
          if(response.data.rdv_status !== 'VALIDATED'){
            navigate('/errors')
          }
          if (response.data.rdv_status === 'VALIDATED' && response.data.pay_status !== null){
            navigate('/errors')
          } else{
            setUserInformations(response.data); console.log('response axios userInfo', response) 
          }
        })
        .catch(err => {console.log('error axios userInfo', err); navigate('/errors')})
    }

    getUserInfo()
  }, [requestInvoice])

  const handleCloseModalOrangeMoney = () => {
    setShowOrangeMoney(false);
  }

  const handleShowModalOrangeMoney = () => {
    setShowOrangeMoney(true);
  }

  const handleCloseModalForLoadingTransactions = () => {
    setLoadingTransactions(false);
  }

  const handleCloseModalForAlertDanger = () => {
    setShowAlertDanger(false);
    setSubmit(false)
  }

  const handleCloseModalForAlertSuccess = () => {
    setShowAlertSuccess(false);
  }

  const handleCloseModalForAlertSuccessWave = () => {
    setShowAlertSuccessWave(false);
  }

  const handleNext = () => {
    if (stepper >= 1 && stepper < 7) {
      setStepper(stepper + 1);
    }
  };
  const handlePrevious = () => {
    if (stepper >= 2 && stepper <= 7) {
      setStepper(stepper - 1);
    }
  };

  useEffect(() => {
    if (user && user?.id) {
      window.localStorage.clear();
      //navigate("/");
    }
  }, []);

  function step5Data(data) {
    setStep5Fom(data);
    setStep5Valid(data.isValid)
    // console.log(stepper, step1Valid,
    //   step2Valid, step3Valid, step4Valid,
    //   step5Valid, submit)
  }

  function step6Data(data) {
    setStep6Fom(data);
    setStep6Valid(data.isValid)
    // console.log(stepper, step1Valid,
    //   step2Valid, step3Valid, step4Valid,
    //   step5Valid, step6Valid, submit)
  }

  function step7Data(data) {
    setStep7Fom(data);
    setStep7Valid(data.isValid)
    // console.log(stepper, step1Valid,
    //   step2Valid, step3Valid, step4Valid,
    //   step5Valid, step6Valid, submit)
  }

  const onChangeTextField = (event) => {
    console.log("otp", otp)
    setOtp(event.target.value)
    setStep7Fom({ ...step7Fom, otp: otp })
  }

  useEffect(() => {
    setStep7Fom({ ...step7Fom, otp: otp })
  }, [otp])

  useEffect(() => {
    setAlertWave(alertWave)
  }, [alertWave])

  //Check if the user can proceed to payment
  useEffect(() => {
    const getUserInfo = async () => {
      await axios.get(`${rdvByIdUlr}/${requestInvoice}`)
        .then(response => { setUserInformations(response.data); console.log('response axios userInfo', response) })
        .catch(err => console.log('error axios userInfo', err))
    }

    getUserInfo()
  }, [requestInvoice])


  function handleSubmitForMobileMoney() {
    setLoadingTransactions(true)
    setSubmit(true)

    if (showOrangeMoney) {
      handleCloseModalOrangeMoney() //Close Modal if canal is Orange Money
    }

    console.log("step7Fom", step7Fom)

    let data = {
      ...userInformations,
      ...step6Fom,
      ...step5Fom,
      pay_status: true,
      country: "Cote d'Ivoire",
      proffession: "none",
    };
    if (!data.formule) {
      data.formule = "basic";
    }
    data['rdvs'] = {
      country: "Cote d'Ivoire",
      ...userInformations,
      ...step6Fom,
      pay_status: true,
      ...step5Fom,
      proffession: "none",
      who_request: userInformations.who_request,
    }

    console.log("data", data)

    axios.all([
      axios.post(`${process.env.REACT_APP_API_PAYMENT}`, {
        "beneficiaire": step7Fom.phonePayment,
        "beneficiaireOmnivox": null,
        "idClientCarte": null,
        "numeroCarte": null,
        "montant": step5Fom.montant,
        "devise": "XOF",
        "canal": step7Fom.canal,
        "type": "Retrait",
        "otp": step7Fom.otp,
        "description": "Paiement demarche Etat Civil",
        "submit": null
      }, {
        headers: {
          'Authorization': `Bearer ${process.env.REACT_APP_WALLETID}`
        }
      }).then((response) => {
        setLoadingTransactions(false);
        window.localStorage.setItem("recu", JSON.stringify(response.data));
        setAlert("sucess"); setShowAlertSuccess(true); setTimeout(() => { navigate('/') }, 10000)


        //Lauch update automatically if payment is ok
        axios.put(`${updatePayStatus}/${requestInvoice}`, data)
          .then((response) => { console.log(response); console.log("update is OK"); })
          .catch((error) => { console.log(error) })
      })
        .catch((error) => { setLoadingTransactions(false); console.log(error); setAlert("danger"); setShowAlertDanger(true); setErrorOfAxios(error.response.data) }),
    ])
  }

  function handleSubmitForWave() {
    setLoadingTransactions(true)
    setSubmit(true)
    console.log("step7Fom MONTANT", step7Fom)

    let data = {
      ...userInformations,
      ...step6Fom,
      ...step5Fom,
      country: "Cote d'Ivoire",
      proffession: "none",
    };
    if (!data.formule) {
      data.formule = "basic";
    }
    data['rdvs'] = {
      country: "Cote d'Ivoire",
      ...userInformations,
      ...step6Fom,
      ...step5Fom,
      proffession: "none",
      who_request: userInformations.who_request,
    }

    axios.all([
      axios.post(`${process.env.REACT_APP_API_PAYMENT}`, {
        "beneficiaire": step7Fom.phonePayment,
        "beneficiaireOmnivox": null,
        "idClientCarte": null,
        "numeroCarte": null,
        "montant": step5Fom.montant,
        "devise": "XOF",
        "canal": "Wave",
        "type": "Retrait",
        "otp": "",
        "description": "Paiment Demarche Etat Civil",
        "submit": null
      }, {
        headers: {
          'Authorization': `Bearer ${process.env.REACT_APP_WALLETID}`,
          'Content-Type': 'application/json',
          'Accept': '*/*'
        }
      }).then((response) => {
        setAlert("pending");
        setShowAlertSuccessWave(true);
        console.log("response Wave", response.data);
        setAlertWave(response.data.paymentUrl);
        window.localStorage.setItem("recu", JSON.stringify(response.data));


        //Lauch update automatically if payment is ok
        axios.put(`${updatePayStatus}/${requestInvoice}`, data)
          .then((response) => { console.log(response); console.log("update is OK"); })
          .catch((error) => { console.log(error) })

      })
        .catch((error) => { setLoadingTransactions(false); console.log("paiment isnt OK"); setAlert("danger"); setShowAlertDanger(true); setSubmit(false); console.log(error); }),
    ])
  }
  return (
    <div>
      {/* Loading for Payment and Register */}
      {
        loadingTransactions ? (
          <Modal show={loadingTransactions} onHide={handleCloseModalForLoadingTransactions} backdrop="static" keyboard={false} size="lg"
            centered>
            <Modal.Header className="">
              <Modal.Title className="">
                <div className="d-flex align-items-center text-center notification-containgImg-text">
                  Veuillez patienter le temps qu'on actionne la procédure de paiement. Cela peut prendre 1 - 2 minutes. (Voir plus si vous ne detenez pas de connexion stable).
                </div>
                <div className="notification-containgImg-spinner text-center">
                  <img src={Spinner} className="notification-containImg-spinner-img" />
                </div>
              </Modal.Title>
            </Modal.Header>
          </Modal>
        ) : ""
      }


      {/* Alert For Mobile Money */}
      {
        alert === "sucess" ? (
          <Modal show={showAlertSuccess} onHide={handleCloseModalForAlertSuccess} backdrop="static" keyboard={false} size="lg"
            centered>
            <Modal.Header className="bg-success-subtle border-bottom border-success">
              <Modal.Title className="w-100">
                <div className="d-flex w-100 justify-content-center text-success">
                  Paiement validé
                </div>
              </Modal.Title>
            </Modal.Header>

            <Modal.Body className="bg-success-subtle">
              <div className="d-flex w-100 justify-content-center">
                Suivez vos mails ou vos SMS pour plus d'informations
              </div>
              <div className="d-flex w-100 justify-content-center">
                Redirection vers la page dans X secondes
              </div>
              <div className="notification-containgImg-spinner text-center">
                <img src={ImgSuccess} className="notification-containImg-spinner-img" />
              </div>
            </Modal.Body>

          </Modal>
        )

          :

          alert === "danger" ?
            (
              <Modal show={showAlertDanger} onHide={handleCloseModalForAlertDanger} backdrop="static" keyboard={false} size="lg"
                centered>
                <Modal.Header className="bg-danger-subtle text-danger border-bottom border-danger" closeButton>
                  <Modal.Title className="w-100">
                    <div className="d-flex w-100 justify-content-center notification-containgImg-text">
                      Paiement réfusé
                    </div>
                  </Modal.Title>
                </Modal.Header>

                <Modal.Body className="bg-danger-subtle">
                  <p>Il se peut que :</p>
                  <div className="notification-containgImg-spinner">
                    <ul>
                      <li>---Les champs pour la procédure soient mal renseignés. Vérifiez et réessayez</li>
                      <li>---Votre connexion WiFi ou donnée mobile ait un problème. Vérifiez votre connexion et réessayez</li>
                      <li>---Le serveur du RDV-ÉtatCivil soit saturé. Patientez quelques minutes et réessayez</li>
                    </ul>
                  </div>
                </Modal.Body>

              </Modal>
            )

            :

            ""
      }

      {/* Alert For Wave */}
      {
        alert === "pending" ? (
          <Modal show={showAlertSuccessWave} onHide={handleCloseModalForAlertSuccessWave} backdrop="static" keyboard={false} size="lg"
            centered>
            <Modal.Header className="bg-info-subtle border-bottom border-info">
              <Modal.Title className="w-100">
                <div className="d-flex w-100 justify-content-center text-info">
                  Paiement presque validé avec Wave Money
                </div>
              </Modal.Title>
            </Modal.Header>

            <Modal.Body className="bg-info-subtle">
              <div className="d-flex w-100 justify-content-center">
                Cliquez sur ce lien pour finaliser votre paiement Wave :
              </div>

              <div className="d-flex w-100 justify-content-center">
                <button role="button"
                  className="link-opacity-100-hover btn btn-info"
                  onClick={() => {
                    window.open(alertWave, "_blank"); setTimeoutDiscount(true);
                    setTimeout(() => { navigate('/') }, 60000)
                  }}
                  target="_blank">
                  {alertWave}
                </button>
              </div>

              {
                timeoutDiscount && (
                  <>
                    <div className="d-flex w-100 justify-content-center">
                      redirection vers la page d'accueil dans X secondes
                    </div>

                    <div className="notification-containgImg-spinner text-center">
                      <img src={ImgSuccess} className="notification-containImg-spinner-img" />
                    </div>
                  </>
                )
              }
            </Modal.Body>

          </Modal>
        )

          :

          ""
      }
      <div className="my-5 stepperContainer d-flex justify-content-center align-items-center">
        {/* Modal for OTP if Step6fom.canal === Orange Money */}
        {
          step6Fom.canal === "Orange Money"

            ?

            <Modal show={showOrangeMoney} onHide={handleCloseModalOrangeMoney}>
              <Modal.Header closeButton>
                <Modal.Title>Vu que vous avez sélectionner Orange Money, entrez sur votre téléphone #144*82#, suivez la procédure. Vous recevrez par SMS, un code important à 4 chiffres que vous entrerez dans le champ ci-dessous</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <TextField className="w-100" value={otp} onChange={onChangeTextField} label="Entrez le code OTP que vous avez reçu par SMS" variant="outlined" />
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseModalOrangeMoney}>
                  Fermer
                </Button>
                <Button variant="primary" onClick={handleSubmitForMobileMoney}>
                  Valider la transaction
                </Button>
              </Modal.Footer>
            </Modal>

            :

            ""

        }

        <div className="my-2 col-10">
          <div className="steps d-flex gap-3">

            {/* Step 1 */}


            {step1Fom.type !== "RETRAIT DE PASSEPORT / VISAS" && (
              <div
                className={`${stepper == 5 ? "activeStep shadow" : ""
                  } px-sm-4 py-sm-2 step d-flex justify-content-center align-items-center text-center rounded-4 `}
              >
                <span className="step-number">1</span>
                <span className="step-title">Expédition d'actes d'états civils</span>
              </div>
            )}

            {step1Fom.type !== "RETRAIT DE PASSEPORT / VISAS" && (
              <div
                className={`${stepper == 6 ? "activeStep shadow" : ""
                  } px-sm-4 py-sm-2 step d-flex justify-content-center align-items-center text-center rounded-4 `}
              >
                <span className="step-number">2</span>
                <span className="step-title">Choix pour paiement</span>
              </div>
            )}

            {step1Fom.type !== "RETRAIT DE PASSEPORT / VISAS" && (
              <div
                className={`${stepper == 7 ? "activeStep shadow" : ""
                  } px-sm-4 py-sm-2 step d-flex justify-content-center align-items-center text-center rounded-4 `}
              >
                <span className="step-number">3</span>
                <span className="step-title">Paiement</span>
              </div>
            )}

          </div>
          <div className="bg-white step-box text-start rounded p-4 mt-3">
            {loading && (
              <div className="text-center rendu mt-3">
                Votre demande a été envoyée avec succès. Veuillez vérifier votre boite mail pour plus de détails!
              </div>
            )}
            {!loading && (
              <>
                <div className="rendu  mt-3">
                  {stepper == 5 && step1Fom.type !== "RETRAIT DE PASSEPORT / VISAS" && (
                    <Step5
                      userInformations={userInformations}
                      step5Fom={step5Fom}
                      step5Data={step5Data}
                      type={step1Fom.type}
                    />
                  )}
                  {stepper == 6 && step1Fom.type !== "RETRAIT DE PASSEPORT / VISAS" && (
                    <Step6
                      stepInformationPers={step3Fom}
                      step6Fom={step6Fom}
                      step6Data={step6Data}
                      type={step1Fom.type}
                    />
                  )}
                  {stepper == 7 && step1Fom.type !== "RETRAIT DE PASSEPORT / VISAS" && (
                    <Step7
                      tarification={step5Fom.formule}
                      canal={step6Fom.canal}
                      infoForPayment={userInformations}
                      step7Data={step7Data}
                    />
                  )}
                </div>

                <div className="my-3 block-btn">
                  {
                    stepper == 5 && step5Valid ? (
                      <div className="text-end align-bottom">
                        <button className="btn-step-next rounded-5" onClick={handleNext}>
                          Suivant
                        </button>
                      </div>
                    ) : stepper > 5 && stepper <= 7 ? (
                      <div className="d-flex justify-content-between">
                        <button className="btn-step-back rounded-5" onClick={handlePrevious}>
                          Retour
                        </button>

                        {stepper === 5 && step5Valid && !submit && (
                          <button className="btn-step-next rounded-5" onClick={handleNext}>
                            Suivant
                          </button>
                        )}


                        {stepper === 6 && step5Valid && !submit && (
                          <button className="btn-step-next rounded-5" onClick={handleNext}>
                            Suivant
                          </button>
                        )}

                        {/* {stepper === 7 && step1Valid &&
                      step2Valid && step3Valid && step4Valid && step5Valid && step6Valid && !submit && (
                        <button className="btn-step-next rounded-5" onClick={handleNext}>
                          Valider
                        </button>
                      )} */}

                        {/* Toggling Validation paiement si c'est orange money ou pas qu'elle a choisi au Step6 */}
                        {stepper === 7 && step5Valid && step6Valid && step6Fom.canal === "Orange Money" && step6Fom.canal !== "Wave" && !submit && (
                          <button className="btn-step-next rounded-5" onClick={handleShowModalOrangeMoney}>
                            Valider
                          </button>
                        )}

                        {stepper === 7 && step5Valid && step6Valid && step6Fom.canal !== "Orange Money" && step6Fom.canal !== "Wave" && !submit && (
                          <button className="btn-step-next rounded-5" onClick={handleSubmitForMobileMoney}>
                            Valider
                          </button>
                        )}
                        {/* Toggling Validation paiement si c'est orange money ou pas */}

                        {/* Case if Mobile Payment choosed is Wave */}
                        {stepper === 7 && step5Valid && step6Valid && step6Fom.canal === "Wave" && !submit && (
                          <button className="btn-step-next rounded-5" onClick={handleSubmitForWave}>
                            Valider
                          </button>
                        )}


                        {/* {stepper === 4 && step4Valid && step33Valid && (
                      <button className="btn-step-next rounded-5" onClick={handleNext}>
                        Suivant
                      </button>
                    )} */}

                      </div>
                    ) : (
                      <div className="d-flex justify-content-between">
                        {
                          step5Valid && submit && (
                            <button className="btn-step-next rounded-5" disabled>
                              Patientez...
                            </button>
                          )}
                        {
                          step5Valid && step6Valid && !submit && (
                            <button className="btn-step-next rounded-5">
                              Soumettre
                            </button>
                          )}
                      </div>
                    )}
                </div>
              </>
            )}

          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentPage;