import { useCallback, useEffect, useState } from "react";
import { Autocomplete, TextField } from "@mui/material";
import { convertExcelToJson } from "../../Data/Convert_XLXS_to_JSON";
import Listes_Communes from "../../Data/Listes_Communes.xlsx";
import { Alert } from "react-bootstrap";

const Step2 = ({ step2Fom, step2Data }) => {
    const [communes, setCommunes] = useState([])
    const [selectedCommune, setSelectedCommune] = useState(null)
    const [displayAlert, setDisplayAlert] = useState(false)
    const [isStructureSelected, setIsStructureSelected] = useState("Mairie")


    useEffect(() => {
        const getCommunes = async () => {
            await convertExcelToJson(Listes_Communes).
                then(res => setCommunes(res));
        }

        getCommunes()
    }, [])

    const [formStep2, setFormStep2] = useState({
        commune: "",
        isValid: false,
        getPassed: true,
        type_commune_or_sousPrefecture: "",
        ...step2Fom,
    });

    const handleUpdate = (event, value) => {
        setSelectedCommune(value)
        setDisplayAlert(true) //toggling display alert
        console.log(value);
        setFormStep2({ ...formStep2, commune: value, });
    }

    useEffect(() => {
        console.log(formStep2)
    }, [selectedCommune])

    useEffect(() => {
        step2Data(formStep2)
    }, [formStep2])

    const handleChange = (event, value) => {
        setFormStep2({ ...formStep2, commune: value });
        console.log('formStep', formStep2);
    };

    useEffect(() => {
        if (formStep2.isValid) {
            setFormStep2({ ...formStep2, isValid: true })
        }
        else {
            setFormStep2({ ...formStep2, isValid: false })
        }
    }, [formStep2]); //used to generate easily the button 'suivant'

    return (
        <div className="d-flex justify-content-center align-items-center">
            <form className="col-md-8">
                <div className="text-danger">* Sélectionnez la commune ou la sous préfecture</div>

                {/* <select style={{ borderRadius: "8px", padding: "10px" }} className="w-100" value={selectedCommune} onChange={handleUpdate}>
                    <option value="">-- Choisissez votre commune ou sous-préfecture --</option>
                    {communes.map((commune, key) => {
                        return (
                            <option key={key} value={commune.nom}>
                                {commune.nom}
                            </option>
                        )
                    })}
                </select>
                */}

                <Autocomplete
                    className="mt-2 w-100 custom-font"
                    id="combo-box-demo"
                    getOptionLabel={(option) => option.nom}
                    options={communes}
                    onChange={(event, value) => { if (value === null) { handleUpdate(event, "") } else { handleUpdate(event, value.nom) } }}
                    sx={{ width: 300, marginTop: 0  }}
                    renderInput={(params) => <TextField  {...params} label="Sélection d'une commune de Côte d'Ivoire" />} />

                {
                    displayAlert ?

                        <div className="mt-3">
                            <Alert variant="danger">
                                <Alert.Heading>IMPORTANT </Alert.Heading>
                                <p>
                                    Après avoir sélectionner l'acte d'état civil ci-dessus, vous devez préter attention au choix juste en-bas.
                                </p>
                                <p>
                                    L'acte d'etat civil doit être édité dans le guichet d'etat civil où votre déclaration de naissance a été effectuer.
                                </p>
                                <p>
                                    Si votre déclaration de naissance a été enregistré dans une mairie, selectionnez Mairie .
                                </p>
                                <p>
                                    Par contre si votre déclaration de naissance a été enregistré dans une Sous Prefecture, selectionnez Sous Prefecture .
                                </p>
                            </Alert>

                            <div className="d-flex justify-content-center gap-4 flex-wrap">
                                {/* <div className="w-100">
                                    <div className="text-danger">* Effectuez votre choix</div>
                                    <select style={{ borderRadius: "8px", padding: "10px" }} className="w-100" value={selectedCommune} onChange={handleUpdate}>
                                        <option value="">-- Choisissez votre type de structure --</option>
                                        <option value="Mairie" onClick={() => { setIsStructureSelected("Mairie"); setFormStep2({ ...formStep2, isValid: true, type_commune_or_sousPrefecture: "Mairie" }); }}>
                                            Mairie
                                        </option>

                                        <option value="Sous-Préfecture" onClick={() => { setIsStructureSelected("Sous-Prefecture"); setFormStep2({ ...formStep2, isValid: true, type_commune_or_sousPrefecture: "Sous-Prefecture" }) }}>
                                            Sous-Préfecture
                                        </option>
                                    </select>

                                </div> */}
                                <div className="d-flex align-items-center gap-1">
                                    <div>
                                        <label for="source_town"><h4>Mairie</h4></label>
                                    </div>
                                    <div className="h-100">
                                        <input className="h-100 checkbox-custom" type="radio" name="source_town" onClick={() => { setIsStructureSelected("Mairie"); setFormStep2({ ...formStep2, isValid: true, type_commune_or_sousPrefecture: "Mairie" }); }} />
                                    </div>
                                </div>

                                <div className="d-flex align-items-center gap-1">
                                    <div>
                                        <label for="source_town"><h4>Sous-préfecture</h4></label>
                                    </div>
                                    <div className="h-100">
                                        <input className="h-100 checkbox-custom" type="radio" name="source_town" onClick={() => { setIsStructureSelected("Sous-Prefecture"); setFormStep2({ ...formStep2, isValid: true, type_commune_or_sousPrefecture: "Sous-Prefecture" }) }} />
                                    </div>
                                </div>
                            </div>
                        </div>

                        :

                        <div className="mt-3 d-flex justify-content-center text-center">
                            -- Une indication s'affichera après avoir sélectionner votre structure --
                        </div>
                }
            </form>
        </div>

    )
}

export default Step2