import React from "react";
import { useNavigate } from "react-router-dom";

const MenuActesNaissance = () => {
  const navigate = useNavigate()
  return (
    <div className="">
      <h2 className="text-center pt-5">
        PIÈCES À FOURNIR POUR LA DEMANDE DE RETRAIT D'UN ACTE DE NAISSANCE
      </h2>
      {/* <div className="d-flex align-items-center justify-content-center text-center mb-5">
          <div className="col-8 fw-lighter text-secondary">
            N.B : le reçu de paiement pour une demande de visa au niveau du
            Consulat Général de Côte d'Ivoire à Paris n'est pas un document de
            voyage. Après l'obtention du reçu de paiement, le requérant est
            obligé de se faire enrôler au Consulat Général de Côte d'Ivoire à
            Paris pour obtenir son visa avant de prendre son vol à destination
            de la Côte d'Ivoire.
          </div>
        </div> */}
      <div className="d-flex align-items-center justify-content-center">
        <div className="col-10 border rounded p-4 mb-3">
          <div className="fw-lighter mb-3">
            <h3 className="text-center">Procédure</h3>
            {/* <div className="mb-3">
                <p className="fw-bolder my-0">NOTA BENE :</p>
                <p className=" my-0">
                  Toute demande de Visa Biométrique devra se faire dans le pays de
                  résidence
                </p>
                <p className=" my-0">
                  RDV sur{" "}
                  <a
                    href="https://snedai.com/fr/"
                    target="_blank"
                    className="text-decoration-none text-black fw-bolder"
                  >
                    https://snedai.com/fr/
                  </a>
                  pour le paiement en ligne.
                </p>
              </div> */}
            <div className="process">
              <div>
                <p className="process-title fw-bold">1- Déclaration de naissance</p>
                <p>
                  ‍Déclaration de Naissance
                  Comment faire la déclaration de naissance
                  il faut :
                  <ul className="list-typeActe">
                    <li className="">Le certificat médical de naissance</li>
                    <li className="">Le carnet d'accouchement</li>
                    <li className="">Les pièces d'identité des parents si elles sont disponibles</li>
                    <li className="">Une Copie de l'acte de mariage si vous êtes mariés délai : trois (03) mois à compter du jour de la naissance</li>
                    <div className="col-8 fw-lighter text-secondary">
                      N.B : la production des pièces des parents n'est pas une condition pour la déclaration de naissance. Si les parents ne sont pas en mesure de les fournir, la déclaration peut se faire.
                    </div>
                  </ul>
                </p>
              </div>
              <div>
                <p className="process-title fw-bold">
                  2-Acte de Naissance
                </p>

                <ul className="list-typeActe">
                  <li className="">
                    Un acte de naissance peut donner lieu à la délivrance de 2 documents différents : la copie intégrale, l'extrait simple .
                  </li>
                  <li>
                    La copie intégrale comporte des informations sur la personne concernée par l'acte (nom, prénoms, date et lieu de naissance), des informations sur ses parents et les mentions marginales lorsqu'elles existent.
                  </li>
                  <li>
                    Un extrait simple comporte uniquement les informations sur la personne concernée par l'acte de naissance.
                  </li>
                </ul>
              </div>
              <div>
                <p className="fw-bold">3- Faire la demande</p>
                <p>
                  Pour une demande de copie intégrale ou un extrait simple, il faut présenter sa pièce d'identité et une copie de l'extrait de naissance des frais de timbre (500 F CFA /Copie) sont applicables
                </p>
              </div>


              <div>
                <p>
                  <span className="fw-bolder">HORAIRES :</span>
                  9h30- 12h30 Dépôt de dossier / 14h30 - 16h Retrait des actes
                  (prendre rdv pour le retrait sur{" "}
                  <a
                    onClick={() => navigate("/rdv")}

                    className="text-decoration-none text-black fw-bolder"
                  >
                    www.rdv-Etat-civil.cloud
                  </a>{" "}
                  )
                </p>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MenuActesNaissance;
