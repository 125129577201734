import { Link } from "react-router-dom"
import { ENUM_LINKS } from "../../../constants/enum_links"
import { ENUM_PAGES_LINKS } from "../../../constants/enum_pages_links"
const mappingLinksTo = () => {
    return (
        <ul class="navbar-nav gap-2">
            {ENUM_LINKS && (
                ENUM_LINKS.map((el) => (

                    <li class="nav-item">
                        <Link class="nav-link" onClick={window.scrollTo(0, 0)} to={el.href}>
                            {el.name}
                        </Link>
                    </li>



                ))
            )}
            
            <li class="nav-item dropdown">
                <Link to={"#"} role="button" class="nav-link dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">Pages</Link>
                <ul class="dropdown-menu  m-0">
                    {ENUM_PAGES_LINKS && (
                        ENUM_PAGES_LINKS.map((li) => (
                            <li>
                                <Link class=" dropdown-item" onClick={window.scrollTo(0, 0)} to={li.href}>
                                    {li.name}
                                </Link>
                            </li>
                        ))
                    )}
                </ul>
            </li>



            {/* <li class="">
                        Pages 
                        <ul class=" dropdown-menu">
                            <li><Link class="dropdown-item" to={"/details/faq"}>FAQ</Link></li>
                            <li><Link class="dropdown-item" to={"/details/actualites"}>Actualités</Link></li>
                        </ul>
                    </li> */}
            {/* <button style={{ backgroundColor: "#fb9302", border: "none", borderRadius: "8px" }} className="nav-item"><li><Link style={{ textDecoration: "none", color: "#fff" }} to={"/details/faq"} > FAQ </Link></li></button>
            <button style={{ backgroundColor: "#048508", border: "none", borderRadius: "8px" }} className="nav-item"><li><Link style={{ textDecoration: "none", color: "#fff" }} to={"/login"} > Connexion </Link></li></button> */}
        </ul>
    )
}

export const LinksTo = () => {
    return (
        <div class="collapse col navbar-collapse " id="navbarNav">
            {mappingLinksTo()}
        </div>
    )
}