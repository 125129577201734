import { Button } from "react-bootstrap";
import Armoirie from "../../imgs/Armoirie.png"
import { useContext, useEffect } from "react";
import { userInfoToPrintContext } from "../../context/UserDetailToPrint";
import RealReceiptDemande from './RealReceipt/RealReceiptDemande'
import axios from "axios"
import { useNavigate, useParams } from "react-router-dom";
import { rdvByIdUlr } from "../../Url";
import generatePDF, { Resolution, Margin } from 'react-to-pdf';

const RecuDemande = () => {
    const { request_invoice } = useParams();
    const navigate = useNavigate();
    const { userInfoToPrint, setUserInfoToPrint } = useContext(userInfoToPrintContext)
    function who_request(who_request) {
        if (who_request == "beneficiaireMajeur") {
            return "Bénéficiaire majeur"
        }
        if (who_request == "beneficiaireMineur") {
            return "Bénéficiaire mineur"
        }
        if (who_request == "beneficiaireMineurAccompagne") {
            return "Bénéficiaire mineur accompagné"
        }
        if (who_request == "moiMeme") {
            return "Demandeur lui-même"
        }
    }

    useEffect(() => {
        const handleGetReceipt = async () => {
            await axios.get(`${rdvByIdUlr}/${request_invoice}`)
                .then(response => {
                    console.log("receipt fetched", response)
                    setUserInfoToPrint({
                        receipt: response.data.request_invoice,
                        firstname: response.data.firstname,
                        lastname: response.data.lastname,
                        who_request: who_request(response.data.who_request),
                        demande: response.data.type,
                        email: response.data.email,
                        phone: response.data.phone,
                        pay_date: response.data.updated_at,
                        formule : response.data.formule,
                        integration_date: response.data.created_at,
                        pay_status: response.data.pay_status,
                        rdv_status: response.data.rdv_status,
                        pay_amount: response.data.pay_amount,
                        pay_gateway: response.data.pay_gateway === null ? "Wave" : response.data.pay_gateway,
                        commune: response.data.commune,
                        type_commune_or_sousPrefecture: response.data.type_commune_or_sousPrefecture,

                        firstname_demandeur : response.data.firstname_demandeur,
                        lastname_demandeur : response.data.lastname_demandeur,
                        birth_day_demandeur : response.data.birth_day_demandeur,
                        birth_place_demandeur : response.data.birth_place_demandeur,
                        phone_demandeur : response.data.phone_demandeur,
                        email_demandeur : response.data.email_demandeur,
                    })

                    console.log('userInfoToPrint in useEffect', userInfoToPrint)
                })
                .catch(error => console.log(error))

        }

        handleGetReceipt()
    }, [request_invoice])

    const Print = () => {
        //console.log('print');  
        let printContents = document.getElementById('printablediv').innerHTML;
        let originalContents = document.body.innerHTML;
        document.body.innerHTML = printContents;
        window.print();
        document.body.innerHTML = originalContents;
    }

    const options = {
        filename: `recu_demande_${userInfoToPrint?.receipt}_${userInfoToPrint?.firstname}_${userInfoToPrint?.lastname}.pdf`,
        method: "save",
        // default is Resolution.MEDIUM = 3, which should be enough, higher values
        // increases the image quality but also the size of the PDF, so be careful
        // using values higher than 10 when having multiple pages generated, it
        // might cause the page to crash or hang.
        resolution: Resolution.EXTREME,
        page: {
            // margin is in MM, default is Margin.NONE = 0
            margin: Margin.SMALL,
            // default is 'A4'
            format: "letter",
            // default is 'portrait'
            orientation: "landscape"
        },
        canvas: {
            // default is 'image/jpeg' for better size performance
            mimeType: "image/jpeg",
            qualityRatio: 1
        },
        // Customize any value passed to the jsPDF instance and html2canvas
        // function. You probably will not need this and things can break,
        // so use with caution.
        overrides: {
            // see https://artskydj.github.io/jsPDF/docs/jsPDF.html for more options
            pdf: {
                compress: true
            },
            // see https://html2canvas.hertzen.com/configuration for more options
            canvas: {
                useCORS: true
            }
        }
    };

    const getTargetElement = () => document.getElementById("pdfSaveInFile");

    const downloadPdf = () => generatePDF(getTargetElement, options);

    return (
        <div className="bg-receipt-blank">
            <div className="position-sticky top-0 d-flex justify-content-center">
                <Button variant="secondary" onClick={() => navigate(`/dashboard/details-demande/${userInfoToPrint?.receipt}`)}>Retour</Button>
                <Button variant="primary" onClick={Print}>Cliquez pour imprimer</Button>
                <Button variant="danger" onClick={downloadPdf}>Cliquez pour télécharger en format PDF</Button>
            </div>

            <div id="pdfSaveInFile">
                <RealReceiptDemande userInfoToPrint={userInfoToPrint} />
            </div>
        </div>
    )
}

export default RecuDemande