import React, { useState, useEffect } from "react";
import Step1 from "../Components/stepper/Step1";
import Step2 from "../Components/stepper/Step2";
import Step3 from "../Components/stepper/Step3";
import Step4 from "../Components/stepper/Step4";
import Step5 from "../Components/stepper/Step5";
import Step6 from "../Components/stepper/Step6";
import StepVerification from "../Components/stepper/StepVerification";
import axios from "axios";
import { registerAdmin, registerUrl } from "../Url";
import { useNavigate } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";
import { TextField } from "@mui/material";
import { Alert } from "react-bootstrap";
import Spinner from "../gifs/Spinner.gif"
import ImgSuccess from "../imgs/checked.png"

const RdvPage = () => {
  const [alert, setAlert] = useState("none")
  const [errorOfAxios, setErrorOfAxios] = useState("")
  const [timeoutDiscount, setTimeoutDiscount] = useState(false)
  const [loadingTransactions, setLoadingTransactions] = useState(false) //Toggle display of loader
  const [showAlertDanger, setShowAlertDanger] = useState(false) //Show Modal for inscription Validé
  const [showAlertSuccess, setShowAlertSuccess] = useState(false) //Show Modal for Inscription Réfusée

  const [stepper, setStepper] = useState(1);
  const [step1Fom, setStep1Fom] = useState({});
  const [step2Fom, setStep2Fom] = useState({});
  const [step3Fom, setStep3Fom] = useState({});
  const [step4Fom, setStep4Fom] = useState({});
  const [step33Fom, setStep33Fom] = useState({});
  const [step333Fom, setStep333Fom] = useState({});
  const [stepVerificationFom, setStepVerificationFom] = useState({});

  const [loading, setLoading] = useState(false);
  const user = JSON.parse(window?.localStorage.getItem("userData") ?? "{}");
  const navigate = useNavigate();
  const [step1Valid, setStep1Valid] = useState(false)
  const [step2Valid, setStep2Valid] = useState(false)
  const [step3Valid, setStep3Valid] = useState(false)
  const [step4Valid, setStep4Valid] = useState(false)
  const [step33Valid, setStep33Valid] = useState(false)
  const [step333Valid, setStep333Valid] = useState(false)
  const [step5Valid, setStep5Valid] = useState(false)
  const [step6Valid, setStep6Valid] = useState(false)
  const [stepVerificationValid, setStepVerificationValid] = useState(false)

  const [submit, setSubmit] = useState(false);

  const handleCloseModalForLoadingTransactions = () => {
    setLoadingTransactions(false);
  }

  const handleCloseModalForAlertDanger = () => {
    setShowAlertDanger(false);
    setSubmit(false)
  }

  const handleCloseModalForAlertSuccess = () => {
    setShowAlertSuccess(false);
  }

  // const handleShowModal = () => {
  //   setLoadingTransactions(true);
  // }

  const handleNext = () => {
    if (stepper >= 1 && stepper < 5) {
      setStepper(stepper + 1);
    }
  };
  const handlePrevious = () => {
    if (stepper >= 2 && stepper <= 5) {
      setStepper(stepper - 1);
    }
  };

  useEffect(() => {
    if (user && user?.id) {
      window.localStorage.clear();
      //navigate("/");
    }
  }, []);

  function step1Data(data) {
    setStep1Fom(data);
    setStep1Valid(data.isValid)
  }

  function step2Data(data) {
    setStep2Fom(data);
    setStep2Valid(data.isValid)
  }

  function step3Data(data) {
    setStep3Fom(data);
    setStep3Valid(data.isValid)
  }

  function step4Data(data) {
    console.log("step4Data in RdvPage",data);
    setStep4Fom(data);
    setStep4Valid(data.isValid)
  }

  function step33Data(data) {
    setStep33Fom(data);
    setStep33Valid(data.isValid)
  }

  function step333Data(data) {
    setStep333Fom(data);
    setStep333Valid(true)
  }

  // function step5Data(data) {
  //   setStep5Fom(data);
  //   setStep5Valid(data.isValid)
  //   console.log(stepper, step1Valid,
  //     step2Valid, step3Valid, step4Valid,
  //     step5Valid, submit)
  // }

  // function step6Data(data) {
  //   setStep6Fom(data);
  //   setStep6Valid(data.isValid)
  //   console.log(stepper, step1Valid,
  //     step2Valid, step3Valid, step4Valid,
  //     step5Valid, step6Valid, submit)
  // }

  function stepVerificationData(data) {
    setStepVerificationFom(data);
    setStepVerificationValid(data.isValid)
    console.log(stepper, step1Valid,
      step2Valid, step3Valid, step4Valid,
      step5Valid, step6Valid, submit)
  }
  // function step7Data(data) {
  //   setStep7Fom(data);
  //   setStep7Valid(data.isValid)
  //   console.log(stepper, step1Valid,
  //     step2Valid, step3Valid, step4Valid,
  //     step5Valid, step6Valid, submit)
  // }

  // const onChangeTextField = (event) => {
  //   console.log("otp", otp)
  //   setOtp(event.target.value)
  //   setStep7Fom({ ...step7Fom, otp: otp })
  // }

  // useEffect(() => {
  //   setStep7Fom({ ...step7Fom, otp: otp })
  // }, [otp])

  // useEffect(() => {
  //   setAlertWave(alertWave)
  // }, [alertWave])

  const handleSubmit = () => {
    setLoadingTransactions(true)
    setSubmit(true) //Toggle "Patientez ..." on onClick "Valider"
    console.log("stepVerificationFom", stepVerificationFom)
    let data = {
      ...step4Fom,
      country: "Cote d'Ivoire",
      ...step2Fom,
      ...step3Fom,
      ...step333Fom,
      ...step33Fom,
      ...step1Fom,
      request_invoice_provided: "none", //After
      proffession: "none",
    };

    data['rdvs'] = {
      ...step4Fom,
      country: "Cote d'Ivoire",
      ...step2Fom,
      ...step3Fom,
      ...step333Fom,
      ...step33Fom,
      ...step1Fom,
      proffession: "none",
      request_invoice_provided: "none", //After
      who_request: step3Fom.who_request,
    }

    console.log("data", data)

    // setTimeout(() => { setLoadingTransactions(false); setAlert("danger"); setShowAlertDanger(true) }, 5000)
    axios.all([
      // axios.post(registerAdmin).then(console.log("Adminn is OK")),
      axios.post(registerUrl, data)
        .then((response) => { console.log("response axios", response); console.log("register is OK"); setLoadingTransactions(false); setAlert("success"); setShowAlertSuccess(true); setTimeout(() => { navigate('/') }, 5000) })
        .catch((error) => { console.log(error); setLoadingTransactions(false); setAlert("danger"); setShowAlertDanger(true); setSubmit(false) })
    ])

  }

  return (
    <div>
      {/* Loading for Payment and Register */}
      {
        loadingTransactions ? (
          <Modal show={loadingTransactions} onHide={handleCloseModalForLoadingTransactions} backdrop="static" keyboard={false} size="lg"
            centered>
            <Modal.Header className="">
              <Modal.Title className="">
                <div className="d-flex align-items-center text-center notification-containgImg-text">
                  Veuillez patienter le temps qu'on actionne la procédure d'enregistrement. Cela peut prendre 1 - 2 minutes.
                </div>
                <div className="notification-containgImg-spinner text-center">
                  <img src={Spinner} className="notification-containImg-spinner-img" />
                </div>
              </Modal.Title>
            </Modal.Header>
          </Modal>
        ) : ""
      }


      {/* Alert For Inscription */}
      {
        alert === "success" ? (
          <Modal show={showAlertSuccess} onHide={handleCloseModalForAlertSuccess} backdrop="static" keyboard={false} size="lg"
            centered>
            <Modal.Header className="bg-success-subtle border-bottom border-success">
              <Modal.Title className="w-100">
                <div className="d-flex w-100 justify-content-center text-success">
                  Inscription validé
                </div>
              </Modal.Title>
            </Modal.Header>

            <Modal.Body className="bg-success-subtle">
              <div className="d-flex w-100 justify-content-center">
                Suivez votre mail ou vos SMS
              </div>
              <div className="d-flex w-100 justify-content-center">
                Redirection vers la page d'accueil dans 4 secondes
              </div>
              <div className="notification-containgImg-spinner text-center">
                <img src={ImgSuccess} className="notification-containImg-spinner-img" />
              </div>
            </Modal.Body>

          </Modal>
        )

          :

          alert === "danger" ?

            (
              <Modal show={showAlertDanger} onHide={handleCloseModalForAlertDanger} backdrop="static" keyboard={false} size="lg"
                centered>
                <Modal.Header className="bg-danger-subtle text-danger border-bottom border-danger" closeButton>
                  <Modal.Title className="w-100">
                    <div className="d-flex w-100 justify-content-center notification-containgImg-text">
                      Inscription réfusée
                    </div>
                  </Modal.Title>
                </Modal.Header>

                <Modal.Body className="bg-danger-subtle">
                  <p>Il se peut que :</p>
                  <div className="notification-containgImg-spinner">
                    <ul>
                      <li>---Votre connexion WiFi ou donnée mobile ait un problème. Vérifiez votre connexion et réessayez</li>
                      <li>---Le serveur du RDV-ÉtatCivil soit saturé. Patientez quelques minutes et réessayez</li>
                    </ul>
                  </div>
                </Modal.Body>

              </Modal>
            )

            :

            ""
      }
      <div className="my-5 stepperContainer d-flex justify-content-center align-items-center">

        {/* Modal for OTP if Step6fom.canal === Orange Money */}
        {/* {
          step6Fom.canal === "Orange Money"

            ?

            <Modal show={show} onHide={handleCloseModal}>
              <Modal.Header closeButton>
                <Modal.Title>Vu que vous avez sélectionner Orange Money, entrez sur votre téléphone #144*82#, suivez la procédure. Vous recevrez par SMS, un code important à 4 chiffres que vous entrerez dans le champ ci-dessous</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <TextField className="w-100" value={otp} onChange={onChangeTextField} label="Entrez le code OTP que vous avez reçu par SMS" variant="outlined" />
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseModal}>
                  Fermer
                </Button>
                <Button variant="primary" onClick={handleSubmitForMobileMoney}>
                  Valider la transaction
                </Button>
              </Modal.Footer>
            </Modal>

            :

            ""

        } */}


        <div className="my-2 col-10">
          <div className="steps d-flex gap-3 justify-content-center">

            {/* Step 1 */}
            <div
              className={`${stepper == 1 ? "activeStep shadow" : ""
                } px-sm-4 py-sm-2 step d-flex justify-content-center align-items-center text-center rounded-4 `}
            >
              <span className="step-number">1</span>
              <span className="step-title">Types d'acte civil</span>
            </div>

            {/* Step 2 */}
            <div
              className={`${stepper == 2 ? "activeStep shadow" : ""
                } px-sm-4 py-sm-2 step d-flex justify-content-center align-items-center text-center rounded-4`}
            >
              <span className="step-number">2</span>
              <span className="step-title">Choix de la commune</span>
            </div>

            {/* Step 3 */}
            <div
              className={`${stepper == 3 ? "activeStep shadow" : ""
                } px-sm-4 py-sm-2 step d-flex justify-content-center align-items-center text-center rounded-4 `}
            >
              <span className="step-number">3</span>
              <span className="step-title">Informations personnelles</span>
            </div>
            {/* Step 4 */}
            {step1Fom.type !== "RETRAIT DE PASSEPORT / VISAS" && (
              <div
                className={`${stepper == 4 ? "activeStep shadow" : ""
                  } px-sm-4 py-sm-2 step d-flex justify-content-center align-items-center text-center rounded-4 `}
              >
                <span className="step-number">4</span>
                <span className="step-title">Pièces requises</span>
              </div>
            )}

            {/* Step Verification -> 5 */}
            {step1Fom.type !== "RETRAIT DE PASSEPORT / VISAS" && (
              <div
                className={`${stepper == 5 ? "activeStep shadow" : ""
                  } px-sm-4 py-sm-2 step d-flex justify-content-center align-items-center text-center rounded-4 `}
              >
                <span className="step-number">5</span>
                <span className="step-title">Vérification</span>
              </div>
            )}

            {/* {step1Fom.type !== "RETRAIT DE PASSEPORT / VISAS" && (
              <div
                className={`${stepper == 5 ? "activeStep shadow" : ""
                  } px-sm-4 py-sm-2 step d-flex justify-content-center align-items-center text-center rounded-4 `}
              >
                <span className="step-number">5</span>
                <span className="step-title">Expédition d'actes d'états civils</span>
              </div>
            )}

            {step1Fom.type !== "RETRAIT DE PASSEPORT / VISAS" && (
              <div
                className={`${stepper == 6 ? "activeStep shadow" : ""
                  } px-sm-4 py-sm-2 step d-flex justify-content-center align-items-center text-center rounded-4 `}
              >
                <span className="step-number">6</span>
                <span className="step-title">Choix pour paiement</span>
              </div>
            )}

            {step1Fom.type !== "RETRAIT DE PASSEPORT / VISAS" && (
              <div
                className={`${stepper == 7 ? "activeStep shadow" : ""
                  } px-sm-4 py-sm-2 step d-flex justify-content-center align-items-center text-center rounded-4 `}
              >
                <span className="step-number">7</span>
                <span className="step-title">Paiement</span>
              </div>
            )} */}

          </div>
          <div className="bg-white shadow step-box text-start rounded p-4 mt-3">
            {loading && (
              <div className="text-center rendu mt-3">
                Votre demande a été envoyée avec succès. Veuillez vérifier votre boite mail pour plus de détails!
              </div>
            )}
            {!loading && (
              <>
                <div className="rendu mt-3">
                  {stepper == 1 && (
                    <Step1
                      step1Fom={step1Fom}
                      step1Data={step1Data}
                    />
                  )}
                  {stepper == 2 && (
                    <Step2
                      step2Fom={step2Fom}
                      step2Data={step2Data}
                    />
                  )}
                  {stepper == 3 && (
                    <Step3
                      step3Fom={step3Fom}
                      step3Data={step3Data}
                      step33Fom={step33Fom}
                      step333Fom={step333Fom}
                      step33Data={step33Data}
                      step333Data={step333Data}
                    />
                  )}
                  {/* {stepper == 4 && (
                  <Step4
                    step4Fom={step4Fom}
                    step4Data={step4Data}
                    step33Fom={step33Fom}
                    step33Data={step33Data}
                  />
                )} */}
                  {stepper == 4 && (
                    <Step4
                      step4Fom={step4Fom}
                      stepActualInformations={{...step3Fom, ...step1Fom, ...step2Fom}}
                      // step33Fom={step33Fom}
                      step4Data={step4Data}
                      type={step1Fom.type}
                    />
                  )}
                  {stepper == 5 && (
                    <StepVerification
                      stepSummarize={{ ...step3Fom, ...step4Fom, ...step1Fom, ...step2Fom }}
                      stepVerificationData={stepVerificationData}
                    />
                  )}
                  {/* {stepper == 5 && step1Fom.type !== "RETRAIT DE PASSEPORT / VISAS" && (
                    <Step5
                      step5Fom={step5Fom}
                      step5Data={step5Data}
                      type={step1Fom.type}
                    />
                  )}
                  {stepper == 6 && step1Fom.type !== "RETRAIT DE PASSEPORT / VISAS" && (
                    <Step6
                      stepInformationPers={step3Fom}
                      step6Fom={step6Fom}
                      step6Data={step6Data}
                      type={step1Fom.type}
                    />
                  )}
                  {stepper == 7 && step1Fom.type !== "RETRAIT DE PASSEPORT / VISAS" && (
                    <stepVerification
                      stepSummarize={{ ...step3Fom, ...step4Fom, ...step5Fom, ...step1Fom, ...step6Fom, ...step2Fom }}
                      stepVerificationData={stepVerificationData}
                    />
                  )} */}
                </div>

                <div className="my-3 block-btn">
                  {stepper == 1 && step1Valid ? (
                    <div className="text-end align-bottom">
                      <button className="btn-step-next rounded-5" onClick={handleNext}>
                        Suivant
                      </button>
                    </div>
                  ) : stepper > 1 && stepper <= 7 ? (
                    <div className="d-flex justify-content-between">
                      {/* <button className="btn-step-back" onClick={handlePrevious}>
                      Retour
                    </button> */}
                      {step1Valid && (
                        <button className="btn-step-back rounded-5" onClick={handlePrevious}>
                          Retour
                        </button>
                      )}
                      {stepper === 2 && step2Valid && (
                        <button className="btn-step-next rounded-5" onClick={handleNext}>
                          Suivant
                        </button>
                      )}

                      {(step3Valid || step33Valid) && stepper === 3 && step1Fom.type !== "RETRAIT DE PASSEPORT / VISAS" && (
                        <button className="btn-step-next rounded-5" onClick={handleNext}>
                          Suivant
                        </button>
                      )}

                      {/* {(step3Valid || step33Valid) && !submit && stepper === 3 && step1Fom.type == "RETRAIT DE PASSEPORT / VISAS" && (
                        <button className="btn-step-next rounded-5" onClick={handleSubmit}>
                          Soumettre
                        </button>
                      )} */}


                      {step4Valid && stepper === 4 && (
                        <button className="btn-step-next rounded-5" onClick={handleNext} >
                          Suivant
                        </button>
                      )}

                      {/* {!submit && stepper === 4 && step1Fom.type == "RETRAIT DE PASSEPORT / VISAS" && (
                        <button className="btn-step-next rounded-5" onClick={handleSubmit}>
                          Soumettre
                        </button>
                      )} */}

                      {step1Valid && step3Valid && submit && (
                        <button className="btn-step-next-disabled rounded-5" disabled>
                          Patientez...
                        </button>
                      )}
                      {stepper === 5 && step1Valid &&
                        step2Valid && (step3Valid || step33Valid || step333Valid) && step4Valid && !submit && (
                          <button className="btn-step-next rounded-5" onClick={handleSubmit}>
                            Valider
                          </button>
                        )}

                      {/* {stepper === 5 && step1Valid &&
                        step2Valid && step3Valid && step5Valid && !submit && (
                          <button className="btn-step-next rounded-5" onClick={handleNext}>
                            Suivant
                          </button>
                        )}


                      {stepper === 6 && step1Valid &&
                        step2Valid && step3Valid && step4Valid && step5Valid && !submit && (
                          <button className="btn-step-next rounded-5" onClick={handleNext}>
                            Suivant
                          </button>
                        )} */}

                      {/* {stepper === 7 && step1Valid &&
                      step2Valid && step3Valid && step4Valid && step5Valid && step6Valid && !submit && (
                        <button className="btn-step-next rounded-5" onClick={handleNext}>
                          Valider
                        </button>
                      )} */}

                      {/* Toggling Validation paiement si c'est orange money ou pas qu'elle a choisi au Step6 */}
                      {/* {stepper === 7 && step1Valid &&
                        step2Valid && step3Valid && step4Valid && step5Valid && step6Valid && step6Fom.canal === "Orange Money" && step6Fom.canal !== "Wave" && !submit && (
                          <button className="btn-step-next rounded-5" onClick={handleShowModal}>
                            Valider
                          </button>
                        )} */}

                      {/* {stepper === 7 && step1Valid &&
                        step2Valid && step3Valid && step4Valid && step5Valid && step6Valid && step6Fom.canal !== "Orange Money" && step6Fom.canal !== "Wave" && !submit && (
                          <button className="btn-step-next rounded-5" onClick={handleSubmitForMobileMoney}>
                            Valider
                          </button>
                        )} */}
                      {/* Toggling Validation paiement si c'est orange money ou pas */}

                      {/* Case if Mobile Payment choosed is Wave */}
                      {/* {stepper === 7 && step1Valid &&
                        step2Valid && step3Valid && step4Valid && step5Valid && step6Valid && step6Fom.canal === "Wave" && !submit && (
                          <button className="btn-step-next rounded-5" onClick={handleSubmitForWave}>
                            Valider
                          </button>
                        )} */}


                      {/* {stepper === 4 && step4Valid && step33Valid && (
                      <button className="btn-step-next rounded-5" onClick={handleNext}>
                        Suivant
                      </button>
                    )} */}

                    </div>
                  ) : (
                    <div className="d-flex justify-content-between">
                      {step1Valid &&
                        step2Valid && step3Valid && step4Valid && submit && (
                          <button className="btn-step-next-disabled rounded-5" disabled>
                            Patientez...
                          </button>
                        )}
                      {step1Valid &&
                        step2Valid && step3Valid && step4Valid && stepVerificationValid && !submit && (
                          <button className="btn-step-next rounded-5">
                            Soumettre
                          </button>
                        )}
                    </div>
                  )}
                </div>
              </>
            )}

          </div>
        </div>
      </div>
    </div>
  )
}

export default RdvPage