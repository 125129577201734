import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ListeActualites from "./ListeActualites";

function DetailsActualite() {
  const [actualite, setActualite] = useState({});
  const [paragraphes, setParagraphes] = useState([]);
  const { id } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
    if (id == 1) {
      const actualitePredefinie = {
        cheminImage: "/images/acctualites-1.jpg",
        source: "KOACI",
        date: "28/7/2022",
        titre: "NOTE D'INFORMATION A L'ATTENTION DES USAGERS",
        paragraphes: [
          "L'ouverture prochaine du Consulat Général de Côte d'Ivoire à Paris en France se précise. En prélude à l'ouverture officielle de cette représentation Diplomatique, le Consul Général SEM Issiaka Konate a été reçu en audience par la première magistrate du 8e arrondissement de la ville, Madame Jeanne D'Hauteserre, également Conseillère régionale et Conseillère métropolitaine, a-t-on appris de sources concordantes.",
          "Le 8eme arrondissement parisien accueille déjà plusieurs missions diplomatiques et consulaires étrangères comme celles des États Unis, de la Grande Bretagne et abrite des sites touristiques majeurs, notamment l'avenue des Champs Elysées, l'Arc de Triomphe, la place de la Concorde et bien d'autres encore.",
          "« C'est dans ce quartier très prisé que la Côte d'Ivoire aura son tout nouveau Consulat Général, non loin de la gare de Saint Lazare », précise le texte qui ajoute qu'il s'est agi pour la partie ivoirienne d'avoir l'appui de la municipalité et de lui présenter les activités majeures de cette mission consulaire dont l'ouverture officielle sera placée sous la présence effective de Son Excellence Madame Kandia Camara, Ministre d'Etat, Ministre des Affaires Étrangères, de l'intégration Africaine et de la Diaspora. »",
        ],
      };
      setActualite(actualitePredefinie);
      setParagraphes(actualitePredefinie.paragraphes);
    } else {
      const actualiteCourante = ListeActualites.find((el) => el.id == id);
      if (actualiteCourante) {
        setActualite(actualiteCourante);
        setParagraphes(actualiteCourante.paragraphes);
      }
    }
    console.log(id);
  }, [id]);

  function renderParagraphes(paragraphes) {
    if (paragraphes) {
      return paragraphes.map((el, index) => (
        <p key={index} className="mb-md-5">
          {el}
        </p>
      ));
    }
  }

  const navigate = useNavigate();

  const handleClick = (e) => {
    navigate("/details/actualites/" + e.currentTarget.id);
  };

  return (
    <div>
      <div className="container" style={{ marginTop: "130px" }}>
        <div className="mx-lg-5 p-lg-5 mx-md-3p-md-3">
          <div className="col-md-7 col-12 container">
            <img
              src={actualite.cheminImage}
              alt={actualite.titre}
              style={{ width: "100%", minWidth: "300px" }}
            />
            <p className="text-secondary text-center">{actualite.source}</p>
            <p className="text-secondary text-center">{actualite.date}</p>
          </div>
          <hr />
          {renderParagraphes(paragraphes)}
        </div>
      </div>
      <hr />
      <div>
        <h1 className="mx-lg-5 px-lg-5 mx-md-3 px-md-3">Autres informations</h1>
        <div className="row gap-5 m-lg-5 p-lg-5 m-md-3 p-md-3 flex-wrap ">
          {ListeActualites.map((actualite) => {
            return (
              <div
                className="container-fluid col-lg-3 col-md-4 col-sm-6 col-12 p-3 d-flex flex-column align-items-center"
                id={actualite.id}
                onClick={handleClick}
                key={actualite.id}
                style={{cursor:'pointer'}}
              >
                <div>
                  <img
                    src={actualite.cheminImage}
                    alt=""
                    style={{
                      width: "100%",
                      objectFit: "cover",
                      minWidth: "200px",
                    }}
                  />
                </div>
                <div>
                  <p className="text-secondary text-center mb-0">
                    {actualite.source}
                  </p>
                  <p className="text-secondary text-center">{actualite.date}</p>
                  <p className="text-secondary">{actualite.titre}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default DetailsActualite;
