import React from "react";
import Form from "../Components/Contact/Form";
import { Navigate, useNavigate } from "react-router-dom";

const MenuCasierJudiciaire = () => {
  const navigate = useNavigate()
  return (
    <div className="">
      <h2 className="text-center pt-5">
        PIÈCES À FOURNIR POUR L'OBTENTION DU CASIER JUDICIAIRE
      </h2>

      <div className="d-flex align-items-center justify-content-center">
        <div className="col-10 border rounded p-4 mb-3">
          <div className="fw-lighter mb-3">
            <h3 className="text-center">Procédure</h3>

            <div className="process">
              <div>
                <p className="process-title fw-bold">Document à fournir</p>
              </div>
              <div className="list-typeActe">
                <ul>
                  <li>Originale de l'extrait de naissance</li>
                  <li>Marquer la situation matrimoniale, la profession, la ville d'habitation</li>
                </ul>
              </div>
                
              <div>
                <p className="fw-bold">Retrait du casier judiciaire</p>
                <ul className="list-typeActe">
                  <li>
                    Le dossier de visa est traité dans un délai de vingt-quatre (24)
                    heures après le dépôt de dossier.
                  </li>
                  <li>
                    Le requérant se présente personnellement au Consulat Général de
                    Côte d'Ivoire à Paris muni de son reçu d'enrôlement pour le
                    retrait de son Visa.
                  </li>
                </ul>
              </div>
              <div>
                <p>
                  <span className="fw-bolder">HORAIRES :</span>
                  9h30- 12h30 Dépôt de dossier / 14h30 - 16h Retrait des casiers judiciaires
                  (prendre rdv pour le retrait sur{" "}
                  <a
                    onClick={() => navigate("/rdv")}
                    // href="www.rdv-consulat.ci"
                    className="text-decoration-none text-black fw-bolder"
                  >
                    www.rdv-etat-civil.cloud
                  </a>{" "}
                  )
                </p>
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MenuCasierJudiciaire;
