import React,{ useEffect } from 'react';
const StepVerification = ({stepSummarize, stepVerificationData}) => {
  useEffect(() => {
    stepVerificationData(stepSummarize)
}, [])
  return (
    <div>
      <div className="">
            <h1 className="text-center">Vérifier les informations entrées</h1>

            <div>
                <form>
                    <div class="row">
                        <div class="col">
                            <label for="formGroupExampleInput">Nom</label>
                            <input type="text" disabled class="form-control" placeholder="" value={stepSummarize.firstname} />
                        </div>
                        <div class="col">
                            <label for="formGroupExampleInput">Prénom</label>
                            <input type="text" disabled class="form-control" placeholder="Last name" value={stepSummarize.lastname} />
                        </div>
                    </div>

                    <div class="row">
                        <div class="col">
                            <label for="formGroupExampleInput">Téléphone</label>
                            <input type="text" disabled class="form-control" placeholder="" value={stepSummarize.phone} />
                        </div>
                        <div class="col">
                            <label for="formGroupExampleInput">Bénéficiaire de l'acte civil</label>
                            <input type="text" disabled class="form-control" placeholder="Last name" value={stepSummarize.who_request} />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <label for="formGroupExampleInput">Type d'acte civil démandé</label>
                            <input type="text" disabled class="form-control" placeholder="" value={stepSummarize.type} />
                        </div>
                        <div class="col">
                            <label for="formGroupExampleInput">Commune / Sous préfecture</label>
                            <input type="text" disabled class="form-control" placeholder="Last name" value={stepSummarize.commune} />
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-6">
                            <label for="formGroupExampleInput">Je suis enrégistré dans ...</label>
                            <input type="text" disabled class="form-control" placeholder="" value={stepSummarize.type_commune_or_sousPrefecture} />
                        </div>
                        
                    </div>
                </form>
            </div>
        </div>
    </div>
  );
};

export default StepVerification;