import { TextField } from "@mui/material";
import { useEffect, useState } from "react";

const ChoicePayment = ({ choicePayment, choicePaymentData }) => {

    const [whichCanalSelected, setWhichCanalSelected] = useState("")
    const [gateWaySelected, setGatewaySelected] = useState("")
    const [operationInChoicePayment, setOperationInChoicePayment] = useState({
        mode_payment: "",
        gateway_payment: "",
        phonePayment: "",
        amount_payment : 2000,
        isValid: false,
        ...choicePayment
    }
    )

    useEffect(() => {
        {
            if (operationInChoicePayment.mode_payment === "Espece") {
                choicePaymentData({ ...operationInChoicePayment, isValid: true })
            } 
            
            else if (operationInChoicePayment.mode_payment === "Mobile Money") {
                if (operationInChoicePayment.gateway_payment !== "" && operationInChoicePayment.phonePayment !== "") {
                    choicePaymentData({ ...operationInChoicePayment, isValid: true })
                } else {
                    choicePaymentData({ ...operationInChoicePayment, isValid: false })
                }
            } 
            
            else {
                choicePaymentData({ ...operationInChoicePayment, isValid: false })
            }
        }
    }, [operationInChoicePayment])


    return (
        <div>
            <div>
                Quel est le moyen de paiement voulu ?
            </div>
            <div className="d-flex align-items-center gap-1">
                <div>
                    <label for="source_town"><h4>En espèces</h4></label>
                </div>
                <div className="h-100">
                    <input className="h-100 checkbox-custom" type="radio" name="source_town" onClick={() => { setWhichCanalSelected("Espece"); setOperationInChoicePayment({ ...operationInChoicePayment, mode_payment: "Espece", phonePayment: "", gateway_payment: "" }); }} />
                </div>
            </div>

            <div className="d-flex align-items-center gap-1">
                <div>
                    <label for="source_town"><h4>Par Mobile Money (Moov, Orange, MTN ou Wave)</h4></label>
                </div>
                <div className="h-100">
                    <input className="h-100 checkbox-custom" type="radio" name="source_town" onClick={() => { setWhichCanalSelected("Mobile Money"); setOperationInChoicePayment({ ...operationInChoicePayment, mode_payment: "Mobile Money" }) }} />
                </div>
            </div>



            {
                whichCanalSelected === "Mobile Money" && (
                    <>
                        <div className="mt-3">Selectionnez votre mobile money pour effectuer une transaction</div>
                        <div className="d-flex align-items-center gap-1">
                            <div>
                                <label for="source_town"><h4>Moov Money</h4></label>
                            </div>
                            <div className="h-100">
                                <input className="h-100 checkbox-custom" type="radio" name="mobile_gateway" onClick={() => { setGatewaySelected("Moov Money"); setOperationInChoicePayment({ ...operationInChoicePayment, gateway_payment: "Moov Money" }); }} />
                            </div>
                        </div>

                        <div className="d-flex align-items-center gap-1">
                            <div>
                                <label for="source_town"><h4>MTN Mobile Money</h4></label>
                            </div>
                            <div className="h-100">
                                <input className="h-100 checkbox-custom" type="radio" name="mobile_gateway" onClick={() => { setGatewaySelected("MTN Mobile Money"); setOperationInChoicePayment({ ...operationInChoicePayment, gateway_payment: "MTN Mobile Money" }); }} />
                            </div>
                        </div>

                        <div className="d-flex align-items-center gap-1">
                            <div>
                                <label for="source_town"><h4>Orange Money</h4></label>
                            </div>
                            <div className="h-100">
                                <input className="h-100 checkbox-custom" type="radio" name="mobile_gateway" onClick={() => { setGatewaySelected("Orange Money"); setOperationInChoicePayment({ ...operationInChoicePayment, gateway_payment: "Orange Money" }); }} />
                            </div>
                        </div>

                        <div className="d-flex align-items-center gap-1">
                            <div>
                                <label for="source_town"><h4>Wave</h4></label>
                            </div>
                            <div className="h-100">
                                <input className="h-100 checkbox-custom" type="radio" name="mobile_gateway" onClick={() => { setGatewaySelected("Wave"); setOperationInChoicePayment({ ...operationInChoicePayment, gateway_payment: "Wave" }) }} />
                            </div>
                        </div>

                        <div className="w-100">
                            <TextField
                                value={operationInChoicePayment.phonePayment}
                                onChange={(e) => {
                                    setOperationInChoicePayment({ ...operationInChoicePayment, phonePayment: e.target.value });
                                    console.log("choicePayment with input", operationInChoicePayment)
                                }}
                                className="w-100 text-danger"
                                label="Entrez le numéro qui sera débité pour le paiement"
                                variant="outlined"
                                type="number"
                            />
                        </div>
                    </>
                )
            }
        </div>
    )
}

export default ChoicePayment;