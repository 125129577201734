import { nanoid } from "nanoid";

const ListeActualites = [
  {
    cheminImage: "/images/acctualites-2.png",
    source: "CGP",
    date: "22/9/2023",
    titre: "NOTE D'INFORMATION A L'ATTENTION DES USAGERS",
    paragraphes: [
      "Il est porté à la connaissance des usagers du Consulat Général de Côte d'Ivoire à Paris et des ivoiriens résidant en France que les services du Consulat Général seront fermés le 28 septembre 2023 en vue de permettre au personnel de prendre part à un atelier de formation.",
      "Le fonctionnement normal des services reprendra le 29 septembre 2023 à 09 heures 30 minutes.",
    ],
  },
  {
    cheminImage: "/images/acctualites-3.jpg",
    source: "CGCIP",
    date: "12/9/2023",
    titre: "NOTE D'INFORMATION A L'ATTENTION DES USAGERS",
    paragraphes: [
      "Rappelant les termes de la note d’information n°003/CGCIP/CG/IK/GL/0AA/04-23 du 13 avril2023, le Consulat Général de Côte d’Ivoire à Paris porte à la connaissance des usagers qu’à compter du lundi 13 novembre2023, la délivrance des laissez-passer consulaires (LPC) est suspendue pour les ivoiriens de sa circonscription.",
      "Le laissez-passer ne pourra être délivré à titre exceptionnel, notamment encas de perte du passeport, qu’à l’ivoirien de passage en France, justifiant d’une situation régulière au regard du droit d’entrée et de séjour des étrangers.",
      "La demande et le dossier sont adressés à travers le lien suivant : www.rdv-consulat.ci et le laissez-passer consulaire délivré le cas échéant, dans un délai de trois (03) jours.",
      "Le Consulat Général de Côte d’Ivoire à Paris remercie les membres de la communauté ivoirienne de leur bonne compréhension et saisit cette opportunité pour inviter les uns et les autres à veiller scrupuleusement au renouvellement de leurs passeports expirés.",
    ],
  },
  {
    cheminImage: "/images/acctualites-4.jpg",
    source: "CGCIP",
    date: "12/9/2023",
    titre: "NOTE D'INFORMATION A L'ATTENTION DES USAGERS",
    paragraphes: [
      "Le Consulat Général de Côte d’Ivoire à Paris informe les usagers que dans le cadre des mesures de précaution sanitaire, l’accès aux locaux du Consulat Général est soumis au port obligatoire du masque.",
      "Le Consul Général remercie les usagers de leur bonne compréhension et attache du prix au respect scrupuleux de cette consigne.",
    ],
  },
  {
    cheminImage: "/images/acctualites-5.jpg",
    source: "ABIDJAN.NET",
    date: "5/8/2023",
    titre:
      "Issiaka Konaté lance une opération spéciale de transcription d’actes pour épurer un passif de 3500 demandes",
    paragraphes: [
      "Conformément à la loi d n°2018-862 du 19 novembre 2018 relative à l’état civil, les Ivoiriens nés à l’extérieur de la Côte d’Ivoire doivent transcrire leurs actes de naissances dans les registres de Côte d’Ivoire par le biais des ambassades et missions consulaires.",
      "Dès sa prise de fonction, SEM Issiaka Konaté, consul général de Côte d’Ivoire à Paris a fait le constat à la fin de l’année 2022 de retards dans les transcriptions des actes d’état civil. « Au terme de l’année 2022, nous avons constaté un stock de 2361 demandes de transcriptions d’actes d’état civil non traitées. Sur ces demandes, 657 ont déjà pu être réalisées et plusieurs nouveaux stocks sont venus gonfler ces chiffres pour finalement passer à 3500 actes », nous a livré le chef de la mission consulaire ivoirienne à Paris.",
      "Aux fins de résorber ce passif, le consul général de Côte d’Ivoire dans la capitale française a lancé une opération spéciale visant le traitement de ces 3500 demandes en attente depuis plusieurs années. Pour les besoins de l’opération exceptionnelle, 10 jeunes diplômés ivoiriens ont été recrutés, et à qui SEM Issiaka Konaté a expliqué la loi et l’obligation faite à l’administration consulaire de délivrer ces documents aux Ivoiriens de France qui ont fait ces demandes.",
      "Il nous a indiqué qu’il « souhaite voir ces 3500 transcriptions réalisées et retournées aux requérants gratuitement. Cette opération s’étendra sur 3mois ».",
      "Dès janvier 2024, une fois la question des stocks traitée, le consul général de Côte d’Ivoire à Paris promet une transcription dans un délai d’une semaine et proposera des services spontanés à ses usagers dans les nouveaux locaux flambant neufs du consulat général dans le huitième arrondissement parisien sis au 19 rue de la pépinière qui ouvrira ses portes avant la fin de l’année 2023.",
      "Notons que ces nouveaux locaux sont sur 5 niveaux et s’étendent sur 1000 m2. Ils disposent d’une quinzaine de bureaux ultra-modernes, d’une salle d’attente pouvant accueillir entre 100 et 150 personnes.",
      "En attendant janvier 2024, SEM Issiaka Konaté invite tous les usagers en attente de la transcription des actes d’adresser un courrier électronique au consulat général de Côte d’Ivoire à Paris avec les reçus des demandes en prenant le soin de bien noter leurs adresses pour les envois. Le consulat général informe également ses usagers que toutes les démarches consulaires seront soumises à une prise de rendez-vous au préalable sur www.rdv-consulat.ci.",
    ],
  },

  {
    cheminImage: "/images/acctualites-6.jpg",
    source: "ABIDJAN.NET",
    date: "11/7/2023",
    titre:
      "Caravane en France du Consulat Général de Côte d’Ivoire à Paris : ce que Issiaka Konaté a dit et fait avec les Ivoiriens (nes) à Nantes",
    paragraphes: [
      "  Les 6, 7 et 8 juillet 2023, une mission du consulat de Côte d’Ivoire à Paris conduite par le consul général Issiaka Konaté était à Nantes (commune de l’ouest de la France). L’objectif était de permettre aux Ivoiriens résidant dans cette ville et des alentours, d’accomplir leurs actes consulaires sans se rendre dans la capitale française.",
      "Un peu plus de 200 Ivoiriens de Nantes et des alentours ont saisi l’occasion de la présence des services du consulat général ivoirien pour établir leurs différents actes administratifs liés à la Côte d’Ivoire. Des centaines de cartes consulaires, des transcription des actes de naissance, des actes de décès, des actes de reconnaissance de filiation et des actes de mariage ont pu être établis.",
      "Ces Ivoiriens de Nantes, ont marqué leur satisfaction de voir une mission consulaire ivoirienne dans leur ville et région. Parmi eux, Martin Gueu, vivant depuis une dizaine d’années dans la région des pays de la Loire a confié qu’il était heureux de cette initiative des autorités consulaires ivoiriennes de Paris. « Cette caravane consulaire initiée par le consul général de Côte d’Ivoire à Paris m’a permis d’établir ma carte consulaire et faire la transcription de l’acte de naissance de mon fils dans l’état civil de Côte d’Ivoire sans me déplacer sur Paris. J’ai fait ainsi l’économie d’un billet de train aller/retour, et quelque fois d’une nuit à l’hôtel quand on n’a pas fini sa démarche administrative en une journée. J’espère tout simplement qu’ils reviennent trois ou quatre fois dans l’année, et qu’ils ajoutent aussi la possibilité de nous faire enrôler pour le renouvellement des passeports biométriques » a-t-il suggéré.",
      "Youssouf Sylla, Ivoirien résidant à Nantes et aussi président de l’association ‘’Maison des Ivoiriens Pays de la Loire” a dit être « heureux de l’initiative du consul général Issiaka Konaté de rapprocher les services consulaires des Ivoiriens de la région des pays de la Loire que nous sommes. »",
      "” Cela marque l’intérêt qu’il a pour nous. C’est nouveau et c’est à saluer. J’ai eu l’occasion de lui demander que cette mission soit instituée et qu’elle devienne périodique dans l’année. Nous avons également indiqué à M. Issiaka Konaté que la Maison des Ivoiriens Pays de la Loire reste entièrement disposée à être son relais à Nantes et sa région » , a dit le patron de la ‘’Maison des Ivoiriens Pays de la Loire’’.",
    ],
  },
  {
    cheminImage: "/images/acctualites-7.jpg",
    source: "ABIDJAN.NET",
    date: "7/7/2023",
    titre:
      "Diplomatie : le Consul Général de la Côte d’Ivoire à Paris, Issiaka Konaté échange avec Didier Leschi, Directeur Général de l’Office Français de l’Immigration et de l’Intégration (OFII)",
    paragraphes: [
      "  Le Consul Général de la Côte d’Ivoire à Paris, Issiaka Konaté a échangé, le 5 juillet 2023, avec le Directeur Général de l’Office Français de l’Immigration et de l’Intégration (OFII), Didier Leschi dans ses bureaux du 15ème arrondissement de Paris lors d’une visite de courtoisie dans le cadre du déploiement des activités consulaires et pour répondre aux préoccupations de la communauté ivoirienne relevant de sa circonscription.",
      "D'après une note parvenue à Abidjan.net, cette visite s’inscrit dans le cadre d’une série de rencontres de certains services de l’administration française pour présenter la mission consulaire et entrevoir les pistes de collaboration.",
      "Pour le Consul Général, il était indispensable de nouer des contacts utiles dans le sens de la prochaine délocalisation de ses services . Il a ainsi marqué sa disponibilité dans la poursuite des échanges autour de projets d’intérêts communs.",
      "Issiaka Konate a également remercié l’OFII pour les appuis apportés dans sa fonction précédente notamment dans l’accompagnement à la création d’entreprises de la diaspora et a souhaité que ces appuis puissent se poursuivre.",
    ],
  },
  {
    cheminImage: "/images/acctualites-8.jpg",
    source: "ABIDJAN.NET",
    date: "3/7/2023",
    titre:
      "France: le Consul Général de Côte d'Ivoire annonce une opération spéciale d’établissement d’actes consulaires",
    paragraphes: [
      "L’Ambassadeur Issiaka KONATE, Consul Général de la République de Côte d’Ivoire à Paris(France), informe, dans un communiqué parvenu à Abidjan.net, les ressortissants ivoiriens résidant dans la région des Pays de la Loire (Loire-Atlantique, Maine-et-Loire, Mayenne, Sarthe et Vendée), qu’une délégation du Consulat Général séjournera, du jeudi 06 au samedi 08 juillet 2023, à Nantes, pour une opération spéciale d’établissement d’actes consulaires, dénommée « Caravane Consulaire ».",
      "D'après un communiqué consulté par Abidjan.net, cette opération qui s’inscrit dans la vision du Consul Général vise à se rapprocher davantage des Ivoiriens et à faciliter leurs démarches consulaires. ",
      "La carte consulaire, la transcription d’actes d’état civil (naissance, mariage, reconnaissance et décès) ou l’établissement de tous autres actes consulaires (certificat de coutume, certificat de célibat, certificat de capacité à mariage, procuration, autorisation parentale, etc.) pourront être sollicités à cette occasion. ",
      "Cette opération spéciale se déroulera à l’Hôtel Mercure Centre-Gare de Nantes, non loin de la Gare SNCF.",
      "'A cet égard, le Consul Général invite les ressortissants ivoiriens des départements précités à se mobiliser et à saisir cette opportunité de délivrance d’actes consulaires', conclut le communiqué.",
    ],
  },
  {
    cheminImage: "/images/acctualites-9.jpg",
    source: "ABIDJAN.NET",
    date: "9/6/2023",
    titre:
      "Prix d’Excellence Alassane Ouattara d’Auvergne: des étudiants récompensés à Clermont Ferrand",
    paragraphes: [
      "  Le prix d’Excellence Alassane Ouattara d’Auvergne est à l’initiative des étudiants ivoiriens de cette région suite à la visite du Président de la République au Centre d’Etudes et de Recherches sur le Développement International ( CERDI) le 29 Septembre 2016 à l’occasion de la célébration des 40 ans de cette prestigieuse unité. Lors de cette visite, le Chef de l’Etat avait reçu ses jeunes compatriotes et leur avait apporté un appui financier à travers l'ancien Directeur Général des Ivoiriens de l’Extérieur M Issiaka Konaté, aujourd’hui Consul Général de Côte d’Ivoire à Paris.",
      "L’association des Etudiants a alors réfléchi à la meilleure utilisation possible de cet appui et a eu l’ingénieuse idée de mettre en place un Prix Spécial Alassane Ouattara pour récompenser les meilleurs d’entre eux dès 2017. Ainsi trois objectifs principaux ont été définis à savoir, promouvoir l'excellence des étudiants ivoiriens en Auvergne, créer une émulation saine et enfin aider financièrement les meilleurs. A travers une organisation rigoureuse et digne d’économistes chevronnés, ils ont lancé ce prix depuis maintenant 6 ans avec l’appui du CERDI, notamment des Professeurs Patrick et Sylviane Guillaumont.",
      "Ce sont au total 38 brillants étudiants qui ont ont été primés depuis la création du Prix dans des domaines allant de l’Economie (80%) aux Finances(12%) , en passant par la Biologie mécatronique (8%).",
      "Cette année, l’Association des Ivoiriens en Auvergne a décidé d’associer le Consul Général de Côte d’Ivoire à Paris comme parrain en tant que témoin historique de la mise en place de ce prix et son homologue de Lyon SEM Aka Ezoa Lambert comme Président. Les deux diplomates Ivoiriens ont été soutenus par l’ambassadeur de Côte d’Ivoire en Corée du Sud, SEM Allou Biti Eugène de passage dans la région. Le CERDI était également à leurs côtés en la personne de Monsieur Simone Bertoli, Directeur-adjoint.",
      "C’est en leur présence qu’ont été remis d’abord des diplômes de participation aux étudiants ayant obtenu plus de 14 de moyenne et ensuite 5 prix aux plus fortes moyennes par niveau pour le Semestre 1.",
      "Ainsi, en Licence 1 BTS2 Travaux Publics, Monsieur Kouakou Koffi Wenceslas avec 15, 16 de moyenne sur 20, en Licence 2 Economie, Madame Kakou Siekoua Marie Esther a quant à elle obtenu 16,075. Pour la Licence 3, Yao Bi Gala Makani-Iman était à 15,619 en Gestion de Parcours Comptabilité Finance- Langues et sa compatriote Kouassi Manuella Grace Esther avec 14,11 venait rafler l’un des 5 prix.",
      "Enfin, le jeune Yapi Aho Luc-Aymar flirtait avec les sommets par une moyenne spectaculaire de 17,74 en Mathématiques Appliquées, Statistiques Parcours et Traitement des données.",
      "Le Consul Général de Côte d’Ivoire à Paris, entouré de ses collègues s’est engagé à travailler avec eux au maintien du Prix afin que de plus de plus d’étudiants puissent en bénéficier. Un appui a été promis à cette fin. Il a exhorté ses jeunes compatriotes à la solidarité, à l’entente et exprimé la fierté de la Côte d’Ivoire et du Chef de l’Etat d’avoir des étudiants si brillants et engagés pour le développement de leur pays.",
      "SEM Aka Ezoa a offert également toute sa solidarité aux primés et prodigué des conseils à ses compatriotes.",
      "Les échanges se sont poursuivis avec les diplomates ivoiriens qui ont répondu aux questions des étudiants autour d’un cocktail offert à leur hôtel.",
    ],
  },
  {
    cheminImage: "/images/acctualites-10.jpg",
    source: "ABIDJAN.NET",
    date: "28/5/2022",
    titre:
      "Mobilisation des diasporas pour le développement: le Consul Général de Côte d’Ivoire à Paris met en avant les efforts du gouvernement ivoirien (Panel)",
    paragraphes: [
      "Invité le 26 mai 2023 dans le cadre de la Journée de l’Afrique aux côtés de spécialistes dont Driss El Yazami, président du Conseil de la communauté marocaine à l’étranger (CCME), Namira Negm, ambassadrice et Directrice de l’Observatoire africain des migrations, à participer à un panel organisé, à Paris, conjointement par l’OCDE et l’Union des Africains du Minnesota ( USA), SEM Issiaka Konaté a partagé les efforts de son pays en la matière.",
      "Il a rappelé la mise en place de structures nationales comme le Cepici, Côte d’Ivoire PME, et récemment le GUD PME, le Guichet Unique de Développement des PME, ainsi que les appuis à la création d’entreprises des jeunes de la diaspora, les appuis aux femmes dont un volet diaspora est en cours de finalisation, et indiqué tous les progrès accomplis depuis 2012. M Konate n’a pas manqué de relever le dynamisme des diasporas dans le monde entier, et appelé à plus de solidarité au sein des diasporas de la CEDEAO en les encourageant à initier des contacts avec celles des autres continents pour un meilleur partage d’expériences.",
      "Cette conférence marquait une semaine particulièrement chargée pour le Consul Général qui avait rencontré quelques jours plus tôt le Directeur de l’Immigration au Ministère de l’Intérieur français M Simon Fetet.",
      "La Côte d’Ivoire travaille à l’ouverture prochaine d’un Consulat Général à Paris pour mieux répondre aux défis administratifs de sa diaspora avec des services de proximité",
    ],
  },
  {
    cheminImage: "/images/acctualites-11.jpg",
    source: "ABIDJAN.NET",
    date: "24/9/2020",
    titre:
      "À la mission consulaire ivoirienne dans les Antilles françaises: Les Ivoiriens de la Martinique demandent la désignation d’un consul honoraire",
    paragraphes: [
      "https://news.abidjan.net/articles/724256/a-la-mission-consulaire-ivoirienne-dans-les-antilles-francaises-les-ivoiriens-de-la-martinique-demandent-la-designation-dun-consul-honoraire",
      "Une délégation du consulat général de Côte d’Ivoire à Paris a séjourné en Martinique du 06 au 10 septembre 2023 dans le cadre d’une mission de prospection et d’échanges avec la communauté ivoirienne résidant dans ce département français d’outre-mer. Entre les visites de courtoisie, audiences et rencontres avec les autorités locales, M. Gbégbé Lanta, consul au consulat général de Côte d’Ivoire à Paris et chef de la délégation ivoirienne, a pu se rendre compte des réalités de ses compatriotes vivant en Martinique. Il a aussi souligné la nomination prochaine d’un consul honoraire dans cette circonscription administrative française.",
      "La mission consulaire et diplomatique ivoirienne a débuté le 07 septembre 2023 par une visite de courtoisie à M. Eugène Larcher, maire de la ville de Les Anses d’Arlet située à 38 km de Fort-de-France. Cette localité se prépare à prendre part à la 34ème édition de la Coupe d’Afrique des Nations (CAN) de football qui se déroulera en Côte d’Ivoire à partir du 13 janvier 2024 dans le cadre d’un partenariat avec le Comité d’Organisation de la CAN (COCAN). Elle enverra 24 jeunes en appui au personnel du Comité d’organisation de cet évènement sportif continental.",
      "Dans la soirée, la délégation ivoirienne a été reçue par le maire de Fort-De-France, M. Didier Laguerre. Rappelons qu’Aimé Césaire fut le tout premier magistrat de cette commune qu’il a administrée pendant 60 ans. Gbégbé Lanta et sa délégation ont eu l’occasion de visiter le bureau de l’illustre écrivain et homme politique.",
      "Le lendemain, M. Serge Letchimy, président du conseil exécutif de la collectivité territoriale de Martinique (CTM), entouré pour la circonstance de la 2ème vice-présidente de l’Assemblée, présidente de la coopération internationale et du président de l’Assemblée, a accordé une audience à la mission consulaire ivoirienne en provenance de Paris.",
      "La journée s’est achevée par une rencontre avec Mme Laurence Gola de Monchy, secrétaire générale de la préfecture de la Martinique. Pour sa part, le consul Gbégbé Lanta a indiqué à chaque rencontre que la Côte d’Ivoire avait un Consul honoraire dans le département, en la personne de Louis Laouchez, décédé en 2017.",
      "Le diplomate ivoirien a aussi souligné qu’en attendant la désignation d’un nouveau consul honoraire, il était nécessaire d’effectuer cette mission pour recueillir les informations de terrain, notamment sur la situation de la communauté ivoirienne résidant dans cette île.",
      "Pour leur part, les autorités martiniquaises ont apprécié l’initiative du consul général, l’ambassadeur Issiaka Konaté d’organiser cette mission qui permettra de relancer la coopération entre la Côte d’Ivoire et les Antilles en général et la Martinique en particulier, dans les domaines d’activités consulaires. Elles appellent la réouverture du consulat honoraire de Côte d’Ivoire en Martinique et souhaitent également la création d’une ligne directe de transport aérien entre la Côte d’Ivoire et le département ultramarin français.",
      "Le 9 septembre 2023, Gbégbé Lanta et sa délégation ont tenu une réunion avec la diaspora ivoirienne. Cette dernière a été informée de la création du consulat général de Paris en novembre 2022, de la délocalisation des locaux dudit consulat sur un nouveau site et du processus de modernisation de la mission consulaire. Le consul ivoirien a surtout a invité ses compatriotes de la Martinique à renforcer la cohésion au sein de leur association, à demeurer unis et solidaires les uns avec les autres et à vivre en harmonie avec leurs frères de la Martinique",
    ],
  },
].map((actualite) => ({ ...actualite, id: nanoid() }));

export default ListeActualites;
