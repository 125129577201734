import React from 'react';
import { useEffect, useState } from 'react';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import { convertExcelToJson } from "../Data/Convert_XLXS_to_JSON";
import Listes_Communes from "../Data/Listes_Communes.xlsx";
import { Autocomplete, TextField } from '@mui/material';

const FormDeclaration = ({ fomDeclaration, formDeclarationData }) => {

  const [data, setData] = useState([]);

  const [communes, setCommunes] = useState([])
  const [selectedCommune, setSelectedCommune] = useState(null)

  const [selectedMairieOrSousprefecture, setSelectedMairieOrSousprefecture] = useState("Mairie")

  const [fomDocumentDeclaration, setFomDocumentDeclaration] = useState({});
  const [fomDeclaratoion, setFomDeclaration] = useState({});

  const [formDeclarationValid, setFormDeclarationValid] = useState(false)

  const [phone1, setPhone1] = useState();
  const [phone2, setPhone2] = useState();

  const [phone11, setPhone11] = useState();
  const [phone22, setPhone22] = useState();

  const [formDeclaration, setFormDeclaration] = useState({
    commune: "", //First Choice from commune
    type_commune_or_sousPrefecture: "Mairie", //First choice from structure
    firstname: "",
    lastname: "",
    birth_day: "",
    birth_place: "",
    birth_hour: "",
    sex: "",
    certificate_number: "",
    father_firstname: "",
    father_lastname: "",
    father_nationality: "",
    father_profession: "",
    father_email:"",
    father_phone: "",
    mother_firstname: "",
    mother_lastname: "",
    mother_nationality: "",
    mother_profession: "",
    mother_email: "",
    mother_phone: "",
    nationality: "",
    birth_territory: "",
    isValid: false,
    getPassed: true,
    ...fomDeclaration
  });

  useEffect(() => {

    if (phone1) {
      if (isValidPhoneNumber(phone1)) {
        setPhone11(phone1)
        setFormDeclaration({ ...formDeclaration, father_phone: phone1 })
      } else {
        setPhone11('')
        setFormDeclaration({ ...formDeclaration, father_phone: "" })
        console.log(formDeclaration, "phone1")
      }

    }

    if (phone2) {
      if (isValidPhoneNumber(phone2)) {
        setPhone22(phone2)
        setFormDeclaration({ ...formDeclaration, mother_phone: phone2 })
      } else {
        setPhone22('')
        setFormDeclaration({ ...formDeclaration, mother_phone: "" })
      }
    }

  }, [phone1, phone2])

  useEffect(() => {
    if (formDeclaration.commune && formDeclaration.type_commune_or_sousPrefecture
      && formDeclaration.firstname && formDeclaration.lastname && formDeclaration.sex
      && formDeclaration.birth_day && formDeclaration.birth_place && formDeclaration.birth_hour
      && formDeclaration.certificate_number && formDeclaration.father_firstname && formDeclaration.father_lastname
      && formDeclaration.birth_territory && formDeclaration.father_nationality
      && formDeclaration.father_profession
      && formDeclaration.mother_firstname && formDeclaration.mother_lastname 
      && formDeclaration.mother_nationality && formDeclaration.mother_profession) {
      setFormDeclaration({ ...formDeclaration, isValid: true })
      formDeclarationData({ ...formDeclaration, isValid: true })
    }
    else {
      setFormDeclaration({ ...formDeclaration, isValid: false })
      formDeclarationData({ ...formDeclaration, isValid: false })
    }
  }, [formDeclaration]);

  useEffect(() => {
    const getCommunes = async () => {
      await convertExcelToJson(Listes_Communes).
        then(res => setCommunes(res));
    }

    getCommunes()
  }, [])


  useEffect(() => {
    console.log(formDeclaration)
  }, [selectedCommune])

  useEffect(() => {
    console.log(formDeclaration)
  }, [selectedMairieOrSousprefecture])


  const handleChange = (e) => {
    setFormDeclaration({ ...formDeclaration, [e.target.name]: e.target.value });
    console.log("formDeclaration in handleChange", formDeclaration)
  };

  const handleUpdate = (event, value) => {
    setSelectedCommune(value)
    console.log(value);
    setFormDeclaration({ ...formDeclaration, commune: value });
  }

  const handleUpdateMairieOrSousprefecture = (event) => {
    setSelectedMairieOrSousprefecture(event.target.value);
    console.log(event.target.value);
    setFormDeclaration({ ...formDeclaration, type_commune_or_sousPrefecture: event.target.value });
  }
  return (


    <>
      <form className="d-flex flex-wrap">
        <div className="d-flex col-12 col-md-12 justify-content-center align-items-center px-3">
          <div className="bande col"></div>
          <div className="col bande-title text-center pb-2 fs-3">
            Informations du nouveau née
          </div>
          <div className="bande col"></div>
        </div>
        <div class="mb-3 col-12 col-md-6 px-3">
          <label for="name" class="form-label fs-smaller">
            *Commune
          </label>
          {/* <select
            value={selectedCommune}
            onChange={handleUpdate}
            class="form-control form-select form-input-droit"
          >
            <option value="">-- Choisissez la commune ou sous-préfecture --</option>
            {communes.map((commune, key) => {
              return (
                <option key={key} value={commune.nom}>
                  {commune.nom}
                </option>
              )
            })}
          </select> */}

          <Autocomplete
            className="w-100 custom-font"
            id="combo-box-demo"
            getOptionLabel={(option) => option.nom}
            options={communes}
            onChange={(event, value) => { if (value === null) { handleUpdate(event, "") } else { handleUpdate(event, value.nom) } }}
            sx={{ width: 300, marginTop: 0 }}
            renderInput={(params) => <TextField placeholder="Selection d'une commune de la Côte d'Ivoire" {...params} />} />

        </div>

        <div class="mb-3 col-12 col-md-6 px-3 ">
          <label for="typetype_commune_or_sousPrefecture" class="form-label fs-smaller">
            *Etablissement de déclaration (Mairie ou Sous Préfecture)
          </label>
          <select
            id='type_commune_or_sousPrefecture'
            value={selectedMairieOrSousprefecture}
            onChange={handleUpdateMairieOrSousprefecture}
            class="form-control form-select form-input-droit form-custom-select"
          >
            <option onClick={() => { setFormDeclaration({ ...formDeclaration, type_commune_or_sousPrefecture: "Mairie" }) }} >Mairie</option>
            <option onClick={() => { setFormDeclaration({ ...formDeclaration, type_commune_or_sousPrefecture: "Sous-Prefecture" }) }} >Sous-Prefecture</option>
          </select>

        </div>
        <div class="mb-3 col-12 col-md-6 px-3 form-style">
          <label for="name" class="form-label fs-smaller">
            *Nom
          </label>
          <input

            type="text"
            class="form-control form-input-droit"
            id="firstname"
            name="firstname"
            value={formDeclaration.firstname}
            required
            onChange={handleChange}
          />
        </div>

        <div class="mb-3 col-12 col-md-6 px-3 form-style">
          <label for="lastname" class="form-label fs-smaller">
            *Prénom (s)
          </label>
          <input

            type="text"
            class="form-control form-input-droit"
            id="lastname"
            name="lastname"
            value={formDeclaration.lastname}
            required
            onChange={handleChange}
          />
        </div>

        <div class="mb-3 col-12 col-md-6 px-3 form-style">
          <label for="birth_day" class="form-label fs-smaller">
            *Date de naissance
          </label>
          <input

            type="date"
            class="form-control form-input-droit"
            id="birth_day"
            name="birth_day"
            value={formDeclaration.birth_day}
            required
            onChange={handleChange}
          />
        </div>

        <div class="mb-3 col-12 col-md-6 px-3 form-style">
          <label for="birth_hour" class="form-label fs-smaller">
            *Heure de naissance
          </label>
          <input

            type="time"
            class="form-control form-input-droit"
            id="birth_hour"
            name="birth_hour"
            value={formDeclaration.birth_hour}
            required
            onChange={handleChange}
          />
        </div>

        <div class="mb-3 col-12 col-md-6 px-3 form-style">
          <label for="birth_territory" class="form-label fs-smaller">
            *Lieu de naissance
          </label>
          <input

            type="text"
            class="form-control form-input-droit"
            id="birth_territory"
            name="birth_territory"
            value={formDeclaration.birth_territory}
            required
            onChange={handleChange}
          />
        </div>

        <div class="mb-3 col-12 col-md-6 px-3 form-style">
          <label for="certificate_number" class="form-label fs-smaller">
            *Nº de certificat
          </label>
          <input

            type="text"
            class="form-control form-input-droit"
            id="nationality"
            name="certificate_number"
            value={formDeclaration.certificate_number}
            required
            onChange={handleChange}
          />
        </div>

        <div class="mb-3 col-12 col-md-6 px-3 form-style">
          <label for="birth_place" class="form-label fs-smaller">
            *Etablissement de naissance
          </label>
          <input

            type="text"
            class="form-control form-input-droit"
            id="birth_place"
            name="birth_place"
            value={formDeclaration.birth_place}
            required
            onChange={handleChange}
          />
        </div>

        <div class="mb-3 col-12 col-md-6 px-3 form-style">
          <label for="birth_place" class="form-label fs-smaller">
            *Nationalité
          </label>
          <input

            type="text"
            class="form-control form-input-droit"
            id="birth_place"
            name="nationality"
            value={formDeclaration.nationality}
            required
            onChange={handleChange}
          />
        </div>

        <div className='w-100 d-flex justify-content-center flex-wrap'>
          <div class="">
            <label class="form-label fs-smaller">
              *Sexe
            </label>
          </div>
          <div class="mb-3 px-3 form-style">
            <label for="masculin" class="form-label fs-smaller ">
              *Masculin
            </label>
            <input
              type="radio"
              class="form-control radio radio-m"
              id="masculin"
              name="sex"
              value="masculin"
              required
              onClick={() => { setFormDeclaration({ ...formDeclaration, sex: "masculin" }) }}
              onChange={handleChange}
            />
          </div>
          <div class="mb-3 px-3 form-style">
            <label for="feminin" class="form-label fs-smaller">
              *Féminin
            </label>
            <input
              type="radio"
              class="form-control radio radio-f"
              id="feminin"
              name="sex"
              value="feminin"
              required
              onClick={() => { setFormDeclaration({ ...formDeclaration, sex: "feminin" }) }}
              onChange={handleChange}
            />
          </div>
        </div>


        <div className="d-flex col-12 col-md-12 justify-content-center align-items-center px-3">
          <div className="bande col"></div>
          <div className="col bande-title text-center pb-2 fs-3">
            Informations du père
          </div>
          <div className="bande col"></div>
        </div>


        <div className="d-flex flex-wrap col-12">
          {/* <div class="mb-3 col-12 col-md-6 px-3">

            <label for="father_commune" class="form-label fs-smaller">
              *Commune
            </label>
            <select
              value={selectedCommunePere}
              class="form-control form-input-droit"
            >
              <option  value="">-- Choisissez la commune ou sous-préfecture --</option>
              {communes.map((commune, key) => {
                return (
                  <option key={key} value={commune.nom}>
                    {commune.nom}
                  </option>
                )
              })}
            </select>

          </div> */}
          <div class="mb-3 col-12 col-md-6 px-3 form-style">
            <label for="father_firstname" class="form-label fs-smaller">
              *Nom
            </label>
            <input

              type="text"
              class="form-control form-input-droit"
              id="father_firstname"
              name="father_firstname"
              value={formDeclaration.father_firstname}
              required
              onChange={handleChange}
            />
          </div>
          <div class="mb-3 col-12 col-md-6 px-3 form-style">
            <label for="father_lastname" class="form-label fs-smaller">
              *Prénom (s)
            </label>
            <input

              type="text"
              class="form-control form-input-droit"
              id="father_lastname"
              name="father_lastname"
              value={formDeclaration.father_lastname}
              required
              onChange={handleChange}
            />
          </div>
          
          <div class="mb-3 col-12 col-md-6 px-3 form-style">
            <label for="father_profession" class="form-label fs-smaller">
              *Profession
            </label>
            <input

              type="text"
              class="form-control form-input-droit"
              id="father_profession"
              name="father_profession"
              value={formDeclaration.father_profession}
              required
              onChange={handleChange}
            />
          </div>

          <div class="mb-3 col-12 col-md-6 px-3 form-style">
            <label for="father_nationality" class="form-label fs-smaller">
              *Nationnalité
            </label>
            <input

              type="text"
              class="form-control form-input-droit"
              id="father_nationality"
              name="father_nationality"
              value={formDeclaration.father_nationality}
              required
              onChange={handleChange}
            />
          </div>
          <div class="mb-3 col-12 col-md-6 px-3 form-style">
            <label for="father_phone" class="form-label fs-smaller">
              *Téléphone
            </label>
            <PhoneInput
              style={{ backgroundColor: 'white' }}
              defaultCountry="CI"
              type="tel"
              class="form-control form-input-droit"
              id="father_phone"
              name="father_phone"
              value={phone1}
              rules={{ required: true }}
              placeholder="+xxxxxxxx"
              onChange={setPhone1}
            />
          </div>

          <div class="mb-3 col-12 col-md-6 px-3 form-style">
            <label for="mother_phone" class="form-label fs-smaller">
              *Email
            </label>
            <input
              style={{ backgroundColor: 'white' }}
              defaultCountry="CI"
              type="email"
              class="form-control form-input-droit"
              id="father_email"
              name="father_email"
              value={formDeclaration.father_email}
              rules={{ required: true }}
              onChange={handleChange}
            />
          </div>
        </div>


        <div className="d-flex col-12 col-md-12 justify-content-center align-items-center px-3">
          <div className="bande col"></div>
          <div className="col bande-title text-center pb-2 fs-3">
            Informations de la mère
          </div>
          <div className="bande col"></div>
        </div>


        <div className="d-flex flex-wrap col-12 ">
          {/* <div class="mb-3 col-12 col-md-6 px-3">

            <label for="mother_commune" class="form-label fs-smaller">
              *Commune
            </label>
            <select
              value={selectedCommuneMere}
              class="form-control form-input-droit"
            >
              <option  value="">-- Choisissez la commune ou sous-préfecture --</option>
              {communes.map((commune, key) => {
                return (
                  <option key={key} value={commune.nom}>
                    {commune.nom}
                  </option>
                )
              })}
            </select>

          </div> */}
          <div class="mb-3 col-12 col-md-6 px-3 form-style">
            <label for="mother_firstname" class="form-label fs-smaller">
              *Nom
            </label>
            <input

              type="text"
              class="form-control form-input-droit"
              id="mother_firstname"
              name="mother_firstname"
              value={formDeclaration.mother_firstname}
              required
              onChange={handleChange}
            />
          </div>
          <div class="mb-3 col-12 col-md-6 px-3 form-style">
            <label for="mother_lastname" class="form-label fs-smaller">
              *Prénom (s)
            </label>
            <input

              type="text"
              class="form-control form-input-droit"
              id="mother_lastname"
              name="mother_lastname"
              value={formDeclaration.mother_lastname}
              required
              onChange={handleChange}
            />
          </div>
          
          <div class="mb-3 col-12 col-md-6 px-3 form-style">
            <label for="profession" class="form-label fs-smaller">
              *Profession
            </label>
            <input

              type="text"
              class="form-control form-input-droit"
              id="mother_profession"
              name="mother_profession"
              value={formDeclaration.profession}
              required
              onChange={handleChange}
            />
          </div>

          <div class="mb-3 col-12 col-md-6 px-3 form-style">
            <label for="mother_nationality" class="form-label fs-smaller">
              *Nationalité
            </label>
            <input
              type="text"
              class="form-control form-input-droit"
              id="mother_nationality"
              name="mother_nationality"
              value={formDeclaration.mother_nationality}
              required
              onChange={handleChange}
            />
          </div>
          <div class="mb-3 col-12 col-md-6 px-3 form-style">
            <label for="mother_phone" class="form-label fs-smaller">
              *Téléphone
            </label>
            <PhoneInput
              style={{ backgroundColor: 'white' }}
              defaultCountry="CI"
              type="tel"
              class="form-control form-input-droit"
              id="mother_phone"
              name="mother_phone"
              value={phone2}
              rules={{ required: true }}
              placeholder="+xxxxxxxx"
              onChange={setPhone2}
            />
          </div>
          <div class="mb-3 col-12 col-md-6 px-3 form-style">
            <label for="mother_phone" class="form-label fs-smaller">
              *Email
            </label>
            <input
              style={{ backgroundColor: 'white' }}
              defaultCountry="CI"
              type="email"
              class="form-control form-input-droit"
              id="mother_email"
              name="mother_email"
              value={formDeclaration.mother_email}
              rules={{ required: true }}
              onChange={handleChange}
            />
          </div>
        </div>

      </form>

    </>


  );
};

export default FormDeclaration;