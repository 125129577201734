import QRCode from "react-qr-code"
import Armoirie from "../../../imgs/Armoirie.png"

const RealReceiptDemande = ({ userInfoToPrint }) => {
    const pay_date = userInfoToPrint?.pay_date.split('').slice(0, 10).join('')
    const pay_hour = userInfoToPrint?.pay_date.split('').slice(11, 16).join('')

    console.log("userInfoToPrint", userInfoToPrint)
    return (
        <div className="bg-receipt-content shadow mx-3" id='printablediv'>
            <header className="text-center py-2 text-secondary">--- Reçu généré par RDV - EtatCivil ---</header>

            <div className="mt-1 p-3 border-top border-bottom info-receipt container">
                <div className="d-flex justify-content-between align-items-center">
                    <div>
                        <span className="fw-bold">Reçu n° {userInfoToPrint?.receipt} DEMANDE ACTE CIVIL</span>
                    </div>

                    <div className="recu-containDivImg">
                        <div className="recu-containDivImg-containImg">
                            <img src={"/images/Armoirie.png"} className="" />
                        </div>
                    </div>
                </div>
            </div>

            <div className="info-small-toPayment mt-4 d-flex justify-content-between container">
                {userInfoToPrint?.who_request === "Demandeur lui-même" && (
                    <div>
                        <h4 className="my-2"><u>Informations sur celui/celle ayant opéré la demande</u></h4>
                        <div>
                            <span className="fw-bold">Nom</span> : {userInfoToPrint?.firstname}
                        </div>
                        <div>
                            <span className="fw-bold">Prénom</span> : {userInfoToPrint?.lastname}
                        </div>
                        <div>
                            <span className="fw-bold">Numéro</span> : {userInfoToPrint?.phone}
                        </div>
                        <div>
                            <span className="fw-bold">Mail</span> : {userInfoToPrint?.email}
                        </div>
                    </div>
                )}

                {userInfoToPrint?.who_request !== "Demandeur lui-même" && (
                    <div>
                        <h4 className="my-2"><u>Informations sur celui/celle ayant opéré la demande</u></h4>
                        <div>
                            <span className="fw-bold">Nom</span> : {userInfoToPrint?.firstname_demandeur}
                        </div>
                        <div>
                            <span className="fw-bold">Prénom</span> : {userInfoToPrint?.lastname_demandeur}
                        </div>
                        <div>
                            <span className="fw-bold">Numéro</span> : {userInfoToPrint?.phone_demandeur}
                        </div>
                        <div>
                            <span className="fw-bold">Mail</span> : {userInfoToPrint?.email_demandeur}
                        </div>
                    </div>
                )}
                <div>
                    <h4 className="my-2"><u>Informations sur le reçu</u></h4>
                    <div>
                        <span className="fw-bold">Numéro de demande</span> : {userInfoToPrint?.receipt}
                    </div>

                    <div>
                        {userInfoToPrint?.pay_date !== userInfoToPrint?.integration_date && <span><span className="fw-bold">Date de paiement</span> : {pay_date}</span>}
                        {userInfoToPrint?.pay_date === userInfoToPrint?.integration_date && <span><span className="fw-bold">Date de paiement</span> : Inconnu</span>}
                    </div>
                    <div>
                        {userInfoToPrint?.pay_date !== userInfoToPrint?.integration_date && <span><span className="fw-bold">Heure de paiement</span> : {pay_hour}</span>}
                        {userInfoToPrint?.pay_date === userInfoToPrint?.integration_date && <span><span className="fw-bold">Heure de paiement</span> : Inconnu</span>}
                    </div>
                    <div className="mt-1 d-flex justify-content-start align-items-center">
                        <span className="fw-bold">Statut de demande</span> :
                        {userInfoToPrint?.rdv_status === null && userInfoToPrint?.pay_status !== "1" &&
                            <div className="bg-warning p-2 mx-2 recu-notification">Pas encore traité</div>}

                        {userInfoToPrint?.pay_status !== "1" && userInfoToPrint?.rdv_status === "PENDING" &&
                            <div className="bg-secondary p-2 mx-2 recu-notification">En attente d'informations</div>}

                        {userInfoToPrint?.pay_status !== "1" && userInfoToPrint?.rdv_status === "VALIDATED" &&
                            <div className="bg-info p-2 text-dark mx-2 recu-notification">En cours de traitement</div>}


                        {userInfoToPrint?.pay_status === "1" && userInfoToPrint?.rdv_status === "VALIDATED" &&
                            <div className="bg-success p-2 mx-2 recu-notification">Succès</div>}

                        {userInfoToPrint?.pay_status !== "1" && userInfoToPrint?.rdv_status === "REFUSED" &&
                            <div className="bg-danger p-2 mx-2 recu-notification">Réfusé</div>}
                    </div>
                </div>
            </div>

            <div className="info-big-toPayment mt-5">

                {userInfoToPrint?.rdv_status === "VALIDATED" && userInfoToPrint?.pay_status === "1" && (
                    <>
                        <h2 className="p-3 container">DETAIL SUR LE PAIEMENT</h2>
                        <section class="intro pb-5">
                            <div class="h-100">
                                <div class="mask d-flex align-items-center h-100">
                                    <div class="w-100">
                                        <div class="row justify-content-start">
                                            <div class="col-12">
                                                <div class="table-responsive bg-white">
                                                    <table class="table mb-0">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">Type</th>
                                                                <th scope="col">Document</th>
                                                                <th scope="col">Telephone Paiement</th>
                                                                <th scope="col">Canal</th>
                                                                <th scope="col">Formule</th>
                                                                <th scope="col">Montant</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <th scope="row">DEMANDE ACTE CIVIL</th>
                                                                <td>{userInfoToPrint?.demande}</td>
                                                                <td>{userInfoToPrint?.phone}</td>
                                                                <td>{userInfoToPrint?.pay_gateway}</td>
                                                                <td>{userInfoToPrint?.formule}</td>
                                                                <td>{userInfoToPrint?.pay_amount} FCFA</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </>)}

                {userInfoToPrint?.rdv_status === "REFUSED" && (<h2 className="p-3 container text-center">NOUS NE POUVONS DONNER DES DETAILS SUR VOTRE PAIEMENT CAR VOUS AVEZ ÉTE RÉFUSÉ(E)</h2>)}

                {userInfoToPrint?.rdv_status === "VALIDATED" && userInfoToPrint?.pay_status !== "1" && (<h2 className="p-3 container text-center">VOTRE DEMANDE EST PRESQUE FINALISÉ. VEUILLEZ PROCÉDER AU PAIEMENT</h2>)}

                {userInfoToPrint?.rdv_status === "PENDING" && userInfoToPrint?.pay_status !== "1" && (<h2 className="p-3 container text-center">VEUILLEZ DONNER LES INFORMATIONS DEMANDÉES PAR LA {userInfoToPrint?.type_commune_or_sousPrefecture.toUpperCase()}.</h2>)}

                {userInfoToPrint?.rdv_status === null && userInfoToPrint?.pay_status !== "1" && (<h2 className="p-3 container text-center">VEUILLEZ PATIENTER. VOTRE DEMANDE N'A PAS ENCORE ÉTÉ TRAITÉE.</h2>)}

            </div>

            <div className="d-flex justify-content-between container">
                <div>
                    <h4><u>Informations sur bénéficiaire</u></h4>
                    {userInfoToPrint?.who_request === "Demandeur lui-même" && userInfoToPrint?.who_request}
                    {userInfoToPrint?.who_request !== "Demandeur lui-même" && (
                        <>
                            <div>Nom du bénéficiaire: {userInfoToPrint?.firstname}</div>
                            <div>Prénom du beneficiaire : {userInfoToPrint?.lastname}</div>
                            <div>Numéro du beneficiaire : {userInfoToPrint?.phone}</div>
                            <div>Mail du beneficiaire : {userInfoToPrint?.mail}</div>

                        </>
                    )}
                </div>
                <div>
                    <h4><u>En charge</u></h4>

                    <div><span className="fw-bold">Type de localité</span> : {userInfoToPrint?.type_commune_or_sousPrefecture}</div>
                    <div><span className="fw-bold">Nom de la {userInfoToPrint?.type_commune_or_sousPrefecture}</span> : {userInfoToPrint?.commune}</div>
                </div>
            </div>

            <div className="container info-addiontional-toPayment d-flex justify-content-start align-items-center mt-5">
                <div className="receipt-containDivCodeQR">
                    <div className="receipt-containDivCodeQR-containCodeQR px-2">
                        <QRCode size={256} viewBox={`0 0 256 256`} style={{ height: "100%", width: "100%" }} value={`https://www.rdv-etatcivil.cloud/receipt_demande/${userInfoToPrint?.receipt}`} />
                    </div>
                </div>

                <div>
                    Ce code QR sera utile pour des vérifications rapides de la {userInfoToPrint?.type_commune_or_sousPrefecture}.
                </div>
            </div>

            <div className="text-center py-4">
                <h4>NOUS VOUS REMERCIONS !</h4>
                <h5 className="disabledMarginBottom">Guichet du RDV - ÉtatCivil</h5>
            </div>

            <footer className="footer-receipt p-3 d-flex justify-content-center">
                <div class="container text-center">
                    <small>Copyright &copy; RDV - EtatCivil.cloud</small>
                </div>
            </footer>
        </div>
    )
}

export default RealReceiptDemande