import React, { useState, useEffect } from "react";
import { FileUploader } from "react-drag-drop-files";
import { uploadUrl } from "../../Url";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { updateDemandeDocumentUrl } from "../../Url";

const Step44 = () => {
  const [piece, setPiece] = useState("");
  const [all, setAll] = useState([]);
  const [pieceSrc, setPieceSrc] = useState("");
  const fileTypes = ["JPG", "PNG", "GIF", "PDF"];
  const [submit, setSubmit] = useState(false);
  const navigate = useNavigate();

  const [formStep5, setFormStep5] = useState({
    documents: [],
    request_invoice: ""
  });

  const params = useParams();

  useEffect(() => {
    setFormStep5({ ...formStep5, request_invoice: params?.request_invoice});
  }, [all]);

  async function handleFileSelect(file, type) {
    const name = file?.name;
    const types = file?.type;
    const size = file?.size;

    const formData = new FormData();
    formData.append("file", file);
    console.log(formData);

    axios
      .post(`${uploadUrl}/upload`, formData)
      .then(async (response) => {
        const doc_uri = response.data.replace("uploads/", "");
        setAll([...all, { name, size, type: types, url: doc_uri }]);
        setFormStep5({
          ...formStep5,
          documents: [...all, { name, size, type: types, url: doc_uri }],
        });
        if (type === "piece") {
          setPieceSrc(doc_uri);
        }
      })
      .catch((error) => {
        console.log(error.message);
      });
  }

  const handleChangeFile = (file, type) => {
    console.log(file);

    const reader = new FileReader();
    const files = file; // we use here an librarie wich take directly the target of file selected

    if (files && files?.name?.length) {
      reader.onload = async () => {};

      handleFileSelect(files, type);
      reader.readAsDataURL(files);

      if (reader.result !== null) {
        if (type === "piece") {
          setPiece(reader.result);
        }
      }
    }
  };

  function handleSubmit() {

    setSubmit(true);
    axios
      .put(`${updateDemandeDocumentUrl}/${formStep5.request_invoice}`, formStep5)
      .then((response) => {
        console.log(response);
        setSubmit(false);
        alert("Vos documents ont été mis à jour avec succès!");
        navigate('/')
      })
      .catch((err) => {
        const errors = err?.response?.data;
        let message = "";
        if(errors){
          Object.values(errors).forEach(item =>{
            message = item+"\n"
          })
        } 
        else{
          message = "Merci de vérifier les pièces à télécharger, certaines pièces n'ont pas été téléchargées!";
        }
        console.log(err?.response?.data);
        alert(message);
        setSubmit(false);
      });
  }

  return (
    <div>
      <div className="d-flex justify-content-center align-items-center py-5 p-md-4 gap-md-5 flex-wrap">
        <div className="col-12 col-md-5">
          <form className="col-12">
            <div className="">
              <p className="fs-smaller py-0 my-0">
                * Veuillez joindre le(s) document(s) demandés(s)
              </p>
              <p className="text-danger  fs-smaller">
                Nous n'acceptons que des copies scannées de vos documents, pas
                de photo de documents
              </p>
            </div>
            <div className="">
              <FileUploader
                handleChange={handleChangeFile}
                name="file"
                types={fileTypes}
                hoverTitle="Veuillez sélectionner un fichier"
                classes="zone-drop col-1"
              />
            </div>

            <div className="pt-4 py-6">
              {formStep5 &&
                formStep5.documents &&
                formStep5.documents.length > 0 && <hr />}
              {formStep5 &&
                formStep5.documents &&
                formStep5.documents.length > 0 &&
                formStep5.documents.map((val, key) => (
                  <div key={key}>
                    <li
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      {val.type == "application/pdf" ? (
                        <span>
                          <i
                            className="fa fa-file-pdf-o"
                            style={{ fontSize: "25px", color: "blue" }}
                          ></i>
                        </span>
                      ) : (
                        <span>
                          <i
                            className="fa fa-file-image-o"
                            style={{ fontSize: "25px", color: "blue" }}
                          ></i>
                        </span>
                      )}

                      <span>{val.name}</span>
                      <span>{val.size}Ko</span>
                    </li>
                    <hr />
                  </div>
                ))}
            </div>

            <div className="d-flex justify-content-end">
              {formStep5 &&
                formStep5.documents &&
                formStep5.documents.length > 0 && submit &&(
                <button className="btn-step-next" disabled>
                  Patientez...
                </button>
              )}
              {formStep5 &&
                formStep5.documents &&
                formStep5.documents.length > 0 && !submit && (
                <button className="btn-step-next" onClick={handleSubmit}>
                  Soumettre
                </button>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Step44;
