import React, { useState, useEffect } from "react";

import { BsCheck, BsFillQuestionCircleFill } from "react-icons/bs";
import Pricing from "../Pricing";

const Step5 = ({ userInformations, step5Fom, step5Data, type }) => {

  const [selectedRadio, setSelectedRadio] = useState(null) //For toggling "Selectionné"

  const [formStep5, setFormStep5] = useState({
    formule: "",
    montant: "",
    isValid: false,
    ...step5Fom,
  });


  const handleSelect = (formule, montant) => {
    setFormStep5({ ...formStep5, formule: formule, montant: montant });
    console.log("formStep5", formStep5);
  };

  useEffect(() => {
    if (formStep5.formule) {
      setFormStep5({ ...formStep5, isValid: true })
    }
    else {
      setFormStep5({ ...formStep5, isValid: false })
    }
    step5Data(formStep5);
  }, [formStep5]);

  const price = (Number(userInformations?.pay_amount_demande) * userInformations?.number_copie) + 350 + (500 * userInformations?.number_copie)

  const priceVIP = (Number(userInformations?.pay_amount_demande) * userInformations?.number_copie) + 350 + (500 * userInformations?.number_copie) + 2000


  return (
    <div className=" gap-5 justify-content-between forfait-container flex-wrap">

      <div className="d-flex  forfait-descript-conatainer">
        <div className="">
          <p className="fs-4">
            Nous vous remercions d’avoir renseigné votre dossier avec les informations et les pièces requises. À présent nous vous invitons à définir les options de prise en charge concernant la livraison de votre dossier traité et le suivi par SMS{" "}
          </p>
          <div className="border d-flex gap-2 py-2 px-2 flex-wrap">
            <div className="col-12 col-md fs-smaller">
              <div className="px-3">
                <BsFillQuestionCircleFill className="text-danger" />{" "}
                <span className="fw-bold">
                  NB : Veuillez choisir votre formule de tarification entre les choix <span style={{ color: "red" }}>Basic</span> et <span style={{ color: "red" }}>VIP</span> qui s'offrent a vous.{" "}
                </span>
              </div>
              <div className="text-danger">
                Le montant affiché est uniquement celui du service de prise en charge offert par Les services de transports et des frais de service de Rdv-EtatCivil.cloud et non de l’acte d'etat civil demandé.
              </div>
            </div>
            <div className=" slide">
              <div className="slide-img">
                <img src="/images/livraison.png" alt="" />
              </div>

            </div>
          </div>
        </div>
      </div>

      <div class="flow">
        <h1 class="main__heading">Tarification</h1>
        <div class="main__cards cards">
          <div class="cards__inner w-100">
            <div class="cards__card card">

              <div className="card__containImg shadow-lg">
                <div>
                  <img className="fa" src="/images/la-personne.png" alt="" />
                </div>
              </div>
              <h2 class="card__heading">Basique</h2>
              <h6>Frais d'utilisation de la plateforme + <br /> Frais de l'opérateur (1% + 100f)</h6>
              {/* <p class="card__price">500 FCFA</p> */}

              <p class="card__price">{price} FCFA</p>
              <ul role="list" class="card__bullets flow">
                <li>Frais de l'acte : {userInformations?.pay_amount_demande} FCFA</li>
                <li>Nombre de copies : {userInformations?.number_copie}</li>
                <li>Frais de timbre : 500 FCFA x {userInformations?.number_copie}</li>
                <li>Frais de la plateforme RDV-ETATCIVIL : 350 FCFA</li>
              </ul>
              {selectedRadio === "basic" ?
                <button class="card__cta cta btn btn-primary disabled">Choisi !</button> : <button class="card__cta cta" onClick={(e) => { handleSelect("basic", price); setSelectedRadio("basic"); /*setTimeout(() => {  }, 10000)*/ }}>Commander</button>
              }
            </div>

            <div class="cards__card card">
              <div className="card__containImg shadow">
                <div>
                  <img className="fa" src="/images/livraison-a-domicile.png" alt="" />
                </div>
              </div>
              <h2 class="card__heading">VIP</h2>
              <h6>Frais d'utilisation de la plateforme + <br /> Frais de l'opérateur (1% + 100f) + <br /> Frais de livraison </h6>

              <p class="card__price">{priceVIP} FCFA</p>
              <ul role="list" class="card__bullets flow">
                <li>Frais de l'acte : {userInformations?.pay_amount_demande} FCFA</li>
                <li>Nombre de copies : {userInformations?.number_copie}</li>
                <li>Frais de timbre : 500 FCFA x {userInformations?.number_copie}</li>
                <li>Livraison : 2000 FCFA</li>
                <li>Frais de la plateforme RDV-ETATCIVIL : 350 FCFA</li>
              </ul>
              {selectedRadio === "VIP" ?
                <button class="card__cta cta btn btn-primary disabled">Choisi !</button> : <button class="card__cta cta" onClick={(e) => { handleSelect("VIP", priceVIP); setSelectedRadio("VIP"); }}>Commander</button>
              }
            </div>
          </div>
          <div class="overlay cards__inner"></div>
        </div>
      </div>
    </div>
  );
}

export default Step5;