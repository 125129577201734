import React from "react";
import { Link, useNavigate } from "react-router-dom";

const NavbarAlternative = () => {
  const navigate = useNavigate()
  return (
    <>
      <nav class="navbar navAlter ">
        <div class="container fw-bold">
          <div
            className="cursor-pointer col-12 col-md-7 col-lg-5 d-flex align-items-center gap-2 p-3"
            role="button"
            onClick={() => navigate("/")}>

            <div className="w-25" >
              <img src="/images/rci.svg" className="w-100" alt="rci" />
              <h6 className="text-center ">Côte d'Ivoire</h6>
            </div>
            <Link class="navbar-brand text-wrap fs-4" to="/">
              RDV - ÉtatCivil.Cloud
            </Link>
            
          </div>
        </div>
      </nav>
    </>
  );
};

export default NavbarAlternative;
