import React, { useEffect } from 'react';

const VerificationDeclaration = ({ declarationSummarize, verificationDeclarationData }) => {
  // verificationDeclarationData(declarationSummarize)
  // console.log("declarationSummarize",declarationSummarize)
  return (
    <div>
      <div className="">
        <h1 className="text-center">Vérifier les informations entrées</h1>

        <div>
          <form>
            <div className="d-flex col-12 col-md-12 justify-content-center align-items-center px-3">
              <div className="bande col"></div>
              <div className="col bande-title text-center pb-2 fs-3">
                Informations du nouveau née
              </div>
              <div className="bande col"></div>
            </div>
            <div class="row">
              <div class="col">
                <label for="formGroupExampleInput">Nom</label>
                <input type="text" disabled class="form-control" placeholder="" value={declarationSummarize.firstname} />
              </div>
              <div class="col">
                <label for="formGroupExampleInput">Prénom</label>
                <input type="text" disabled class="form-control" placeholder="Last name" value={declarationSummarize.lastname} />
              </div>
            </div>

            <div class="row">
              <div class="col">
                <label for="formGroupExampleInput">Commune</label>
                <input type="text" disabled class="form-control" placeholder="" value={declarationSummarize.commune} />
              </div>
              <div class="col">
                <label for="formGroupExampleInput">Etablissement de déclaration</label>
                <input type="text" disabled class="form-control" placeholder="Last name" value={declarationSummarize.type_commune_or_sousPrefecture} />
              </div>
            </div>
            <div class="row">
              <div class="col">
                <label for="formGroupExampleInput">Date de naissance</label>
                <input type="text" disabled class="form-control" placeholder="" value={declarationSummarize.birth_day} />
              </div>
              <div class="col">
                <label for="formGroupExampleInput">Heure de naissance</label>
                <input type="text" disabled class="form-control" placeholder="Last name" value={declarationSummarize.birth_hour} />
              </div>
            </div>

            <div class="row">
              <div class="col">
                <label for="formGroupExampleInput">Lieu de naissance</label>
                <input type="text" disabled class="form-control" placeholder="" value={declarationSummarize.birth_place} />
              </div>
              <div class="col">
                <label for="formGroupExampleInput">Sexe</label>
                <input type="text" disabled class="form-control" placeholder="" value={declarationSummarize.sex} />
              </div>

            </div>
            <div class="row">
              <div class="col-6">
                <label for="formGroupExampleInput">Numéro de certificat</label>
                <input type="text" disabled class="form-control" placeholder="" value={declarationSummarize.certificate_number} />
              </div>
            </div>
            <div className="d-flex col-12 col-md-12 justify-content-center align-items-center px-3">
              <div className="bande col"></div>
              <div className="col bande-title text-center pb-2 fs-3">
                Informations du père
              </div>
              <div className="bande col"></div>
            </div>
            <div class="row">
              <div class="col">
                <label for="formGroupExampleInput">Nom du père</label>
                <input type="text" disabled class="form-control" placeholder="" value={declarationSummarize.father_firstname} />
              </div>
              <div class="col">
                <label for="formGroupExampleInput">Prénoms du père</label>
                <input type="text" disabled class="form-control" placeholder="Last name" value={declarationSummarize.father_lastname} />
              </div>
            </div>
            <div class="row">
              <div class="col">
                <label for="formGroupExampleInput">Date de naissance du père</label>
                <input type="text" disabled class="form-control" placeholder="" value={declarationSummarize.father_birth_day} />
              </div>
              <div class="col">
                <label for="formGroupExampleInput">Nationnnalité du père</label>
                <input type="text" disabled class="form-control" placeholder="Last name" value={declarationSummarize.father_nationality} />
              </div>
            </div>

            <div class="row">
              <div class="col">
                <label for="formGroupExampleInput">Profession du père</label>
                <input type="text" disabled class="form-control" placeholder="" value={declarationSummarize.father_profession} />
              </div>
              <div class="col">
                <label for="formGroupExampleInput">Téléphone du père</label>
                <input type="text" disabled class="form-control" placeholder="" value={declarationSummarize.father_phone} />
              </div>

            </div>
            <div className="d-flex col-12 col-md-12 justify-content-center align-items-center px-3">
              <div className="bande col"></div>
              <div className="col bande-title text-center pb-2 fs-3">
                Informations de la mère
              </div>
              <div className="bande col"></div>
            </div>
            <div class="row">
              <div class="col">
                <label for="formGroupExampleInput">Nom de la mère</label>
                <input type="text" disabled class="form-control" placeholder="" value={declarationSummarize.mother_firstname} />
              </div>
              <div class="col">
                <label for="formGroupExampleInput">Prénoms de la mère</label>
                <input type="text" disabled class="form-control" placeholder="Last name" value={declarationSummarize.mother_lastname} />
              </div>
            </div>
            <div class="row">
              <div class="col">
                <label for="formGroupExampleInput">Date de naissance de la mère</label>
                <input type="text" disabled class="form-control" placeholder="" value={declarationSummarize.mother_birth_day} />
              </div>
              <div class="col">
                <label for="formGroupExampleInput">Nationnnalité de la mère</label>
                <input type="text" disabled class="form-control" placeholder="Last name" value={declarationSummarize.mother_nationality} />
              </div>
            </div>

            <div class="row">
              <div class="col">
                <label for="formGroupExampleInput">Profession de la mère</label>
                <input type="text" disabled class="form-control" placeholder="" value={declarationSummarize.mother_profession} />
              </div>
              <div class="col">
                <label for="formGroupExampleInput">Téléphone de la mère</label>
                <input type="text" disabled class="form-control" placeholder="" value={declarationSummarize.mother_phone} />
              </div>

            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default VerificationDeclaration;
