export const dataChoice = [
    // "DEMANDE CARTE CONSULAIRE - Majeurs résidents",
    // "DEMANDE CARTE CONSULAIRE - Mineurs",
    // "DEMANDE CARTE CONSULAIRE - Mineurs accompagnés par une association",
    {name : "COPIE D'ACTE DE NAISSANCE", montant : 500},
    {name : "COPIE D'ACTE DE MARIAGE", montant : 500},
    {name : "COPIE D'ACTE DE DECES", montant : 500},
    // "COPIE D'ACTE DE RECONNAISSANCE D’ENFANT",
    {name : "DEMANDE DE RECONNAISSANCE D'ENFANT", montant : 0},
    {name : "DOSSIER EN VUE DE MARIAGE", montant : 41000},
    {name : "DEMANDE DE CELEBRATION DE MARIAGE", montant : 20000},
    {name : "JUGEMENT SUPPLETIF", montant : 0},
    {name : "LEGITIMATION A MA PROGENITURE", montant : 0},
    // "DECLARATION DE DECES"
    // "CERTIFICAT DE CELIBAT",
    // "AUTRES TYPES DE CERTIFICATS DE COUTUME - Pour les demandeurs ivoiriens résidents",
    // "AUTRES TYPES DE CERTIFICATS DE COUTUME - Pour les demandeurs non ivoiriens",
    // "LAISSEZ-PASSER - Pour les majeurs",
    // "LAISSEZ-PASSER - Pour les mineurs",
    // "AUTRES ATTESTATIONS CONSULAIRES",
    // "CERTIFICAT DE VIE - Pour les ivoiriens",
    // "CERTIFICAT DE VIE - Pour les non ivoiriens",
    // "ATTESTATION CONSULAIRE D'IDENTITE",
    // "AUTORISATION DE TRANSFERT DE CORPS",
    // "PROCURATION",
    // "LEGALISATION DES ACTES/ LEGALISATION DE SIGNATURE - Acte à légaliser",
    // "LEGALISATION DES ACTES/ LEGALISATION DE SIGNATURE - Acte sous-seing privé",
    // "LEGALISATION DES ACTES/ LEGALISATION DE SIGNATURE - Diplômes - Autres documents (Contrats - conventions…)",
    // "DECLARATION DE PERTE",
    // "AUTHENTIFICATION DE PERMIS",
    // "AUTORISATION PARENTALE",
    // "AVIS DE NON OPPOSITION (PUBLICATION)",
    // "DEMANDE DE CONFIRMATION DE SIGNATURE PAR LES BANQUES OU AUTRES INSTITUTIONS",
    // "DOCUMENTS « À CERTIFIER CONFORME » À L'ORIGINAL",
    // "AVIS DE MENTION",
    // "DEMANDE DE PASSEPORT",
    // "RETRAIT DE PASSEPORT / VISAS"

]