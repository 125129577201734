import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Cart from "../Components/Cart";
import MenuActesNaissance from "./MenuActesNaissance"; //instead of visa
import MenuActesCivil from "./MenuActesCivil"; //instead of Actes Consulaires
import MenuCasierJudiciaire from "./MenuCasierJudiciaire"; //instead of contact
import MenuEtatCivil from "./MenuEtatCivil";
import MenuCertificatResidence from "./MenuCertificatResidence"; //instead of pieces requises
import MenuCertificatNational from "./MenuCertificatNational"; 
import MenuConstruction from "./MenuConstruction" //instead of Construction page
import MenuImmatriculation from "./MenuImmatriculation";
import MenuFaq from "./MenuFaq"
import Actualites from "../Components/Actualites/Actualites";

const DetailMenu = () => {
  const [title, setTitle] = useState("");
  const { lien } = useParams();

  useEffect(() => {
    if (lien == "certificat-residences") {
      setTitle("Certificat de résidence");
    }
    if (lien == "actes-civil") {
      setTitle("Actes d'etat civil");
    }
    if (lien == "actes-naissance") {
      setTitle("Actes de naissances");
    }
    if (lien == "certificat-nationalite") {
      setTitle("Certificat de nationalité");
    }
    if (lien == "casier-judiciaire") {
      setTitle("Casier Judiciaire");
    }
    if (lien == "immatriculation") {
      // setTitle("Immatriculation");
      setTitle("Pièces requises et droits de chancellerie");
    }
    if (lien == "autres-actes") {
      // setTitle("Autres actes");
      setTitle("Pièces requises et droits de chancellerie");
    }
    if (lien == "etat-civil") {
      // setTitle("Etat civil");
      setTitle("Pièces requises et droits de chancellerie");
    }

    if (lien == "actualites") {
      // setTitle("Etat civil");
      setTitle("Actualités");
    }
    if (lien == "actualites-details") {
      // setTitle("Etat civil");
      setTitle("Actualités");
    }
    if (lien == "faq") {
      // setTitle("Etat civil");
      setTitle("Foire aux questions");
    }
    if (lien == "actualite") {
      // setTitle("Etat civil");
      setTitle("Actualités");
    }
    if (lien == "construction") {
      // setTitle("Etat civil");
      setTitle("EN CONSTRUCTION");
    }
  }, [lien]);

  return (
    <div>
      <div className="detail-section text-center py-5 mt-5 d-flex align-items-center justify-content-center">
        <h2
          className="fw-bold display-6 mt-5 fw-bold"
          style={{ textAlign:"center center", color: " #fff" }}
        >
          {title.toUpperCase()}
        </h2>
      </div>
      {/* <Cart></Cart> */}

      {lien ==="actes-naissance" && <MenuActesNaissance />}
      {lien ==="certificat-residences" && <MenuCertificatResidence />}
      {lien ==="actes-civil" && <MenuActesCivil />}
      {lien ==="casier-judiciaire" && <MenuCasierJudiciaire />}
      {/* {lien ==="etat-civil" && <MenuPieceRequises />} */}
      {lien ==="certificat-nationalite" && <MenuCertificatNational />}
      {/* {lien ==="immatriculation" && <MenuPieceRequises />}
      {lien ==="autres-actes" && <MenuPieceRequises />} */}

      {lien ==="actualites" && <Actualites />}
      {lien ==="faq" && <MenuFaq />}
      {lien ==="construction" && <MenuConstruction />}
      
    </div>
  );
};

export default DetailMenu;
