import { useParams, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import React, { useState, useEffect } from "react";
import { FaEnvelope, FaMailchimp, FaPhoneFlip, FaWhatsapp } from "react-icons/fa6";
import { GrMail } from "react-icons/gr";
import { mailUrl, staticUrl, checkDeclarationById, frontUrl, APP_NAME } from "../Url";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const DetailDeclarationPage = () => {

  const [show, setShow] = useState(false);

  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [user, setUser] = useState({});
  const storedToken = window.localStorage.getItem("token");
  const [load, setLoad] = useState(false);
  const [lien, setLien] = useState("");
  const [message, setMessage] = useState("");
  const [text, setText] = useState("");
  const [typ, setTyp] = useState("");
  const [url, setUrl] = useState("");
  const [mailSubject, setMailSubject] = useState(""); //Get the mail subject
  const auth = JSON.parse(window?.localStorage.getItem("userData") ?? "{}");
  const [confirm_message, setConfirm_Message] = useState("Voulez-vous confirmer l'envoie de la notification ?");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleChange = (e) => {
    setUser({
      ...user,
      [e.target.name]: e.target.value,
      id: Date.now(),
    });
  };

  useEffect(() => {
    axios
      .get(
        `${checkDeclarationById}/${params?.request_invoice}`,
        {
          headers: {
            Authorization: `Bearer ${storedToken}`,
          },
        }
      )
      .then((response) => {
        console.log(response)
        if (response?.data) {
          console.log(response.data)
          setUser(response.data, "Anne");
        }
        else {
          navigate('/dashboard/list-des-nouvelles-declarations')
        }

      })
      .catch((err) => {
        navigate('/dashboard/list-des-nouvelles-declarations')
        console.log(err);
      });
  }, [params]);

  const handleDetailClick = (email) => {
    setLoad(true);

    console.log("data to send", {
      request_invoice: user.request_invoice,
      email: email,
      lien: lien,
      commune: user.commune,
      structure: user.type_commune_or_sousPrefecture,
      document: user.type,
      message: message,
      type: typ //Le type de confirmation envoyé
    })

    // setLoad(false);

    axios
      .post(
        mailUrl,
        {
          request_invoice: user.request_invoice,
          email: email,
          lien: lien,
          commune: user.commune,
          structure: user.type_commune_or_sousPrefecture,
          document: user.type,
          message: message,
          type: typ //Le type de confirmation envoyé
        },
        {
          headers: {
            Authorization: `Bearer ${storedToken}`,
          },
        }
      )
      .then((response) => {
        setLoad(false);
        alert("Mail envoyé avec succès");
        console.log(response);
      })
      .catch((err) => {
        setLoad(false);
        console.log(err);
        console.log(err);
      });
  };


  function handleMail(type) {
    if (type === "calendar") {

      let other_link = "https://calendly.com/sesame-rdv/autres-actes-consulaires";
      let passeport_link = " https://calendly.com/sesame-rdv/passport";
      let visa_link = "https://calendly.com/sesame-rdv/rdv-visas";
      let link = other_link;



      setConfirm_Message("Voulez-vous confirmer l'envoie de la notification ?")
      setText("Formulaire d'envoie de prise de rendez-vous")
      setTyp(type)
      setLien(link)
      setMessage(`Dans le cadre de votre demande effectuée sur la plateforme ${APP_NAME}, vous êtes invité(e) au siège de ${APP_NAME} pour rencontrer un conseiller client.\n\nPrière imprimer la lettre de RDV en pièce jointe et de prendre votre RDV.`)
    }

    if (type === "retrait_calendar") {

      let other_link = "https://calendly.com/sesame-rdv/autres-actes-consulaires";
      let passeport_link = " https://calendly.com/sesame-rdv/passport";
      let visa_link = "https://calendly.com/sesame-rdv/rdv-visas";
      let link = other_link;

      if (user?.type?.toUpperCase().includes('DEMANDE DE VISA')) {
        link = visa_link
      }
      if (user?.type?.toUpperCase().includes('DEMANDE DE PASSEPORT')) {
        link = passeport_link
      }

      setConfirm_Message("Voulez-vous confirmer l'envoie de la notification ?")
      setText("Formulaire d'envoie de prise de rendez-vous")
      setTyp(type)
      setLien(link)
      setMessage(`Dans le cadre de votre demande effectuée sur la plateforme ${APP_NAME}, vous êtes invité(e) au siège de ${APP_NAME} pour le retrait.\n\nPrière imprimer la lettre de RDV en pièce jointe et de prendre votre RDV.`)
    }

    if (type === "finalisation") {
      setConfirm_Message("Voulez-vous confirmer l'envoie de la notification ?")
      setTyp(type)
      setLien('')
      setText("Notification de finalisation de procédure")
      setMessage(`Dans le cadre de votre demande effectuée sur la plateforme ${APP_NAME}, nous vous remercions de votre passage au siège de ${APP_NAME} pour rencontrer un conseiller client.\n\nVotre dossier est à présent à l'étape de validation par nos services. Vous serez prochainement informé(e) de la suite donnée à votre demande.\n\nPour tout besoin d'informations veuillez nous contacter : 20 27 00 00 00.`)
    }

    if (type === "information") {
      setConfirm_Message("Veuillez lister la liste des pièces à demander dans ce message!")
      setTyp(type)
      setLien(`${frontUrl}/completer-documents/${user?.request_invoice}`)
      setText("Demande d'informations supplementaires")
      setMessage(`- \n- \n- `)
    }

    if (type === "dossier_complete") {
      setConfirm_Message("Voulez-vous confirmer l'envoie de la notification ?")
      setTyp(type)
      setMessage(`Votre demande effectuée sur la plateforme ${APP_NAME} est à présent complète. Elle est en cours de traitement pour préparer votre prochain RDV avec un conseiller client au siège de ${APP_NAME}.\n\nNous vous remercions et à très bientôt !`)
      setText("Notification de dossier au complet")
      setLien("")
    }

    if (type === "refus_dossier") {
      setConfirm_Message("Précisez les raisons du refus de la demande.")
      setTyp(type)
      // setRefusMessage("")
      setMessage("-\n-\n-\n-")
      setText("Notification de refus de demande")
      setLien("")
    }

    // if (type === "fiche_identification" || type === "autorisation_parentale") {
    //   setConfirm_Message("Voulez-vous confirmer l'envoie de la notification ?")
    //   setTyp(type)
    //   setLien(`${frontUrl}/completer-documents/${user?.request_invoice}`)
    //   setText("Demande d'informations supplementaires")
    //   setMessage(`Dans le cadre de votre demande effectuée sur la plateforme ${APP_NAME}, nous vous contactons pour des compléments d'informations sur votre dossier.\nA cet effet veuillez télécharger la pièce jointe à ce mail, la remplie correctement et ensuite la soumettre via le lien ci-dessous.`)
    // }

  }


  useEffect(() => {
    const urlencodedtext = encodeURIComponent(`Bonjour ${user.firstname} ${user.lastname},\n\n${message}\n\nSESAME,\nLe Guichet Unique des PME`);
    const url = `https://wa.me/${user.whatsapp}/?text=${urlencodedtext}`;
    setUrl(url)
  }, [message])


  return (
    <div>

      <div>
        <h1 className="text-yellow">Documents fournis pour la demande</h1>
        <div className="d-flex justify-content-evenly mx-5">
          <div>
            {user && user?.documents && user.documents?.length > 0
              && user.documents.map((doc, key) => (
                <div class="px-4 download-doc" key={key}>
                  <i class='bx bx-cloud-download'></i>
                  <a className="" target="_blank" href={`${doc.url}`}>
                    Télécharger {doc.name}
                  </a>
                </div>
              ))}
          </div>

          <div class="px-4 download-doc-gray cursor-pointer" onClick={() => { navigate(`/receipt_declaration/${user.request_receipt}`) }}>
            <i class='bx bxs-printer'></i>
            Télécharger reçu de la demande
          </div>
        </div>

        {auth && (auth.roles == "ROOT" || auth.roles == "ADMIN") && (
          <>
            <div class="col-12 mt-3 d-flex flex-wrap btn-conteneur gap-sm-0 p-0">
              <div className="flex-menu">

              </div>

              {/* Desactivate options if user already pays his fees or if user is refused */}
              {(user.declaration_status != "VALIDATED" && user.declaration_status != "REFUSED") && (
                <>
                  <div class="menu-box ">
                    <h2 class="text-yellow">Documents manquants</h2>
                    <ul class="menu-box-menu">
                      <li>
                        <div onClick={() => { handleShow(); handleMail("autorisation_parentale") }} class="menu-box-tab" >Envoie d'autorisation Parentale<button class="menu-box-number"><i class="bx bx-send"></i></button></div>
                      </li>
                      <li>
                        <div onClick={() => { handleShow(); handleMail("information") }} class="menu-box-tab" >Demande d'information supplementaires<button class="menu-box-number"><i class="bx bx-send"></i></button></div>
                      </li>
                    </ul>
                  </div>
                  <div className="menu-box">
                    <h2 class="text-yellow">Rendez-vous</h2>
                    <ul class="menu-box-menu">
                      <li>
                        <div onClick={() => { handleShow(); handleMail("calendar") }} class="menu-box-tab" >Envoyer un mail pour prise de rendez-vous<button class="menu-box-number"><i class="bx bx-send"></i></button></div>
                      </li>
                      <li>
                        <div onClick={() => { handleShow(); handleMail("retrait_calendar") }} class="menu-box-tab" >Envoyer un mail de rendez-vous pour retrait<button class="menu-box-number"><i class="bx bx-send"></i></button></div>
                      </li>
                    </ul>
                  </div>
                  <div className="menu-box">
                    <h2 class="text-yellow">Décision</h2>
                    <ul class="menu-box-menu">
                      <li>
                        <div onClick={() => { handleShow(); handleMail("dossier_complete") }} class="menu-box-tab" >Confirmation du dossier au complet<button class="menu-box-number"><i class="bx bx-send"></i></button></div>
                      </li>

                      <li>
                        <div onClick={() => { handleShow(); handleMail("refus_dossier") }} class="menu-box-tab" >Refus de la demande<button class="menu-box-number"><i class="bx bx-send"></i></button></div>
                      </li>
                    </ul>
                  </div>
                </>
              )}

              {/* <button
              className=" shadow col-11 col-sm-3"
              onClick={() => { handleShow(); handleMail("fiche_identification") }}
            >
              Envoie de fiche d'identification
            </button>

            <button
              // data-bs-toggle="modal"
              // data-bs-target="#exampleModal"
              className=" shadow col-11 col-sm-3"
              onClick={() => { handleShow(); handleMail("autorisation_parentale") }}
            >
              Envoie d'autorisation Parentale
            </button>

            <button
              // data-bs-toggle="modal"
              // data-bs-target="#exampleModal"
              className=" shadow col-11 col-sm-3"
              onClick={() => { handleShow(); handleMail("information") }}
            >
              Demande d'information supplementaires
            </button>

            <button
              onClick={() => { handleShow(); handleMail("dossier_complete") }}
              // data-bs-toggle="modal"
              // data-bs-target="#exampleModal"
              className=" shadow col-11 col-sm-3"
            >
              Confirmation de dossier au complet
            </button>

            <button
              onClick={() => { handleShow(); handleMail("calendar") }}
              // data-bs-toggle="modal"
              // data-bs-target="#exampleModal"
              className=" shadow col-11 col-sm-3"
            >
              Envoyer un mail pour prise de rendez-vous
            </button>

            <button
              onClick={() => { handleShow(); handleMail("finalisation") }}
              // data-bs-toggle="modal"
              // data-bs-target="#exampleModal"
              className=" shadow col-11 col-sm-3"
            >
              Envoyer mail de finalisation de procédure
            </button>

            <button
              onClick={() => { handleShow(); handleMail("retrait_calendar") }}
              // data-bs-toggle="modal"
              // data-bs-target="#exampleModal"
              className=" shadow col-11 col-sm-3"
            >
              Envoyer un mail pour prise de rendez-vous de retrait
            </button> */}

              {/* {message && (
                <button
                  onClick={() => window.open(url, "_blank")}
                  className=" shadow col-11 col-sm-3"
                >
                  Envoyer une copie par whatsapp
                </button>
              )} */}
            </div>
          </>
        )}
      </div>


      <form class=" mt-3 row g-3">

        <div className="row mb-5 mt-5">
          <h1 className="text-yellow">Information du nouveau née</h1>
          <div class="col-lg-6">
            <label for="name" class="form-label text-green">
              Nom
            </label>
            <input
              type="text"
              class="form-control"
              id="name"
              name="name"
              value={user?.firstname}
              onChange={handleChange}
              required
            />
          </div>
          <div class="col-lg-6">
            <label for="prenom" class="form-label text-green">
              Prénom
            </label>
            <input
              type="text"
              class="form-control"
              id="prenom"
              name="lastname"
              onChange={handleChange}
              value={user?.lastname}
              required
            />
          </div>


          <div class="col-lg-6">
            <label for="proffession" class="form-label text-green">
              Date de naissance
            </label>
            <div className="p-1 bg-white border rounded">
              <input
                type="text"
                class=" border-0 form-control"
                id="proffession"
                name="proffession"
                onChange={handleChange}
                value={user?.birth_day}
                required
              />
            </div>
          </div>
          <div class="col-lg-6">
            <label for="proffession" class="form-label text-green">
              Heure de naissance
            </label>
            <div className="p-1 bg-white border rounded">
              <input
                type="text"
                class=" border-0 form-control"
                id="proffession"
                name="proffession"
                onChange={handleChange}
                value={user?.birth_hour}
                required
              />
            </div>
          </div>
          <div class="col-lg-6">
            <label for="proffession" class="form-label text-green">
              Sexe
            </label>
            <div className="p-1 bg-white border rounded">
              <input
                type="text"
                class=" border-0 form-control"
                id="proffession"
                name="proffession"
                onChange={handleChange}
                value={user?.sex}
                required
              />
            </div>
          </div>
          <div class="col-lg-6">
            <label for="mobile" class="form-label text-green">
              Commune
            </label>
            <div className="d-flex py-1 px-2  justify-content-center bg-white align-items-center border rounded">
              <input
                type="text"
                class="form-control border-0"
                id="mobile"
                name="father_phone"
                value={user?.commune}
                onChange={handleChange}
                required
              />
            </div>
          </div>



          <div class="col-lg-6">
            <label for="type_commune_or_sousPrefecture" class="form-label text-green">
              Etablissement de déclaration
            </label>
            <div className="p-1 bg-white border rounded">
              <input
                type="text"
                class=" border-0 form-control"
                id="type_commune_or_sousPrefecture"
                name="type_commune_or_sousPrefecture"
                onChange={handleChange}
                value={user?.type_commune_or_sousPrefecture}
                required
              />
            </div>
          </div>
          <div class="col-lg-6">
            <label for="proffession" class="form-label text-green">
              Lieu de naissance
            </label>
            <div className="p-1 bg-white border rounded">
              <input
                type="text"
                class=" border-0 form-control"
                id="proffession"
                name="proffession"
                onChange={handleChange}
                value={user?.birth_territory}
                required
              />
            </div>
          </div>
          <div class="col-lg-6">
            <label for="birth_place" class="form-label text-green">
              Etablissement de naissance
            </label>
            <div className="p-1 bg-white border rounded">
              <input
                type="text"
                class=" border-0 form-control"
                id="birth_place"
                name="birth_place"
                onChange={handleChange}
                value={user?.birth_place}
                required
              />
            </div>
          </div>

          <div class="col-lg-6">
            <label for="nationality" class="form-label text-green">
              Nationalité
            </label>
            <div className="p-1 bg-white border rounded">
              <input
                type="text"
                class=" border-0 form-control"
                id="nationality"
                name="nationality"
                onChange={handleChange}
                value={user?.nationality}
                required
              />
            </div>
          </div>

        </div>

        <div className="row mb-5 mt-5">
          <h1 className="text-yellow">Information du père</h1>
          <div class="col-lg-6">
            <label for="father_firstname" class="form-label text-green">
              Nom
            </label>
            <input
              type="text"
              class="form-control"
              id="father_firstname"
              name="father_firstname"
              value={user?.father_firstname}
              onChange={handleChange}
              required
            />
          </div>
          <div class="col-lg-6">
            <label for="prenom" class="form-label text-green">
              Prénom
            </label>
            <input
              type="text"
              class="form-control"
              id="prenom"
              name="father_lastname"
              onChange={handleChange}
              value={user?.father_lastname}
              required
            />
          </div>

          <div class="col-lg-6">
            <label for="mobile" class="form-label text-green">
              Telephone mobile
            </label>
            <div className="d-flex py-1 px-2  justify-content-center bg-white align-items-center border rounded">
              <FaPhoneFlip className="" />
              <input
                type="text"
                class="form-control border-0"
                id="mobile"
                name="father_phone"
                value={user?.father_phone}
                onChange={handleChange}
                required
              />
            </div>
          </div>
          <div class="col-lg-6">
            <label for="father_email" class="form-label text-green">
              Email
            </label>
            <div className="d-flex py-1 px-2  justify-content-center bg-white align-items-center border rounded">
              <FaEnvelope className="" />
              <input
                type="text"
                class="form-control border-0"
                id="father_email"
                name="father_email"
                value={user?.father_email}
                onChange={handleChange}

              />
            </div>
          </div>



          <div class="col-lg-6">
            <label for="proffession" class="form-label text-green">
              Proffession
            </label>
            <div className="p-1 bg-white border rounded">
              <input
                type="text"
                class=" border-0 form-control"
                id="proffession"
                name="proffession"
                onChange={handleChange}
                value={user?.father_profession}
                required
              />
            </div>
          </div>
          <div class="col-lg-6">
            <label for="nationality" class="form-label text-green">
              Nationalité
            </label>
            <div className="p-1 bg-white border rounded">
              <input
                type="text"
                class=" border-0 form-control"
                id="nationality"
                name="nationality"
                onChange={handleChange}
                value={user?.father_nationality}
                required
              />
            </div>
          </div>

        </div>


        <div className="row mb-5">
          <h1 className="text-yellow">Informations de la mère</h1>
          <div class="col-lg-6">
            <label for="nom" class="form-label text-green">
              Nom
            </label>
            <input
              type="text"
              class="form-control"
              id="name"
              name="mother_firstname"
              value={user?.mother_firstname}
              onChange={handleChange}
              required
            />
          </div>
          <div class="col-lg-6">
            <label for="prenom" class="form-label text-green">
              Prénom
            </label>
            <input
              type="text"
              class="form-control"
              id="prenom"
              name="mother_lastname"
              onChange={handleChange}
              value={user?.mother_lastname}
              required
            />
          </div>

          <div class="col-lg-6">
            <label for="mother_phone" class="form-label text-green">
              Telephone mobile
            </label>
            <div className="d-flex py-1 px-2  justify-content-center bg-white align-items-center border rounded">
              <FaPhoneFlip className="" />
              <input
                type="text"
                class="form-control border-0"
                id="mother_phone"
                name="mother_phone"
                value={user?.mother_phone}
                onChange={handleChange}
                required
              />
            </div>
          </div>
          <div class="col-lg-6">
            <label for="mother_email" class="form-label text-green">
              Email
            </label>
            <div className="d-flex py-1 px-2  justify-content-center bg-white align-items-center border rounded">
              <FaEnvelope className="" />
              <input
                type="text"
                class="form-control border-0"
                id="mother_email"
                name="mother_email"
                value={user?.mother_email}
                onChange={handleChange}
                required
              />
            </div>
          </div>


          <div class="col-lg-6">
            <label for="mother_proffession" class="form-label text-green">
              Proffession
            </label>
            <div className="p-1 bg-white border rounded">
              <input
                type="text"
                class=" border-0 form-control"
                id="mother_proffession"
                name="mother_profession"
                onChange={handleChange}
                value={user?.mother_profession}
                required
              />
            </div>
          </div>

          <div class="col-lg-6">
            <label for="mother_nationality" class="form-label text-green">
              Nationalité
            </label>
            <div className="p-1 bg-white border rounded">
              <input
                type="text"
                class=" border-0 form-control"
                id="mother_nationality"
                name="mother_nationality"
                onChange={handleChange}
                value={user?.father_nationality}
                required
              />
            </div>
          </div>
        </div>

        <div className="row">
          <h1 className="text-yellow">Demande</h1>
          <div class="col-lg-6">
            <label for="country" class="form-label text-green">
              Pays
            </label>
            <input
              type="text"
              class="form-control"
              id="country"
              name="country"
              value={user?.country}
              onChange={handleChange}
              required
              disabled
            />
          </div>

          <div class="col-lg-6">
            <label for="certificate_number" class="form-label text-green">
              Numéro du certificat de naissance
            </label>
            <div className="d-flex py-1 px-2  justify-content-center bg-white align-items-center border rounded">
              <input
                type="text"
                class="form-control border-0"
                id="certificate_number"
                name="certificate_number"
                value={user?.certificate_number}
                onChange={handleChange}
                required
                disabled
              />
            </div>
          </div>

          {/* <div class="col-lg-6">
    <label for="request_invoice_provided" class="form-label text-green">
      Numero de demande fournir pour retrait passport
    </label>
    <div className="d-flex py-1 px-2 justify-content-center bg-white align-items-center border rounded">
      <input
        type="text"
        class="border-0 form-control"
        id="request_invoice_provided"
        name="request_invoice_provided"
        onChange={handleChange}
        value={user?.request_invoice_provided}
        required
        disabled
      />
    </div>
  </div> */}

          <div class="col-lg-6">
            <label for="request_invoice" class="form-label text-green">
              Identifiant de la déclaration
            </label>
            <div className="d-flex py-1 px-2 justify-content-center bg-white align-items-center border rounded">
              <input
                type="text"
                class="border-0 form-control"
                id="request_invoice"
                name="request_invoice"
                onChange={handleChange}
                value={user?.request_receipt}
                required
                disabled
              />
            </div>
          </div>

          <div class="col-lg-6">
            <label for="withdraw_invoice" class="form-label text-green">
              Numero de retrait
            </label>
            <div className="d-flex py-1 px-2 justify-content-center bg-white align-items-center border rounded">
              <input
                type="text"
                class="border-0 form-control"
                id="withdraw_invoice"
                name="withdraw_invoice"
                onChange={handleChange}
                value={user?.request_receipt}
                required
                disabled
              />
            </div>
          </div>

          <div class="col-lg-6">
            <label for="formule" class="form-label text-green">
              Formule de paiement
            </label>
            <div className="p-1 bg-white border rounded">
              <input
                type="text"
                class=" border-0 form-control"
                id="formule"
                name="formule"
                onChange={handleChange}
                value={user?.mode_payment}
                required
                disabled
              />
            </div>
          </div>

          <div class="col-lg-6">
            <label for="pay_amount" class="form-label text-green">
              Montant payé
            </label>
            <div className="p-1 bg-white border rounded">
              <input
                type="text"
                class=" border-0 form-control"
                id="pay_amount"
                name="pay_amount"
                onChange={handleChange}
                value={user?.pay_amount}
                required
                disabled
              />
            </div>
          </div>

          <div class="col-lg-6">
            <label for="pay_reference" class="form-label text-green">
              Reference de paiement
            </label>
            <div className="p-1 bg-white border rounded">
              <input
                type="text"
                class=" border-0 form-control"
                id="pay_reference"
                name="pay_reference"
                onChange={handleChange}
                value={user?.pay_reference}
                required
                disabled
              />
            </div>
          </div>

          <div class="col-lg-6">
            <label for="pay_gateway" class="form-label text-green">
              Canal de paiement
            </label>
            <div className="p-1 bg-white border rounded">
              <input
                type="text"
                class=" border-0 form-control"
                id="pay_gateway"
                name="pay_gateway"
                onChange={handleChange}
                value={`AIGLE -> ${user?.gateway_payment === null ? "Canal de paiement pas encore chosi" : user?.gateway_payment}`}
                required
                disabled
              />
            </div>
          </div>

          <div class="col-lg-6">
            <label for="pay_status" class="form-label text-green">
              Statut de paiement
            </label>
            <div className="p-1 bg-white border rounded">
              <input
                type="text"
                class=" border-0 form-control"
                id="pay_status"
                name="pay_status"
                onChange={handleChange}
                value={user?.status_payment}
                required
                disabled
              />
            </div>
          </div>

        </div>
      </form>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{text}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {confirm_message}

          {typ == "information" && (
            <textarea
              class="form-control"
              id="message-text"
              rows="10"
              name="message"
              onChange={(e) => setMessage(e.target.value)}
              value={message}
            >
            </textarea>
          )}

          {typ == "refus_dossier" && (
            <textarea
              class="form-control"
              id="message-text"
              rows="10"
              name="message"
              onChange={(e) => setMessage(e.target.value)}
              value={message}
            >
            </textarea>
          )}

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Fermer
          </Button>
          <Button variant="primary" onClick={() => { handleDetailClick(user?.user?.email); handleClose() }}>
            {load ? "Patientez..." : "Confirmer"}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default DetailDeclarationPage;