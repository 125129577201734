import React, { useState, useEffect } from "react";
import { BsFillQuestionSquareFill } from "react-icons/bs";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { loginUrl, registerAdmin, registerRoot, ToastTokenLoginUrl } from "../Url";
import toast, { Toaster } from 'react-hot-toast';

const LoginPage = () => {

  const location = useLocation();
  const { tokenParams } = useParams();
  const [userFromToken, setUserFromToken] = useState();

  const navigate = useNavigate();
  const [loginForm, setLoginForm] = useState({
    email: "",
    password: "",
  });

  {/* View if user is good for login so we will notify him by a toast */ }
  useEffect(() => {
    const handleToast = async () => {
      if (tokenParams) {
        await axios.get(`${ToastTokenLoginUrl}/${tokenParams}`)
          .then(response => {
            console.log('response login token', response.data)
            setUserFromToken(response.data);
            toast('Votre compte est validé. Vous pouvez vous connecter.');
          })
      }
    }

    handleToast()
  }, [tokenParams])

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [show, setShow] = useState(false);

  useEffect(() => {
    console.log(show, "show")
  }, [show])

  const user = JSON.parse(window?.localStorage.getItem("userData") ?? "{}");
  const [submit, setSubmit] = useState(false);

  const handleChange = (e) => {
    console.log(e.target.value)
    e.preventDefault()
    setLoginForm({ ...loginForm, [e?.taget?.name]: e?.taget?.value });
  };

  useEffect(() => {
    if (user && user?.id) {
      window.localStorage.clear();
      //navigate("/dashboard/list-des-demandes");
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmit(true)
    axios.all([
      // axios.post("http://localhost:8000/api/register-admin")
      //   .then(response => console.log('registerAmin is ok')),
      axios
        .post(loginUrl, { email, password })
        .then(async (response) => {
          window.localStorage.setItem("token", response.data.accessToken);
          window.localStorage.setItem(
            "userData",
            JSON.stringify(response.data.userData)
          );
          setSubmit(false)
          navigate("/dashboard/list-des-nouvelles-demandes");
        })
        .catch((err) => {
          const errors = err?.response?.data;
          let message = "";
          if (errors) {
            Object.values(errors).forEach(item => {
              message = item + "\n"
            })
          }
          else {
            message = "Une erreur s'est produite. Veuillez contacter un administrateur!";
          }
          console.log(err?.response?.data);
          alert(message);
          setSubmit(false);
        })
    ])
  };

  return (
    // <div className="loginPage d-flex py-5 justify-content-center align-items-center">
    //   <div className="col-10 col-sm-7 col-lg-4">
    //     <div className="nouvelle-demande p-4">
    //       <h2 className="">Nouvelle demande ?</h2>
    //       <div className="py-3">
    //         <button onClick={()=>navigate('/rdv')} type="button" className="login-button  fs-4 w-100">
    //           Effectuer une nouvelle demande
    //         </button>
    //       </div>
    //     </div>
    //     <div className="py-4"></div>
    //     <div className="loginFormDiv p-4">
    //       <h2>Se connecter à mon compte</h2>
    //       <form class="row g-3 py-3" onSubmit={handleSubmit}>
    //         <div class="col-12">
    //           <label for="email" class="form-label">
    //             * Adresse électronique
    //           </label>
    //           <input
    //             type="email"
    //             class="form-control p-2"
    //             id="email"
    //             name="email"
    //             value={email}
    //             onChange={(e)=>setEmail(e.target.value)}
    //             required
    //           />
    //         </div>
    //         <div class="col-12">
    //           <label for="password" class="form-label">
    //             * Mot de passe
    //           </label>
    //           <input
    //             type={show ? "text" :"password"}
    //             class="form-control p-2"
    //             id="password"
    //             name="password"
    //             value={password}
    //             onChange={(e)=>setPassword(e.target.value)}
    //             required
    //           />
    //         </div>
    //         <div class="col-12">
    //           <div class="form-check d-flex align-items-center gap-2">
    //             <input
    //               class="form-check-input"
    //               type="checkbox"
    //               id="invalidCheck2"
    //               value={show}
    //               onClick={(e)=>setShow(!show)}
    //             />
    //             <label class="form-check-label" for="invalidCheck2">
    //               Afficher le mot de passe
    //             </label>
    //           </div>
    //         </div>
    //         <div class="col-12">

    //           {submit && (
    //             <button className="login-button w-100 fs-4" disabled>
    //               Patientez...
    //             </button>
    //           )}
    //           {!submit && (
    //             <button className="login-button w-100 fs-4" type="submit">
    //               Se connecter
    //             </button>
    //           )}
    //         </div>
    //       </form>
    //       <div>
    //         <p className="d-flex align-items-center gap-2">
    //           <BsFillQuestionSquareFill />
    //           <span>Mot de passe oublié ?</span>
    //         </p>
    //       </div>
    //     </div>
    //   </div>
    // </div>
    <section className="login_page" style={{ display: "flex" }}>
      {
        tokenParams !== "" && (
          <Toaster />
        )
      }
      <div class="login-form">

        <h1>Connexion</h1>
        <form class="row g-3 py-3" onSubmit={handleSubmit}>
          <div class="col-12">
            <label for="email" class="form-label">
              * Adresse électronique
            </label>
            <input
              type="email"
              class="form-control p-2"
              id="email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div class="col-12">
            <label for="password" class="form-label">
              * Mot de passe
            </label>
            <input
              type={show ? "text" : "password"}
              class="form-control p-2"
              id="password"
              name="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <div class="col-12">
            <div class="form-check d-flex align-items-center gap-2">
              <input
                class="form-check-input"
                type="checkbox"
                id="invalidCheck2"
                value={show}
                onClick={(e) => setShow(!show)}
              />
              <label class="form-check-label" for="invalidCheck2">
                Afficher le mot de passe
              </label>
            </div>
          </div>
          <div class="col-12">

            {submit && (
              <button className="login-button fs-4 " disabled>
                Patientez...
              </button>
            )}
            {!submit && (
              <button className="login-button fs-4" type="submit">
                Se connecter
              </button>
            )}
          </div>
        </form>
        <div class="text-info">
          <p>Nouvelle demande? <button onClick={() => navigate('/rdv')} type="button" className="login-button ">Faire une demande</button></p>
        </div>
      </div>
      <div class="login-img">
        <h1>Page de Connexion</h1>
        <p>Veuillez entrez vos identifiants</p>
      </div>
    </section>
  );
};

export default LoginPage;
