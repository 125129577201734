import * as XLSX from "xlsx";
import Listes_Communes from "./Listes_Communes.xlsx"
import { useEffect, useState } from "react";
import { Autocomplete, TextField } from "@mui/material";

/**
 * Get XLSX file and convert it to an ArrayBuffer. It's the first step.
 * @param file_xlsx - XLSX file.
 */
const fetchFromXLSX = async (com) => {
    try {
        // Fetch the Excel file
        const response = await fetch(com);

        if (!response.ok) {
            throw new Error(`Failed to download Excel file. Status: ${response.status}`);
        }
        // Convert the response to ArrayBuffer
        const arrayBuffer = await response.arrayBuffer();
        return arrayBuffer;
    } catch (error) {
        console.error("Error downloading Excel file:", error);
        throw error;
    }
}


/**
 * Get the xlsx file and convert it to json. It's the main and important. USED IT WHEN TRYING TO CONVERT EXCEL TO JSON.
 * @param file_xlsx - XLSX file.
 */
export const convertExcelToJson = async (comToconvert) => {
    try {
        // Download the Excel file as ArrayBuffer
        const arrayBuffer = await fetchFromXLSX(comToconvert);

        // Convert the Excel buffer to a workbook
        const workbook = XLSX.read(arrayBuffer, { type: "array" });

        // // Get the first sheet
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];

        // Convert the sheet data to JSON
        const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

        // Map the array to an array of objects
        const resultArray = jsonData.map((row) => {
            const obj = {};
            for (let i = 0; i < jsonData[0].length; i++) {
                obj[jsonData[0][i]] = row[i];
            }
            return obj;
        });

        return resultArray.slice(1);
    } catch (error) {
        console.error("Error converting Excel to JSON:", error);
        throw error;
    }
}

/**
 * Put json data from converted excel file and put it into an useState for the AutoComplete.
 * @returns void
 */
export const ReceiveAnd_MappingCommunes = () => {
    const [communes, setCommunes] = useState([])

    useEffect(() => {
        const getCommunes = async () => {
            await convertExcelToJson(Listes_Communes).
                then(res => setCommunes(res));
        }

        getCommunes()
    }, [])



    return (
        // <select>
        //     {communes.map((com, index) => {
        //         return <option key={index} value={com.nom}>{com.nom}</option>
        //     })}
        // </select>
        <Autocomplete
            className="mt-2 w-100"
            id="combo-box-demo"
            getOptionLabel={(option) => option.nom}
            options={communes}
            sx={{ width: 300 }}
            renderInput={(params) => <TextField className="" {...params} label="Sélection d'une commune de Côte d'Ivoire" />}
        />
    )
}