import React from "react";
import { useNavigate, Link } from "react-router-dom";
import DemarcheCertificatNaissance from "../Components/Home/DemarcheCertificatNaissance";
// import DernierActualite from "../Components/Home/DernierActualite";
import Faq from "../Components/Home/Faq";
import DemarcheActeNaissance from "../Components/Home/DemarcheActeNaissance";
// import CalendrierConsulat from "../Components/Home/CalendrierConsulat";
import FooterMain from "../Components/Home/Footer_Main";
import { ENUM_LINKS } from "../constants/enum_links";
// import Banner from "../Components/Banner/Banner";


const HomePage = () => {
  const navigate = useNavigate();

  const mappingChoicesInHomePage = () => {
    return (
      <div className="btn-group-demarche pb-3 fs-5 d-flex justify-content-center gap-lg-2 gap-md-3 gap-sm-1 gap-0 flex-wrap flex-xxl-nowrap">
        <a>
          <button
            onClick={() => { navigate("/rdv"); window.scrollTo(0, 0) }}
            className="pulse-button btn-demarche text-center"
          >
            <i class="bx bx-cart"></i>
            Faites votre demande
          </button>
        </a>
        <a>
          <button
            // onClick={() => { navigate("/details/construction"); window.scrollTo(0, 0) }}
            onClick={() => { navigate("/declaration"); window.scrollTo(0, 0) }}
            className="pulse-button btn-demarche text-center"
          >
            <i class="bx bx-file"></i>
            Faites une déclaration
          </button>
        </a>

        {/* {ENUM_LINKS.map((link) => (
          <a className="text-white">
            <button
              onClick={() => { navigate(link.href);; window.scrollTo(0, 0) }}
              className="btn-demarche text-center"
            >
              {link.name}
            </button>
          </a>
        ))} */}
      </div>

    )
  }

  return (
    <>
      <div className="home-section-1 d-flex align-items-center justify-content-center text-center">
        <div className="section1-container">
          <h1 className="text-center fw-bold fs-1 text">
            Vos demarches d'actes d'état civil
          </h1>
          <p className="text-white text-center fs-5 mb-4">
            Faites une nouvelle demande ou une nouvelle déclaration
          </p>

          {mappingChoicesInHomePage()}

        </div>
      </div>

      {/* <div className="home-section-2">*/}
      {/* <div
          id="carouselExampleAutoplaying"
          class="carousel slide home-slide"
          data-bs-ride="carousel"
        >

          <div class="carousel-inner">
            <div class="carousel-item active">
              <img
                src="/images/slide11.jpg"
                class="d-block mx-auto"
                alt="..."
              />
            </div>
            <div class="carousel-item">
              <img
                src="/images/slide22.jpg"
                class="d-block mx-auto"
                alt="..."
              />
            </div>
            <div class="carousel-item">
              <img
                src="/images/slide33.jpg"
                class="d-block mx-auto"
                alt="..."
              />
            </div>
            <div class="carousel-item">
              <img
                src="/images/slide44.jpg"
                class="d-block mx-auto"
                alt="..."
              />
            </div>
          </div>

          <button
            class="carousel-control-prev"
            type="button"
            data-bs-target="#carouselExampleAutoplaying"
            data-bs-slide="prev"
          >
            <span
              class="carousel-control-prev-icon bg-default"
              aria-hidden="true"
            ></span>
            <span class="visually-hidden bg-primary">Previous</span>
          </button>
          <button
            class="carousel-control-next"
            type="button"
            data-bs-target="#carouselExampleAutoplaying"
            data-bs-slide="next"
          >
            <span
              class="carousel-control-next-icon bg-default"
              aria-hidden="true"
            ></span>
            <span class="visually-hidden bg-primary">Next</span>
          </button>
        </div> */}
      <div class="container-fluid my-5 p-0 pt-5 pb-5 " >
        <div class="row justify-content-center">
          <div class="col-12 col-lg-10 border-start  border-5 border-success ps-4 mb-5">
            <h1 class=" text-uppercase  mb-3 animated slideInDown ">Comment ça marche</h1>
          </div>
        </div>
        <div class="row g-0">
          <div class="col-xl-3 col-sm-6 wow fadeIn" data-wow-delay="0.1s">
            <div class="position-relative">
              <img class="img-fluid w-75" src="images/formulaire-de-contact.png" alt="" />
              <div class="facts-overlay">
                <h1 class="display-1">01<i class="fa fa-arrow-right ms-3"></i></h1>
                <h4 class="text-white mb-3">Faire une demande</h4>
                <p class="text-white">
                  Créer votre demande et vous serez contacter après vérification des documents.</p>
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-sm-6 wow fadeIn" data-wow-delay="0.3s">
            <div class="position-relative">
              <img class="img-fluid w-75" src="images/paiement-mobile.png" alt="" />
              <div class="facts-overlay">
                <h1 class="display-1">02<i class="fa fa-arrow-right ms-3"></i></h1>
                <h4 class="text-white mb-3">Payer les frais d'envoi </h4>
                <p class="text-white">Payer les frais de la commande et/ou de la livraison en toute sécurité via AIGLE.</p>
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-sm-6 wow fadeIn" data-wow-delay="0.5s">
            <div class="position-relative">
              <img class="img-fluid w-75" src="images/suivi-de-commande.png" alt="" />
              <div class="facts-overlay">
                <h1 class="display-1">03<i class="fa fa-arrow-right ms-3"></i></h1>
                <h4 class="text-white mb-3">Suivre la commande</h4>
                <p class="text-white">Suivre l’état d’avancement de la commande pas à pas grâce aux alertes via sms , mails et whatsapp.</p>
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-sm-6 wow fadeIn" data-wow-delay="0.7s">
            <div class="position-relative">
              <img class="img-fluid w-75" src="images/livraison-a-domicile.png" alt="" />
              <div class="facts-overlay">
                <h1 class="display-1">04</h1>
                <h4 class="text-white mb-3">Recevoir les documents</h4>
                <p class="text-white">Recevoir les documents par livraison à l’adresse renseignée ou dans le guichet d'état civil renseigné.</p>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div class="container-fluid my-5 p-0 pt-5 pb-5" >
        <div class="row justify-content-center">
          <div class="col-12 col-lg-10 border-start  border-5 border-success ps-4 mb-5">
            <h1 class=" text-uppercase  mb-3 animated slideInDown ">Les actes d'état civil les plus démandés</h1>
          </div>
        </div>
        <div style={{ display: "flex", alignItems: "center", justifyContent: 'space-evenly', flexWrap: 'wrap' }} class="col-12  d-flex">
          <Link to="/details/actes-naissance" class="btn  py-3 px-5" style={{backgroundColor:"#3b8c7d"}}>
            <div style={{ minHeight: '200px', maxWidth: '200px' }}>
              <img src="images/nouveau-nee.png" style={{ width: '150px' }} alt="" />
              <h4 class="text-white mb-0">Acte de naissance</h4>
            </div>
          </Link>
          <Link to="/details/certificat-nationalite" class="btn  py-3 px-5" style={{backgroundColor:"#3b8c7d"}}>
            <div style={{ minHeight: '200px', maxWidth: '200px', borderRadius: '2px' }} >
              <img src="images/citoyennete.png" style={{ width: '150px' }} alt="" />
              <h4 class="text-white mb-0">Certificat de nationalité</h4>
            </div>
          </Link>
          <Link to="/details/certificat-residences" class="btn  py-3 px-5" style={{backgroundColor:"#3b8c7d"}}>
            <div style={{ minHeight: '200px', maxWidth: '200px' }}>
              <img src="images/certificat-de-cle-publique.png" style={{ width: '150px' }} alt="" />
              <h4 class="text-white mb-0">Certificat de résidence</h4>
            </div>
          </Link>
          <Link to="/details/casier-judiciaire" class="btn  py-3 px-5" style={{backgroundColor:"#3b8c7d"}}>
            <div style={{ minHeight: '200px', maxWidth: '200px' }}>
              <img src="images/justice.png" style={{ width: '150px' }} alt="" />
              <h4 class="text-white mb-0">Casier judiciaire</h4>
            </div>
          </Link>
        </div>
      </div>

      

      <div class=" container-fluid p-0 mb-5 wow fadeIn" data-wow-delay="0.1s">
        <div id="header-carousel" class="carousel slide" data-bs-ride="carousel">
          <div class="carousel-inner">
            <div class="carousel-item active">
              <img class="w-100" src="/images/carousel-4.jpg" alt="Image" />
              <div class="carousel-caption">
                <div class="container">
                  <div class="row justify-content-center">
                    <div class="col-12 col-lg-10">
                      <h1 class=" text-light mb-3 animated slideInDown ">Guichet éléctronique</h1>
                      <h5 class="text-light text-uppercase mb-3 animated slideInDown">Vos différents actes d'etats civil à porter de main</h5>

                      <Link to={"/rdv"} class="btn btn-success  py-3 px-5">Nouvelle demande</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="carousel-item">
              <img class="w-100" src="/images/carousel-2.jpg" alt="Image" />
              <div class="carousel-caption">
                <div class="container">
                  <div class="row justify-content-center">
                    <div class="col-12 col-lg-10">
                      <h1 class=" text-light mb-3 animated slideInDown ">Les questons fréquentes</h1>
                      <h5 class="text-light text-uppercase mb-3 animated slideInDown">Ce que vous démandez le plus</h5>

                      <Link to={"/details/faq"} class="btn btn-success py-3 px-5">FAQ</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="carousel-item">
              <img class="w-100" src="/images/carousel-5.jpg" alt="Image" />
              <div class="carousel-caption">
                <div class="container">
                  <div class="row justify-content-center">
                    <div class="col-12 col-lg-10">
                      <h1 class=" text-uppercase text-light mb-3 animated slideInDown ">Renseignez vous!</h1>
                      <h5 class="text-light  mb-3 animated slideInDown">Informez-vous sur les différentes démarches à suivre et sur chaque service</h5>

                      <Link to={"/details/actes-civil"} class="btn btn-success py-3 px-5">Actes d'etats civil</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <button class="carousel-control-prev" type="button" data-bs-target="#header-carousel"
            data-bs-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Précédent</span>
          </button>
          <button class="carousel-control-next" type="button" data-bs-target="#header-carousel"
            data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Suivant</span>
          </button>
        </div>
      </div>
      <div class="container-fluid my-5 p-0 pt-5 pb-5" >
        <div class="row justify-content-center">
          <div class="col-12 col-lg-10 border-start  border-5 border-success ps-4 mb-5">
            <h1 class=" text-uppercase  mb-3 animated slideInDown ">Nos partenaires</h1>
          </div>
        </div>
        <div className=" slide-logo">
          <div className="slide-logo-img">
            <img src="/images/logo-aigle.png" alt="" />
            <img src="/images/logo-mairie-attecoube.png" alt="" />
          </div>
        </div>
      </div>
      {/*  <div className="container d-flex py-3 flex-wrap">
          <div className="col-12 col-sm-6 col-lg-8 display-5 fw-bold">
            <p>Evaluez votre expérience auprès du consulat.</p>
          </div>
          <div className="col-12 col-sm-6 col-lg-4 d-flex align-items-center justify-content-center">
            <button
              className="btn-experience text-white p-4 fw-bold fs-5"
              onClick={() => navigate("/avis")}
            >
              je partage mon expérience
            </button>
          </div>
        </div>
      </div> */}

      {/* <div className="w-100">
        <Banner />
      </div> */}

      {/* <div className="home-section-3 text-center py-5">
        <h2 className="fw-bold display-6 mt-5 text-white">
          Besoin de contacter les services consulaires ?
        </h2>
        <p className="text-white fw-bold">
          Pour toute information, réclamation, remarque ou suggestion, nous vous
          invitons à contacter le Centre d'Appel, à envoyer un Email ou écrire
          directement au Consul.{" "}
        </p>
        <div className="btn-group-demarche d-flex justify-content-center gap-3 flex-wrap">
          <a href="mailto:support@rdv-consulat.ci" target="_blank">
            <button className="btn-demarche2 fw-bold text-center">
              support@rdv-consulat.ci
            </button>
          </a>
          <a className="text-white ">
            <button
              onClick={() => navigate("/details/contact")}
              className="btn-demarche2 fw-bold text-center"
            >
              Nous contacter
            </button>
          </a>
          <a className="text-white ">
            <button className="btn-demarche2 fw-bold text-center" onClick={() => navigate("/details/contact#tous")}>
              Tous les consulats
            </button>
          </a>
        </div>
      </div> */}

      {/* <DemarcheCertificatNaissance/>
      <DemarcheActeNaissance/> */}
      {/* <Faq/> */}
      {/* <DernierActualite/> */}
      {/* <CalendrierConsulat/> */}

      <FooterMain />
    </>
  );
};

export default HomePage;
