import { Button, TextField } from "@mui/material"
import axios from "axios"
import { useEffect, useState } from "react"

const Step6 = ({ stepInformationPers, step6Fom, step6Data }) => {

    console.log("stepInformationPers", stepInformationPers)
    const [choicePayment, setChoicePayment] = useState({
        phonePayment: "",
        canal: "",
        otp: "",
        isValid: true,
        ...step6Fom
    })

    useEffect(() => {
        step6Data(choicePayment);
    }, [choicePayment])
    /* For register */
    // const [userPayment, setUserPayment] = useState({
    //     "firstName": "example",
    //     "lastName": "example_22",
    //     "phone": "0101010102",
    //     "password": "example@etatcivil.com",
    //     "email": "example2@gmail.com",
    //     "address": "example store",
    //     "accountType": "Particuier",
    //     "account": {
    //         "compagnyName": "ETAT CIVIL",
    //         "accountType": "Particulier"
    //     },
    //     "compagnyName": "ETAT CIVIL",
    //     "submit" : null
    // })

    // const handleRegisterInAigle = (e) => {
    //     e.preventDefault()
    //     console.log("setUserPayment in Step6", step6Fom)
    //     console.log("setUserPayment in userPayment", userPayment)


    //     axios.post('https://api.mobiletransaction4africa.tech/aigle/api/v1/register', userPayment)
    //         .then((response) => (console.log(response)))
    //         .catch(error => console.log(error))
    // }

    /* For Payment */
    // const [userPayment, setUserPayment] = useState({
    //     "beneficiaire": "0701985778",
    //     "beneficiaireOmnivox": null,
    //     "idClientCarte": null,
    //     "numeroCarte": null,
    //     "montant": "400",
    //     "devise": "XOF",
    //     "canal": "Orange Money",
    //     "type": "Retrait",
    //     "webhookUrl": "",
    //     "description": "Retrait 300",
    //     "submit": null
    // })

    const handleChange = (event) => {
        setChoicePayment({ ...choicePayment, canal: event.target.value });
        console.log(event.target.value)
        console.log("choicePayment on handleChange", choicePayment)
    }

    // const handlePaymentInAigle = (e) => {
    //     e.preventDefault()
    //     console.log("setUserPayment in Step6", step6Fom)
    //     console.log("setUserPayment in userPayment", userPayment)

    //     axios.post(`${process.env.API_PAYMENT}`, userPayment, {
    //         headers: {
    //             "Authorization": `Bearer ${process.env.REACT_APP_WALLETID}`
    //         }
    //     })
    //         .then((response) => (console.log(response)))
    //         .catch(error => console.log(error))
    // }

    // useEffect(() => {
    //     console.log("setUserPayment in Step6", step6Fom)
    // }, [userPayment])

    return (
        <div className="d-flex justify-content-center">
            <form className="w-100">
                <div>
                    <h5 className="text-danger">Veuillez sélectionner votre mode de paiement</h5>
                    <select value={choicePayment.canal} onChange={handleChange} className="fs-4 w-100 mb-3">
                        <option value="">-- Choisissez votre canal mobile money --</option>
                        <option value="Orange Money">Orange Money</option>
                        <option value="Moov Money">Moov Money</option>
                        <option value="MTN Mobile Money">MTN Mobile Money</option>
                        <option value="Wave">Wave</option>
                    </select>
                </div>

                <div className="w-100">
                    <TextField
                        value={choicePayment.phonePayment}
                        onChange={(e) => {
                            setChoicePayment({ ...choicePayment, phonePayment: e.target.value });
                            console.log("choicePayment with input", choicePayment)
                        }}
                        className="w-100 text-danger"
                        label="Entrez le numéro qui sera débité pour le paiment"
                        variant="outlined"
                    />
                </div>
            </form>
        </div>
    )
}


export default Step6