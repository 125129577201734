import React, { useState } from "react";
import { Rating } from "react-simple-star-rating";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";

const AvisPage = () => {
  const [formsent, setFormsent] = useState(false);
  const [formWaiting, setFormWaiting] = useState(false);
  const [ratingForm, setRatingForm] = useState({
    nom: "",
    note: "",
    satisfaction: "",
    commentaire: "",
  });

  const handleStar = (n) => {
    setRatingForm({ ...ratingForm, note: n });
  };
  const handleSatisfaction = (s) => {
    setRatingForm({ ...ratingForm, satisfaction: s });
  };

  const handleChange = (e) => {
    setRatingForm({ ...ratingForm, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormWaiting(true);
    console.log(ratingForm);
    setRatingForm({ nom: "", note: "", satisfaction: "", commentaire: "" });
    setFormsent(true);
    setFormWaiting(false);
  };
  return (
    <>
      {formsent ? (
        <div className="msge-sent d-flex justify-content-center align-items-center mt-5">
          <div className="col-lg-5 text-center p-5 bloc-avis ">
            Votre avis a été envoyé avec succès ! Nous vous remercions.
          </div>
        </div>
      ) : (
        <div className="d-flex m-lg-5 my-2 mx-1 justify-content-center align-items-center">
          <div style={{marginTop: 130}} className="col-lg-6 p-2 bloc-avis p-lg-5">
            <div className="form-title text-center py-4 mb-3">
              <h1>Consulat Général de Côte d'Ivoire à Paris</h1>
              <span className="fw-lighter fs-5">
                Merci de nous faire part de votre expérience avec notre service.
              </span>
            </div>
            <form onSubmit={handleSubmit}>
              <div class="col">
                <label for="nom" className="form-label my-0">
                  Votre nom complet
                </label>
                <input
                  type="text"
                  class="form-control px-3 py-2"
                  id="nom"
                  name="nom"
                  value={ratingForm.nom}
                  onChange={handleChange}
                />
              </div>

              <div className="mt-3">
                <label className="my-0">
                  Comment évaluez-vous votre expérience avec nous ?
                </label>
                <div className="fs-3 note-star my-0">
                  {[1, 2, 3, 4, 5].map((n) => (
                    <FontAwesomeIcon
                      key={n}
                      icon={faStar}
                      onClick={() => handleStar(n)}
                      style={{
                        cursor: "pointer",
                        color: n <= ratingForm.note ? "gold" : "gray",
                      }}
                    />
                  ))}
                </div>
              </div>
              <div className="py-3">
                <label>
                  Quel est votre degré de satisfaction à l'égard de notre
                  service ?
                </label>
                <div className=" ">
                  <div className="d-flex justify-content-between fs-3 note-span ">
                    {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((s) => (
                      <span
                        key={s}
                        onClick={() => handleSatisfaction(s)}
                        className={`${
                          s == ratingForm.satisfaction ? "note-active" : ""
                        } , note-number px-2 px-lg-3 rounded`}
                      >
                        {s}{" "}
                      </span>
                    ))}{" "}
                  </div>
                  <div className="d-flex justify-content-between fw-lighter fs-smaller">
                    <span>Very Unsatisfied</span>
                    <span>Very Satisfied</span>
                  </div>
                </div>
              </div>

              <div class="mb-3">
                <label for="commentaire" class="form-label">
                  Avez-vous des suggestions pour l'améliorer ?
                </label>
                <textarea
                  class="form-control"
                  id="commentaire"
                  rows={5}
                  name="commentaire"
                  onChange={handleChange}
                  value={ratingForm.commentaire}
                ></textarea>
              </div>

              <button type="submit" className="btn btn-avis px-3 py-2">
                Envoyer
              </button>
              <div>Vos réponses sont anonymes</div>
              {formWaiting && (
                <div class="spinner-border" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              )}
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default AvisPage;
