import React, { useState, useEffect } from "react";
import {
  BsFillExclamationTriangleFill,
  BsFillQuestionCircleFill,
} from "react-icons/bs";
import { dataChoice } from "../../Data/step-choice";
import Step11 from "./Step11";

const Step1 = ({ step1Fom, step1Data }) => {
  const [formStep1, setFormStep1] = useState({
    type: dataChoice[0].name,
    request_invoice_provided: "",
    isValid: false,
    number_copie: 1,
    montant_demande: dataChoice[0].montant,
    ...step1Fom,
  });

  const handleNumberCopie = (e) => {
    setFormStep1({ ...formStep1, number_copie: e.target.value })
    console.log("formStep1", formStep1)
  }

  const handleMontant = (data) => {
    const choice = dataChoice.find(item => item.name === data)

    if (choice === undefined) {
      return 0;
    }
    return choice?.montant

  }

  const handleChange = (e) => {
    setFormStep1({ ...formStep1, [e.target.name]: e.target.value, montant_demande: handleMontant(e.target.value) });
    // handleMontant(e.target.value);
    console.log("formStep1", formStep1)
  };

  useEffect(() => {

    if (formStep1.type && formStep1.number_copie && formStep1.type !== "RETRAIT DE PASSEPORT / VISAS") {
      setFormStep1({ ...formStep1, isValid: true })
    } else {
      if (formStep1.type && formStep1.type === "RETRAIT DE PASSEPORT / VISAS" && formStep1.request_invoice_provided) {
        setFormStep1({ ...formStep1, isValid: true })
      }
      else {
        setFormStep1({ ...formStep1, isValid: false })
      }
    }

    step1Data(formStep1);
  }, [formStep1]);

  return (
    <div className="">
      <div className="d-flex justify-content-center align-items-center text-danger fs-5">

        {formStep1.type == "DEMANDE DE PASSEPORT" && (
          <span>
            Assurez-vous d'avoir reglé les frais de passeport qui s'élèvent à 115 euros sur
            <span className="text-black"><a href=" https://monpasseport.ci" target="_blanck" className="text-black"> https://monpasseport.ci/</a></span>  et disposer
            de votre reçu de paiement avant de poursuivre.
          </span>
        )}

      </div>
      <div className="d-flex justify-content-center align-items-center py-5 p-md-4 gap-md-5 type-acces-container ">
        <div className="col-12 col-md-6">
          {formStep1.type !== "DEMANDE DE PASSEPORT" && formStep1.type !== "RETRAIT DE PASSEPORT / VISAS" && (
            <span style={{ fontSize: "25px", color: "red" }}>
              Veuillez choisir l'acte d'état civil que vous désirez éditer
            </span>
          )}
          <form className="">
            <div className="fs-smaller">
              * Sélectionnez le type d'acte civil
            </div>
            <select
              class="form-select form-select-lg mb-3 form-input-droit"
              aria-label="Large select example"
              name="type"
              value={formStep1.type}
              onChange={handleChange}
              style={{ borderRadius: "8px" }}>
              {dataChoice && dataChoice.map((item) => <option value={item.name}>{item.name}</option>)}
            </select>

            {(formStep1.type == "COPIE D'ACTE DE NAISSANCE"
              || formStep1.type == "COPIE D'ACTE DE MARIAGE"
              || formStep1.type == "COPIE D'ACTE DE DECES") && (
                <>
                  <div className="fs-smaller text-danger">
                    * Veuillez mettre le nombre de copie voulue pour {formStep1.type}
                  </div>
                  <input
                    type="number"
                    class="form-control form-control-lg form-input-droit mb-4"
                    id="number_copie"
                    name="number_copie"
                    onChange={handleNumberCopie}
                    value={formStep1.number_copie}
                  />
                </>
              )}

            {/* {formStep1.type == "RETRAIT DE PASSEPORT / VISAS" && (
              <>
                <div className="fs-smaller">
                  * Veuillez renseigner le numero de dépôt du passeport ou du visa
                </div>
                <input
                  type="text"
                  class="form-control form-control-lg form-input-droit mb-4"
                  id="request_invoice_provided"
                  name="request_invoice_provided"
                  aria-describedby="request_invoice_provided"
                  onChange={handleChange}
                  value={formStep1.request_invoice_provided}
                />
              </>
            )} */}
          </form>
        </div>
        <Step11 type={formStep1.type} />
      </div>
    </div >
  );
}

export default Step1