import React from "react";
import Form from "../Components/Contact/Form";
import { Navigate, useNavigate } from "react-router-dom";

const MenuFaq = () => {
  const navigate = useNavigate()
  return (
    <div className="container">

<div className="side-1 mt-5 mb-5 mb-sm-2">
        <h5>ACTES D'ETAT CIVIL</h5>
        <div className="mt-4 border rounded-3 p-2">
          <div
            className="accordion accordion-flush"
            id="accordionFlushExample1"
          >
            <div className="accordion-item">
              <h2 className="accordion-header" id="flush-headingOne1">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseOne1"
                  aria-expanded="false"
                  aria-controls="flush-collapseOne1"
                >
                  Quels types d'actes d'etat civil puis-je demander sur la
                  plateforme ?
                </button>
              </h2>
              <div
                id="flush-collapseOne1"
                className="accordion-collapse collapse"
                aria-labelledby="flush-headingOne1"
                data-bs-parent="#accordionFlushExample1"
              >
                <div className="accordion-body">
                  <p>
                    Vous pouvez demander divers actes d'état civil tels que les
                    copies d'actes de naissances, les copies d'actes de mariages, les copies d'actes de décès, et d'autres
                    services d'état civil.
                  </p>
                  <p>
                    Voir la liste des actes d'état civil et le délai moyen de
                    traitement de votre dossier <span onClick={() => navigate("/details/actes-civil")}>ici</span>.
                  </p>
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="flush-headingTwo1">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseTwo1"
                  aria-expanded="false"
                  aria-controls="flush-collapseTwo1"
                >
                  Quelle est la procédure pour demander un acte d'etat civil en
                  ligne ?
                </button>
              </h2>
              <div
                id="flush-collapseTwo1"
                className="accordion-collapse collapse"
                aria-labelledby="flush-headingTwo1"
                data-bs-parent="#accordionFlushExample1"
              >
                <div className="accordion-body">
                  <p>Désormais pour demander de nouveaux actes d'état civil :</p>
                  <ul>
                    <li>
                      Vérifiez dans la liste des actes d'état civil les documents réquis pour effectuer une demande selon l'acte d'etat civil souhaiter, le coût et le délais moyen de traitement de votre demande.
                    </li>
                    <li>
                      Procédez d’abord à la demande de votre acte d'etat civil sur
                      <a className="text-danger" onClick={() => navigate("/")}>www.rdv-Etatcivil.cloud</a> via le formulaire et soumettez vos
                      documents en ligne <a className="text-danger" onClick={() => navigate("/rdv")}>ici</a> !
                    </li>
                    <li>
                      Faire ensuite le paiement des frais de services
                      d'ETATCIVIL et de l'acte souhaiter après validation de votre dossier par un agent de l'etat civil .
                    </li>
                    
                  </ul>
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="flush-headingThree1">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseThree1"
                  aria-expanded="false"
                  aria-controls="flush-collapseThree1"
                >
                  Puis-je suivre l'état de ma demande en ligne ?
                </button>
              </h2>
              <div
                id="flush-collapseThree1"
                className="accordion-collapse collapse"
                aria-labelledby="flush-headingThree1"
                data-bs-parent="#accordionFlushExample1"
              >
                <div className="accordion-body">
                  <p>
                    Oui, vous pouvez suivre l'état de votre demande
                    grâce au suivi par SMS et mail inclut dans les frais de services de RDV-ETATCIVIL.
                  </p>
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="flush-headingFour1">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseFour1"
                  aria-expanded="false"
                  aria-controls="flush-collapseFour1"
                >
                  Puis-je demander la livraison de mon dossier traité à mon
                  domicile ?
                </button>
              </h2>
              <div
                id="flush-collapseFour1"
                className="accordion-collapse collapse"
                aria-labelledby="flush-headingFour1"
                data-bs-parent="#accordionFlushExample1"
              >
                <div className="accordion-body">
                  Oui, vous pouvez demander la livraison de votre dossier traité
                  à votre domicile. RDV-ETATCIVIL vous propose un service de livraison à domicile qui vous est proposer avec la formule VIP? qui est la formule complète de prise en charge de votre dossier. Nous vous
                  recommandons donc de souscrire à la formule VIP  pour une prise en charge la plus optimal possible de votre dossie.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="side-2 mt-5 mb-5">
        <h5>PIECES REQUISES & DROITS</h5>
        <div className="mt-4 border rounded-3 p-2">
          <div
            className="accordion accordion-flush"
            id="accordionFlushExample2"
          >
            <div className="accordion-item">
              <h2 className="accordion-header" id="flush-headingOne2">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseOne2"
                  aria-expanded="false"
                  aria-controls="flush-collapseOne2"
                >
                  Quels documents faut-il fournir pour ma demande des différents actes
                  d'etas civil ?
                </button>
              </h2>
              <div
                id="flush-collapseOne2"
                className="accordion-collapse collapse"
                aria-labelledby="flush-headingOne2"
                data-bs-parent="#accordionFlushExample2"
              >
                <div className="accordion-body">
                  <p>
                    Les documents requis varient en fonction du service demandé.
                    Consultez la rubrique correspondante sur la plateforme pour
                    connaître les pièces nécessaires <a onClick={() => navigate("/details/actes-civil")}>ici</a>.
                  </p>
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="flush-headingTwo2">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseTwo2"
                  aria-expanded="false"
                  aria-controls="flush-collapseTwo2"
                >
                  Comment puis-je connaître les droits d'état civil pour ma
                  demande ?
                </button>
              </h2>
              <div
                id="flush-collapseTwo2"
                className="accordion-collapse collapse"
                aria-labelledby="flush-headingTwo2"
                data-bs-parent="#accordionFlushExample2"
              >
                <div className="accordion-body">
                  <p>
                    Les droits d'état civil sont généralement affichés sur la
                    plateforme. Vous pouvez les trouver dans la section dédiée <a onClick={() => navigate("/details/actes-civil")}>ici</a>.
                  </p>
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="flush-headingThree2">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseThree2"
                  aria-expanded="false"
                  aria-controls="flush-collapseThree2"
                >
                  Quels sont les délais de traitement pour les demandes d'actes
                  d'état civil ?
                </button>
              </h2>
              <div
                id="flush-collapseThree2"
                className="accordion-collapse collapse"
                aria-labelledby="flush-headingThree2"
                data-bs-parent="#accordionFlushExample2"
              >
                <div className="accordion-body">
                  <p>
                    Les délais de traitement varient en fonction du type de
                    demande. Vous pouvez trouver des informations spécifiques
                    sur les délais sur la plateforme <span  onClick={() => navigate("/details/actes-civil")}>ici</span>.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="side-3 mt-5 mb-5">
        <h5>CASIER JUDICIAIRE</h5>
        <div className="mt-4 border rounded-3 p-2">
          <div
            className="accordion accordion-flush"
            id="accordionFlushExample3"
          >
            <div className="accordion-item">
              <h2 className="accordion-header" id="flush-headingOne3">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseOne3"
                  aria-expanded="false"
                  aria-controls="flush-collapseOne3"
                >
                  Comment puis-je demander un casier judiciaire en ligne ?
                </button>
              </h2>
              <div
                id="flush-collapseOne3"
                className="accordion-collapse collapse"
                aria-labelledby="flush-headingOne3"
                data-bs-parent="#accordionFlushExample3"
              >
                <div className="accordion-body">
                  <ul>
                    <li>
                      Cette action est pour le moment indisponible sur la plateforme
                    </li>
                    
                  </ul>
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="flush-headingTwo3">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseTwo3"
                  aria-expanded="false"
                  aria-controls="flush-collapseTwo3"
                >
                  Dois-je me rendre au palais de justice pour retirer mon casier judiciaire ?
                </button>
              </h2>
              <div
                id="flush-collapseTwo3"
                className="accordion-collapse collapse"
                aria-labelledby="flush-headingTwo3"
                data-bs-parent="#accordionFlushExample3"
              >
                <div className="accordion-body">
                  <p>
                  Cette action est pour le moment indisponible sur la plateforme
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="side-4 mt-5 mb-5">
        <h5>CERTIFICAT DE RESIDENCE</h5>
        <div className="mt-4 border rounded-3 p-2">
          <div
            className="accordion accordion-flush"
            id="accordionFlushExample4"
          >
            <div className="accordion-item">
              <h2 className="accordion-header" id="flush-headingOne4">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseOne4"
                  aria-expanded="false"
                  aria-controls="flush-collapseOne4"
                >
                  Comment renouveler mon certificat de residence?
                </button>
              </h2>
              <div
                id="flush-collapseOne4"
                className="accordion-collapse collapse"
                aria-labelledby="flush-headingOne4"
                data-bs-parent="#accordionFlushExample4"
              >
                <div className="accordion-body">
                  <p>
                  Cette action est pour le moment indisponible sur la plateforme
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="side-5 mt-5 mb-5">
        <h5>CONTACT</h5>
        <div className="mt-4 border rounded-3 p-2">
          <div
            className="accordion accordion-flush"
            id="accordionFlushExample5"
          >
            <div className="accordion-item">
              <h2 className="accordion-header" id="flush-headingOne5">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseOne5"
                  aria-expanded="false"
                  aria-controls="flush-collapseOne5"
                >
                  Comment puis-je contacter le consulat en cas de question ou de
                  problème ?
                </button>
              </h2>
              <div
                id="flush-collapseOne5"
                className="accordion-collapse collapse"
                aria-labelledby="flush-headingOne5"
                data-bs-parent="#accordionFlushExample5"
              >
                <div className="accordion-body">
                  <p>
                    Vous pouvez nous contacter en utilisant les coordonnées
                    suivantes :
                  </p>
                  <div>
                    <p>Pour les questions générales au niveau du guichet d'etat civil</p>
                    <p>Adresse mail : guichet.ci@etatcivil.gouv.ci</p>
                    <p>Tél : +225 01 01 01 01 01</p>
                  </div>
                  <div>
                    <p>
                      Pour les questions liées à votre dossier via la plateforme
                    </p>
                    <p>Adresse mail : support@rdv-etatcivil.cloud</p>
                    <p>WhatsApp : +225 01 01 01 01 01</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="flush-headingTwo5">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseTwo5"
                  aria-expanded="false"
                  aria-controls="flush-collapseTwo5"
                >
                  A quelle adresse se situe les locaux des guichets d'états civil en Côte d'Ivoire ?
                </button>
              </h2>
              <div
                id="flush-collapseTwo5"
                className="accordion-collapse collapse"
                aria-labelledby="flush-headingTwo5"
                data-bs-parent="#accordionFlushExample5"
              >
                <div className="accordion-body">
                  <p>
                  En Côte d'Ivoire, les guichets d'état civil sont généralement situés dans les mairies des différentes communes. Chaque commune a son propre bureau d'état civil où l'on peut effectuer des démarches telles que les demandes d'actes de naissance, de mariage, de décès, etc.
                  </p>
                  <p>
                    Cependant il est aussi possible d'effectuer toute ces démandes sur la plateforme <a className="text-danger" onClick={() => navigate("/")}>www.rdv-Etatcivil.cloud</a>.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="side-6 mt-5 mb-5">
        <h5>PRENDRE RDV</h5>
        <div className="mt-4 border rounded-3 p-2">
          <div
            className="accordion accordion-flush"
            id="accordionFlushExample6"
          >
            <div className="accordion-item">
              <h2 className="accordion-header" id="flush-headingOne6">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseOne6"
                  aria-expanded="false"
                  aria-controls="flush-collapseOne6"
                >
                  Comment puis-je prendre rendez-vous pour mes démarches
                  d'actes d'état civil ?
                </button>
              </h2>
              <div
                id="flush-collapseOne6"
                className="accordion-collapse collapse"
                aria-labelledby="flush-headingOne6"
                data-bs-parent="#accordionFlushExample6"
              >
                <div className="accordion-body">
                  <p>
                    Après avoir fait une demande en ligne, votre dossier est
                    traité par la Mairie en charge de votre dossier.
                    Lorsque votre dossier est complet, vous recevez une lettre
                    de rendez-vous vous invitant à venir récupérer votre document ou a vous faire livrer.
                  </p>
                </div>
              </div>
            </div>
            <div className="accordion-item">
              <h2 className="accordion-header" id="flush-headingTwo6">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseTwo6"
                  aria-expanded="false"
                  aria-controls="flush-collapseTwo6"
                >
                  J'ai des problèmes pour me connecter à mon compte. Que dois-je
                  faire ?
                </button>
              </h2>
              <div
                id="flush-collapseTwo6"
                className="accordion-collapse collapse"
                aria-labelledby="flush-headingTwo6"
                data-bs-parent="#accordionFlushExample6"
              >
                <div className="accordion-body">
                  <p>
                    Si vous rencontrez des problèmes de connexion, utilisez la
                    fonction de réinitialisation du code d'accès ou contactez
                    notre support technique pour obtenir de l'aide.
                  </p>
                  <p>Adresse mail : support@rdv-etatcivil.cloud</p>
                  <p>WhatsApp : +225 01 01 01 01 01 01</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MenuFaq;
