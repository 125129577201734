import { useLocation, useParams } from "react-router-dom";
import axios from "axios";
import React, { useState, useEffect } from "react";
import { FaPhoneFlip, FaWhatsapp } from "react-icons/fa6";
import { GrMail } from "react-icons/gr";
import { myProfile } from "../Url";
import { Button } from "@mui/material";
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';


const ProfilePage = () => {
  const params = useParams();
  const location = useLocation();
  const storedToken = window.localStorage.getItem("token");

  const [showModal, setShowModal] = useState(false);

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);


  const [user, setUser] = useState({
    id: "",
    firstname: "",
    lastname: "",
    address: "",
    birth_day: "",
    birth_place: "",
    age: "",
    demandeur: "",
    proffession: "",
    phone: "",
    whatsapp: "",
    email: ""
  });

  const [password, setPassword] = useState({
    currentPassword: "",
    confirmPassword: "",
  })

  const handleChange = (e) => {
    setUser({
      ...user,
      [e.target.name]: e.target.value,
      id: Date.now(),
    });
  };

  useEffect(() => {
    axios
      .get(
        myProfile,
        {
          headers: {
            Authorization: `Bearer ${storedToken}`,
          },
        }
      )
      .then((response) => {
        setUser(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [params]);

  const handleVerificationOfData = (password) => {
    if (password.currentPassword === "" || password.confirmPassword === "") {
      alert("Veuillez remplir tous les champs");
      return;
    } else {
      axios.post(`lien de vérification`, { id: user.id, password: password })
        .then((response) => { console.log(response) })
    }
  }


  return (
    <div>

      {user.pay_status !== "1" && (
        <form class="mt-3 row g-3">
          <h1 className="text-yellow">Mon profile</h1>

          <div>
            <Button onClick={handleShow} variant="contained" className="btn-profile-update">Modifier les informations</Button>
          </div>

          <div class="col-lg-6">
            <label for="nom" class="form-label text-green">
              Mon nom
            </label>
            <input
              type="text"
              class="form-control"
              id="name"
              name="firstname"
              value={user.firstname}
              onChange={handleChange}
              required
            />
          </div>
          <div class="col-lg-6">
            <label for="prenom" class="form-label text-green">
              Mon prénom
            </label>
            <input
              type="text"
              class="form-control"
              id="prenom"
              name="lastname"
              onChange={handleChange}
              value={user.lastname}
              required
            />
          </div>

          <div class="col-lg-6">
            <label for="mobile" class="form-label text-green">
              Telephone mobile
            </label>
            <div className="d-flex py-1 px-2  justify-content-center bg-white align-items-center border rounded">
              <FaPhoneFlip className="" />
              <input
                type="text"
                class="form-control border-0"
                id="mobile"
                name="phone"
                value={user.phone}
                onChange={handleChange}
                required
              />
            </div>
          </div>
          <div class="col-lg-6">
            <label for="whatsapp" class="form-label text-green">
              Whatsapp
            </label>
            <div className="d-flex py-1 px-2  justify-content-center bg-white align-items-center border rounded">
              <FaWhatsapp />
              <input
                type="text"
                class=" border-0 form-control"
                id="whatsapp"
                name="whatsapp"
                onChange={handleChange}
                value={user.whatsapp}
                required
              />
            </div>
          </div>
          <div class="col-lg-6">
            <label for="email" class="form-label text-green">
              Adresse email
            </label>
            <div className="d-flex py-1 px-2 justify-content-center bg-white align-items-center border rounded">
              <GrMail />
              <input
                type="email"
                class="border-0 form-control"
                id="email"
                name="email"
                onChange={handleChange}
                value={user.email}
                required
              />
            </div>
          </div>

          <div class="col-lg-6">
            <label for="birth_day" class="form-label text-green">
              Date de Naissance
            </label>
            <div className="d-flex py-1 px-2 justify-content-center bg-white align-items-center border rounded">
              <input
                type="date"
                class="border-0 form-control"
                id="birth_day"
                name="birth_day"
                onChange={handleChange}
                value={user.birth_day}
                required
              />
            </div>
          </div>

          <div class="col-lg-6">
            <label for="birth_place" class="form-label text-green">
              Lieu de naissance
            </label>
            <div className="d-flex py-1 px-2 justify-content-center bg-white align-items-center border rounded">
              <input
                type="birth_place"
                class="border-0 form-control"
                id="birth_place"
                name="birth_place"
                onChange={handleChange}
                value={user.birth_place}
                required
              />
            </div>
          </div>

          <div class="col-lg-6">
            <label for="address" class="form-label text-green">
              Adresse
            </label>
            <div className="p-1 bg-white border rounded">
              <input
                type="text"
                class=" border-0 form-control"
                id="address"
                name="address"
                onChange={handleChange}
                value={user.address}
                required
              />
            </div>
          </div>
        </form>
      )}

      {/*disable input if user has already pay his fees*/}
      {user.pay_status === "1" && (
        <form class="mt-3 row g-3">
          <h1 className="text-yellow">Mon profile</h1>

          <div>
            <Button variant="contained" className="btn-profile-update">Modifier les informations</Button>
          </div>

          <div class="col-lg-6">
            <label for="nom" class="form-label text-green">
              Mon nom
            </label>
            <input
              type="text"
              class="form-control"
              id="name"
              name="firstname"
              value={user.firstname}
              onChange={handleChange}
              required
              disabled
            />
          </div>
          <div class="col-lg-6">
            <label for="prenom" class="form-label text-green">
              Mon prénom
            </label>
            <input
              type="text"
              class="form-control"
              id="prenom"
              name="lastname"
              onChange={handleChange}
              value={user.lastname}
              required
              disabled
            />
          </div>

          <div class="col-lg-6">
            <label for="mobile" class="form-label text-green">
              Telephone mobile
            </label>
            <div className="d-flex py-1 px-2  justify-content-center bg-white align-items-center border rounded">
              <FaPhoneFlip className="" />
              <input
                type="text"
                class="form-control border-0"
                id="mobile"
                name="phone"
                value={user.phone}
                onChange={handleChange}
                required
                disabled
              />
            </div>
          </div>
          <div class="col-lg-6">
            <label for="whatsapp" class="form-label text-green">
              Whatsapp
            </label>
            <div className="d-flex py-1 px-2  justify-content-center bg-white align-items-center border rounded">
              <FaWhatsapp />
              <input
                type="text"
                class=" border-0 form-control"
                id="whatsapp"
                name="whatsapp"
                onChange={handleChange}
                value={user.whatsapp}
                required
                disabled
              />
            </div>
          </div>
          <div class="col-lg-6">
            <label for="email" class="form-label text-green">
              Adresse email
            </label>
            <div className="d-flex py-1 px-2 justify-content-center bg-white align-items-center border rounded">
              <GrMail />
              <input
                type="email"
                class="border-0 form-control"
                id="email"
                name="email"
                onChange={handleChange}
                value={user.email}
                required
                disabled
              />
            </div>
          </div>

          <div class="col-lg-6">
            <label for="birth_day" class="form-label text-green">
              Date de Naissance
            </label>
            <div className="d-flex py-1 px-2 justify-content-center bg-white align-items-center border rounded">
              <input
                type="date"
                class="border-0 form-control"
                id="birth_day"
                name="birth_day"
                onChange={handleChange}
                value={user.birth_day}
                required
                disabled
              />
            </div>
          </div>

          <div class="col-lg-6">
            <label for="birth_place" class="form-label text-green">
              Lieu de naissance
            </label>
            <div className="d-flex py-1 px-2 justify-content-center bg-white align-items-center border rounded">
              <input
                type="birth_place"
                class="border-0 form-control"
                id="birth_place"
                name="birth_place"
                onChange={handleChange}
                value={user.birth_place}
                required
                disabled
              />
            </div>
          </div>

          <div class="col-lg-6">
            <label for="address" class="form-label text-green">
              Adresse
            </label>
            <div className="p-1 bg-white border rounded">
              <input
                type="text"
                class=" border-0 form-control"
                id="address"
                name="address"
                onChange={handleChange}
                value={user.address}
                required
                disabled
              />
            </div>
          </div>

          <div class="col-lg-6">
            <label for="proffession" class="form-label text-green">
              Proffession
            </label>
            <div className="p-1 bg-white border rounded">
              <input
                type="text"
                class=" border-0 form-control"
                id="proffession"
                name="proffession"
                onChange={handleChange}
                value={user.proffession}
                required
                disabled
              />
            </div>
          </div>

        </form>
      )}

      {/*Modal if user want to operate modification*/}
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Modal heading</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Entrez le mot de passe de l'actuel utilisateur</Form.Label>
              <Form.Control
                type="password"
                value={password.currentPassword}
                onChange={(e) => (setPassword({ ...password, currentPassword: e.target.value }))}
                placeholder=""
                autoFocus
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Confirmer le mot de passe en le retapant</Form.Label>
              <Form.Control
                type="password"
                value={password.confirmPassword}
                onChange={(e) => (setPassword({ ...password, confirmPassword: e.target.value }))}
                placeholder=""
                autoFocus
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer className="gap-3">
          <Button variant="contained" color="error" onClick={handleClose}>
            Fermer
          </Button>
          <Button className="btn-profile-update" variant="contained" onClick={handleClose}>
            Valider
          </Button>
        </Modal.Footer>
      </Modal>



    </div>

  );
};

export default ProfilePage;
