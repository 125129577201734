import { useEffect } from "react"
import { useAccordionButton } from "react-bootstrap"

const Step7 = ({tarification, infoForPayment, canal, step7Data}) => {

    useEffect(() => {
        step7Data(infoForPayment)
    }, [])

    console.log("infoForPayment", infoForPayment)
    console.log("step7Data", step7Data)
    return (
        <div className="">
            <h1 className="text-center">Vérifier les informations entrées</h1>

            <div>
                <form>
                    <div class="row">
                        <div class="col">
                            <label for="formGroupExampleInput">Nom</label>
                            <input type="text" disabled class="form-control" placeholder="" value={infoForPayment.firstname} />
                        </div>
                        <div class="col">
                            <label for="formGroupExampleInput">Prénom</label>
                            <input type="text" disabled class="form-control" placeholder="" value={infoForPayment.lastname} />
                        </div>
                    </div>

                    <div class="row">
                        <div class="col">
                            <label for="formGroupExampleInput">Téléphone</label>
                            <input type="text" disabled class="form-control" placeholder="" value={infoForPayment.phone} />
                        </div>
                        <div class="col">
                            <label for="formGroupExampleInput">Bénéficiaire de l'acte civil</label>
                            <input type="text" disabled class="form-control" placeholder="" value={infoForPayment.who_request} />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <label for="formGroupExampleInput">Type d'acte civil démandé</label>
                            <input type="text" disabled class="form-control" placeholder="" value={infoForPayment.type} />
                        </div>
                        <div class="col">
                            <label for="formGroupExampleInput">Commune / Sous préfecture</label>
                            <input type="text" disabled class="form-control" placeholder="" value={infoForPayment.commune} />
                        </div>
                    </div>

                    <div class="row">
                        <div class="col">
                            <label for="formGroupExampleInput">Je suis enrégistré dans ...</label>
                            <input type="text" disabled class="form-control" placeholder="" value={infoForPayment.type_commune_or_sousPrefecture} />
                        </div>
                        <div class="col">
                            <label for="formGroupExampleInput">Canal de paiement choisi</label>
                            <input type="text" disabled class="form-control" placeholder="" value={canal} />
                        </div>
                    </div>
                    <div className="row">
                    <div class="col-6">
                            <label for="formGroupExampleInput">Type de tarification choisi</label>
                            <input type="text" disabled class="form-control" placeholder="" value={tarification} />
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default Step7;