import React, { useEffect, useState } from 'react';
import "./dashboard.css"
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { NavLink } from 'react-router-dom';
import DatatableHistory from '../Components/Table/DataTableHistory';
import ProfilePage from '../Pages/ProfilePage';
import DetailPage from '../Pages/DetailPage';
import LoginPage from '../Pages/LoginPage';
import EnvoiDocumentPage from '../Pages/EnvoiDocumentPage';
import DatatableDeclarations from '../Components/Table/DataTableDeclarations';
import DatatableDemandes from '../Components/Table/DataTableDemandes';
import DetailDeclarationPage from '../Pages/DetailDeclarationPage';
import DatatableAgents from '../Components/Table/DataTableAgents';
import AddAgent from '../Components/Agents/AddAgent';
import UpdateAgentTable from '../Components/Agents/UpdateAgentTable';
import UpdateAgentForm from '../Components/Agents/UpdateAgentForm';


const SideNav = () => {
  const params = useLocation();
  const user = JSON.parse(window?.localStorage.getItem("userData") ?? "{}");
  console.log(user?.roles, "roles");
  const navigate = useNavigate();
  const profile = "dashboard/mon-profile/" + user?.email;
  useEffect(() => {
    if (!user && params.pathname.includes("dashboard")) {
      navigate("/login");
    }
  }, []);

  const [showAccordion, setShowAccordion] = useState(false);

  const handleShowAccordion = (state) => {
    if (state === "show") {
      setShowAccordion(true);
    } else {
      setShowAccordion(false);
    }
  }


  const [isActive, setIsActive] = useState(false);

  const toggleClass = () => {
    setIsActive(!isActive);
  }

  return (
    <>
      <div class={isActive ? 'open sidebar' : 'sidebar'}>
        <div class="logo-details position-sticky">
          {/* <i class='bx bxl-c-plus-plus icon'></i> */}
          <div class="logo_name">RDV-ETAT-CIVIL</div>
          <i class='bx bx-menu' id="btn" onClick={toggleClass} ></i>
        </div>

        <ul class="nav-list">
          {/* ACCUEIL */}
          {user && (user.roles === "CLIENT" || user.roles === "ADMIN" || user.roles === "ROOT") && (
            <li>
              <div className={isActive ? "d-none" : "title_nav-list mt-4"}>ACCUEIL</div>
              <NavLink onClick={() => window.localStorage.clear()} to={"/"} >
                <i class='bx bx-home' ></i>
                <span class="links_name">Accueil</span>
              </NavLink >
              <span class="tooltip">Accueil</span>
            </li>
          )}
          {/* <li>
            <i class='bx bx-search' onClick={toggleClass}></i>
            <input type="text" placeholder="Recherche..." />
            <span class="tooltip">Recherche</span>
          </li> */}

          {/* MON COMPTE */}
          {user && (user.roles === "CLIENT" || user.roles === "ADMIN" || user.roles === "ROOT") && (
            <li>
              <div className={isActive ? "d-none" : "title_nav-list mt-4"}>MON COMPTE</div>
              <NavLink to={profile} >
                <i class='bx bx-user' ></i>
                <span class="links_name">Mon compte</span>
              </NavLink >
              <span class="tooltip">Mon compte</span>
            </li>
          )}

          {/* HISTORIQUES */}
          {user && (user.roles === "ADMIN" || user.roles === "ROOT") && (
            <li>
              <div className={isActive ? "d-none" : "title_nav-list mt-4"}>HISTORIQUE DES DEMANDES</div>
              <NavLink to={"dashboard/historique-demandes"}>
                <i class='bi bi-clock-history' ></i>
                <span class="links_name">Historique des demandes</span>
              </NavLink>
            </li>
          )}

          {/* DEMANDES */}
          {user && (user.roles === "ADMIN" || user.roles === "ROOT") && (
            <>
              <li>
                <div className={isActive ? "d-none" : "title_nav-list mt-4"}>DEMANDES</div>
                <li>
                  <NavLink to={"dashboard/list-des-nouvelles-demandes"}>
                    <i class='bx bx-folder' ></i>
                    <span class="links_name">Listes des nouvelles demandes</span>
                  </NavLink>
                  <span class="tooltip">Listes des nouvelles demandes</span>
                </li>

                <li>
                  <NavLink to={"dashboard/demandes-valides"}>
                    <i class='bx bx-folder' ></i>
                    <span class="links_name">Liste des dossiers validés <br /> et en attente de paiement</span>
                  </NavLink>
                  <span class="tooltip">Liste ds dossiers validés</span>
                </li>

                <li>
                  <NavLink to={"dashboard/demandes-info-attente"}>
                    <i class='bx bx-folder' ></i>
                    <span class="links_name">Liste des dossiers <br /> en attentes d'informations <br /> supplémentaire</span>
                  </NavLink>
                  <span class="tooltip">Liste des dossiers <br /> en attentes d'informations supplémentaire</span>
                </li>

                <li>
                  <NavLink to={"dashboard/demandes-refus"}>
                    <i class='bx bx-folder' ></i>
                    <span class="links_name">Liste des dossiers réfusés</span>
                  </NavLink>
                  <span class="tooltip">Liste ds dossiers réfusés</span>
                </li>

                <li>
                  <NavLink to={"dashboard/demandes-final"}>
                    <i class='bx bx-folder' ></i>
                    <span class="links_name">Liste des dossiers finalisés</span>
                  </NavLink>
                  <span class="tooltip">Liste des dossiers finalisés</span>
                </li>
              </li>
            </>
          )}

          {/* DECLARATIONS */}
          {user && (user.roles === "ADMIN" || user.roles === "ROOT") && (
            <>
              <li>
                <div className={isActive ? "d-none" : "title_nav-list mt-4"}>DECLARATIONS</div>
                <NavLink to={"dashboard/list-des-nouvelles-declarations"}>
                  <i class='bx bx-folder' ></i>
                  <span class="links_name">Liste des nouvelles declarations</span>
                </NavLink>
                <span class="tooltip">Liste des nouvelles declarations</span>
              </li>
              <li>
                <NavLink to={"dashboard/declarations-finals"}>
                  <i class='bx bx-folder' ></i>
                  <span class="links_name">Liste des declarations finalisées</span>
                </NavLink>
                <span class="tooltip">Liste des declarations finalisées</span>
              </li>
            </>
          )}

          {/* MANAGER */}
          {/* {user && (user.roles === "ADMIN" || user.roles === "ROOT") && (
            <>
              <li>
                <div className={isActive ? "d-none" : "title_nav-list mt-4"}>GESTION DES AGENTS</div>
                <NavLink to={`dashboard/${user?.commune}/agents`}>
                  <i class='bx bx-folder' ></i>
                  <span class="links_name">Gérer les agents</span>
                </NavLink>
                <span class="tooltip">Gérer les agents</span>
              </li>
            </>
          )} */}


          {/* PROCÉDER À UNE NOUVELLE DEMANDE */}
          <li>
            <div className={isActive ? "d-none" : "title_nav-list mt-4"}>PROCÉDER À UNE NOUVELLE DEMANDE</div>
            <NavLink to={"/rdv"}>
              <i class='bi bi-folder-plus' ></i>
              <span class="links_name">Nouvelle demande</span>
            </NavLink>
          </li>

          <li class="profile">
            <NavLink
              onClick={() => {
                window.localStorage.clear();
                navigate("/login");
              }}
              to={"/login"}
            >
              <i class='bx bx-log-out' id="log_out" ></i>
            </NavLink>


          </li>
        </ul>
      </div>


      <section class="home-section">
        <div id="header" class={isActive ? 'open' : ''}>
          <div class="header uboxed">
            <ul class="logo">
              <li>
                <img className='w-100' src="/images/rci.svg" alt="RDV-Etat-Civil" />
              </li>
            </ul>
          </div>
        </div>
        {/* <div class="header-space"></div>
        <div class="text">TABLEAU DE BORD
        </div> */}

        <div class="main last">
          <Routes>
            <Route
              path="/dashboard/mon-profile/:email"
              element={<ProfilePage />}
            />
            <Route
              path="/dashboard/details-demande/:request_invoice"
              element={<DetailPage />}
            />

            <Route path="/dashboard/historique-demandes" element={<DatatableDemandes />} />

            <Route
              path="/dashboard/list-des-nouvelles-demandes"
              element={<DatatableDemandes />} />

            <Route
              path="/dashboard/demandes-info-attente"
              element={<DatatableDemandes />} />

            <Route
              path="/dashboard/demandes-refus"
              element={<DatatableDemandes />} />

            <Route
              path="/dashboard/demandes-valides"
              element={<DatatableDemandes />} />

            <Route
              path="/dashboard/demandes-final"
              element={<DatatableDemandes />} />

            <Route
              path="/dashboard/list-des-nouvelles-declarations"
              element={<DatatableDeclarations />} />

            <Route
              path="/dashboard/details-declaration/:request_invoice"
              element={<DetailDeclarationPage />}
            />
            <Route
              path="/dashboard/declarations-finals"
              element={<DatatableDeclarations />} />

            <Route
              path="/dashboard/:commune/agents"
              element={<DatatableAgents />} />

            <Route
              path="/dashboard/:commune/agents/add_agent"
              element={<AddAgent />} />

            <Route
              path="/dashboard/:commune/agents/update_agent"
              element={<UpdateAgentTable />} />

            <Route
              path="/dashboard/:commune/agents/update_agent/:mat"
              element={<UpdateAgentForm />} />


            <Route path="/login" element={<LoginPage />} />

            {user && user.roles === "CLIENT" && (
              <Route
                path="completer-une-demande"
                element={<EnvoiDocumentPage />}
              ></Route>
            )}

            {user && user.roles != "CLIENT" && (
              <Route
                path="completer-une-demande"
                element={<EnvoiDocumentPage />}
              ></Route>
            )}
          </Routes>
        </div>
      </section>
    </>
  );
};

export default SideNav;