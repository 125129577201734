import React, { useState, useEffect } from "react";
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import TextField from '@mui/material/TextField';
const Step3 = ({ step3Fom, step3Data, step33Fom, step333Fom, step333Data, step33Data }) => {
    const [formInfoPerso, setFormInfoPerso] = useState({
        firstname: "",
        lastname: "",
        address: "",
        birth_day: "",
        birth_place: "",
        phone: "",

        email: "",

        pieceDemande: "",
        numeroActe: "",
        father_name: "",
        mother_name: "",
        date_naissance_father: "",
        date_naissance_mother: "",

        who_request: "",
        send_email: false,
        isValid: true,
        ...step3Fom,
    });

    console.log("step333Fom",step333Fom);

    const [formAutreBenef, setFormAutreBenef] = useState({
        firstname: "",
        lastname: "",
        address: "",
        birth_day: "",
        birth_place: "",
        phone: "",

        pieceDemande: "",
        numeroActe: "",
        father_name: "",
        mother_name: "",
        date_naissance_father: "",
        date_naissance_mother: "",


        email: "",
        isValid: true,
        ...step33Fom,
    });

    const [formDemandeur, setFormDemandeur] = useState({
        firstname_demandeur: "",
        lastname_demandeur: "",
        address_demandeur: "",
        birth_day_demandeur: "",
        birth_place_demandeur: "",
        phone_demandeur: "",

        send_email_demandeur: false,
        email_demandeur: "",
        isValid: true,
        ...step333Fom,
    });

    function handleChangeDemandeurPhone() {
        setFormInfoPerso({ ...formInfoPerso, phone: formInfoPerso.phone })
    }

    const [phone1, setPhone1] = useState();
    const [phone2, setPhone2] = useState();
    const [phone3, setPhone3] = useState();

    const [phone11, setPhone11] = useState();
    const [phone22, setPhone22] = useState();
    const [phone33, setPhone33] = useState();

    const [whatsapp1, setWhatsapp1] = useState();
    const [whatsapp2, setWhatsapp2] = useState();
    const [whatsapp3, setWhatsapp3] = useState();

    const [whatsapp11, setWhatsapp11] = useState();
    const [whatsapp22, setWhatsapp22] = useState();
    const [whatsapp33, setWhatsapp33] = useState();

    useEffect(() => {

        if (phone1) {
            if (isValidPhoneNumber(phone1)) {
                setPhone11(phone1)
                setFormInfoPerso({ ...formInfoPerso, phone: phone1 })
            } else {
                setPhone11('')
                setFormInfoPerso({ ...formInfoPerso, phone: "" })
                console.log(formInfoPerso, "phone1")
            }

        }

        // if (whatsapp1) {
        //     if (isValidPhoneNumber(whatsapp1)) {
        //         setWhatsapp11(whatsapp1)
        //         setFormInfoPerso({ ...formInfoPerso, whatsapp: whatsapp1 })
        //     } else {
        //         setWhatsapp11('')
        //         setFormInfoPerso({ ...formInfoPerso, whatsapp: "" })
        //         console.log(formInfoPerso, "whatsapp1")
        //     }
        // }

        if (phone2) {
            if (isValidPhoneNumber(phone2)) {
                setPhone22(phone2)
                setFormDemandeur({ ...formDemandeur, phone_demandeur: phone2 })
            } else {
                setPhone22('')
                setFormDemandeur({ ...formDemandeur, phone_demandeur: "" })
            }
        }

        // if (whatsapp2) {
        //     if (isValidPhoneNumber(whatsapp2)) {
        //         setWhatsapp22(whatsapp2)
        //         setFormInfoPerso({ ...formInfoPerso, whatsapp: whatsapp2 })
        //     } else {
        //         setWhatsapp22('')
        //         setFormInfoPerso({ ...formInfoPerso, whatsapp: "" })
        //     }
        // }

        if (phone3) {
            if (isValidPhoneNumber(phone3)) {
                setFormAutreBenef({ ...formAutreBenef, phone: phone3 })
                setPhone33(phone3)
            } else {
                setPhone33('')
                setFormAutreBenef({ ...formAutreBenef, phone: "" })
            }
        }

        // if (whatsapp3) {
        //     if (isValidPhoneNumber(whatsapp3)) {
        //         setFormAutreBenef({ ...formAutreBenef, whatsapp: whatsapp3 })
        //         setWhatsapp33(whatsapp3)
        //     } else {
        //         setFormAutreBenef({ ...formAutreBenef, whatsapp: "" })
        //         setWhatsapp33('')
        //     }
        // }

    },
        [phone1, phone2, phone3])

    const handleChange = (e) => {
        setFormInfoPerso({ ...formInfoPerso, [e.target.name]: e.target.value });
    };
    const handleChangeBenef = (e) => {
        setFormAutreBenef({ ...formAutreBenef, [e.target.name]: e.target.value });
    };
    const handleChangeDemandeur = (e) => {
        setFormDemandeur({ ...formDemandeur, [e.target.name]: e.target.value });
    };


    useEffect(() => {
        if (formAutreBenef.firstname && formAutreBenef.lastname && formAutreBenef.address
            && formAutreBenef.birth_day && formAutreBenef.birth_place
            && formAutreBenef.phone && formAutreBenef.email) {
            setFormAutreBenef({ ...formAutreBenef, isValid: true })
        }
        else {
            setFormAutreBenef({ ...formAutreBenef, isValid: false })
        }
        step33Data(formAutreBenef);
    }, [formAutreBenef]);

    useEffect(() => {
        if (formDemandeur.firstname_demandeur && formDemandeur.lastname_demandeur && formDemandeur.address_demandeur
            && formDemandeur.birth_day_demandeur && formDemandeur.birth_place_demandeur
            && formDemandeur.phone_demandeur && formDemandeur.email_demandeur) {
            setFormDemandeur({ ...formDemandeur, isValid: true })
        }
        else {
            setFormDemandeur({ ...formDemandeur, isValid: false })
        }
        step333Data(formDemandeur);
    }, [formDemandeur]);

    useEffect(() => {
        if (formInfoPerso.send_email && formInfoPerso.who_request == "moiMeme") {
            step33Data(formInfoPerso);
            setFormAutreBenef({ ...formInfoPerso, isValid: true })
        }
        if (formInfoPerso.send_email && formInfoPerso.firstname && formInfoPerso.lastname && formInfoPerso.address
            && formInfoPerso.birth_day && formInfoPerso.birth_place
            && formInfoPerso.phone && formInfoPerso.email) {
            setFormInfoPerso({ ...formInfoPerso, isValid: true })
        }
        else {
            setFormInfoPerso({ ...formInfoPerso, isValid: false })
        }
        step3Data(formInfoPerso);
    }, [formInfoPerso]);

    return (
        <div>
            <form>
                <div className="text-center fs-3">
                    Voulez-vous faire votre demande pour ?
                </div>
                <div className="d-flex gap-3 radio-choix-demandeur justify-content-center flex-wrap pb-3">
                    <div class="form-check">

                        <input
                            autoComplete="off"
                            class="form-check-input fs-5"
                            type="radio"
                            name="who_request"
                            id="moiMeme"
                            value="moiMeme"
                            checked={formInfoPerso.who_request == "moiMeme" ? true : false}
                            onChange={handleChange}
                        />
                        <label class="form-check-label fs-5" for="moiMeme">
                            Moi-même
                        </label>
                    </div>
                    <div class="form-check fs-5">
                        <input
                            autoComplete="off"
                            class="form-check-input"
                            type="radio"
                            name="who_request"
                            id="beneficiaireMajeur"
                            value="beneficiaireMajeur"
                            checked={formInfoPerso.who_request == "beneficiaireMajeur" ? true : false}
                            onChange={handleChange}
                        />
                        <label class="form-check-label fs-5" for="beneficiaireMajeur">
                            Bénéficiaire Majeur
                        </label>
                    </div>
                    <div class="form-check fs-5">
                        <input
                            autoComplete="off"
                            class="form-check-input"
                            type="radio"
                            name="who_request"
                            id="beneficiaireMineur"
                            value="beneficiaireMineur"
                            checked={formInfoPerso.who_request == "beneficiaireMineur" ? true : false}
                            onChange={handleChange}
                        />
                        <label class="form-check-label fs-5" for="beneficiaireMineur">
                            Bénéficiaire Mineur
                        </label>
                    </div>
                    <div class="form-check fs-5">
                        <input
                            autoComplete="off"
                            class="form-check-input"
                            type="radio"
                            name="who_request"
                            id="beneficiaireMineurAccompagne"
                            value="beneficiaireMineurAccompagne"
                            checked={formInfoPerso.who_request == "beneficiaireMineurAccompagne" ? true : false}
                            onChange={handleChange}
                        />
                        <label class="form-check-label fs-5" for="beneficiaireMineurAccompagne">
                            Bénéficiaire Mineur Accompagné
                        </label>
                    </div>
                </div>

                {
                    formInfoPerso.who_request == "moiMeme" && (
                        <>
                            <div className="text-center fs-3">
                                Avez vous l'une des pièce demandée ?
                            </div>
                            <div className="d-flex gap-3 radio-choix-demandeur justify-content-center flex-wrap pb-3">
                                <div class="form-check">

                                    <input
                                        autoComplete="off"
                                        class="form-check-input fs-5"
                                        type="radio"
                                        name="pieceDemande"
                                        id="numeroActe"
                                        value="numeroActe"
                                        checked={formInfoPerso.pieceDemande == "numeroActe" ? true : false}
                                        onChange={handleChange}
                                    />
                                    <label class="form-check-label fs-5" for="numeroActe">
                                        Numero de l'acte
                                    </label>
                                </div>
                                <div class="form-check fs-5">
                                    <input
                                        autoComplete="off"
                                        class="form-check-input"
                                        type="radio"
                                        name="pieceDemande"
                                        id="InfoParents"
                                        value="InfoParents"
                                        checked={formInfoPerso.pieceDemande == "InfoParents" ? true : false}
                                        onChange={handleChange}
                                    />
                                    <label class="form-check-label fs-5" for="InfoParents">
                                        Les informations sur les parents
                                    </label>
                                </div>
                            </div>
                        </>
                    )
                }

                {
                    (formInfoPerso.who_request == "beneficiaireMajeur" ||
                    formInfoPerso.who_request == "beneficiaireMineur" ||
                    formInfoPerso.who_request == "beneficiaireMineurAccompagne") && (
                        <>
                            <div className="text-center fs-3">
                                Le bénéficiaire a-t-il l'une des pièces demandées ?
                            </div>
                            <div className="d-flex gap-3 radio-choix-demandeur justify-content-center flex-wrap pb-3">
                                <div class="form-check">

                                    <input
                                        autoComplete="off"
                                        class="form-check-input fs-5"
                                        type="radio"
                                        name="pieceDemande"
                                        id="numeroActe"
                                        value="numeroActe"
                                        checked={formAutreBenef.pieceDemande == "numeroActe" ? true : false}
                                        onChange={handleChangeBenef}
                                    />
                                    <label class="form-check-label fs-5" for="numeroActe">
                                        Numero de l'acte
                                    </label>
                                </div>
                                <div class="form-check fs-5">
                                    <input
                                        autoComplete="off"
                                        class="form-check-input"
                                        type="radio"
                                        name="pieceDemande"
                                        id="InfoParents"
                                        value="InfoParents"
                                        checked={formAutreBenef.pieceDemande == "InfoParents" ? true : false}
                                        onChange={handleChangeBenef}
                                    />
                                    <label class="form-check-label fs-5" for="InfoParents">
                                        Les informations sur les parents
                                    </label>
                                </div>
                            </div>
                        </>
                    )
                }

                {formInfoPerso.who_request == "moiMeme" ? (
                    <div className="d-flex flex-wrap form-style">
                        <div class="mb-3 col-12 col-md-6 px-3">
                            <label for="firstname" class="form-label fs-smaller">
                                *Nom
                            </label>
                            <input
                                autoComplete="off"
                                type="text"
                                class="form-control form-input-droit"
                                id="firstname"
                                name="firstname"
                                value={formInfoPerso.firstname}
                                required
                                onChange={handleChange}
                            />
                        </div>
                        <div class="mb-3 col-12 col-md-6 px-3">
                            <label for="lastname" class="form-label fs-smaller">
                                *Prénom (s)
                            </label>
                            <input
                                autoComplete="off"
                                type="text"
                                class="form-control form-input-droit"
                                id="lastname"
                                name="lastname"
                                value={formInfoPerso.lastname}
                                required
                                onChange={handleChange}
                            />
                        </div>

                        <div class="mb-3 col-12 col-md-6 px-3">
                            <label for="birth_day" class="form-label fs-smaller">
                                *Date de naissance
                            </label>
                            <input
                                autoComplete="off"
                                type="date"
                                class="form-control form-input-droit"
                                id="birth_day"
                                name="birth_day"
                                value={formInfoPerso.birth_day}
                                required
                                onChange={handleChange}
                            />
                        </div>

                        <div class="mb-3 col-12 col-md-6 px-3">
                            <label for="birth_place" class="form-label fs-smaller">
                                *Lieu de naissance
                            </label>
                            <input
                                autoComplete="off"
                                type="text"
                                class="form-control form-input-droit"
                                id="birth_place"
                                name="birth_place"
                                value={formInfoPerso.birth_place}
                                required
                                onChange={handleChange}
                            />
                        </div>

                        <div class="mb-3 col-12 col-md-6 px-3">
                            <label for="address" class="form-label fs-smaller">
                                *Votre adresse géographique (Abidjan, Cocody, II Plateaux par exemple)
                            </label>
                            <input
                                autoComplete="off"
                                type="text"
                                class="form-control form-input-droit"
                                id="address"
                                name="address"
                                value={formInfoPerso.address}
                                required
                                onChange={handleChange}
                            />
                        </div>
                        <div class="mb-3 col-12 col-md-6 px-3">
                            <label for="email" class="form-label fs-smaller">
                                *Email
                            </label>
                            <input
                                autoComplete="off"
                                type="email"
                                class="form-control form-input-droit"
                                id="email"
                                name="email"
                                value={formInfoPerso.email}
                                required
                                onChange={handleChange}
                            />
                        </div>
                        <div class="mb-3 col-12 col-md-6 px-3">
                            <label for="phone" class="form-label fs-smaller">
                                *Téléphone(de préférence Whatsapp)
                            </label>
                            <PhoneInput
                                style={{ backgroundColor: 'white' }}
                                defaultCountry="CI"
                                type="tel"
                                class="form-control form-input-droit"
                                id="phone"
                                name="phone"
                                value={phone1}
                                rules={{ required: true }}
                                placeholder="+xxxxxxxx"
                                onChange={setPhone1}
                            />
                        </div>

                        {formInfoPerso.pieceDemande === "numeroActe" && (
                            <div class="mb-3 col-12 col-md-6 px-3">
                                <label for="birth_place" class="form-label fs-smaller">
                                    *Numéro de l'acte
                                </label>
                                <input
                                    autoComplete="off"
                                    type="text"
                                    class="form-control form-input-droit"
                                    id="numeroActe"
                                    name="numeroActe"
                                    value={formInfoPerso.numeroActe}
                                    required
                                    onChange={handleChange}
                                />
                            </div>
                        )}

                        {formInfoPerso.pieceDemande === "InfoParents" && (
                            <>
                                <div class="mb-3 col-12 col-md-6 px-3">
                                    <label for="father_name" class="form-label fs-smaller">
                                        *Nom & Prenoms du père
                                    </label>
                                    <input
                                        autoComplete="off"
                                        type="text"
                                        class="form-control form-input-droit"
                                        id="father_name"
                                        name="father_name"
                                        value={formInfoPerso.father_name}
                                        required
                                        onChange={handleChange}
                                    />
                                </div>

                                <div class="mb-3 col-12 col-md-6 px-3">
                                    <label for="date_naissance_father" class="form-label fs-smaller">
                                        *Date de naissance du père
                                    </label>
                                    <input
                                        autoComplete="off"
                                        type="date"
                                        class="form-control form-input-droit"
                                        id="date_naissance_father"
                                        name="date_naissance_father"
                                        value={formInfoPerso.date_naissance_father}
                                        required
                                        onChange={handleChange}
                                    />
                                </div>

                                <div class="mb-3 col-12 col-md-6 px-3">
                                    <label for="mother_name" class="form-label fs-smaller">
                                        *Nom & Prenoms de la mère
                                    </label>
                                    <input
                                        autoComplete="off"
                                        type="text"
                                        class="form-control form-input-droit"
                                        id="mother_name"
                                        name="mother_name"
                                        value={formInfoPerso.mother_name}
                                        required
                                        onChange={handleChange}
                                    />
                                </div>

                                <div class="mb-3 col-12 col-md-6 px-3">
                                    <label for="date_naissance_mother" class="form-label fs-smaller">
                                        *Date de naissance de la mère
                                    </label>
                                    <input
                                        autoComplete="off"
                                        type="date"
                                        class="form-control form-input-droit"
                                        id="date_naissance_mother"
                                        name="date_naissance_mother"
                                        value={formInfoPerso.date_naissance_mother}
                                        required
                                        onChange={handleChange}
                                    />
                                </div>
                            </>
                        )}


                        <div class="form-check fs-5">
                            <input
                                autoComplete="off"
                                class="form-check-input"
                                type="radio"
                                name="send_email"
                                id="send_email"
                                value="send_email"
                                checked={formInfoPerso.send_email == "send_email" ? true : false}
                                onChange={handleChange}
                            />
                            <label class="form-check-label text-danger fs-6" for="send_email">
                                En cochant cette case, vous consentez à recevoir des emails de la part de RDV-EtatCivil et ses partenaires techniques concernant cette présente demande. Vous pouvez vous désabonner à tout moment.
                            </label>
                        </div>

                    </div>
                ) : formInfoPerso.who_request == "beneficiaireMajeur" ||
                    formInfoPerso.who_request == "beneficiaireMineur" ||
                    formInfoPerso.who_request == "beneficiaireMineurAccompagne" ? (
                    <div className="d-flex-wrap form-style">
                        <div className="col-12">
                            <div className="d-flex justify-content-center align-items-center px-3">
                                <div className="col bande"></div>
                                <div className="col bande-title text-center pb-2 fs-3">
                                    Informations du demandeur
                                </div>
                                <div className="col bande"></div>
                            </div>
                            <div className="d-flex flex-wrap ">
                                <div class="mb-3 col-12 col-md-6 px-3">
                                    <label for="firstname_demandeur" class="form-label fs-smaller">
                                        *Nom
                                    </label>
                                    <input
                                        autoComplete="off"
                                        type="text"
                                        class="form-control form-input-droit"
                                        id="firstname_demandeur"
                                        name="firstname_demandeur"
                                        value={formDemandeur.firstname_demandeur}
                                        required
                                        onChange={handleChangeDemandeur}
                                    />
                                </div>
                                <div class="mb-3 col-12 col-md-6 px-3">
                                    <label for="lastname_demandeur" class="form-label fs-smaller">
                                        *Prénom (s)
                                    </label>
                                    <input
                                        autoComplete="off"
                                        type="text"
                                        class="form-control form-input-droit"
                                        id="lastname_demandeur"
                                        name="lastname_demandeur"
                                        value={formDemandeur.lastname_demandeur}
                                        required
                                        onChange={handleChangeDemandeur}
                                    />
                                </div>
                                <div class="mb-3 col-12 col-md-6 px-3">
                                    <label for="birth_day_demandeur" class="form-label fs-smaller">
                                        *Date de naissance
                                    </label>
                                    <input
                                        autoComplete="off"
                                        type="date"
                                        class="form-control form-input-droit"
                                        id="birth_day_demandeur"
                                        name="birth_day_demandeur"
                                        value={formDemandeur.birth_day_demandeur}
                                        required
                                        onChange={handleChangeDemandeur}
                                    />
                                </div>
                                <div class="mb-3 col-12 col-md-6 px-3">
                                    <label for="birth_place_demandeur" class="form-label fs-smaller">
                                        *Lieu de naissance
                                    </label>
                                    <input
                                        autoComplete="off"
                                        type="text"
                                        class="form-control form-input-droit"
                                        id="birth_place_demandeur"
                                        name="birth_place_demandeur"
                                        value={formDemandeur.birth_place_demandeur}
                                        required
                                        onChange={handleChangeDemandeur}
                                    />
                                </div>

                                <div class="mb-3 col-12 col-md-6 px-3">
                                    <label for="address_demandeur" class="form-label fs-smaller">
                                        Votre adresse géographique (Abidjan, Cocody, II Plateaux par exemple)
                                    </label>
                                    <input
                                        autoComplete="off"
                                        type="text"
                                        class="form-control form-input-droit"
                                        id="address_demandeur"
                                        name="address_demandeur"
                                        value={formDemandeur.address_demandeur}
                                        required
                                        onChange={handleChangeDemandeur}
                                    />
                                </div>
                                <div class="mb-3 col-12 col-md-6 px-3">
                                    <label for="email_demandeur" class="form-label fs-smaller">
                                        *Email
                                    </label>
                                    <input
                                        autoComplete="off"
                                        type="email"
                                        class="form-control form-input-droit"
                                        id="email_demandeur"
                                        name="email_demandeur"
                                        value={formDemandeur.email_demandeur}
                                        required
                                        onChange={handleChangeDemandeur}
                                    />
                                </div>
                                <div class="mb-3 col-12 col-md-6 px-3">
                                    <label for="phone" class="form-label fs-smaller">
                                        *Téléphone(de préférence Whatsapp)
                                    </label>
                                    <PhoneInput
                                        style={{ backgroundColor: 'white' }}
                                        defaultCountry="CI"
                                        type="tel"
                                        class="form-control form-input-droit"
                                        id="phone"
                                        name="phone"
                                        value={phone2}
                                        rules={{ required: true }}
                                        placeholder="+xxxxxxxx"
                                        onChange={setPhone2}
                                    />
                                </div>
                                {/* <div class="mb-3 col-12 col-md-6 px-3">
                                    <label for="whatsapp" class="form-label fs-smaller">
                                        *Whatsapp
                                    </label>
                                    <PhoneInput
                                        type="tel"
                                        defaultCountry="CI"
                                        class="form-control form-input-droit"
                                        id="whatsapp"
                                        name="whatsapp"
                                        value={whatsapp2}
                                        placeholder="+xxxxxxxx"
                                        rules={{ required: true }}
                                        onChange={setWhatsapp2}
                                    />
                                </div> */}
                                <div class="form-check fs-5">
                                    <input
                                        autoComplete="off"
                                        class="form-check-input"
                                        type="radio"
                                        name="send_email_demandeur"
                                        id="send_email_demandeur"
                                        value="send_email_demandeur"
                                        checked={formDemandeur.send_email_demandeur == "send_email_demandeur" ? true : false}
                                        onChange={handleChangeDemandeur}
                                    />
                                    <label class="form-check-label fs-5" for="send_email_demandeur">
                                        En cochant cette case, vous consentez à recevoir des emails de la part RDV-Etat-Civil et ses partenaires techniques concernant cette présente demande. Vous pouvez vous désabonner à tout moment.
                                    </label>
                                </div>
                                {/* <div class="mb-3 col-12 col-md-6 px-3">
                                    <label for="age" class="form-label fs-smaller">
                                        *Majeur - Mineur
                                    </label>
                                    <div className="d-flex gap-3 align-items-center">
                                        <div class="form-check d-flex gap-2 align-items-center">
                                            <input
                                                autoComplete="off"
                                                class="form-check-input"
                                                type="radio"
                                                name="age"
                                                id="majeur"
                                                value="majeur"
                                                required
                                            />
                                            <label class="form-check-label" for="age">
                                                Majeur
                                            </label>
                                        </div>
                                        <div class="form-check d-flex gap-2 align-items-center">
                                            <input
                                                autoComplete="off"
                                                class="form-check-input"
                                                type="radio"
                                                name="age"
                                                id="mineur"
                                                value="mineur"
                                                required
                                            />
                                            <label class="form-check-label" for="mineur">
                                                Mineur
                                            </label>
                                        </div>
                                        <div class="form-check d-flex gap-2 align-items-center">
                                            <input
                                                autoComplete="off"
                                                class="form-check-input"
                                                type="radio"
                                                name="age"
                                                id="mineur_accompagné"
                                                value="mineur_accompagné"
                                                required
                                            />
                                            <label class="form-check-label" for="mineur_accompagné">
                                                Mineur accompagné par une association
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div class="mb-3 col-12 col-md-6 px-3">
                                    <label for="demandeur" class="form-label fs-smaller">
                                        *Qui sera le demandeur
                                    </label>
                                    <div className="d-flex gap-3 align-items-center">
                                        <div class="form-check d-flex gap-2 align-items-center">
                                            <input
                                                autoComplete="off"
                                                class="form-check-input"
                                                type="radio"
                                                name="demandeur"
                                                id="person"
                                                value="person"
                                                required
                                            />
                                            <label class="form-check-label" for="demandeur">
                                                L'acte sera établi en son nom
                                            </label>
                                        </div>
                                        <div class="form-check d-flex gap-2 align-items-center">
                                            <input
                                                autoComplete="off"
                                                class="form-check-input"
                                                type="radio"
                                                name="demandeur"
                                                id="otherPerson"
                                                value="otherPerson"
                                                required
                                            />
                                            <label class="form-check-label" for="otherPerson">
                                                L'acte sera établi pour une autre personne
                                            </label>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </div>

                        <div className="col-12">
                            <div className="d-flex justify-content-center align-items-center px-3">
                                <div className="bande col"></div>
                                <div className="col bande-title text-center pb-2 fs-3">
                                    Informations du bénéficiaire
                                </div>
                                <div className="bande col"></div>
                            </div>
                            <div className="d-flex flex-wrap ">
                                <div class="mb-3 col-12 col-md-6 px-3">
                                    <label for="lastname" class="form-label fs-smaller">
                                        *Nom
                                    </label>
                                    <input
                                        autoComplete="off"
                                        type="text"
                                        class="form-control form-input-droit"
                                        id="lastname"
                                        name="lastname"
                                        value={formAutreBenef.firstname}
                                        required
                                        onChange={handleChangeBenef}
                                    />
                                </div>
                                <div class="mb-3 col-12 col-md-6 px-3">
                                    <label for="firstname" class="form-label fs-smaller">
                                        *Prénom (s)
                                    </label>
                                    <input
                                        autoComplete="off"
                                        type="text"
                                        class="form-control form-input-droit"
                                        id="firstname"
                                        name="firstname"
                                        value={formAutreBenef.lastname}
                                        required
                                        onChange={handleChangeBenef}
                                    />
                                </div>
                                <div class="mb-3 col-12 col-md-6 px-3">
                                    <label for="birth_day" class="form-label fs-smaller">
                                        *Date de naissance
                                    </label>
                                    <input
                                        autoComplete="off"
                                        type="date"
                                        class="form-control form-input-droit"
                                        id="birth_day"
                                        name="birth_day"
                                        value={formAutreBenef.birth_day}
                                        required
                                        onChange={handleChangeBenef}
                                    />
                                </div>
                                <div class="mb-3 col-12 col-md-6 px-3">
                                    <label for="birth_place" class="form-label fs-smaller">
                                        *Lieu de naissance
                                    </label>
                                    <input
                                        autoComplete="off"
                                        type="text"
                                        class="form-control form-input-droit"
                                        id="birth_place"
                                        name="birth_place"
                                        value={formAutreBenef.birth_place}
                                        required
                                        onChange={handleChangeBenef}
                                    />
                                </div>

                                <div class="mb-3 col-12 col-md-6 px-3">
                                    <label for="address" class="form-label fs-smaller">
                                        *Votre adresse géographique (Abidjan, Cocody, II Plateaux par exemple)
                                    </label>
                                    <input
                                        autoComplete="off"
                                        type="text"
                                        class="form-control form-input-droit"
                                        id="address"
                                        name="address"
                                        value={formAutreBenef.address}
                                        required
                                        onChange={handleChangeBenef}
                                    />
                                </div>
                                <div class="mb-3 col-12 col-md-6 px-3">
                                    <label for="email" class="form-label fs-smaller">
                                        *Email
                                    </label>
                                    <input
                                        autoComplete="off"
                                        type="email"
                                        class="form-control form-input-droit"
                                        id="email"
                                        name="email"
                                        value={formAutreBenef.email}
                                        required
                                        onChange={handleChangeBenef}
                                    />
                                </div>

                                <div class="mb-3 col-12 col-md-6 px-3">
                                    <label for="phone" class="form-label fs-smaller">
                                        *Téléphone(de préférence Whatsapp)
                                    </label>
                                    <PhoneInput
                                        style={{ backgroundColor: 'white' }}
                                        defaultCountry="CI"
                                        type="tel"
                                        class="form-control form-input-droit"
                                        id="phone"
                                        name="phone"
                                        value={phone3}
                                        rules={{ required: true }}
                                        placeholder="+xxxxxxxx"
                                        onChange={setPhone3}
                                    />
                                </div>

                                {formAutreBenef.pieceDemande === "numeroActe" && (
                                    <div class="mb-3 col-12 col-md-6 px-3">
                                        <label for="birth_place" class="form-label fs-smaller">
                                            *Numéro de l'acte
                                        </label>
                                        <input
                                            autoComplete="off"
                                            type="text"
                                            class="form-control form-input-droit"
                                            id="numeroActe"
                                            name="numeroActe"
                                            value={formAutreBenef.numeroActe}
                                            required
                                            onChange={handleChangeBenef}
                                        />
                                    </div>
                                )}

                                {formAutreBenef.pieceDemande === "InfoParents" && (
                                    <>
                                        <div class="mb-3 col-12 col-md-6 px-3">
                                            <label for="father_name" class="form-label fs-smaller">
                                                *Nom & Prenoms du père
                                            </label>
                                            <input
                                                autoComplete="off"
                                                type="text"
                                                class="form-control form-input-droit"
                                                id="father_name"
                                                name="father_name"
                                                value={formAutreBenef.father_name}
                                                required
                                                onChange={handleChangeBenef}
                                            />
                                        </div>

                                        <div class="mb-3 col-12 col-md-6 px-3">
                                            <label for="date_naissance_father" class="form-label fs-smaller">
                                                *Date de naissance du père
                                            </label>
                                            <input
                                                autoComplete="off"
                                                type="date"
                                                class="form-control form-input-droit"
                                                id="date_naissance_father"
                                                name="date_naissance_father"
                                                value={formAutreBenef.date_naissance_father}
                                                required
                                                onChange={handleChangeBenef}
                                            />
                                        </div>

                                        <div class="mb-3 col-12 col-md-6 px-3">
                                            <label for="mother_name" class="form-label fs-smaller">
                                                *Nom & Prenoms de la mère
                                            </label>
                                            <input
                                                autoComplete="off"
                                                type="text"
                                                class="form-control form-input-droit"
                                                id="mother_name"
                                                name="mother_name"
                                                value={formAutreBenef.mother_name}
                                                required
                                                onChange={handleChangeBenef}
                                            />
                                        </div>

                                        <div class="mb-3 col-12 col-md-6 px-3">
                                            <label for="date_naissance_mother" class="form-label fs-smaller">
                                                *Date de naissance de la mère
                                            </label>
                                            <input
                                                autoComplete="off"
                                                type="date"
                                                class="form-control form-input-droit"
                                                id="date_naissance_mother"
                                                name="date_naissance_mother"
                                                value={formAutreBenef.date_naissance_mother}
                                                required
                                                onChange={handleChangeBenef}
                                            />
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                ) : (
                    ""
                )}
            </form>
        </div>
    )
}

export default Step3