import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { MDBDataTable } from "mdbreact";
import { checkAgents } from "../../Url";

import "@fortawesome/fontawesome-free/css/all.min.css";
import "mdbreact/dist/css/mdb.css";
import '../../style/sesame/index.css';
import '../../style/sesame/listAndTable.css';
import '../../style/sesame/sesame.css';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';

const DatatableAgents = () => {
    const navigate = useNavigate();
    let storedToken = window.localStorage.getItem("token");
    const location = useLocation();
    const [load, setLoad] = useState(true); //Set for loading state while axios is retrieving data from display
    const handleDetailClick = (email) => {
        navigate(`/dashboard/details-declaration/${email}`);
    };

    const [agentsGlobal, setAgentsGlobal] = useState([]); //Global retrieve of all procedures of users demandes
    const [users, setUsers] = useState([]); //This is the main User we gonna use for toggling if pathname is historique-demandes or not

    const user = JSON.parse(window?.localStorage.getItem("userData") ?? "{}");

    useEffect(() => {
        if (!user?.id) {
            navigate('/login')
        }
    }, [])

    useEffect(() => {
        const getagents = () => {
            axios
                .post(`${checkAgents}`, { commune: user?.commune })
                .then((response) => {
                    console.log("axios post for get agents", response.data);
                    setAgentsGlobal(response.data)
                    setLoad(false)
                })
                .catch((err) => {
                    console.log(err);
                    setLoad(false)
                });
        }

        getagents()
    }, [])

    //Remove agents who already pays
    useEffect(() => {
        setUsers(agentsGlobal.filter(
            element => element.status_payment != "PAID" && element.declaration_status == "NEW"
        ))
    }, [agentsGlobal, location.pathname])

    const datas = {
        columns: [
            {
                label: "Matricule",
                field: "mat",
                sort: "asc",
                width: 200,
            },
            {
                label: "Nom-Agent",
                field: "firstname_agent",
                sort: "asc",
                width: 150,
            },
            {
                label: "Prenom-Agent",
                field: "lastname_agent",
                sort: "asc",
                width: 270,
            },
            {
                label: "Status",
                field: "status_connexion",
                sort: "asc",
                width: 200,
            },
            {
                label: "Poste",
                field: "poste_agent",
                sort: "asc",
                width: 200,
            },
            {
                label: "Commune en charge",
                field: "commune",
                sort: "asc",
                width: 200,
            },
            {
                label: "Actions",
                field: "actions",
                width: 100,
            },
        ],
        rows: [
            /*{
              firstname: "Consulat",
              lastname: "Admin",
              phone: "+2250555475465",
              email: "admin-fr@gmail.com",
              numero: "6534984337",
              id: "01",
              type: "Information"
            },
            {
              firstname: "Consulat",
              lastname: "Root",
              phone: "+2250555475465",
              email: "root-fr@gmail.com",
              numero: "6534984337",
              id: "02",
              type: "Formation"
            }*/
        ],
    }

    const viewConnexion = (log) => {
        if(log === 0){
            return "Non connecté"
        } else {
            return "Connecté"
        }
    } 

    const [data, setData] = useState(datas);

    useEffect(() => {
        if (agentsGlobal?.length > 0) {

            const newData = [];
            for (const agent of agentsGlobal) {
                newData.push({
                    mat: agent.agent_matricule,
                    firstname_agent: agent.agent_firstname,
                    lastname_agent: agent.agent_lastname,
                    status_connexion: viewConnexion(agent.agent_status_connexion),
                    poste_agent: agent.agent_poste,
                    commune: agent.commune,
                })
            }
            console.log("data as", data)
            setData({ columns: data.columns, rows: newData })
        }
    }, [users])

    const rows = data.rows.map((rowData, index) => {
        return {
            ...rowData,
            actions: (
                <button
                    className="btn-details px-3 py-1 shadow d-flex justify-content-center"
                    onClick={() => handleDetailClick(rowData.numero)}
                    disabled={rowData.numero ? false : true}
                >
                    Detail
                </button>
            ),
            id: index,
        };
    });

    return (
        <>
            <div className="text-center">
                <ButtonGroup aria-label="Basic example">
                    <Button variant="success" onClick={() => navigate(`/dashboard/${user?.commune}/agents/add_agent`)}>Ajouter un agent</Button>
                    <Button variant="primary" onClick={() => navigate(`/dashboard/${user?.commune}/agents/update_agent`)}>Modifier un agent</Button>
                    <Button variant="danger" onClick={() => navigate(`/dashboard/${user?.commune}/agents/delete_agents`)}>Suppprimer un agent</Button>
                </ButtonGroup>
            </div>
            {agentsGlobal?.length > 0 &&
                (<>
                    <div className="scroll-x">
                        <MDBDataTable striped bordered small data={{ ...data, rows }} />
                    </div>
                </>)}


            {agentsGlobal?.length < 1 &&
                <div>
                    {load === true ? "Chargement..." : "Aucune procédure enregistrée par ici."}
                </div>}
        </>
    );
};

export default DatatableAgents;
