import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import { Autocomplete, TextField } from "@mui/material";
import { useEffect, useState } from 'react';
import { registerAgent } from '../../Url';
import { useNavigate } from 'react-router-dom';
import axios from 'axios'

const AddAgent = () => {
    const postes = [{ name: "Directeur Declaration" }, { name: "Directeur Etat Civil" }, { name: "Agent" }]
    const sexes = [{ name: "masculin" }, { name: "feminin" }]
    const navigate = useNavigate();

    const [viewPassword, setViewPassword] = useState("password")
    const [selectedPoste, setSelectedPoste] = useState("")
    const [selectedSexe, setSelectedSexe] = useState("")

    const user = JSON.parse(window?.localStorage.getItem("userData") ?? "{}");
    // console.log("user addagent",user)
    const [phone11, setPhone11] = useState("")
    const [phone1, setPhone1] = useState("")

    const [form, setForm] = useState({
        agent_firstname: "",
        agent_lastname: "",
        agent_poste: "",
        country : user?.country,
        commune: user?.commune,
        agent_phone: "",
        agent_email: "",
        agent_sex: "",
        agent_password: "",

    })

    useEffect(() => {
        if (phone1) {
            if (isValidPhoneNumber(phone1)) {
                setPhone11(phone1)
                setForm({ ...form, agent_phone: phone1 })
            } else {
                setPhone11('')
                setForm({ ...form, agent_phone: "" })
                console.log(form, "phone1")
            }

        }
    }, [phone1])

    function handleViewPassword() {
        if (viewPassword === "text") {
            setViewPassword("password")
        } else {
            setViewPassword("text")
        }
    }

    const handleUpdatePoste = (event, value) => {
        setSelectedPoste(value)
        console.log(value);
        setForm({ ...form, agent_poste: value });
    }

    const handleUpdateSexe = (event, value) => {
        setSelectedSexe(value)
        console.log(value);
        setForm({ ...form, agent_sex: value });
    }

    useEffect(() => {
        console.log("form addagent",form)
    }, [selectedPoste, selectedSexe])

    const handleSubmit = (e) => {
        e.preventDefault()
        axios.post(registerAgent, form)
            .then(response => {
                navigate('/dashboard/list-des-nouvelles-demandes')
            })
            .catch(error => console.log(error));
    }

    return (
        <>
            <h2>Formulaire d'ajout d'un agent</h2>
            <Form>
                <Form.Group className="mb-3 row" controlId="formBasicEmail">
                    <div className='col'>
                        <Form.Label>Nom de l'agent</Form.Label>
                        <Form.Control onChange={(e) => setForm({ ...form, agent_firstname: e.target.value })} type="text" placeholder="Entrez le nom de l'agent" />
                    </div>

                    <div className='col'>
                        <Form.Label>Prénom de l'agent</Form.Label>
                        <Form.Control onChange={(e) => setForm({ ...form, agent_lastname: e.target.value })} type="text" placeholder="Entrez le prenom de l'agent" />
                    </div>
                </Form.Group>

                <Form.Group className="mb-3 row" controlId="formBasicEmail">
                    <div className='col'>
                        <Form.Label>Poste de l'agent</Form.Label>
                        <Autocomplete
                            className="custom-bg w-100 custom-font"
                            id="combo-box-demo"
                            getOptionLabel={(option) => option.name}
                            options={postes}
                            onChange={(event, value) => { if (value === null) { handleUpdatePoste(event, "") } else { handleUpdatePoste(event, value.name) } }}
                            sx={{ width: 300, marginTop: 0 }}
                            renderInput={(params) => <TextField  {...params} label="Entrez le poste de l'agent" />} />

                    </div>
                    <div className='col'>
                        <Form.Label>Sexe de l'agent</Form.Label>
                        <Autocomplete
                            className="custom-bg w-100 custom-font"
                            id="combo-box-demo"
                            getOptionLabel={(option) => option.name}
                            options={sexes}
                            onChange={(event, value) => { if (value === null) { handleUpdateSexe(event, "") } else { handleUpdateSexe(event, value.name) } }}
                            sx={{ width: 300, marginTop: 0 }}
                            renderInput={(params) => <TextField  {...params} label="Entrez le sexe de l'agent" />} />
                    </div>
                </Form.Group>

                <Form.Group className="mb-3 row" controlId="formBasicEmail">
                    <div className='col'>
                        <Form.Label>Mail de l'agent</Form.Label>
                        <Form.Control type="email" onChange={(e) => setForm({...form, agent_email : e.target.value})} placeholder="Entrez le mail de l'agent" />
                    </div>

                    <div className='col'>
                        <Form.Label>Numéro joignable de l'agent</Form.Label>
                        <PhoneInput
                            // style={{ backgroundColor: 'white' }}
                            defaultCountry="CI"
                            type="tel"
                            class="form-control form-input-droit"
                            id="phone_agent"
                            name="phone_agent"
                            value={phone1}
                            rules={{ required: true }}
                            placeholder="+xxxxxxxx"
                            onChange={setPhone1}
                        />
                    </div>
                </Form.Group>

                <Form.Group className="row" controlId="formBasicEmail">
                    <div className='col'>
                        <Form.Label>Mot de passe de l'agent</Form.Label>
                        <Form.Control type={viewPassword} onChange={(e) => setForm({...form, agent_password : e.target.value})} placeholder="Entrez le mot de passe de l'agent" />
                    </div>
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicCheckbox">
                    <Form.Check type="checkbox" onClick={() => handleViewPassword()} label="Afficher le mot de passe" />
                </Form.Group>
                <Button variant="success" type="button" onClick={(e) => handleSubmit(e)}>
                    Cliquez pour enregistrer l'agent
                </Button>
            </Form>
        </>
    )
}

export default AddAgent;