import React from "react";

const MenuActesCivil = () => {
  return (
    <div className="">
      <h2 className="text-center py-5">LISTE DES ACTES D'ETAT CIVIL ET ACTE DE JUSTICE</h2>

      <div className="d-flex align-items-center justify-content-center">
        <div className="col-10 border rounded p-4 mb-3">
          <div className="fw-lighter mb-3">
            <div className="process">
              <h3 className="text-center py-3">
                ETAT CIVIL
              </h3>
              <ol>

                <li>
                  ETAT CIVIL / TRANSCRIPTIONS <br />
                  <span className="fst-italic fw-lighter text-success">
                    Délai moyen (01 mois)
                  </span>
                  <ul className="list-typeActe">
                    <li>Actes de naissance</li>
                    <li> Actes de mariage et avis de mention</li>
                    <li>Actes de reconnaissance et avis de mention</li>
                    <li>Actes de décès et avis de mention.</li>
                  </ul>
                </li>
                <li>DEMANDE DE RECONNAISSANCE D'ENFANT</li>
                <li>DEMANDE DE CELEBRATION DE MARIAGE</li>
                <li>
                  PUBLICATION DE BAN / AVIS DE NON OPPOSITION <br />
                  <span className="fst-italic fw-lighter text-success">
                    Délai moyen (01 semaine)
                  </span>
                </li>
              </ol>
            </div>
            <div className="process">
              <h3 className="text-center py-3">
                ACTES DE JUSTICE
              </h3>
              <ol>
                <li>EXTRAIT DE CASIER JUDICIAIRE</li>
                <li>CERTIFICAT DE NATIONNALITE</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MenuActesCivil;
