import React from "react";
import ListeActualites from "./ListeActualites";
import { useNavigate } from "react-router-dom";

function Actualites() {
  const navigate = useNavigate();

  return (
    <div className="mt-5 container-fluid" style={{ overflowX: 'hidden' }}>
      <div
        className="row gap-4 justify-content-center"
        id="1"
        onClick={(e) => {
          navigate("/details/actualites/" + e.currentTarget.id);
        }}
        style={{cursor:'pointer'}}
      >
        <div className="col-md-5">
          <img
            src="/images/acctualites-1.jpg"
            alt=""
            style={{ width: "95%" }}
          />
        </div>
        <div className="col-md-6">
          <h1>
            Diplomatie, l'ouverture prochaine du consulat général de Côte
            d'Ivoire à Paris se précise
          </h1>
          <p className="text-secondary">
            Diplomatie, l'ouverture prochaine du consulat général de Côte
            d'Ivoire à Paris se précise
          </p>
        </div>
      </div>
      <div className="row m-md-5 p-lg-5 p-md-2 p-1">
        {ListeActualites.map((actualite) => {
          return (
            <div
              className="col-lg-4 my-5"
              id={actualite.id}
              key={actualite.id}
            >
              <div className="text-center">
                <img
                  src={actualite.cheminImage}
                  alt=""
                  style={{ width: "95%" }}
                />
              </div>
              <div className="m-0">
                <p className="text-secondary text-center mb-0">
                  {actualite.source}
                </p>
                <p className="text-secondary text-center">{actualite.date}</p>
                <p className="text-secondary fs-4">{actualite.titre}</p>
              </div>
              <button
                style={{ color: "#000" }}
                className="col-md-4 col-12 btn-plus"
                onClick={() => {
                  navigate("/details/actualites/" +actualite.id);
                }}
              >
                Lire plus
              </button>
            </div>
          );
        })}
      </div>
    </div>
  );
}
export default Actualites;
