import React, { useState } from "react";
import { staticUrl, updateUserUrl, uploadUrl } from "../Url";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const EnvoiDocumentPage = () => {
  const [montant, setMontant] = useState("");
  const [piece, setPiece] = useState("");
  const [bilan, setBilan] = useState("");
  const [load, setLoad] = useState(false);
  const [pieceSrc, setPieceSrc] = useState("");
  const [bilanSrc, setBilanSrc] = useState("");
  const navigate = useNavigate();

  const storedToken = window.localStorage.getItem("token");
  const user = JSON.parse(window?.localStorage.getItem("userData") ?? "{}");

  async function handleFileSelect(file, type) {
    const name = file?.name;
    const formData = new FormData();
    formData.append("file", file);
    console.log(formData);

    axios
      .post(`${uploadUrl}/upload`, formData)
      .then(async (response) => {
        const doc_uri = response.data.replace("uploads/", "");
        if (type === "bilan") {
          setBilanSrc(doc_uri);
        }
        if (type === "piece") {
          setPieceSrc(doc_uri);
        }
      })
      .catch((error) => {
        console.log(error.message);
      });
  }

  const handleInputImageChange = (file, type) => {
    const reader = new FileReader();
    const { files } = file.target;

    if (files && files?.length !== 0) {
      reader.onload = async () => {};

      handleFileSelect(files[0], type);
      reader.readAsDataURL(files[0]);

      if (reader.result !== null) {
        if (type === "bilan") {
          setBilan(reader.result);
        } else {
          setPiece(reader.result);
        }
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const body = {
      montant,
      piece: pieceSrc,
      bilan: bilanSrc,
      email: user?.email,
    };
    console.log(body);
    axios
      .post(updateUserUrl, body, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      })
      .then((response) => {
        setLoad(true);
        alert("Document envoyé avec succès");
        navigate("/mon-profile/" + user?.email);
        console.log(response);
      })
      .catch((err) => {
        setLoad(false);
        console.log(err);
      });
    console.log(montant, piece, bilan);
  };
  return (
    <div>
      <form onSubmit={handleSubmit} class="row g-3">
        <div class="col-sm-12 col-lg-6">
          <label for="montant" class="form-label">
            Montant de financement
          </label>
          <input
            type="number"
            class="form-control border-0"
            id="montant"
            name="montant"
            value={montant}
            onChange={(e) => setMontant(e.target.value)}
            required
          />
        </div>
        <div class="col-sm-12 col-lg-6">
          <label for="bilan" class="form-label">
            Bilan financier antérieur
          </label>

          <input
            type="file"
            class=" border-0 form-control"
            id="bilan"
            name="bilan"
            // accept="image/png, image/jpeg, image/jpg, application/*"
            onChange={(event) => handleInputImageChange(event, "bilan")}
            required
          />
        </div>
        <div class="col-sm-12 col-lg-6">
          <label for="piece" class="form-label">
            Votre pièce d'identification
          </label>

          <input
            type="file"
            class=" border-0 form-control"
            id="piece"
            name="piece"
            // accept="image/png, image/jpeg, image/jpg, application/*"
            onChange={(event) => handleInputImageChange(event, "piece")}
            required
          />
        </div>
        <button type="submit" className="btn-submit btn-soumission">
          {load ? "Partientez..." : "Soumettre"}
        </button>
      </form>
    </div>
  );
};

export default EnvoiDocumentPage;
