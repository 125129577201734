import React, {useEffect, useState} from "react";
import { BsCloudSun } from "react-icons/bs";

const InfoFlash = () => {

  const lat = window.localStorage.getItem('latitude');
  const longitude = window.localStorage.getItem('longitude');

  const [lati, setLati] = useState();
  const [long, setLong] = useState();
  const [temp, setTemp] = useState({temp: "29", city: "Abidjan"});

  useEffect(() =>{

    if(!lat && !longitude){
      if ("geolocation" in navigator) {
        navigator.geolocation.getCurrentPosition(function (position) {
          console.log(position, "position")
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;
          console.log(latitude, longitude, "my position")
          setLati(latitude)
          setLong(longitude)
          window.localStorage.setItem("latitude",latitude);
          window.localStorage.setItem("longitude",longitude);
        }, function (error) {
          console.error("Error getting location:", error);
        });
      } else {
        console.log("Geolocation is not available in this browser.");
      } 
    }
    else{
      setLati(lat)
      setLong(longitude)
    }

    if((lat && longitude) || (lati && long)){

      const apiKey = "38b83a09ebeca0e8375cf73ca9436aca"; // Replace with your OpenWeatherMap API key
      const apiUrl = `https://api.openweathermap.org/data/2.5/weather?lat=${lat}&lon=${longitude}&appid=${apiKey}`;

      fetch(apiUrl)
        .then(response => response.json())
        .then(data => {
          const weatherDescription = data.weather[0].description;
          const temperature = Math.floor(data.main.temp/10);
          setTemp({temp: temperature -1, city: data.name})
        })
        .catch(error => {
          console.error("Error fetching weather data:", error);
        });
    }
    
  }, [])

  return (
    <div className="info-banner">
      <div className="d-flex align-items-center" style={{ marginLeft: "52px"}}>
        <div className="fs-6 col-lg-1 col-sm-3 text-center bg-black py-2 text-white info-flash">
          Info Flash
        </div>
        <div class="messagedefilant col">
          <div
            data-text="Désormais pour tous vos actes consulaires, prenez rendez-vous sur www.rdv-consulat.ci.
            <span> </span> Ouverture prochaine des nouveaux locaux du Consulat Général de Côte d’Ivoire à Paris.  France: Le Consul Général de Côte d'Ivoire annonce une opération spéciale d'établissement d'actes consulaires.  Le Consul Général de la Côte d’Ivoire à Paris, Issiaka Konaté échange avec Didier Leschi, Directeur Général de l’Office Français de l’Immigration et de l’Intégration."
          >
            <span>
              Payez les frais de vos demandes des actes d'états civils via la plateforme AIGLE 
            </span>
          </div>
        </div>
        <div className=" col-lg-2 col-sm-4 col-md-3 text-center py-2">
          <span className="text-whites" style={{marginRight: "12px"}}>{temp?.temp}°C</span>
          <BsCloudSun className="text-white fs-5" />
          <span className="text-whites" style={{marginLeft: "12px"}}>{temp?.city}</span>
        </div>
      </div>
    </div>

  );
};

export default InfoFlash;
