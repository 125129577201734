import React from "react";
import { FaLocationDot, FaXTwitter } from "react-icons/fa6";
import {
  FaEnvelope,
  FaFacebookF,
  FaLinkedinIn,
  FaYoutube,
  FaInstagram,
} from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const Footer = () => {

  const navigate = useNavigate()

  return (
    <div className="footer">
      <div className=" footer-div container py-4 d-flex justify-content-between flex-wrap fs-5">
        <div className="">
          <FaLocationDot className="text-white" />{" "}
          <span className="text-white">
            Abidjan, Côte d'Ivoire
          </span>
        </div>
        <div className="">
          {/* <FaEnvelope className="text-white"/>{" "} */}
          <span className="text-white">COPYRIGHT © CSN-CI (Compagnie des services numériques)</span>
        </div>
        <div className="d-flex gap-2" style={{ cursor: "pointer"}}>
          <FaYoutube className="text-white"/>
          <FaLinkedinIn className="text-white"/>
          <FaInstagram className="text-white"/>
          <FaFacebookF className="text-white"/>
          <FaXTwitter className="text-white"/>
        </div>
      </div>
    </div>
  );
};

export default Footer;
